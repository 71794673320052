import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment, { duration } from 'moment';
import { ISolutionGridInitialValues, formatsValues, messagesValues } from '../../store/constants/solution-const';
import { Log } from 'ng2-logger';
import * as Types from '../../store/types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ISolutionGridState } from '../../store/types';
import Select from 'react-select';
import DisplayEventModal from './display-event-modal';
import * as Constants from '../../store/constants/all';
import { constants } from '../../store/constants/solution-const';
import Translator from '../../services/translate-factory';
import { jsPDF } from 'jspdf';
import h2c from "html2canvas";
const T = Translator.create();
const localizer = momentLocalizer(moment);
const Logger = Log.create('SolutionGrid');

function getInitialState(): Types.ISolutionGridState {
  return Object.assign({}, ISolutionGridInitialValues);
}

class SolutionGrid extends Component<any, ISolutionGridState> {
  state: Types.ISolutionGridState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };


  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
    this.getSelectOptions();
  }


  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (!prevProps.model || (prevProps.model && prevProps.model.createDate !== (this.props.model && this.props.model.createDate))) {
      this.getSelectOptions();
    }

    if (prevProps.activeTab !== this.props.activeTab || prevProps.solutionId !== this.props.solutionId) {
      this.setState({
        ...this.state,
        filteredModel: [],
        resultModel: [],
        filters: {
          programs: [],
          classes: [],
          classrooms: [],
          instructors: [],
          invigilators: []
        },
        selected_Options: {
          programs: [],
          classes: [],
          classrooms: [],
          instructors: [],
          invigilators: []
        }
      });
    }
  }

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;
    if (props && props.examDates) {
      hasNewState = true;
      const startDate = moment(props.examDates.start_date).toDate();
      const startHour = moment(props.examDates.start_hour, 'HH:mm').format('H');
      const endHour = moment(props.examDates.end_hour, 'HH:mm').format('H');
      const min = parseInt(startHour, 10);
      const max = parseInt(endHour, 10);

      const duration_hour = moment(props.examDates.slot_duration, 'HH:mm').format('H');
      const duration_mins = moment(props.examDates.slot_duration, 'HH:mm').format('m');

      const duration_in_minutes = parseInt(duration_hour, 10) * 60 + parseInt(duration_mins, 10);
      const step = 15;
      const slotDuration = duration_in_minutes / step;

      const isScheduleCreated = props.examDates.start_date && props.examDates.start_date;

      state.scheduleData.startDate = startDate;
      state.scheduleData.min = min;
      state.scheduleData.max = max;
      state.scheduleData.slotDuration = slotDuration;
      state.scheduleData.isScheduleCreated = isScheduleCreated;
    }
    if (props.model && props.model.createDate) {
      const convertToSelectType = (arr: Array<{ item1: string; item2: number }>): Types.ISelectOption[] =>
        arr.map((piece: { item1: string; item2: number }) => ({ label: piece.item1, value: piece.item2 }));

      const programs = props.model.programs.map((item: any) => ({ label: item.item1, value: item.item2 }));
      const classes = props.model.classes.map((item: any) => ({ label: item.item1, value: item.item2 })).sort();
      const instructors = props.model.instructors.map((item: any) => ({ label: item.item1, value: item.item2 }));
      const classrooms = props.model.classrooms.map((item: any) => ({ label: item.item1, value: item.item2 }));
      const invigilators = props.model.invigilators.map((item: any) => ({ label: item.item1, value: item.item2 }));

      // const programs = convertToSelectType(props.model.programs);
      // const instructors = convertToSelectType(props.model.instructors);
      // const classrooms = convertToSelectType(props.model.classrooms);
      // const invigilators = convertToSelectType(props.model.invigilators);

      state.dataModel = props.model;
      state.initialModel = props.model.solutionExams;
      state.selectOptions = {
        ...state.filters,
        programs,
        classes,
        instructors,
        classrooms,
        invigilators
      };
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  getSelectOptions = () => {
    if (this.props.model && Object.keys(this.props.model).length) {
      const dataModel = this.props.model;
      const programs = this.convertToSelectOptionType(dataModel.programs);
      const classes = this.convertToSelectOptionType(dataModel.classes);
      const instructors = this.convertToSelectOptionType(dataModel.instructors);
      const classrooms = this.convertToSelectOptionType(dataModel.classrooms);
      const invigilators = this.convertToSelectOptionType(dataModel.invigilators);
      this.setState({
        ...this.state,
        dataModel: this.props.model,
        initialModel: this.props.model.solutionExams,
        selectOptions: {
          ...this.state.filters,
          programs,
          classes,
          instructors,
          classrooms,
          invigilators
        }
      });
    }
  };

  convertToSelectOptionType = (arr: Array<{ item1: string; item2: number }>): Types.ISelectOption[] => {
    return arr.map((piece: { item1: string; item2: number }) => ({ label: piece.item1, value: piece.item2 }));
  };

  // generates model based on applied filters.
  createSolution = () => {
    let filteredModel: any = this.state.initialModel;
    if (this.state.filters.programs.length) {
      const programs: number[] = this.state.filters.programs;
      const model = filteredModel.filter((item: any) => programs.indexOf(item.programId) !== -1);
      filteredModel = model;
    }

    if (this.state.filters.classes.length) {
      const classes: number[] = this.state.filters.classes;
      const model = filteredModel.filter((item: any) => classes.indexOf(Number(item.term)) !== -1);
      filteredModel = model;
    }

    if (this.state.filters.instructors.length) {
      const instructors: number[] = this.state.filters.instructors;
      const model = filteredModel.filter((item: any) => instructors.indexOf(item.instructorId) !== -1);
      filteredModel = model;
    }

    if (this.state.filters.classrooms.length) {
      let examIds: Array<number> = [];
      this.state.filters.classrooms.map((_id: number) => {
        this.state.dataModel.solutionClassrooms.map((item: any) => {
          if (item.classroomId === _id) examIds.push(item.examId);
        });
      });

      const model = filteredModel.filter((item: any) => examIds.indexOf(item.examId) !== -1);
      filteredModel = model;
    }

    if (this.state.filters.invigilators.length) {
      let examIds: Array<number> = [];
      this.state.filters.invigilators.map((_id: number) => {
        this.state.dataModel.solutionInvigilators.map((item: any) => {
          if (item.instructorId === _id) examIds.push(item.examId);
        });
      });

      const ownExams = filteredModel.filter(
        (item: any) => this.state.filters.invigilators.indexOf(item.instructorId) !== -1
      );

      const model = filteredModel.filter((item: any) => examIds.indexOf(item.examId) !== -1);

      const customModel = model.map((exam: any) => ({ ...exam, type: 'invigilator' }));
      filteredModel = [...ownExams, ...customModel];
    }

    if (
      !this.state.filters.programs.length &&
      !this.state.filters.classes.length &&
      !this.state.filters.instructors.length &&
      !this.state.filters.classrooms.length &&
      !this.state.filters.invigilators.length
    ) {
      filteredModel = [];
    }


    this.createDataToComponent(filteredModel);
  };

  // converts model type to the component data type
  createDataToComponent = (filteredModel: Array<any>) => {
    const resultModel = filteredModel.map((exam: any) => {
      const slotDuration = this.props.examDates.slot_duration.split(':');
      const durationMinutes = slotDuration[0] * 60 + parseInt(slotDuration[1], 10);
      const startDate = moment(exam.startDate);
      const endDate = startDate.clone().add(durationMinutes * exam.examDuration, 'm');
      let classroomsArray: any = [];

      const classrooms = this.state.dataModel.solutionClassrooms.reduce((result: any, item: any) => {
        if (item.examId === exam.examId && item.subExamId === exam.subExamId)
          result.push({ id: item.classroomId, code: item.classroomCode, studentCount: item.classroomStudentCount, buildingName: item.buildingName });
        return result;
      }, []);

      const invigilators = this.state.dataModel.solutionInvigilators.reduce((result: any, item: any) => {
        if (item.examId === exam.examId && item.subExamId === exam.subExamId)
          result.push({ id: item.instructorId, code: item.invigilatorCode, classroomCode: item.classroomCode });
        return result;
      }, []);

      classrooms.map((classroom: { id: number; code: string; studentCount: number; buildingName: string; }) => {
        var invis = invigilators.filter(
          (invigilator: { id: number; code: string; classroomCode: string }) =>
            classroom.code === invigilator.classroomCode
        );
        classroomsArray.push({ ...classroom, invigilators: invis });
      });

      const examInfo = {
        id: exam.examId,
        code: exam.courseCode,
        name: exam.courseName,
        date: moment(exam.startDate).format('DD MMMM YYYY dddd, HH:mm'),
        campus: exam.campusCode,
        faculty: exam.facultyName,
        duration: durationMinutes * exam.examDuration,
        instructor: exam.title + " " + exam.instructorName,
        building: classroomsArray[0] && classroomsArray[0].buildingName,
        program: exam.programName,
        studentCount: exam.studentCount,
        classrooms: classroomsArray,
        invigilators
      };

      return {
        id: exam.examId,
        title: '• ' + exam.courseCode + ' - ' + exam.courseName + ' - ' + exam.programName + ' - ' + exam.term + ' - ' + exam.branch + "\n"
          + '• ' + exam.title + ' ' + exam.instructorName + "\n",
        start: startDate.toDate(),
        end: endDate.toDate(),
        desc: JSON.stringify(examInfo),
        type: exam.type ? exam.type : ''
      };
    });

    this.setState({
      ...this.state,
      filteredModel,
      resultModel
    });
  };

  printDocument() {
    const input = document.getElementById('printed-component');
    h2c(input!)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png', 1.0);
        const pdf = new jsPDF("l", "mm", "a4");
        var doc = new jsPDF("l", "mm", "a4");
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        pdf.addImage(imgData, 'PNG', 15, 3, 265, 200, '', 'MEDIUM', 0);
        pdf.save("Çozüm.pdf");
      })
      ;
  }

  render() {
    return (
      <React.Fragment>
        <div id="printed-component">
          <div className="row solution-row">
            {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
              this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS ||
              this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS_INSTRUCTORS ||
              this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS_EX_INVIGILATORS) && (
                <div className="col-md-3 col-ml-3">
                  <div className="add-custom-tag mb-3">
                    <div className="react-select-container">
                      <label>{T.t('gen_program_tables')}</label>
                      <Select
                        className="react-select"
                        isMulti={true}
                        closeMenuOnSelect={false}
                        options={this.state.selectOptions.programs}
                        placeholder={T.t('gen_select_program')}
                        onChange={(options: any) => {
                          const ids =
                            options && options.length ? options.map((option: Types.ISelectOption) => option.value) : [];
                          this.setState(
                            {
                              ...this.state,
                              filters: {
                                ...this.state.filters,
                                programs: ids
                              },
                              selected_Options: {
                                ...this.state.selected_Options,
                                programs: options
                              }
                            },
                            () => this.createSolution()
                          );
                        }}
                        value={this.state.selected_Options.programs}
                        noOptionsMessage={(): string => T.t('gen_select_no_program')}
                      />
                    </div>
                  </div>
                </div>
              )}
            {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
              this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS_INSTRUCTORS ||
              this.props.activeTab === constants.SOLUTION_GET_BY_INSTRUCTORS) && (
                <div className="col-md-3 col-ml-3">
                  <div className="add-custom-tag mb-3">
                    <div className="react-select-container">
                      <label>{T.t('gen_instructor_tables')}</label>
                      <Select
                        className="react-select"
                        isMulti={true}
                        closeMenuOnSelect={false}
                        options={this.state.selectOptions.instructors}
                        placeholder={T.t('gen_select_instructor')}
                        onChange={(options: any) => {
                          const ids =
                            options && options.length ? options.map((option: Types.ISelectOption) => option.value) : [];
                          this.setState(
                            {
                              ...this.state,
                              filters: {
                                ...this.state.filters,
                                instructors: ids
                              },
                              selected_Options: {
                                ...this.state.selected_Options,
                                instructors: options
                              }
                            },
                            () => this.createSolution()
                          );
                        }}
                        value={this.state.selected_Options.instructors}
                        noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                      />
                    </div>
                  </div>
                </div>
              )}
            {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
              this.props.activeTab === constants.SOLUTION_GET_BY_INVIGILATORS) && (
                <div className="col-md-3">
                  <div className="add-custom-tag mb-3">
                    <div className="react-select-container">
                      <label>{T.t('gen_invigilator_tables')}</label>
                      <Select
                        className="react-select"
                        isMulti={true}
                        closeMenuOnSelect={false}
                        options={this.state.selectOptions.invigilators}
                        placeholder={T.t('gen_select_instructor')}
                        onChange={(options: any) => {
                          const ids =
                            options && options.length ? options.map((option: Types.ISelectOption) => option.value) : [];
                          this.setState(
                            {
                              ...this.state,
                              filters: {
                                ...this.state.filters,
                                invigilators: ids
                              },
                              selected_Options: {
                                ...this.state.selected_Options,
                                invigilators: options
                              }
                            },
                            () => this.createSolution()
                          );
                        }}
                        value={this.state.selected_Options.invigilators}
                        noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                      />
                    </div>
                  </div>
                </div>
              )}
            {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
              this.props.activeTab === constants.SOLUTION_GET_BY_CLASSROOMS) && (
                <div className="col-ml-3">
                  <div className="add-custom-tag ml-3">
                    <div className="react-select-container">
                      <label>{T.t('gen_classroom_tables')}</label>
                      <Select
                        className="react-select"
                        isMulti={true}
                        closeMenuOnSelect={false}
                        options={this.state.selectOptions.classrooms}
                        placeholder={T.t('gen_select_classroom')}
                        onChange={(options: any) => {
                          const ids =
                            options && options.length ? options.map((option: Types.ISelectOption) => option.value) : [];
                          this.setState(
                            {
                              ...this.state,
                              filters: {
                                ...this.state.filters,
                                classrooms: ids
                              },
                              selected_Options: {
                                ...this.state.selected_Options,
                                classrooms: options
                              }
                            },
                            () => this.createSolution()
                          );
                        }}
                        value={this.state.selected_Options.classrooms}
                        noOptionsMessage={(): string => T.t('gen_select_no_classroom')}
                      />
                    </div>
                  </div>
                </div>
              )}
            {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
              this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS_INSTRUCTORS ||
              this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS ||
              this.props.activeTab === constants.SOLUTION_GET_BY_CLASSROOMS) && (
                <div className="col-ml-1">
                  <div className="add-custom-tag mb-3 ml-3 mr-3">
                    <div className="react-select-container">
                      <label>{T.t('gen_class_tables')}</label>
                      <Select
                        className="react-select"
                        isMulti={true}
                        closeMenuOnSelect={false}
                        options={this.state.selectOptions.classes}
                        placeholder={T.t('gen_select_grade')}
                        onChange={(options: any) => {
                          const ids =
                            options && options.length ? options.map((option: Types.ISelectOption) => option.value) : [];
                          this.setState(
                            {
                              ...this.state,
                              filters: {
                                ...this.state.filters,
                                classes: ids
                              },
                              selected_Options: {
                                ...this.state.selected_Options,
                                classes: options
                              }
                            },
                            () => this.createSolution()
                          );
                        }}
                        value={this.state.selected_Options.classes}
                        noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                      />
                    </div>
                  </div>
                </div>
              )}
            {this.props.activeTab == constants.SOLUTION_GET_BY_PROGRAMS ? (<div className="col-md-7 col-ml-7"></div>) : null}
            {this.props.activeTab == constants.SOLUTION_GET_BY_PROGRAMS_INSTRUCTORS ? (<div className="col-md-4 col-ml-4"></div>) : null}
            {this.props.activeTab == constants.SOLUTION_GET_BY_CLASSROOMS ? (<div className="col-md-9 col-ml-9"></div>) : null}
            <div className="col-md-11 print-button">
              <div className="add-custom-tag">
                <button className="category-tag-square tag-green" onClick={this.printDocument}>
                  {T.t('gen_print')}
                </button>
              </div>
            </div>
            {this.props.activeTab === constants.SOLUTION_GET_BY_INVIGILATORS && (
              <div
                style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                className="col-md-9 mb-2"
              >
                <div style={{ height: '20px', width: '20px', backgroundColor: '#265985', borderRadius: '3px' }} />
                <span className="ml-1 mr-2">{T.t('gen_own_exams')}</span>
                <div style={{ height: '20px', width: '20px', backgroundColor: '#f64332', borderRadius: '3px' }} />
                <span className="ml-1 mr-1">{T.t('gen_invigilatings')}</span>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} />
              </div>
            )}
          </div>
          <div className="generic-wrapper" style={{ height: '100vh' }}>
            {this.state.scheduleData.isScheduleCreated && (
              <Calendar
                events={this.state.resultModel}
                min={new Date(0, 0, 0, this.state.scheduleData.min, 0, 0)}
                max={new Date(0, 0, 0, this.state.scheduleData.max, 0, 0)}
                formats={formatsValues}
                step={this.state.scheduleData.step}
                timeslots={this.state.scheduleData.slotDuration}
                defaultDate={this.state.scheduleData.startDate}
                localizer={localizer}
                messages={messagesValues}
                onSelectEvent={(event: Object, e: React.SyntheticEvent) => {
                  this.setState({
                    ...this.state,
                    selectedEvent: event,
                    eventModalIsOpen: true
                  });
                }}
                eventPropGetter={(event, start, end, isSelected) => {
                  let newStyle = {
                    backgroundColor: '#265985',
                    color: 'white',
                    borderRadius: '4px',
                    border: 'none'
                  };
                  if (
                    event.type &&
                    event.type === 'invigilator' &&
                    this.props.activeTab === constants.SOLUTION_GET_BY_INVIGILATORS
                  ) {
                    // newStyle.backgroundColor = '#f01500';
                    newStyle.backgroundColor = '#f64332';
                  }

                  return {
                    className: '',
                    style: newStyle
                  };
                }}
              />
            )}
          </div>
          {this.state.eventModalIsOpen && (
            <DisplayEventModal
              event={this.state.selectedEvent}
              modalIsOpen={this.state.eventModalIsOpen}
              onClose={() =>
                this.setState({
                  ...this.state,
                  eventModalIsOpen: !this.state.eventModalIsOpen
                })
              }
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,

  ownProps: any
): any => {
  if (!store || !store.state) {
    return ownProps;
  }

  const newProps: any = Object.assign({}, ownProps, {
    model: store.state.solution_page && store.state.solution_page.solution,
    filters: store.state.solution_page && store.state.solution_page.filters,
    examDates: store.state.examPeriodModal && store.state.examPeriodModal.exam_dates,
    user: store.state.user
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.solution_page) {
    return (
      !!equal(
        prev.state.solution_page && prev.state.solution_page,
        next.state.solution_page && next.state.solution_page
      ) &&
      !!equal(
        prev.state.solution_page && prev.state.solution_page.solution && prev.state.solution_page.solution.solutionList,
        next.state.solution_page && next.state.solution_page.solution && next.state.solution_page.solution.solutionList
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SolutionGrid);

export default container;
