import React from 'react';
import { Log } from 'ng2-logger';
import PlainLoyout from '../layouts/plain-layout';
import imgLogo from '../../assets/logos/favicon.png';
import LoginForm from './login-form';
import Spinner from '../../components/templates/spinner';
import AppFactory from '../../services/app-factory';
import * as GT from '../../tools/general-tools';
import { routes as Routes } from '../../store/constants/routes';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import CourseSolutionModal from './course-solution-modal';
import ExamSolutionModal from './exam-solution-modal';
import TermSelectorModal from './term/term-selector-solution-modal';
import * as Types from '../../store/types';
import { connect } from 'react-redux';
import Translator from '../../services/translate-factory';

const L = Log.create('LoginPage');
const AF = AppFactory.create();
const T = Translator.create();

class LoginPage extends React.Component<Types.ILoginPageProps, Types.ILoginPageState> {
  state: Types.ILoginPageState = {
    activeArea: 'login',
    loginForm: {
      username_loginpage: '',
      password_loginpage: '',
      account_id: ''
    },
    forgotForm: {
      email: '',
      account_id: '',
      token: '',
      password: '',
      password_confirm: '',
      usernameOrEmail: ''
    },
    termDefinationModalIsOpen: false,
    term_id: -1,
    term_type: -1,
    solution_id: -1,
    publicSolution: null
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  getPublicSolution = (term_type: number) => {
    this.props.dispatch(
      Actions.ApiRequest(
        Constants.solution.COURSE_SOLUTION_PUBLIC, term_type, 'solution-public-spinner'
      )
    );
  };
  
  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    this.state.solution_id = Number(window.location.pathname.substr(window.location.pathname.lastIndexOf("/")+1, window.location.pathname.length));
    
    if ('/login/course/' + window.location.pathname.substr(window.location.pathname.lastIndexOf("/")+1, window.location.pathname.length) == window.location.pathname) {

      this.getPublicSolution(0);

      setTimeout(() => {
        const solution = this.props.publicSolution && this.props.publicSolution.find((term: any) => term.solutionId == this.state.solution_id);
        this.state.publicSolution = solution;

        if (solution) {
          this.onTermChange(solution.termId, solution.term_type, this.state.solution_id);

          this.setState({
            ...this.state,
            termDefinationModalIsOpen: !this.state.termDefinationModalIsOpen
          });
          if (this.state.termDefinationModalIsOpen && this.state.term_type === 1) {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN, '/exam/' + this.state.solution_id)));
          } else if (this.state.termDefinationModalIsOpen && this.state.term_type === 0) {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN, '/course/' + this.state.solution_id)));
          } else {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN)));
          }
        }

      }, 400);
    }

    if ('/login/exam/' + window.location.pathname.substr(window.location.pathname.lastIndexOf("/")+1, window.location.pathname.length) == window.location.pathname) {

      this.getPublicSolution(1);

      setTimeout(() => {
        const solution = this.props.publicSolution && this.props.publicSolution.find((term: any) => term.solutionId == this.state.solution_id);
        this.state.publicSolution = solution;
  
        if (solution) {
          this.onTermChange(solution.termId, solution.term_type, this.state.solution_id);

          this.setState({
            ...this.state,
            termDefinationModalIsOpen: !this.state.termDefinationModalIsOpen
          });
          if (this.state.termDefinationModalIsOpen && this.state.term_type === 1) {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN, '/exam/' + this.state.solution_id)));
          } else if (this.state.termDefinationModalIsOpen && this.state.term_type === 0) {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN, '/course/' + this.state.solution_id)));
          } else {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN)));
          }
        }

      }, 400);
    }
  }

  componentWillMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    if (AF.hasLoggedOn()) {
      window.location.pathname = GT.Route(Routes.EXAM_PERIODS);
    }
  }

  switchTermDefinationModalStatus = (term_type: number) => {

    this.getPublicSolution(term_type);

    this.showModal();

  };

  showModal = (confirmDisabled: boolean = false, noCloseButton: boolean | undefined = undefined) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_please_select_term'),
        body: <TermSelectorModal
          publicSolution={this.props.publicSolution}
          onTermChange={this.onTermChange}
        />,
        name: 'term_select',
        icon: '',
        iconColor: 'red',
        confirm: T.t('gen_select'),
        cancel: T.t('gen_cancel'),
        onConfirm: this.onTermSelect,
        confirmDisabled: confirmDisabled,
        noCloseButton: false
      })
    );
  };

  onTermSelect = () => {
    this.setState({
      ...this.state,
      termDefinationModalIsOpen: !this.state.termDefinationModalIsOpen
    });

    if (!this.state.termDefinationModalIsOpen && this.state.term_type === 1) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN, '/exam/' + this.state.solution_id)));
    } else if (!this.state.termDefinationModalIsOpen && this.state.term_type === 0) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN, '/course/' + this.state.solution_id)));
    } else {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN)));
    }
  };

  onTermChange = (term_id: number, term_type: number, solution_id: number) => {
    if (this.props.term_id === -1 && term_id > 0) {
      this.showModal(false);
    }
    this.state.term_id = term_id;
    this.state.term_type = term_type;
    this.state.solution_id = solution_id;
    this.setState(this.state);
  };

  static getDerivedStateFromProps(props: Types.ILoginPageProps, state: Types.ILoginPageState) {
    let hasNewState: boolean = false;
    if (props.publicSolution) {
      hasNewState = true;
      state.publicSolution = props.publicSolution;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    if (AF.hasLoggedOn()) return <React.Fragment />;
    return (
      <PlainLoyout>
        <div className="login-wrapper">
          <div className="min-container">
            <div className="login-block">
              <Spinner name="login-form-spin" />
              <img className="img-fluid" src={imgLogo} alt="APlan" />
              <LoginForm />
              <div className="white-container mt-4">
                <div className="row align-items-center mb-sm-12 mb-12">
                  <div className="col-md-6 col-sm-6 col-6">
                    <button className="btn btn-info float-left" type="submit" onClick={() => this.switchTermDefinationModalStatus(1)}>
                      Sınav Programı &nbsp; <i className="material-icons">event</i>
                    </button>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <button className="btn btn-info float-right" type="submit" onClick={() => this.switchTermDefinationModalStatus(0)}>
                      Ders Programı &nbsp; <i className="material-icons">event</i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="material-icons mr-1">language</i>
                  TR
                  <i className="material-icons ml-3 arrow">unfold_more</i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="#">
                    TR
                  </a>
                  <a className="dropdown-item" href="#">
                    EN
                  </a>
                </div>
              </div>
            </div>
            <div className="d-flex login-block-footer">
              <a href="" data-toggle="modal" data-target="#coypright-modal">
                <img src={imgLogo} alt="APlan" />
                <span>2013-2021 © APlan & Advancity</span>
              </a>
            </div>
          </div>
        </div>
        {this.state.termDefinationModalIsOpen && this.state.term_type === 0 ? (
          <CourseSolutionModal
            modalIsOpen={this.state.termDefinationModalIsOpen}
            onClose={this.onTermSelect}
            publicSolution={this.state.publicSolution}
          />
        ) : null}
        {this.state.termDefinationModalIsOpen && this.state.term_type === 1 ? (
          <ExamSolutionModal
            modalIsOpen={this.state.termDefinationModalIsOpen}
            onClose={this.onTermSelect}
            publicSolution={this.state.publicSolution}
          />
        ) : null}
      </PlainLoyout>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ILoginPageProps): Types.ILoginPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }

  const newProps: any = Object.assign({}, ownProps, {
    account: store.state.account,
    definitions: store.state.definitions,
    publicSolution: store.state.solution_page && store.state.solution_page.publicSolution,
  });

  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps)(LoginPage);

export default container;