import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Log } from 'ng2-logger';
import * as Types from '../../store/types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { IInvigilationCountsState, IInvigilationCountsProps } from '../../store/types';
import { ISolutionModelInitialValues } from '../../store/constants/solution-const';
import Select from 'react-select';
import * as Constants from '../../store/constants/all';
import SortedColumn from '../../components/table/sorted-column';
import * as Icons from 'react-icons/md';

import Translator from '../../services/translate-factory';
const T = Translator.create();
const Logger = Log.create('Invigilation Numbers');

function getInitialState(): any {
  return Object.assign(
    {},
    {
      model: ISolutionModelInitialValues,
      result: {},
      totalRow: {},
      selectOptions: [],
      selected_Options: [],
      filterResult: [],
      isFilterActive: false,
      orderBy: ''
    }
  );
}
class InvigilationNumbers extends Component<IInvigilationCountsProps, any> {
  state: any = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
    this.setState({ model: this.props.model! });
    if (this.props.model && this.props.model.createDate) {
      this.createTable(this.props.model);
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  convertToSelectType = (arr: any): Types.ISelectOption[] =>
    arr.map((piece: { item1: string; item2: number }) => ({ label: piece.item1, value: piece.item2 }));

  createTable = (model: Types.ISolutionModel) => {
    //  01 - 1.öğretim
    //  02 - 2.ögretim
    const programs = this.convertToSelectType(model.programs);
    const instructors = this.convertToSelectType(model.instructors);
    const invigilators = this.convertToSelectType(model.invigilators);
    const programs01 = programs.filter((program: Types.ISelectOption) => !program.label.includes('İÖ'));
    const programs02 = programs.filter((program: Types.ISelectOption) => program.label.includes('İÖ'));

    const exams = model.solutionExams.map((item: Types.ISolutionExam) => ({
      examId: item.examId,
      instructorId: item.instructorId,
      programId: item.programId
    }));
    const exams01 = exams.filter((item: any) => programs02.findIndex((program) => program.value == item.programId) < 0);
    const exams02 = exams.filter(
      (item: any) => programs02.findIndex((program) => program.value == item.programId) >= 0
    );

    const invigs = model.solutionInvigilators.reduce(function (arr, item) {
      arr[item.instructorId] = arr[item.instructorId] || [];
      arr[item.instructorId].push(item.examId);
      return arr;
    }, Object.create(null));

    const invigsArray = Object.entries(invigs).map(([key, value]) => ({
      instructorId: key,
      exams: value
    }));

    const invigsNumbers = invigsArray.map((item: any) => {
      const invigs02 = item.exams.filter(
        (examId: number) => exams02.findIndex((item: any) => item.examId == examId) >= 0
      );
      const invigs01 = item.exams.filter(
        (examId: number) => exams02.findIndex((item: any) => item.examId == examId) < 0
      );
      return { instructorId: parseInt(item.instructorId, 10), invigs01: invigs01.length, invigs02: invigs02.length };
    });

    const instructor01obj = exams01.reduce(function (arr, item) {
      arr[item.instructorId] = arr[item.instructorId] || [];
      arr[item.instructorId].push(item.examId);
      return arr;
    }, Object.create(null));

    const instructr02obj = exams02.reduce(function (arr, item) {
      arr[item.instructorId] = arr[item.instructorId] || [];
      arr[item.instructorId].push(item.examId);
      return arr;
    }, Object.create(null));

    const instructor01arr = Object.entries(instructor01obj).map(([key, value]) => ({
      instructorId: parseInt(key, 10),
      exams: value
    }));

    const instructr02arr = Object.entries(instructr02obj).map(([key, value]) => ({
      instructorId: parseInt(key, 10),
      exams: value
    }));

    const instructor01 = instructor01arr.map((i: any) => ({ instructorId: i.instructorId, exams01: i.exams.length }));
    const instructor02 = instructr02arr.map((i: any) => ({ instructorId: i.instructorId, exams02: i.exams.length }));

    const instructorsAll = [...instructor01, ...instructor02, ...invigsNumbers];

    var b: any = {},
      arr = [];
    for (var instructorId in instructorsAll) {
      var oa: any = instructorsAll[instructorId],
        ob = b[oa.instructorId];
      if (!ob) arr.push((ob = b[oa.instructorId] = {}));
      for (var k in oa) ob[k] = k === 'instructorId' ? oa.instructorId : (ob[k] || 0) + oa[k];
    }

    const allValues = Object.entries(b).map(([key, value]) => ({
      value
    }));

    const spreaded = allValues.map((i) => i.value);
    const allInstructors = [...instructors, ...invigilators];

    this.removeDuplicates(allInstructors, 'value');

    const filteredResult = spreaded.map((item: any) => {
      const instructor = allInstructors.find((inst: Types.ISelectOption) => inst.value == item.instructorId);
      return { instructorName: instructor!.label, ...item };
    });

    const result = filteredResult.map((item: any) => {
      if (item.exams01 == undefined) {
        item.exams01 = 0;
      }
      if (item.exams02 == undefined) {
        item.exams02 = 0;
      }
      if (item.invigs01 == undefined) {
        item.invigs01 = 0;
      }
      if (item.invigs02 == undefined) {
        item.invigs02 = 0;
      }

      item.all01 = item.exams01 + item.invigs01;
      item.all02 = item.exams02 + item.invigs02;
      item.allExams = item.exams01 + item.exams02;
      item.allInvigs = item.invigs01 + item.invigs02;
      item.total = item.all01 + item.all02;
      return item;
    });

    const totalRow = {
      exams01: result.reduce((a, b) => a + (b.exams01 || 0), 0),
      exams02: result.reduce((a, b) => a + (b.exams02 || 0), 0),
      invigs01: result.reduce((a, b) => a + (b.invigs01 || 0), 0),
      invigs02: result.reduce((a, b) => a + (b.invigs02 || 0), 0),
      all01: result.reduce((a, b) => a + (b.all01 || 0), 0),
      all02: result.reduce((a, b) => a + (b.all02 || 0), 0),
      allExams: result.reduce((a, b) => a + (b.allExams || 0), 0),
      allInvigs: result.reduce((a, b) => a + (b.allInvigs || 0), 0),
      total: result.reduce((a, b) => a + (b.total || 0), 0)
    };

    this.setState({ result, totalRow });
  };

  calculateTotalNumbers = (arr: any[]) => {
    const totalRow = {
      exams01: arr.reduce((a, b) => a + (b.exams01 || 0), 0),
      exams02: arr.reduce((a, b) => a + (b.exams02 || 0), 0),
      invigs01: arr.reduce((a, b) => a + (b.invigs01 || 0), 0),
      invigs02: arr.reduce((a, b) => a + (b.invigs02 || 0), 0),
      all01: arr.reduce((a, b) => a + (b.all01 || 0), 0),
      all02: arr.reduce((a, b) => a + (b.all02 || 0), 0),
      allExams: arr.reduce((a, b) => a + (b.allExams || 0), 0),
      allInvigs: arr.reduce((a, b) => a + (b.allInvigs || 0), 0),
      total: arr.reduce((a, b) => a + (b.total || 0), 0)
    };

    return totalRow;
  };

  removeDuplicates = (originalArray: any, prop: any) => {
    var selectOptions = [];
    var lookupObject: any = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      selectOptions.push(lookupObject[i]);
    }
    this.setState({ selectOptions });
    //  return selectOptions;
  };

  // sort = (sortKey: string) => {
  //   const instructorsArray = this.state.isFilterActive ? this.state.filterResult : this.state.result;
  //   instructorsArray.sort((a: any,b: any) => b[sortKey] - a[sortKey]);
  //   this.setState({
  //     ...this.state,
  //     result: this.state.isFilterActive ? this.state.result : instructorsArray,
  //     filteredResult: this.state.isFilterActive ? instructorsArray : this.state.filteredResult,
  //   })
  // }

  sortingIcon = (sortKey: string) => {
    if (this.state.orderBy.includes(sortKey)) {
      if (this.state.orderBy.includes('asc')) return <Icons.MdKeyboardArrowDown />;
      else return <Icons.MdKeyboardArrowUp />;
    }
    return <Icons.MdSort />;
  };

  sort = (sortKey: string) => {
    let instructorsArray = this.state.isFilterActive ? this.state.filterResult : this.state.result;

    let orderBy = this.state.orderBy;
    if (orderBy.includes(sortKey)) {
      if (orderBy.includes('desc')) {
        orderBy = sortKey + '_asc';
        instructorsArray.sort((a: any, b: any) => (a[sortKey] > b[sortKey] ? 1 : b[sortKey] > a[sortKey] ? -1 : 0));
      } else {
        orderBy = sortKey + '_desc';
        instructorsArray.sort((a: any, b: any) => (a[sortKey] > b[sortKey] ? -1 : b[sortKey] > a[sortKey] ? 1 : 0));
      }
    } else {
      orderBy = sortKey + '_desc';
      instructorsArray.sort((a: any, b: any) => (a[sortKey] > b[sortKey] ? -1 : b[sortKey] > a[sortKey] ? 1 : 0));
    }
    this.setState({
      result: this.state.isFilterActive ? this.state.result : instructorsArray,
      filterResult: this.state.isFilterActive ? instructorsArray : this.state.filteredResult,
      orderBy
    });
  };

  render() {
    const totalRow = this.state.totalRow;
    const instructorsArray = this.state.isFilterActive ? this.state.filterResult : this.state.result;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6">
            <div className="add-custom-tag">
              <div className="react-select-container">
                <label>{T.t('gen_instructor_tables')} Filtrele</label>
                <Select
                  className="react-select"
                  isMulti={true}
                  closeMenuOnSelect={false}
                  options={this.state.selectOptions}
                  placeholder={T.t('gen_select_instructor')}
                  onChange={(options: any) => {
                    const filteredResult =
                      options && options.length
                        ? this.state.result.filter(
                            (item: any) => options.findIndex((option: any) => option.value == item.instructorId) >= 0
                          )
                        : this.state.filterResult;
                    const totalRow =
                      options && options.length
                        ? this.calculateTotalNumbers(filteredResult)
                        : this.calculateTotalNumbers(this.state.result);

                    this.setState({
                      ...this.state,
                      selected_Options: options,
                      isFilterActive: options && options.length ? true : false,
                      filterResult: filteredResult,
                      totalRow
                    });
                  }}
                  value={this.state.selected_Options}
                  noOptionsMessage={(): string => T.t('gen_select_no_program')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="white-container">
          <div className="row">
            <div className="col-12">
              <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                <thead>
                  <tr>
                    <th scope="col" className="text-center">
                      #{' '}
                    </th>

                    <th scope="col" className="text-center" onClick={() => this.sort('instructorName')}>
                      {T.t('gen_instructor')}&nbsp;{this.sortingIcon('instructorName')}
                    </th>
                    <th scope="col" className="text-center" onClick={() => this.sort('exams01')}>
                      {T.t('gen_exams')} (ÖÖ)&nbsp;{this.sortingIcon('exams01')}
                    </th>
                    <th scope="col" className="text-center" onClick={() => this.sort('invigs01')}>
                      {T.t('gen_invigilatings')} (ÖÖ)&nbsp;{this.sortingIcon('invigs01')}
                    </th>
                    <th scope="col" className="text-center" onClick={() => this.sort('all01')}>
                      {T.t('gen_exams')} + {T.t('gen_invigilatings')} (ÖÖ)&nbsp;{this.sortingIcon('all01')}
                    </th>
                    <th scope="col" className="text-center" onClick={() => this.sort('exams02')}>
                      {T.t('gen_exams')} (İÖ)&nbsp;{this.sortingIcon('exams02')}
                    </th>
                    <th scope="col" className="text-center" onClick={() => this.sort('invigs02')}>
                      {T.t('gen_invigilatings')} (İÖ)&nbsp;{this.sortingIcon('invigs02')}
                    </th>
                    <th scope="col" className="text-center" onClick={() => this.sort('all02')}>
                      {T.t('gen_exams')} + {T.t('gen_invigilatings')} (İÖ)&nbsp;{this.sortingIcon('all02')}
                    </th>
                    <th scope="col" className="text-center" onClick={() => this.sort('allExams')}>
                      {T.t('gen_total')} {T.t('gen_exams')}&nbsp;{this.sortingIcon('allExams')}
                    </th>
                    <th scope="col" className="text-center" onClick={() => this.sort('allInvigs')}>
                      {T.t('gen_total')} {T.t('gen_invigilatings')}&nbsp;{this.sortingIcon('allInvigs')}
                    </th>
                    <th scope="col" className="text-center" onClick={() => this.sort('total')}>
                      {T.t('gen_exams')} + {T.t('gen_invigilatings')} ( {T.t('gen_total')})&nbsp;
                      {this.sortingIcon('total')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {instructorsArray && instructorsArray.length
                    ? instructorsArray.map((item: any, index: number) => (
                        <tr key={'solution-number-' + item.instructorId} data-title={item.instructorName}>
                          <td scope="row" data-label={T.t('gen_door_number')} style={{ textAlign: 'center' }}>
                            {index + 1}
                          </td>
                          <td scope="row" data-label="Öğretim Elemanı" style={{ textAlign: 'center' }}>
                            {item.instructorName}
                          </td>
                          <td scope="row" data-label="Sınavlar (ÖÖ)" style={{ textAlign: 'center' }}>
                            {item.exams01}
                          </td>
                          <td scope="row" data-label="Gözetmenlikler (ÖÖ)" style={{ textAlign: 'center' }}>
                            {item.invigs01}
                          </td>
                          <td scope="row" data-label="Sınav + Gözetmenlik (ÖÖ)" style={{ textAlign: 'center' }}>
                            {item.all01}
                          </td>
                          <td scope="row" data-label="Sınavlar (İÖ)" style={{ textAlign: 'center' }}>
                            {item.exams02}
                          </td>
                          <td scope="row" data-label="Gözetmenlikler (İÖ)" style={{ textAlign: 'center' }}>
                            {item.invigs02}
                          </td>
                          <td scope="row" data-label="Sınav + Gözetmenlik (İÖ)" style={{ textAlign: 'center' }}>
                            {item.all02}
                          </td>
                          <td scope="row" data-label="Toplam Sınavlar" style={{ textAlign: 'center' }}>
                            {item.allExams}
                          </td>
                          <td scope="row" data-label="Toplam Gözetmenlikler" style={{ textAlign: 'center' }}>
                            {item.allInvigs}
                          </td>
                          <td scope="row" data-label="Sınav + Gözetmenlik (Toplam)" style={{ textAlign: 'center' }}>
                            {item.total}
                          </td>
                        </tr>
                      ))
                    : null}
                  <tr key={'solution-number-total'} data-title={'Toplamlar'}>
                    <td
                      scope="row"
                      data-label={T.t('gen_door_number')}
                      style={{ textAlign: 'center', fontWeight: 700 }}
                    >
                      -
                    </td>
                    <td scope="row" data-label="Öğretim Elemanı" style={{ textAlign: 'center', fontWeight: 700 }}>
                      {T.t('gen_total')}
                    </td>
                    <td scope="row" data-label="Sınavlar (ÖÖ)" style={{ textAlign: 'center', fontWeight: 700 }}>
                      {totalRow.exams01}
                    </td>
                    <td scope="row" data-label="Gözetmenlikler (ÖÖ)" style={{ textAlign: 'center', fontWeight: 700 }}>
                      {totalRow.invigs01}
                    </td>
                    <td
                      scope="row"
                      data-label="Sınav + Gözetmenlik (ÖÖ)"
                      style={{ textAlign: 'center', fontWeight: 700 }}
                    >
                      {totalRow.all01}
                    </td>
                    <td scope="row" data-label="Sınavlar (İÖ)" style={{ textAlign: 'center', fontWeight: 700 }}>
                      {totalRow.exams02}
                    </td>
                    <td scope="row" data-label="Gözetmenlikler (İÖ)" style={{ textAlign: 'center', fontWeight: 700 }}>
                      {totalRow.invigs02}
                    </td>
                    <td
                      scope="row"
                      data-label="Sınav + Gözetmenlik (İÖ)"
                      style={{ textAlign: 'center', fontWeight: 700 }}
                    >
                      {totalRow.all02}
                    </td>
                    <td scope="row" data-label="Toplam Sınavlar" style={{ textAlign: 'center', fontWeight: 700 }}>
                      {totalRow.allExams}
                    </td>
                    <td scope="row" data-label="Toplam Gözetmenlikler" style={{ textAlign: 'center', fontWeight: 700 }}>
                      {totalRow.allInvigs}
                    </td>
                    <td
                      scope="row"
                      data-label="Sınav + Gözetmenlik (Toplam)"
                      style={{ textAlign: 'center', fontWeight: 700 }}
                    >
                      {totalRow.total}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,

  ownProps: IInvigilationCountsProps
): IInvigilationCountsProps => {
  if (!store || !store.state) {
    return ownProps;
  }

  const newProps: IInvigilationCountsProps = Object.assign({}, ownProps, {
    model: store.state.solution_page && store.state.solution_page.solution
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.solution_page) {
    return !!equal(
      prev.state.solution_page && prev.state.solution_page.solution,
      next.state.solution_page && next.state.solution_page.solution
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(InvigilationNumbers);

export default container;
