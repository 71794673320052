import * as Yup from 'yup';

export const CourseFormValidation = Yup.object().shape({
  course_code: Yup.string().required('Boş bırakılamaz!').max(50, 'En fazla 50 karakter!'),
  name: Yup.string().required('Boş bırakılamaz!'),
  exam_duration: Yup.number().required("Boş bırakılamaz!"),
  student_count: Yup.number().required("Boş bırakılamaz!"),
  invigilator_gap_before_exam: Yup.number().required("Boş bırakılamaz!"),
  invigilator_gap_after_exam: Yup.number().required("Boş bırakılamaz!"),
  exam_session: Yup.number().required("Boş bırakılamaz!"),
  classroom_combine_status: Yup.number().required("Boş bırakılamaz!"),
  campus_id: Yup.number().required("Boş bırakılamaz!")
});
