import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Log } from 'ng2-logger';
import { Modal} from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import Spinner from '../../components/templates/spinner';
import SolutionGrid from '../course-period/solution/solution-calendar';
import { constants } from '../../store/constants/solution-const';

import Translator from '../../services/translate-factory';
const T = Translator.create();

const L = Log.create('CampusIntegrationModal');

function getInitialState(): any {
  const initialValues: any = {
    activeTab: constants.SOLUTION_GET_BY_PROGRAMS,
    tabManager: [
      { key: constants.SOLUTION_GET_SINGLE_TABLES, value: true },
      { key: constants.SOLUTION_GET_BY_PROGRAMS, value: false },
      { key: constants.SOLUTION_GET_BY_INSTRUCTORS, value: false },
      { key: constants.SOLUTION_GET_BY_CLASSROOMS, value: false },
      { key: constants.SOLUTION_GET_BY_INVIGILATORS, value: false },
      { key: constants.SOLUTION_GET_INVIGILATION_COUNTS, value: false }
    ]
  };
  return Object.assign({}, initialValues);
}

class CourseSolutionModal extends Component<any, any> {
  state = getInitialState();

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.searchSolution();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.props.dispatch(
      Actions.ApiRequest(Constants.solution.COURSE_SOLUTION_GET_SINGLE_TABLES, { reset: true }, 'solution-schedule-spinner')
    );
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  searchSolution() {
    this.setState({
      ...this.state,
      term_id: this.props.publicSolution[0].termId,
      filters: {
        ...this.state.filters,
        term_id: this.props.publicSolution[0].termId
      }
    });

    let model: Types.IFilterSolutionModel = {
      term_id: this.props.publicSolution[0].termId,
      active_tab: '0',
      page: 1,
      total: -1,
      size: 10,
      solutionId: 0
    };

    this.props.dispatch(
      Actions.ApiRequest(
        Constants.exam_period.EXAM_PERIOD_GET_PERIOD_DATES,
        this.props.publicSolution[0].termId,
        'exam-period-modal-tab-spin'
      )
    );

    this.props.dispatch(
      Actions.ApiRequest(Constants.solution.COURSE_SOLUTION_GET_SINGLE_TABLES, model, 'solution-schedule-spinner')
    );

  }

  selectActiveTab = (tabName: string) => {
    let tabs =
      this.state.tabManager &&
      this.state.tabManager.map((tab: any) => {
        return tab.key == tabName ? { key: tab.key, value: true } : { key: tab.key, value: false };
      });

    let activeTab = tabs && tabs.find((item: any) => item.value == true);
    let tabConstant = activeTab && activeTab.key;

    this.setState({
      tab_reloaded: true,
      tabManager: tabs,
      activeTab: tabConstant
    });
  };

  isActiveTab = (tabName: string) => {
    let activeTab = this.state.tabManager && this.state.tabManager.find((item: any) => item.key == tabName);
    if (activeTab) {
      return activeTab.value;
    } else return false;
  };

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.integration.GET_INTEGRATION, { reset: true }, 'integration-spinner')
      );
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {this.props.publicSolution[0].institutionName}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <Spinner name="solution-schedule-spinner" />
            <div className="main editor-screen-main" style={{ display: 'block' }}>
              <div
                className="white-container mt-4 editor-screen collapse editor-screen"
                style={{ display: 'block', minHeight: '100vh' }}
              >
                <div className="row">
                  <Spinner name="exam-period-modal-tab-spin" />
                  <div className="col-12">
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className={
                          this.isActiveTab(Constants.solution.SOLUTION_GET_INVIGILATION_COUNTS)
                            ? 'tab-pane fade'
                            : 'tab-pane fade show active'
                        }
                        id="singleTables"
                        role="tabpanel"
                        aria-labelledby="singleTables-tab"
                      >
                        <SolutionGrid activeTab={this.state.activeTab} solutionId={0} />
                      </div>
                      <div
                        className={
                          this.isActiveTab(Constants.solution.SOLUTION_GET_INVIGILATION_COUNTS)
                            ? 'tab-pane fade show active'
                            : 'tab-pane fade'
                        }
                        id="singleTables"
                        role="tabpanel"
                        aria-labelledby="singleTables-tab"
                      >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseSolutionModal);

export default container;
