import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { ClassroomCombineTypes, CourseFormInitialValues, TermTypes, ExamPeriodCourseTermLectureLocations, Grades } from '../../store/constants/course-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';
import MultiSelectionTable from '../exam-period/multi-selection-table';
const T = Translator.create();

const equal = require('deep-equal');

const Logger = Log.create('CopyCourseModal');

let multiSelectedRow: boolean = false;
let saveButtonClicked: boolean = false;

let faculty_ids_selected: Array<any> = [];
let program_ids_selected: Array<any> = [];
let class_ids_selected: Array<any> = [];
let section_ids_selected: Array<any> = [];
let lecture_staff_ids_selected: Array<any> = [];
let faculties_selected: Array<any> = [];
let programs_selected: Array<any> = [];
let classes_selected: Array<any> = [];
let sections_selected: Array<any> = [];
let lecture_staff_selected: Array<any> = [];

let faculty_ids_labels: Array<any> = [];
let faculties_labels: Array<any> = [];
let program_ids_labels: Array<any> = [];
let programs_labels: Array<any> = [];
let class_ids_labels: Array<any> = [];
let classes_labels: Array<any> = [];
let section_ids_labels: Array<any> = [];
let sections_labels: Array<any> = [];
let lecture_staff_ids_labels: Array<any> = [];
let lecture_staff_labels: Array<any> = [];

class CopyCourseModal extends Component<any, any> {
  state: any = {
    term_id: -1,
    combined_courses: [],
    formValues: {
      student_count: -1
    },
    isAdded: false,
    alreadyInTheList: false
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/);
    let id = regexObject ? parseInt(regexObject[0], 10) : -1;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    faculty_ids_labels.splice(0, faculty_ids_labels.length);
    faculties_labels.splice(0, faculties_labels.length);
    program_ids_labels.splice(0, program_ids_labels.length);
    programs_labels.splice(0, programs_labels.length);
    class_ids_labels.splice(0, class_ids_labels.length);
    classes_labels.splice(0, classes_labels.length);
    section_ids_labels.splice(0, section_ids_labels.length);
    sections_labels.splice(0, sections_labels.length);
    lecture_staff_ids_labels.splice(0, lecture_staff_ids_labels.length);
    lecture_staff_labels.splice(0, lecture_staff_labels.length);

    faculty_ids_selected.splice(0, faculty_ids_selected.length);
    program_ids_selected.splice(0, program_ids_selected.length);
    class_ids_selected.splice(0, class_ids_selected.length);
    section_ids_selected.splice(0, section_ids_selected.length);
    lecture_staff_ids_selected.splice(0, lecture_staff_ids_selected.length);
    faculties_selected.splice(0, faculties_selected.length);
    programs_selected.splice(0, programs_selected.length);
    classes_selected.splice(0, classes_selected.length);
    sections_selected.splice(0, sections_selected.length);
    lecture_staff_selected.splice(0, lecture_staff_selected.length);
  }

  putToTable = (values: any, plusButtonClicked: boolean) => {
    faculty_ids_labels.length == 0 && plusButtonClicked == false ? faculty_ids_labels = values.faculty_ids : faculty_ids_labels = faculty_ids_labels;
    faculty_ids_labels = values.faculty_id != undefined && plusButtonClicked ? faculty_ids_labels.concat(values.faculty_id) : faculty_ids_labels;
    faculties_labels = values.faculty_id != undefined && plusButtonClicked ? faculties_labels.concat(values.faculties) : values.faculties;

    program_ids_labels.length == 0 && plusButtonClicked == false ? program_ids_labels = values.program_ids : program_ids_labels = program_ids_labels;
    program_ids_labels = values.program_id != undefined && plusButtonClicked ? program_ids_labels.concat(values.program_id) : program_ids_labels;
    programs_labels = values.program_id != undefined && plusButtonClicked ? programs_labels.concat(values.programs) : values.programs;

    lecture_staff_ids_labels.length == 0 && plusButtonClicked == false ? lecture_staff_ids_labels = values.instructor_ids : lecture_staff_ids_labels = lecture_staff_ids_labels;
    lecture_staff_ids_labels = values.instructor_id != undefined && plusButtonClicked ? lecture_staff_ids_labels.concat(values.instructor_id) : lecture_staff_ids_labels;
    lecture_staff_labels = values.instructor_id != undefined && plusButtonClicked ? lecture_staff_labels.concat(values.instructors) : values.instructors;

    classes_labels = classes_labels.concat(values.classes);

    if (values.classes.length == 1 && class_ids_labels.length == 0) {
      class_ids_labels = class_ids_labels.concat(values.classes.value);
    } else if (class_ids_labels.length == 0 && values.classes.length > 1) {
      class_ids_labels = values.classes && values.classes.map((item: any) => item.value);
    } else if (class_ids_labels.length == 0 && values.classes.length == 1) {
      class_ids_labels = values.classes && values.classes.value;
    }

    if (values.class_id != undefined && plusButtonClicked) {
      class_ids_labels = class_ids_labels.concat(values.class_id);
    } else {
      class_ids_labels = class_ids_labels;
    }

    if ((section_ids_labels.length == 0 || sections_labels.length == 0) && plusButtonClicked == false) {
      section_ids_labels = values.sections;
      sections_labels = values.sections;
    }

    if (values.section != undefined && values.section != '' && plusButtonClicked) {
      section_ids_labels = section_ids_labels.concat(values.section);
      sections_labels = sections_labels.concat(values.section);
    } else if ((values.section == undefined || values.section == '') && plusButtonClicked) {
      section_ids_labels = section_ids_labels.concat('A');
      sections_labels = sections_labels.concat('A');
      values.section = 'A'
    }

    this.setState({
      isAdded: true
    })
    this.state.isAdded = true;

    plusButtonClicked = false;
  };

  multiSelecteds(
    faculty_ids: any, program_ids: any, class_ids: any, section_ids: any, lecture_staff_ids: any,
    faculties: any, programs: any, classes: any, sections: any, lecture_staff: any
  ) {
    multiSelectedRow = true;
    faculty_ids_selected = faculty_ids;
    program_ids_selected = program_ids;
    class_ids_selected = class_ids;
    section_ids_selected = section_ids;
    lecture_staff_ids_selected = lecture_staff_ids;

    faculties_selected = faculties;
    programs_selected = programs;
    classes_selected = classes;
    sections_selected = sections;
    lecture_staff_selected = lecture_staff;

    faculty_ids_labels = faculty_ids;
    faculties_labels = faculties;
    program_ids_labels = program_ids;
    programs_labels = programs;
    class_ids_labels = class_ids;
    classes_labels = classes;
    section_ids_labels = section_ids;
    sections_labels = sections;
    lecture_staff_ids_labels = lecture_staff_ids;
    lecture_staff_labels = lecture_staff;
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onFormSave = (model: Types.ICopyCourseFormValues, FormActions: FormikActions<Types.ICopyCourseFormValues>) => {
    if (saveButtonClicked) {
      let newModel;
      let course_programs: any;

      course_programs = faculty_ids_selected.map((faculty_id: any, index: any) => (
        {
          faculty_id: faculty_id,
          program_id: program_ids_selected[index],
          class: class_ids_selected[index],
          instructor_id: lecture_staff_ids_selected[index],
          section: section_ids_selected[index]
        }));

      if (course_programs == undefined || course_programs == [] || course_programs.length == 0) {
        this.props.dispatch(Actions.Notification('Liste boş bırakılmaz. Lütfen tüm alanları doldurunuz.', 'gen_warning', 'danger'))
      } else {
        if (multiSelectedRow) {
          newModel = {
            assistant_staff: model.assistant_staff,
            assistant_staff_ids: model.assistant_staff_ids,
            campus: model.campus,
            campus_id: model.campus_id,
            classroom_combine_status: model.classroom_combine_status,
            //combined_course_ids: model.combined_course_ids,
            conjugate_course_code: model.conjugate_course_code,
            course_code: model.course_code,
            //course_term_status: model.course_term_status,
            description: model.description,
            exam_duration: model.exam_duration,
            exam_session: model.exam_session,
            course_programs: course_programs,
            invigilator_gap_after_exam: model.invigilator_gap_after_exam,
            invigilator_gap_before_exam: model.invigilator_gap_before_exam,
            name: model.name,
            student_count: model.student_count,
            term_id: model.term_id
          }
          multiSelectedRow = false;
        }

        let courseInfo: any = {};

        let courseArray: { id: number; item: Types.IExamPeriod_CourseItem } = this.props.course[0];
        if (courseArray && courseArray.item && courseArray.item.courseInfo) {
          courseInfo = { ...courseArray.item.courseInfo, ...newModel, term_id: this.state.term_id };
        }
        if (courseInfo && Object.keys(courseInfo).length) {
          courseInfo = Object.keys(courseInfo).reduce((object: any, key: string) => {
            if (key !== 'course_id') {
              object[key] = courseInfo[key];
            }
            return object;
          }, {});
        }

        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_activity_copy'),
            body: T.t('gen_copy_activity_question'),
            name: 'examPeriod_courses_delete',
            icon: 'warning',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (status: number) => {
                if (status === 200 || status === 201) {
                  this.setCloseModal();
                  this.onUpdateListFromModal();
                }
              };

              this.props.dispatch(
                Actions.ApiRequest(
                  Constants.exam_period.EXAM_PERIOD_COPY_COURSE_TO_PERIOD,
                  courseInfo,
                  'ep-copy-course',
                  resultCallback
                )
              );
            }
          })
        );

        saveButtonClicked = false;
        FormActions.setSubmitting(false);
      }
    }
  };

  static getDerivedStateFromProps(props: any, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;

    if (state.combineExamsModalIsOpen && !props.modalIsOpen) {
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  createFieldValues = (): Types.ICopyCourseFormValues => {
    let fields: Types.ICopyCourseFormValues = {
      course_code: '',
      conjugate_course_code: '',
      name: '',
      student_count: -1,
      instructor_ids: [],
      assistant_staff: [],
      assistant_staff_ids: [],
      programs: [],
      program_ids: [],
      campus: undefined,
      campus_id: 0,
      faculty: undefined,
      exam_duration: undefined,
      class: undefined,
      section: '',
      section_ids: [],
      sections: [],
      exam_session: undefined,
      description: '',
      instructor_id: 0,
      class_id: 0,
      program_id: 0,
      faculty_id: 0,
    };

    if (this.props.course && this.props.course.length) {
      let courseArray: { id: number; item: Types.IExamPeriod_CourseItem } = this.props.course[0];

      if (courseArray && courseArray.item && courseArray.item.courseInfo) {
        let courseInfo = courseArray.item.courseInfo;
        fields = {
          course_code: courseInfo.course_code ? courseInfo.course_code : '',
          conjugate_course_code: courseInfo.conjugate_course_code ? courseInfo.conjugate_course_code : '',
          name: courseInfo.name ? courseInfo.name : '',
          campus: courseInfo.campus ? courseInfo.campus : undefined,
          campus_id: courseInfo.campus_id ? courseInfo.campus_id : undefined,
          faculties: courseInfo.faculties ? courseInfo.faculties : undefined,
          faculty_ids: courseInfo.faculty_ids ? courseInfo.faculty_ids : undefined,
          student_count: courseInfo.student_count ? courseInfo.student_count : 0,
          invigilator_gap_before_exam: courseInfo.invigilator_gap_before_exam ? courseInfo.invigilator_gap_before_exam : 0,
          invigilator_gap_after_exam: courseInfo.invigilator_gap_after_exam ? courseInfo.invigilator_gap_after_exam : 0,
          instructors: courseInfo.instructors ? courseInfo.instructors : [],
          instructor_ids: courseInfo.instructor_ids ? courseInfo.instructor_ids : [],
          assistant_staff: courseInfo.assistant_staff ? courseInfo.assistant_staff : [],
          assistant_staff_ids: courseInfo.assistant_staff_ids ? courseInfo.assistant_staff_ids : [],
          programs: courseInfo.programs ? courseInfo.programs : [],
          program_ids: courseInfo.program_ids ? courseInfo.program_ids : [],
          class: courseInfo.class ? courseInfo.class : undefined,
          classes: courseInfo.classes ? courseInfo.classes : undefined,
          sections: courseInfo.sections ? courseInfo.sections : undefined,
          exam_duration: courseInfo.exam_duration ? courseInfo.exam_duration : undefined,
          exam_session: courseInfo.exam_session ? courseInfo.exam_session : undefined,
          classroom_combine_status: courseInfo.classroom_combine_status,
          description: courseInfo.description ? courseInfo.description : undefined,
        };
      }
    }
    return fields;
  };


  render() {
    const formInitialValues = this.createFieldValues();

    if (faculty_ids_labels.length == 0 || faculty_ids_labels == undefined) {
      this.putToTable(formInitialValues, false);
    }

    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const CampusSelectOptions = [...ExamPeriodCourseTermLectureLocations, ...campusOptions];
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_copy_exam')}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="white-container collapse show" id="advance-search" style={{ boxShadow: 'none' }}>
                  <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                      this.onFormSave(values, actions);
                    }}
                  >
                    {(props: FormikProps<Types.ICopyCourseFormValues>) => {
                      const { values, handleChange, errors, handleBlur, handleSubmit, isSubmitting } = props;

                      let table_values = faculty_ids_labels && faculty_ids_labels.map((faculty_id_label: any, index: any) => (
                        {
                          values:
                            faculty_ids_labels![index] + ',' +
                            program_ids_labels![index] + ',' +
                            class_ids_labels![index] + ',' +
                            section_ids_labels![index] + ',' +
                            lecture_staff_ids_labels![index]
                        }))

                      let current_form_values = { values: values.faculty_id + ',' + values.program_id + ',' + values.class_id + ',' + values.section + ',' + values.instructor_id }
                      if (table_values!.some((e: { values: string; }) => e.values == current_form_values.values)) {
                        this.state.alreadyInTheList = true;
                        this.state.isAdded = true;
                      } else if (this.state.isAdded) {
                        this.state.alreadyInTheList = false;
                        this.state.isAdded = false;
                      } else {
                        this.state.alreadyInTheList = false;
                        this.state.isAdded = false;
                      }

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="course_code"
                                name="course_code"
                                value={values.course_code || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="course_code">
                                {T.t('gen_code')}
                              </label>
                              <ErrorMessage component="div" className="error" name="course_code" />
                              <div className="col-md-12 list-error">
                                {
                                  values.course_code == '' || values.course_code == null ?
                                    <span>Kodu Boş Bırakılamaz!</span>
                                    : null
                                }
                              </div>
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                type="text"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="name">Sınav Adı</label>
                              <ErrorMessage component="div" className="error" name="name" />
                              <div className="col-md-12 list-error">
                                {
                                  values.name == '' || values.name == null ?
                                    <span>Adı Boş Bırakılamaz!</span>
                                    : null
                                }
                              </div>
                            </div>
                            {errors && errors.name && props.submitCount > 0 && (
                              <div className="col-md-2 error">Boş bırakılamaz!</div>
                            )}
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="conjugate_course_code"
                                name="conjugate_course_code"
                                value={values.conjugate_course_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="conjugate_course_code">{T.t('gen_conjugate_course_code')}</label>
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="student_count"
                                name="student_count"
                                value={values.student_count}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="student_count">{T.t('gen_student_count')}</label>
                              <ErrorMessage component="div" className="error" name="student_count" />
                              <div className="col-md-12 list-error">
                                {
                                  values.student_count == 0 || values.student_count == null ?
                                    <span>Öğrenci Sayısı Boş Bırakılamaz!</span>
                                    : null
                                }
                              </div>
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="exam_duration"
                                name="exam_duration"
                                value={values.exam_duration || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="exam_duration">{T.t('gen_exam_duration')}</label>
                              <ErrorMessage component="div" className="error" name="exam_duration" />
                              <div className="col-md-12 list-error">
                                {
                                  values.exam_duration == 0 || values.exam_duration == null ?
                                    <span>Sınav Süresi Boş Bırakılamaz!</span>
                                    : null
                                }
                              </div>
                            </div>
                            <div className="col-md-2 form-input form-group with-icon mt-3">
                              <input
                                id="exam_session"
                                name="exam_session"
                                value={values.exam_session || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="exam_session">{T.t('gen_session_count')}</label>
                              <ErrorMessage component="div" className="error" name="exam_session" />
                              <div className="col-md-12 list-error">
                                {
                                  values.exam_session == 0 || values.exam_session == null ?
                                    <span>Oturum Sayısı Boş Bırakılamaz!</span>
                                    : null
                                }
                              </div>
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="invigilator_gap_before_exam"
                                name="invigilator_gap_before_exam"
                                value={values.invigilator_gap_before_exam || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="invigilator_gap_before_exam">{T.t('gen_invigilator_gap_before_exam')}</label>
                              <ErrorMessage component="div" className="error" name="invigilator_gap_before_exam" />
                              <div className="col-md-12 list-error">
                                {
                                  values.invigilator_gap_before_exam == undefined || values.invigilator_gap_before_exam == null ?
                                    <span>Gözetmenlik Öncesi Boşluk Boş Bırakılamaz!</span>
                                    : null
                                }
                              </div>
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="invigilator_gap_after_exam"
                                name="invigilator_gap_after_exam"
                                value={values.invigilator_gap_after_exam || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="invigilator_gap_after_exam">{T.t('gen_invigilator_gap_after_exam')}</label>
                              <ErrorMessage component="div" className="error" name="invigilator_gap_after_exam" />
                              <div className="col-md-12 list-error">
                                {
                                  values.invigilator_gap_after_exam == undefined || values.invigilator_gap_after_exam == null ?
                                    <span>Gözetmenlik Sonrası Boşluk Boş Bırakılamaz!</span>
                                    : null
                                }
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_campus')}</label>
                                  <Select
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={true}
                                    options={CampusSelectOptions}
                                    placeholder={T.t('gen_select_location')}
                                    value={values.campus}
                                    onChange={(option: any) => {
                                      props.setFieldValue('campus', option);
                                      props.setFieldValue('campus_id', option && option.value);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                  />
                                </div>

                                {errors && errors.campus_id && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_classroom_combine_status')}</label>
                                  <Select
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={true}
                                    options={ClassroomCombineTypes}
                                    placeholder={T.t('gen_select_status')}
                                    value={
                                      values.classroom_combine_status != undefined
                                        ? ClassroomCombineTypes.find(
                                          (option) => option.value === values.classroom_combine_status
                                        )
                                        : null
                                    }
                                    onChange={(option: any) =>
                                      props.setFieldValue('classroom_combine_status', option.value)
                                    }
                                    noOptionsMessage={(): string => T.t('gen_select_no_status')}
                                  />
                                </div>
                                {errors && errors.classroom_combine_status && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_course_assistants')}</label>
                                  <Select
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={false}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.instructors
                                        ? this.props.selectOptions.instructors
                                        : []
                                    }
                                    placeholder={T.t('gen_select_assistant')}
                                    value={values.assistant_staff}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('assistant_staff', list);
                                      props.setFieldValue(
                                        'assistant_staff_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_assistan')}
                                  />
                                </div>

                                {errors && errors.assistant_staff && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 form-input form-group with-icon mt-3">
                              <textarea
                                name="description"
                                className="form-input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows={2}
                                id="description"
                                value={values.description}
                                placeholder={T.t('gen_description') + ' ' + T.t('gen_max_char_200')}
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="description" />
                              <ErrorMessage component="div" className="error" name="description" />
                            </div>
                            <div className="col-md-12">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_faculty_college_institute')}</label>
                                  <Select
                                    className="react-select"
                                    isMulti={false}
                                    isClearable={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={true}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.faculties
                                        ? this.props.selectOptions.faculties
                                        : []
                                    }
                                    placeholder={T.t('gen_select_faculty_college_institute')}
                                    onChange={(option: any) => {
                                      props.setFieldValue('faculty_id', option && option.value);
                                      props.setFieldValue('faculties', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                  />
                                </div>
                                {errors && errors.faculty_ids && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_program_department')}</label>
                                  <Select
                                    className="react-select"
                                    isDisabled={values.faculty_id == undefined || values.faculty_id == 0 ? true : false}
                                    isMulti={false}
                                    isClearable={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={true}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.programs
                                        ? this.props.selectOptions.programs
                                        : []
                                    }
                                    placeholder={T.t('gen_select_program_department')}
                                    onChange={(option: any) => {
                                      props.setFieldValue('program_id', option && option.value);
                                      props.setFieldValue('programs', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                  />
                                </div>
                                {errors && errors.program_ids && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_grade')}</label>
                                  <Select
                                    className="react-select"
                                    isMulti={false}
                                    isClearable={true}
                                    isDisabled={values.faculty_id == undefined || values.faculty_id == 0 || values.program_id == 0 || values.program_id == undefined ? true : false}
                                    closeMenuOnSelect={true}
                                    options={Grades}
                                    placeholder={T.t('gen_select_grade')}
                                    onChange={(option: any) => {
                                      props.setFieldValue('class_id', option && option.value);
                                      props.setFieldValue('classes', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                                  />
                                </div>
                                {errors && errors.class_ids && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 form-input form-group with-icon mt-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <input
                                    id="section"
                                    name="section"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={values.faculty_id == undefined || values.faculty_id == 0 || values.program_id == 0 || values.program_id == undefined || values.class_id == 0 || values.class_id == undefined ? true : false}
                                    type="text"
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="gen_section">{T.t('gen_section')}</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_course_instructors')}</label>
                                  <Select
                                    className="react-select"
                                    isMulti={false}
                                    isClearable={true}
                                    isDisabled={values.faculty_id == undefined || values.faculty_id == 0 || values.program_id == 0 || values.program_id == undefined || values.class_id == undefined ? true : false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={true}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.instructors
                                        ? this.props.selectOptions.instructors
                                        : []
                                    }
                                    placeholder={T.t('gen_select_instructor')}
                                    onChange={(option: any) => {
                                      props.setFieldValue('instructor_id', option && option.value);
                                      props.setFieldValue('instructors', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                  />
                                </div>
                                {errors && errors.instructor_ids && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md text-md-right mb-3">
                              <button
                                type="button"
                                disabled={
                                  ((values.instructor_id != undefined && values.instructor_id != 0) ? false : true)
                                  || (this.state.alreadyInTheList ? true : false)
                                }
                                data-dismiss="modal"
                                onClick={
                                  this.state.alreadyInTheList ?
                                    () => { } :
                                    () => { this.putToTable(values, true) }
                                }
                                className="btn btn-green"
                                aria-label="alert-success"
                              >
                                <i className="material-icons mr-2">add_circle_outline</i>
                              </button>
                            </div>
                            {/* <div className="col-md-12 list-error">
                              {
                                this.state.alreadyInTheList && this.state.isAdded ?
                                  <span>Listede zaten eklidir. Lütfen farkı bilgiler giriniz.</span>
                                  : null
                              }
                            </div> */}
                            <div className="col-md-12 list-error">
                              {
                                lecture_staff_ids_labels.length == 0 ?
                                  <span>Dersin Öğretim Elemanları alanı boş bırakılamaz. Lütfen bilgileri eksiksiz giriniz!</span>
                                  : null
                              }
                            </div>
                            <div className="col-md-12 list-error">
                              {
                                class_ids_labels.length == 0 ?
                                  <span>Sınıf alanı boş bırakılamaz. Lütfen bilgileri eksiksiz giriniz!</span>
                                  : null
                              }
                            </div>
                            <div className="col-md-12 list-error">
                              {
                                program_ids_labels.length == 0 ?
                                  <span>Program alanı boş bırakılamaz. Lütfen bilgileri eksiksiz giriniz!</span>
                                  : null
                              }
                            </div>
                            <div className="col-md-12 list-error">
                              {
                                faculty_ids_labels.length == 0 ?
                                  <span>Fakülte alanı boş bırakılamaz. Lütfen bilgileri eksiksiz giriniz!</span>
                                  : null
                              }
                            </div>
                            <MultiSelectionTable
                              faculty_ids_labels={faculty_ids_labels}
                              faculties_labels={faculties_labels}
                              program_ids_labels={program_ids_labels}
                              programs_labels={programs_labels}
                              class_ids_labels={class_ids_labels}
                              classes_labels={classes_labels}
                              section_ids_labels={section_ids_labels}
                              sections_labels={sections_labels}
                              lecture_staff_ids_labels={lecture_staff_ids_labels}
                              lecture_staff_labels={lecture_staff_labels}
                              multiSelectionObjectFunction={this.multiSelecteds}
                            />
                          </div>
                          <div className="row mt-3">
                            <div className="col-6"></div>
                            <div className="col-6 text-right">
                              <button
                                type="button"
                                disabled={
                                  (values.name == '' || values.name == null || values.course_code == '' || values.course_code == null || values.student_count == 0 || values.student_count == null || values.exam_duration == 0 || values.exam_duration == null || values.exam_session == 0 || values.exam_session == null ? true : false)
                                }
                                className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2"
                                onClick={() => {
                                  props.handleSubmit()
                                  saveButtonClicked = true
                                }}
                              >
                                {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                          <hr />
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="col-12">
                  <h6>{T.t('gen_copied_activity')}</h6>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          <span className="text-center">Durum</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">Kodu</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">Adı</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_conjugate_course_code')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_student_count')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">Kampüs</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">Fakülte</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_exam_duration')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_session_count')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_grade')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_section')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_classroom_combine_status')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_connected_programs')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_instructors')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_assistants')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_description')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.course &&
                        this.props.course.length ?
                        this.props.course.map((courseObject: { id: number; item: Types.IExamPeriod_CourseItem }, index: any) => {
                          let termObject: any = {};
                          let ClassroomCombineStatusObject: any = {};
                          let courseItem = courseObject.item.courseInfo;
                          if (courseItem && courseItem.term) {
                            let term = courseItem.term;
                            termObject = TermTypes.find((object) => object.value == term);
                          }
                          if (courseItem && courseItem.classroom_combine_status) {
                            let combineStatus = courseItem.classroom_combine_status;
                            ClassroomCombineStatusObject = ClassroomCombineTypes.find(
                              (object) => object.value == combineStatus
                            );
                          }
                          return (
                            <tr
                              key={index}
                              data-title={courseItem && courseItem.name}
                            >
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    className={
                                      `small-tag text-uppercase` +
                                      (courseItem && courseItem.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(courseItem && courseItem.status)}
                                  </button>
                                </div>
                              </td>
                              <td className="text-center" scope="row" data-label={T.t('gen_code')}>
                                <div className="table-scrollable-td">{courseItem && courseItem.course_code ? courseItem.course_code.split(';').join('; ') : '-'}</div>
                              </td>
                              <td data-label={T.t('gen_name')}>
                                <div className="table-scrollable-td">{courseItem && courseItem.name}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_conjugate_course_code')} className="text-center">
                                {courseItem && courseItem.conjugate_course_code ? courseItem.conjugate_course_code : '-'}
                              </td>
                              <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                                {courseItem && courseItem.student_count}
                              </td>
                              <td data-label={T.t('gen_campus')}>
                                {courseItem && courseItem.campus_name ? courseItem.campus_name : '-'}
                              </td>
                              <td
                                data-cell="faculty"
                                id={'faculty-popover-copymodal-' + (courseItem && courseItem.course_id)}
                                style={{ textAlign: 'center' }}
                              >
                                {
                                  courseItem && courseItem.faculties && courseItem.faculties.length > 10 ?
                                    <div className="table-scrollable-td">
                                      {courseItem.faculties && courseItem.faculties.map((i: any) => <>{i.label}, </>)}
                                    </div>
                                    :
                                    <>
                                      {courseItem && courseItem.faculties && courseItem.faculties.length > 0 && (
                                        <button className="multiple-info-tag small-tag group-ellipsis">
                                          {courseItem && courseItem.faculties[0].label}
                                        </button>
                                      )}
                                      {courseItem && courseItem.faculties && courseItem.faculties.length > 1 && (
                                        <React.Fragment>
                                          <button className="multiple-info-tag small-tag group-ellipsis">
                                            +{courseItem && courseItem.faculties.length - 1} {T.t('gen_faculty')}
                                          </button>
                                          <UncontrolledPopover
                                            trigger="hover focus"
                                            placement="bottom"
                                            target={'faculty-popover-copymodal-' + (courseItem && courseItem.course_id)}
                                          >
                                            <PopoverHeader>{T.t('gen_faculty')}</PopoverHeader>
                                            <PopoverBody className="scrollable-popoverbody">
                                              {courseItem && courseItem.faculties &&
                                                courseItem.faculties.length > 0 &&
                                                courseItem.faculties.map((faculty: Types.ISelectOption, index: any) => {
                                                  return (
                                                    <React.Fragment key={index}>
                                                      <button className="multiple-info-tag small-tag text-capitalize">
                                                        {faculty.label}
                                                      </button>
                                                      <br />
                                                    </React.Fragment>
                                                  );
                                                })}
                                            </PopoverBody>
                                          </UncontrolledPopover>
                                        </React.Fragment>
                                      )}
                                    </>
                                }
                              </td>
                              <td scope="row" data-label={T.t('gen_exam_duration')} className="text-center">
                                {courseItem && courseItem.exam_duration ? courseItem.exam_duration : '-'}
                              </td>
                              <td data-label={T.t('gen_session_count')} className="text-center">
                                {courseItem && courseItem.exam_session ? courseItem.exam_session : '-'}
                              </td>
                              <td
                                data-cell="class"
                                id={'class-popover-copymodal-' + (courseItem && courseItem.course_id)}
                                style={{ textAlign: 'center' }}
                              >
                                {
                                  courseItem && courseItem.classes && courseItem.classes.length > 10 ?
                                    <div className="table-scrollable-td">
                                      {courseItem.classes && courseItem.classes.map((i: any) => <>{i.label}, </>)}
                                    </div>
                                    :
                                    <>
                                      {courseItem && courseItem.classes && courseItem.classes.length > 0 && (
                                        <button className="multiple-info-tag small-tag group-ellipsis">
                                          {courseItem.classes[0].label}
                                        </button>
                                      )}
                                      {courseItem && courseItem.classes && courseItem.classes.length > 1 && (
                                        <React.Fragment>
                                          <button className="multiple-info-tag small-tag group-ellipsis">
                                            +{courseItem && courseItem.classes.length - 1} {T.t('gen_grade')}
                                          </button>
                                          <UncontrolledPopover
                                            trigger="hover focus"
                                            placement="bottom"
                                            target={'class-popover-copymodal-' + (courseItem && courseItem.course_id)}
                                          >
                                            <PopoverHeader>{T.t('gen_grade')}</PopoverHeader>
                                            <PopoverBody className="scrollable-popoverbody">
                                              {courseItem && courseItem.classes &&
                                                courseItem.classes.length > 0 &&
                                                courseItem.classes.map((classes: Types.ISelectOption, index: any) => {
                                                  return (
                                                    <React.Fragment key={index}>
                                                      <button className="multiple-info-tag small-tag text-capitalize">
                                                        {classes.label}
                                                      </button>
                                                      <br />
                                                    </React.Fragment>
                                                  );
                                                })}
                                            </PopoverBody>
                                          </UncontrolledPopover>
                                        </React.Fragment>
                                      )}
                                    </>
                                }
                              </td>
                              <td
                                data-cell="section"
                                id={'section-popover-copymodal-' + (courseItem && courseItem.course_id)}
                                style={{ textAlign: 'center' }}
                              >
                                {
                                  courseItem && courseItem.sections && courseItem.sections.length > 10 ?
                                    <div className="table-scrollable-td">
                                      {courseItem && courseItem.sections && courseItem.sections.map((i: any) => <>{i.label}, </>)}
                                    </div>
                                    :
                                    <>
                                      {courseItem && courseItem.sections && courseItem.sections.length > 0 && (
                                        <button className="multiple-info-tag small-tag group-ellipsis">
                                          {courseItem.sections[0]}
                                        </button>
                                      )}
                                      {courseItem && courseItem.sections && courseItem.sections.length > 1 && (
                                        <React.Fragment>
                                          <button className="multiple-info-tag small-tag group-ellipsis">
                                            +{courseItem && courseItem.sections.length - 1} {T.t('gen_section')}
                                          </button>
                                          <UncontrolledPopover
                                            trigger="hover focus"
                                            placement="bottom"
                                            target={'section-popover-copymodal-' + (courseItem && courseItem.course_id)}
                                          >
                                            <PopoverHeader>{T.t('gen_section')}</PopoverHeader>
                                            <PopoverBody className="scrollable-popoverbody">
                                              {courseItem && courseItem.sections &&
                                                courseItem.sections.length > 0 &&
                                                courseItem.sections.map((sections: Types.ISelectOption, index: any) => {
                                                  return (
                                                    <React.Fragment key={index}>
                                                      <button className="multiple-info-tag small-tag text-capitalize">
                                                        {sections}
                                                      </button>
                                                      <br />
                                                    </React.Fragment>
                                                  );
                                                })}
                                            </PopoverBody>
                                          </UncontrolledPopover>
                                        </React.Fragment>
                                      )}
                                    </>
                                }
                              </td>
                              <td data-label={T.t('gen_classroom_combine_status')} className="text-center">
                                {courseItem &&
                                  (courseItem.classroom_combine_status == 0 ? 'Birleşik' :
                                    courseItem.classroom_combine_status == 1 ? 'Öğretim Elemanlarına Göre' :
                                      courseItem.classroom_combine_status == 2 ? 'Programlara Göre' :
                                        courseItem.classroom_combine_status
                                  )
                                }
                              </td>
                              <td
                                data-cell="connected_program"
                                id={'connected_program-popover-copymodal-' + (courseItem && courseItem.course_id)}
                                style={{ textAlign: 'center' }}
                              >
                                {
                                  courseItem && courseItem.programs && courseItem.programs.length > 10 ?
                                    <div className="table-scrollable-td">
                                      {courseItem && courseItem.programs && courseItem.programs.map((i: any) => <>{i.label}, </>)}
                                    </div>
                                    :
                                    <>
                                      {courseItem && courseItem.programs && courseItem && courseItem.programs.length > 0 && (
                                        <button className="multiple-info-tag small-tag group-ellipsis">
                                          {courseItem && courseItem.programs[0].label}
                                        </button>
                                      )}
                                      {courseItem && courseItem.programs && courseItem && courseItem.programs.length > 1 && (
                                        <React.Fragment>
                                          <button className="multiple-info-tag small-tag group-ellipsis">
                                            +{courseItem && courseItem.programs.length - 1} Program
                                        </button>
                                          <UncontrolledPopover
                                            trigger="hover focus"
                                            placement="bottom"
                                            target={'connected_program-popover-copymodal-' + (courseItem && courseItem.course_id)}
                                          >
                                            <PopoverHeader>{T.t('gen_connected_programs')}</PopoverHeader>
                                            <PopoverBody className="scrollable-popoverbody">
                                              {courseItem &&
                                                courseItem.programs &&
                                                courseItem &&
                                                courseItem.programs.length > 0 &&
                                                courseItem &&
                                                courseItem.programs.map((connected_program: Types.ISelectOption, index: any) => {
                                                  return (
                                                    <React.Fragment key={index}>
                                                      <button className="multiple-info-tag small-tag text-capitalize">
                                                        {connected_program.label}
                                                      </button>
                                                      <br />
                                                    </React.Fragment>
                                                  );
                                                })}
                                            </PopoverBody>
                                          </UncontrolledPopover>
                                        </React.Fragment>
                                      )}
                                    </>
                                }
                              </td>
                              <td
                                data-cell="instructors"
                                id={'instructors-popover-copymodal-' + (courseItem && courseItem.course_id)}
                                style={{ textAlign: 'center' }}
                              >
                                {
                                  courseItem && courseItem.instructors && courseItem.instructors.length > 10 ?
                                    <div className="table-scrollable-td">
                                      {courseItem && courseItem.instructors && courseItem.instructors.map((i: any) => <>{i.label}, </>)}
                                    </div>
                                    :
                                    <>
                                      {courseItem &&
                                        courseItem.instructors &&
                                        courseItem &&
                                        courseItem.instructors.length > 0 && (
                                          <button className="multiple-info-tag small-tag group-ellipsis">
                                            {courseItem && courseItem.instructors[0].label}
                                          </button>
                                        )}
                                      {courseItem &&
                                        courseItem.instructors &&
                                        courseItem &&
                                        courseItem.instructors.length > 1 && (
                                          <React.Fragment>
                                            <button className="multiple-info-tag small-tag group-ellipsis">
                                              +{courseItem.instructors && courseItem.instructors.length - 1} Kişi
                                          </button>
                                            <UncontrolledPopover
                                              trigger="hover focus"
                                              placement="bottom"
                                              target={'instructors-popover-copymodal-' + (courseItem && courseItem.course_id)}
                                            >
                                              <PopoverHeader>{T.t('gen_instructors')}</PopoverHeader>
                                              <PopoverBody className="scrollable-popoverbody">
                                                {courseItem &&
                                                  courseItem.instructors &&
                                                  courseItem &&
                                                  courseItem.instructors.length > 0 &&
                                                  courseItem &&
                                                  courseItem.instructors.map((instructors: Types.ISelectOption, index: any) => {
                                                    return (
                                                      <React.Fragment key={index}>
                                                        <button className="multiple-info-tag small-tag text-capitalize">
                                                          {instructors.label}
                                                        </button>
                                                        <br />
                                                      </React.Fragment>
                                                    );
                                                  })}
                                              </PopoverBody>
                                            </UncontrolledPopover>
                                          </React.Fragment>
                                        )}
                                    </>
                                }
                              </td>
                              <td
                                data-cell="assistant"
                                id={'assistant-popover-' + (courseItem && courseItem.course_id)}
                                style={{ textAlign: 'center' }}
                              >
                                {courseItem &&
                                  courseItem.assistant_staff &&
                                  courseItem &&
                                  courseItem.assistant_staff.length > 0 && (
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {courseItem && courseItem.assistant_staff[0].label}
                                    </button>
                                  )}
                                {courseItem &&
                                  courseItem.assistant_staff &&
                                  courseItem &&
                                  courseItem.assistant_staff.length > 1 && (
                                    <React.Fragment>
                                      <button className="multiple-info-tag small-tag group-ellipsis">
                                        +{courseItem && courseItem.assistant_staff.length - 1} Kişi
                                      </button>
                                      <UncontrolledPopover
                                        trigger="hover focus"
                                        placement="bottom"
                                        target={'assistant-popover-' + (courseItem && courseItem.course_id)}
                                      >
                                        <PopoverHeader>{T.t('gen_assistants')}</PopoverHeader>
                                        <PopoverBody className="scrollable-popoverbody">
                                          {courseItem &&
                                            courseItem.assistant_staff &&
                                            courseItem &&
                                            courseItem.assistant_staff.length > 0 &&
                                            courseItem &&
                                            courseItem.assistant_staff.map((asistan: Types.ISelectOption, index: any) => {
                                              return (
                                                <React.Fragment key={index}>
                                                  <button className="multiple-info-tag small-tag text-capitalize">
                                                    {asistan.label}
                                                  </button>
                                                  <br />
                                                </React.Fragment>
                                              );
                                            })}
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </React.Fragment>
                                  )}
                              </td>
                              <td data-label={T.t('gen_description')} className="text-center">
                                {courseItem && courseItem.description ? courseItem.description : '-'}
                              </td>
                            </tr>
                          );
                        }) : null}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    // results: store.state.course_page && store.state.course_page.results,
    // filters: store.state.course_page && store.state.course_page.filters,
    // all_ids: store.state.course_page && store.state.course_page.all_ids,
    selectOptions: store.state.select_options && store.state.select_options.coursePage
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
  // if (next.state.course_page) {
  //   return (
  //     !!equal(
  //       prev.state.course_page && prev.state.course_page.results,
  //       next.state.course_page && next.state.course_page.results
  //     ) &&
  //     !!equal(
  //       prev.state.select_options && prev.state.select_options.coursePage,
  //       next.state.select_options && next.state.select_options.coursePage
  //     )
  //   );
  // } else {
  //   return true;
  // }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CopyCourseModal);

export default container;
