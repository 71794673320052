import * as Yup from 'yup';

export const FacultyFormValidation = Yup.object().shape({
  faculty_code: Yup.string()
    .required('Boş bırakılamaz!')
    .max(50, 'En fazla 50 karakter!'),
  name: Yup.string().required('Boş bırakılamaz!'),
  campus_id: Yup.number().required('Boş bırakılamaz!'),
  /*
  building_ids: Yup.array()
    .of(Yup.number())
    .required('Boş bırakılamaz!'),
  */
  description: Yup.string()
    .max(200, 'En fazla 200 karakter!')
});
