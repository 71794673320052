import { escapeLeadingUnderscores } from 'typescript';
import * as Types from '../../store/types';

export const constants = {
  EXAM_PERIOD_LIST_SEARCH: 'EXAM_PERIOD:LIST_SEARCH',
  EXAM_PERIOD_CREATE: 'EXAM_PERIOD:CREATE',
  EXAM_PERIOD_UPDATE: 'EXAM_PERIOD:UPDATE',
  EXAM_PERIOD_DELETE: 'EXAM_PERIOD:DELETE',
  EXAM_PERIOD_GET_BY_ID: 'EXAM_PERIOD:GET_BY_ID',
  EXAM_PERIOD_GET_LIST: 'EXAM_PERIOD_GET_LIST',
  EXAM_PERIOD_COPY: 'EXAM_PERIOD:COPY',
  EXAM_PERIOD_COPY_REQUEST_STATUS: 'EXAM_PERIOD:COPY_REQUEST_STATUS',
  // exam dates tab
  EXAM_PERIOD_GET_PERIOD_DATES: 'EXAM_PERIOD_GET_PERIOD_DATES',
  EXAM_PERIOD_CREATE_EXAM_DATES: 'EXAM_PERIOD_CREATE_EXAM_DATES',
  EXAM_PERIOD_UPDATE_EXAM_DATES: 'EXAM_PERIOD_UPDATE_EXAM_DATES',
  EXAM_PERIOD_DELETE_EXAM_DATES: 'EXAM_PERIOD_DELETE_EXAM_DATES',
  EXAM_PERIOD_SAVE_SCHEDULE: 'EXAM_PERIOD_SAVE_SCHEDULE',
  // instuctors hours tab
  EXAM_PERIOD_GET_INSTRUCTOR_HOURS: 'EXAM_PERIOD_GET_INSTRUCTOR_HOURS',
  EXAM_PERIOD_DELETE_INSTRUCTORS_FROM_PERIOD: 'EXAM_PERIOD_DELETE_INSTRUCTOR_FROM_PERIOD',
  EXAM_PERIOD_ADD_INSTRUCTORS_TO_PERIOD: 'EXAM_PERIOD_ADD_INSTRUCTORS_TO_PERIOD',
  EXAM_PERIOD_SAVE_INSTRUCTOR_INVIGILATIONS: 'EXAM_PERIOD_SAVE_INSTRUCTOR_INVIGILATIONS',
  EXAM_PERIOD_UPDATE_INSTRUCTOR_HOURS: 'EXAM_PERIOD_UPDATE_INSTRUCTOR_HOURS',
  EXAM_PERIOD_UPDATE_MULTIPLE_INSTRUCTOR_HOURS: 'EXAM_PERIOD_UPDATE_MULTIPLE_INSTRUCTOR_HOURS',
  // classrooms hours tab
  EXAM_PERIOD_GET_CLASSROOM_HOURS: 'EXAM_PERIOD_GET_CLASSROOM_HOURS',
  EXAM_PERIOD_ADD_CLASSROOMS_TO_PERIOD: 'EXAM_PERIOD_ADD_CLASSROOMS_TO_PERIOD',
  EXAM_PERIOD_CREATE_CLASSROOM_HOURS: 'EXAM_PERIOD_CREATE_CLASSROOM_HOURS',
  EXAM_PERIOD_UPDATE_CLASSROOM_HOURS: 'EXAM_PERIOD_UPDATE_CLASSROOM_HOURS',
  EXAM_PERIOD_UPDATE_MULTIPLE_CLASSROOM_HOURS: 'EXAM_PERIOD_UPDATE_MULTIPLE_CLASSROOM_HOURS',
  EXAM_PERIOD_DELETE_CLASSROOMS_FROM_PERIOD: 'EXAM_PERIOD_DELETE_CLASSROOMS_FROM_PERIOD',
  // course hours tab
  EXAM_PERIOD_GET_COURSE_HOURS: 'EXAM_PERIOD_GET_COURSE_HOURS',
  EXAM_PERIOD_GET_COURSETERM_COURSE_HOURS: 'EXAM_PERIOD_GET_COURSETERM_COURSE_HOURS',
  EXAM_PERIOD_GET_EXAM_HOURS: 'EXAM_PERIOD_GET_EXAM_HOURS',
  EXAM_PERIOD_ADD_COURSES_TO_PERIOD: 'EXAM_PERIOD_ADD_COURSES_TO_PERIOD',
  EXAM_PERIOD_ADD_ACTIVITIES_TO_PERIOD: 'EXAM_PERIOD_ADD_ACTIVITIES_TO_PERIOD',
  EXAM_PERIOD_COPY_COURSE_TO_PERIOD: 'EXAM_PERIOD_COPY_COURSE_TO_PERIOD',
  EXAM_PERIOD_COURSETERM_COPY_COURSE_TO_PERIOD: 'EXAM_PERIOD_COURSETERM_COPY_COURSE_TO_PERIOD',
  EXAM_PERIOD_EDIT_COURSE: 'EXAM_PERIOD_EDIT_COURSE',
  EXAM_PERIOD_COURSETERM_EDIT_COURSE: 'EXAM_PERIOD_COURSETERM_EDIT_COURSE',
  EXAM_PERIOD_CREATE_COMBINE_EXAMS: 'EXAM_PERIOD_CREATE_COMBINE_EXAMS',
  EXAM_PERIOD_CREATE_COMBINE_ACTIVITES: 'EXAM_PERIOD_CREATE_COMBINE_ACTIVITES',
  EXAM_PERIOD_UPDATE_COMBINE_EXAMS: 'EXAM_PERIOD_UPDATE_COMBINE_EXAMS',
  EXAM_PERIOD_ATTACH_CLASSROOMS_TO_COURSES: 'EXAM_PERIOD_ATTACH_CLASSROOMS_TO_COURSES',
  EXAM_PERIOD_COURSETERM_ATTACH_CLASSROOMS_TO_COURSES: 'EXAM_PERIOD_COURSETERM_ATTACH_CLASSROOMS_TO_COURSES',
  EXAM_PERIOD_GET_ATTACHED_CLASSROOMS: 'EXAM_PERIOD_GET_ATTACHED_CLASSROOMS',
  EXAM_PERIOD_DELETE_ATTACHED_CLASSROOMS: 'EXAM_PERIOD_DELETE_ATTACHED_CLASSROOMS',
  EXAM_PERIOD_GET_OVERLAPS: 'EXAM_PERIOD_GET_OVERLAPS',
  EXAM_PERIOD_COURSETERM_GET_OVERLAPS: 'EXAM_PERIOD_COURSETERM_GET_OVERLAPS',
  EXAM_PERIOD_CREATE_COURSE_HOURS: 'EXAM_PERIOD_CREATE_COURSE_HOURS',
  EXAM_PERIOD_UPDATE_COURSE_HOURS: 'EXAM_PERIOD_UPDATE_COURSE_HOURS',
  EXAM_PERIOD_UPDATE_MULTIPLE_COURSE_HOURS: 'EXAM_PERIOD_UPDATE_MULTIPLE_COURSE_HOURS',
  EXAM_PERIOD_DELETE_COURSES_FROM_PERIOD: 'EXAM_PERIOD_DELETE_COURSES_FROM_PERIOD',
  // students tab
  EXAM_PERIOD_GET_STUDENTS: 'EXAM_PERIOD_GET_STUDENTS',
  EXAM_PERIOD_DELETE_STUDENTS_FROM_PERIOD: 'EXAM_PERIOD_DELETE_STUDENTS_FROM_PERIOD',
  EXAM_PERIOD_DELETE_ALL_STUDENTS_FROM_PERIOD: 'EXAM_PERIOD_DELETE_ALL_STUDENTS_FROM_PERIOD',
  EXAM_PERIOD_STUDENTS_CREATE_BULK: 'EXAM_PERIOD_STUDENTS_CREATE_BULK'
};

export const ExamPeriodSearchInitialValues: Types.IFilterExamPeriod = {
  name: '',
  status: [],
  terms: [],
  year: undefined,
  page: 1,
  order_by: '',
  query: '',
  total: -1,
  size: 10
};

export const CalendarModalSearchInitialValues: Types.IFilterExamPeriodCalendarModal = {
  term_id: undefined,
  course_id: undefined,
  select_all: false,
  active_tab: 0,
  query: '',
  page: 1,
  order_by: '',
  total: -1,
  size: 10,
  delete_list: [],
  add_list: []
};

export const ExamPeriodFormInitialValues: Types.ITermItem = {
  term_id: undefined,
  status: 1,
  name: '',
  academic_term: undefined,
  term_type: 1,
  year: undefined,
  calendar_status: 0,
  description: ''
};

export const ExamCalendarInitialValues: Types.IExamCalendarModalItem = {
  term_id: undefined,
  name: '',
  tabManager: [
    { key: constants.EXAM_PERIOD_GET_PERIOD_DATES, value: true },
    { key: constants.EXAM_PERIOD_GET_INSTRUCTOR_HOURS, value: false },
    { key: constants.EXAM_PERIOD_GET_CLASSROOM_HOURS, value: false },
    { key: constants.EXAM_PERIOD_GET_EXAM_HOURS, value: false },
    { key: constants.EXAM_PERIOD_GET_COURSE_HOURS, value: false },
    { key: constants.EXAM_PERIOD_GET_COURSETERM_COURSE_HOURS, value: false },
    { key: constants.EXAM_PERIOD_GET_STUDENTS, value: false }
  ]
};

//#region Exam Dates Tab

export const ExamDatesInitialValues: Types.IExamDatesItem = {
  id: undefined,
  is_calendar_created: false,
  allowCalendarEdit: true,
  isExpanded: false,
  testquery: '',
  scheduleHasTrueValues: false,
  name: '',
  dates: [],
  start_hour: '',
  start_date: '',
  end_date: '',
  end_hour: '',
  time_period: '',
  calendar: {
    times: [],
    days: [],
    schedule: {}
  },
  emptyDurations: []
};

export const ExamDatesDataModelValues: Types.IExamDates_DataModel = {
  term_id: undefined,
  name: '',
  term_slots: {},
  start_date: '',
  end_date: '',
  start_hour: '',
  end_hour: '',
  slot_duration: '',
  calendar_status: 0,
  empty_durations: '',
  all_slots: {}
};

//#endregion

//#region Instructor Hours Tab

export const InstructorHoursDataModelValues: Types.IExamPeriod_InstructorDataModel = {
  term_id: undefined,
  instructor_id: -1,
  send_mail: false,
  time_slots: {},
  invigilator_time_slots: {}
};

export const SelectedInstructorEmptyValues = {
  term_id: undefined,
  instructor_id: 0,
  status: 0,
  mail_status: 0,
  time_slots: {},
  invigilator_time_slots: {},
  notes: {}
};

export const InstructorHoursInitialValues: Types.IExamPeriod_Instructor_LocalState = {
  all_ids: [],
  instructor_list: [],
  checked_list: [],
  all_checked: false,
  filterIsOpen: false,
  addModalIsOpen: false,
  showSearchBar: false,
  selected_Instructor: SelectedInstructorEmptyValues,
  // others
  term_id: undefined,
  is_calendar_created: false,
  isExpanded: false,
  tabManager: [
    { key: 0, value: true },
    { key: 1, value: false },
    { key: 2, value: false },
    { key: 3, value: false }
  ],
  rightSideTabs: [
    { key: 'hours', value: true },
    { key: 'invigilations', value: false },
    { key: 'notes', value: false }
  ],
  initialSchedule: {},
  addType: 'both',
  controlledUpdate: 1,
  active_tab: 0,
  name: '',
  calendar: {
    times: [],
    days: [],
    schedule: {}
  },
  invigilations: {
    invigilation_campuses: [],
    invigilation_programs: [],
    invigilation_campus_ids: [],
    invigilation_program_ids: [],
    invigilation_faculties: [],
    invigilation_faculty_ids: [],
    invigilator_level: -1
  }
};

//#endregion

//#region Classroom Hours Tab

export const ClassroomHoursDataModelValues: Types.IExamPeriod_ClassroomDataModel = {
  term_id: undefined,
  classroom_id: -1,
  time_slots: {}
};

export const ClassroomHoursInitialValues: Types.IExamPeriod_Classroom_LocalState = {
  classroom_list: [],
  checked_list: [],
  all_checked: false,
  addModalIsOpen: false,
  showSearchBar: false,
  displayListPage: false,
  selected_Classroom: {
    term_id: undefined,
    classroom_id: 0,
    status: 0,
    time_slots: {}
  },
  // others
  term_id: undefined,
  is_calendar_created: false,
  isExpanded: false,
  initialSchedule: {},
  addType: 'classroom',
  name: '',
  calendar: {
    times: [],
    days: [],
    schedule: {}
  }
};

//#endregion

//#region Course Hours Tab

export const CourseHoursDataModelValues: Types.IExamPeriod_CourseDataModel = {
  term_id: undefined,
  course_id: -1,
  attached_classrooms: [],
  time_slots: {}
};

export const ActivityHoursDataModelValues: Types.IExamPeriod_ActivityDataModel = {
  term_id: undefined,
  activity_no: -1,
  attached_classrooms: [],
  time_slots: {},
  course_id: -1,
  course_ids: [],
  course_hour_ids: []
};

export const CourseHoursInitialValues: Types.IExamPeriod_Course_LocalState = {
  all_ids: [],
  course_list: [],
  checked_list: [],
  all_checked: false,
  addModalIsOpen: false,
  classroomAddModalIsOpen: false,
  showAsCompactTable: false,
  showSearchBar: false,
  displayListPage: true,
  overlaps: {},
  selected_Course: {
    term_id: undefined,
    course_id: 0,
    status: 0,
    time_slots: {},
    attached_classrooms: []
  },
  // others
  term_id: undefined,
  course_id: -1,
  is_calendar_created: false,
  tabManager: [
    { key: 'hours', value: true },
    { key: 'classrooms', value: false },
    { key: 'overlaps', value: false }
  ],
  isExpanded: false,
  initialSchedule: {},
  addType: 'course',
  name: '',
  calendar: {
    times: [],
    days: [],
    schedule: {}
  }
};

export const ActivityHoursInitialValues: Types.IExamPeriod_Activity_LocalState = {
  all_ids: [],
  activity_list: [],
  checked_list: [],
  checked_courses_ids: [],
  selected_list_items: [],
  all_checked: false,
  addModalIsOpen: false,
  classroomAddModalIsOpen: false,
  showAsCompactTable: false,
  showSearchBar: false,
  displayListPage: true,
  overlaps: {},
  selected_Activity: {
    term_id: undefined,
    activity_no: 0,
    status: 0,
    time_slots: {},
    attached_classrooms: [],
    course_code: '',
    course_name: ''
  },
  // others
  term_id: undefined,
  course_id: -1,
  is_calendar_created: false,
  tabManager: [
    { key: 'hours', value: true },
    { key: 'classrooms', value: false },
    { key: 'overlaps', value: false }
  ],
  isExpanded: false,
  initialSchedule: {},
  addType: 'course',
  name: '',
  calendar: {
    times: [],
    days: [],
    schedule: {}
  }
};

//#endregion

//#region Students

export const StudentSearchInitialValues: Types.IFilterStudent = {
  order_by: '',
  page: 1,
  total: -1,
  size: 10,
  tc_no: undefined,
  select_all: false,
  student_number: undefined,
  delete_all: false,
  name: '',
  status: [1],
  group: undefined,
  class: undefined,
  course_code: undefined,
  course_name: ''
};

export const StudentFormInitialValues: Types.IStudentItem = {
  term_id: undefined,
  status: 1,
  tc_no: undefined,
  student_number: undefined,
  name: '',
  course_faculty_code: '',
  course_faculty_name: '',
  course_program_code: '',
  course_program_name: '',
  enrolled_program_name: '',
  enrolled_program_code: '',
  course_code: '',
  course_name: '',
  class: undefined,
  group: undefined,
  description: ''
};

//#endregion

export const TermTypes: Array<Types.ISelectOption> = [
  { label: 'Güz', value: 1 },
  { label: 'Bahar', value: 2 },
  { label: 'Yaz', value: 3 }
];
