import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment, { duration } from 'moment';
import {
  ICourseSolutionGridInitialValues,
  courseFormatsValues,
  messagesValues,
  weekSelectOptions
} from '../../../store/constants/solution-const';
import { Log } from 'ng2-logger';
import * as Types from '../../../store/types';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ICourseSolutionGridState } from '../../../store/types';
import Select from 'react-select';
import DisplayEventModal from './display-event-modal';
import * as Constants from '../../../store/constants/all';
import { constants } from '../../../store/constants/solution-const';
import { jsPDF } from 'jspdf';
import h2c from "html2canvas";

import Translator from '../../../services/translate-factory';
const T = Translator.create();
const localizer = momentLocalizer(moment);
const Logger = Log.create('SolutionGrid');

function getInitialState(): Types.ICourseSolutionGridState {
  return Object.assign({}, ICourseSolutionGridInitialValues);
}

class SolutionGrid extends Component<any, ICourseSolutionGridState> {
  state: Types.ICourseSolutionGridState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
    this.getSelectOptions();
    this.setState(
      {
        ...this.state,
        filters: {
          ...this.state.filters
        },
        selected_Options: {
          ...this.state.selected_Options
        }
      },
      () => this.createSolution()
    );
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (!prevProps.model || (prevProps.model && prevProps.model.createDate !== (this.props.model && this.props.model.createDate))) {
      this.getSelectOptions();
    }

    if (prevProps.activeTab !== this.props.activeTab || prevProps.solutionId !== this.props.solutionId) {
      this.setState({
        ...this.state,
        filteredModel: [],
        resultModel: [],
        filters: {
          weeks: [],
          classroomSolution: [],
          instructorSolution: [],
          programSolution: [],
          branchSolution: [],
        },
        selected_Options: {
          weeks: [],
          classroomSolution: [],
          instructorSolution: [],
          programSolution: [],
          branchSolution: [],
        }
      });
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;
    if (props && props.solutionInfo) {
      hasNewState = true;
      const startDate = moment(props.solutionInfo.start_date).toDate();

      const startHour = moment(props.solutionInfo.start_hour, 'HH:mm').format('H');
      const endHour = moment(props.solutionInfo.end_hour, 'HH:mm').format('H');
      const min = parseInt(startHour, 10);
      const max = parseInt(endHour, 11);

      const duration_hour = moment(props.solutionInfo.slot_duration, 'HH:mm').format('H');
      const duration_mins = moment(props.solutionInfo.slot_duration, 'HH:mm').format('m');

      const duration_in_minutes = parseInt(duration_hour, 10) * 60 + parseInt(duration_mins, 10);
      const step = 15;
      const slotDuration = duration_in_minutes / step;

      const isScheduleCreated = props.solutionInfo.start_date && props.solutionInfo.start_date;

      state.scheduleData.startDate = startDate;
      state.scheduleData.min = min;
      state.scheduleData.max = max;
      state.scheduleData.slotDuration = slotDuration;
      state.scheduleData.isScheduleCreated = isScheduleCreated;
    }

    if (props.model && props.model.createDate) {
      const convertToSelectType = (arr: Array<{ item1: string; item2: number }>): Types.ISelectOption[] =>
        arr.map((piece: { item1: string; item2: number }) => ({ label: piece.item1, value: piece.item2 }));
        
      const classroomSolution = props.model.classroomSolution.map((item: any) => ({ label: item.label, value: item.value }));
      const instructorSolution = props.model.instructorSolution.map((item: any) => ({ label: item.label, value: item.value }));
      const programSolution = props.model.programSolution.map((item: any) => ({ label: item.value + " - " + item.label, value: item.value }));
      const branchSolution = props.model.branchSolution.map((item: any) => ({ label: item.item1, value: item.item2 }));

      // let programSolution = null;
      // let programSolutionEduType = props.model.programSolution.map((item: any) => ({ label: item.label.substring(0, item.label.indexOf('N.' || 'O.' || 'Ö.' || 'U.' || 'Ü.')), value: item.value }));
      // if(programSolutionEduType.label != null){
      //   programSolution =props.model.programSolution.map((item: any) => ({ label: item.value + " - " + item.label.substring(0, item.label.indexOf('N.' || 'O.' || 'Ö.' || 'U.' || 'Ü.')), value: item.value }));
      // }else{
      //   programSolution = props.model.programSolution.map((item: any) => ({ label: item.value + " - " + item.label, value: item.value }));
      // }

      state.dataModel = props.model;
      state.initialModel = props.model.solutionActivities;
      state.selectOptions = {
        ...state.filters,
        classroomSolution,
        instructorSolution,
        programSolution,
        branchSolution
      };
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  getSelectOptions = () => {
    if (this.props.model && Object.keys(this.props.model).length) {
      const dataModel = this.props.model;
      const classroomSolution = this.props.model.classroomSolution.map((item: any) => ({ label: item.label, value: item.value }));
      const instructorSolution = this.props.model.instructorSolution.map((item: any) => ({ label: item.label, value: item.value }));
      const programSolution = this.props.model.programSolution.map((item: any) => ({ label: item.value + " - " + item.label, value: item.value }));
      const branchSolution = this.props.model.branchSolution.map((item: any) => ({ label: item.item1, value: item.item2 }));

      // let programSolution = null;
      // let programSolutionEduType = this.props.model.programSolution.map((item: any) => ({ label: item.label.substring(0, item.label.indexOf('N.' || 'O.' || 'Ö.' || 'U.' || 'Ü.')), value: item.value }));
      // if(programSolutionEduType.label != null){
      //   programSolution = this.props.model.programSolution.map((item: any) => ({ label: item.value + " - " + item.label.substring(0, item.label.indexOf('N.' || 'O.' || 'Ö.' || 'U.' || 'Ü.')), value: item.value }));
      // }else{
      //   programSolution = this.props.model.programSolution.map((item: any) => ({ label: item.value + " - " + item.label, value: item.value }));
      // }

      this.setState({
        ...this.state,
        dataModel: this.props.model,
        initialModel: this.props.model.solutionActivities,
        selectOptions: {
          ...this.state.filters,
          classroomSolution,
          instructorSolution,
          programSolution,
          branchSolution
        }
      });
    }
  };

  // generates model based on applied filters.
  createSolution = () => {
    let filteredModel: any = this.state.initialModel;

    if (this.state.filters.programSolution.length) {
      const programSolution: any[] = this.state.filters.programSolution;
      const solutionPrograms = this.state.dataModel.solutionPrograms.filter((item: any) =>
        //item.programCode === programSolution.toString()
        programSolution.some((program) => item.programCode === program)
      );
      const _ids = solutionPrograms.map((item: any) => item.activityNo);
      const model = filteredModel.filter((activity: any) => _ids.some((_id: any) => activity.activityNo == _id));
      filteredModel = model;
    }

    if (this.state.filters.branchSolution.length) {
      const branches: any[] = this.state.filters.branchSolution;
      const model = filteredModel.filter((item: any) => branches.includes(item.branch));
      filteredModel = model;
    }

    if (this.state.filters.instructorSolution.length) {
      const instructorSolution: any[] = this.state.filters.instructorSolution;
      const model = filteredModel.filter((item: any) =>
        item.instructorCodes.some((code: any) => instructorSolution.includes(code))
      );
      filteredModel = model;
    }

    if (this.state.filters.classroomSolution.length) {
      const classroomSolution: any[] = this.state.filters.classroomSolution;
      const model = filteredModel.filter((item: any) =>
        classroomSolution.some((classroom) => classroom == item.classroomCode)
      );
      filteredModel = model;
    }

    if (this.state.filters.weeks.length) {
      const week = this.state.filters.weeks;
      const model = filteredModel.filter((item: any) =>
        item.weeks.some((code: any) => week.includes(code))
      );
      filteredModel = model;
    }

    if (
      !this.state.filters.programSolution.length &&
      !this.state.filters.instructorSolution.length &&
      !this.state.filters.classroomSolution.length &&
      !this.state.filters.branchSolution.length
    ) {
      filteredModel = [];
    }

    this.createDataToComponent(filteredModel);
  };

  createDataToComponent = (filteredModel: Array<any>) => {

    // Ders döneminde takvimin gösterebileceği örnek bir {T.t('gen_week')} olması gerekiyordu.
    // Tarihleri önemli değil sadece pazartesiden pazara kadar günleri - gerçek tarihlere göre - doğru içermesi yeterli.
    const weekDays = [
      '2020-11-16T',
      '2020-11-17T',
      '2020-11-18T',
      '2020-11-19T',
      '2020-11-20T',
      '2020-11-21T',
      '2020-11-22T'
    ];
    const resultModel = filteredModel.map((activity: any) => {
      const slotDuration = this.props.solutionInfo.slot_duration.split(':');
      let activityProgramInfo;
      const durationMinutes = slotDuration[0] * 60 + parseInt(slotDuration[1], 10);
      const activityDateInfo = this.state.dataModel.solutionStartDates.find(
        (item: any) => item.activityNo == activity.activityNo
      );

      const hourToString = activityDateInfo.hour.toString() + ':' + activityDateInfo.minute.toString();
      const createHour = moment(hourToString, 'HH:m')
      const startDate = moment(weekDays[activityDateInfo.weekOfDay - 1] + createHour.format('HH:mm:ss'));
      const endDate = startDate.clone().add(durationMinutes * activity.activityDuration, 'm');

      if (this.state.filters.programSolution.length) {
        activityProgramInfo = this.state.dataModel.solutionPrograms.find(
          (item: any) => item.activityNo == activity.activityNo && item.programCode == this.state.filters.programSolution[0]
        );
      } else {
        activityProgramInfo = this.state.dataModel.solutionPrograms.find(
          (item: any) => item.activityNo == activity.activityNo
        );
      }
      const activityProgram = activityProgramInfo ? activityProgramInfo.programCode + ' - ' + activityProgramInfo.programName : '-';

      // let activityProgram = null;
      // let programNameSub = activityProgramInfo.programName.substring(0, activityProgramInfo.programName.indexOf('N.' || 'O.' || 'Ö.' || 'U.' || 'Ü.'));
      // if(programNameSub != null){
      //   activityProgram = activityProgramInfo ? activityProgramInfo.programCode +' - '+ activityProgramInfo.programName.substring(0, activityProgramInfo.programName.indexOf('N.' || 'O.' || 'Ö.' || 'U.' || 'Ü.')) : '-';
      // } 
      // activityProgram = activityProgramInfo ? activityProgramInfo.programCode +' - '+ activityProgramInfo.programName : '-';
      // startDate =  2020-01-16T13:00:00

      const activityInfo = {
        id: activity.activityNo,
        code: activity.courseCode,
        name: activity.courseName,
        date: moment(startDate).format('dddd HH:mm') + ' - ' + moment(endDate).format('HH:mm'),
        campus: activity.campusName,
        duration: durationMinutes * activity.activityDuration,
        instructor: activity.instructorName,
        program: activityProgram,
        educationType: activity.education,
        studentCount: activity.studentCount,
        classroom: activity.classroomCode ? activity.classroomCode + ' - ' + activity.classroomName : '-'
      };

      return {
        id: activity.activityNo,
        title: activity.courseCode + ' - ' + activity.courseName + ('\n') + activity.campusName,
        start: startDate.toDate(),
        end: endDate.toDate(),
        desc: JSON.stringify(activityInfo),
        type: activity.type ? activity.type : ''
      };
    });
    this.setState({
      ...this.state,
      filteredModel,
      resultModel
    });
  };

  printDocument() {
    const input = document.getElementById('printed-component');
    h2c(input!)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png', 1.0);
        const pdf = new jsPDF("l", "mm", "a4");
        var doc = new jsPDF("l", "mm", "a4");
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        pdf.addImage(imgData, 'PNG', 15, 3, 265, 200, '', 'MEDIUM', 0);
        pdf.save("Çozüm.pdf");
      })
      ;
  }

  render() {
    return (
      <React.Fragment>
        <div id="printed-component">
          <div className="row solution-row">
            {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
              this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS ||
              this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS_EX_INVIGILATORS) && (
                <div className="col-md-3">
                  <div className="add-custom-tag mb-3">
                    <div className="react-select-container">
                      <label>{T.t('gen_program_tables')}</label>
                      <Select
                        className="react-select"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={this.state.selectOptions.programSolution}
                        placeholder={T.t('gen_select_program')}
                        onChange={(options: any) => {
                          // const ids =
                          //   options && options.length ? options.map((option: Types.ISelectOption) => option.value) : [];
                          const id = [options.value];
                          this.setState(
                            {
                              ...this.state,
                              filters: {
                                ...this.state.filters,
                                programSolution: id
                              },
                              selected_Options: {
                                ...this.state.selected_Options,
                                programSolution: options
                              }
                            },
                            () => this.createSolution()
                          );
                        }}
                        value={this.state.selected_Options.programSolution}
                        noOptionsMessage={(): string => T.t('gen_select_no_program')}
                      />
                    </div>
                  </div>
                </div>
              )}
            {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
              this.props.activeTab === constants.SOLUTION_GET_BY_PROGRAMS) && (
                <div className="col-ml-1">
                  <div className="add-custom-tag mb-3 ml-3 mr-3">
                    <div className="react-select-container">
                      <label>{T.t('gen_branch_tables')}</label>
                      <Select
                        className="react-select"
                        isMulti={true}
                        closeMenuOnSelect={false}
                        options={this.state.selectOptions.branchSolution}
                        placeholder={T.t('gen_select_branch')}
                        onChange={(options: any) => {
                          const ids =
                            options && options.length ? options.map((option: Types.ISelectOption) => option.value) : [];
                          this.setState(
                            {
                              ...this.state,
                              filters: {
                                ...this.state.filters,
                                branchSolution: ids
                              },
                              selected_Options: {
                                ...this.state.selected_Options,
                                branchSolution: options
                              }
                            },
                            () => this.createSolution()
                          );
                        }}
                        value={this.state.selected_Options.branchSolution}
                        noOptionsMessage={(): string => T.t('gen_select_no_branch')}
                      />
                    </div>
                  </div>
                </div>
              )}
            {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
              this.props.activeTab === constants.SOLUTION_GET_BY_INSTRUCTORS) && (
                <div className="col-md-3">
                  <div className="add-custom-tag mb-3">
                    <div className="react-select-container">
                      <label>{T.t('gen_instructor_tables')}</label>
                      <Select
                        className="react-select"
                        isMulti={true}
                        closeMenuOnSelect={false}
                        options={this.state.selectOptions.instructorSolution}
                        placeholder={T.t('gen_select_instructor')}
                        onChange={(options: any) => {
                          const ids =
                            options && options.length ? options.map((option: Types.ISelectOption) => option.value) : [];
                          this.setState(
                            {
                              ...this.state,
                              filters: {
                                ...this.state.filters,
                                instructorSolution: ids
                              },
                              selected_Options: {
                                ...this.state.selected_Options,
                                instructorSolution: options
                              }
                            },
                            () => this.createSolution()
                          );
                        }}
                        value={this.state.selected_Options.instructorSolution}
                        noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                      />
                    </div>
                  </div>
                </div>
              )}
            {(this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES ||
              this.props.activeTab === constants.SOLUTION_GET_BY_CLASSROOMS) && (
                <div className="col-md-2">
                  <div className="add-custom-tag mb-3">
                    <div className="react-select-container">
                      <label>{T.t('gen_classroom_tables')}</label>
                      <Select
                        className="react-select"
                        isMulti={true}
                        closeMenuOnSelect={false}
                        options={this.state.selectOptions.classroomSolution}
                        placeholder={T.t('gen_select_classroom')}
                        onChange={(options: any) => {
                          const ids =
                            options && options.length ? options.map((option: Types.ISelectOption) => option.value) : [];
                          this.setState(
                            {
                              ...this.state,
                              filters: {
                                ...this.state.filters,
                                classroomSolution: ids
                              },
                              selected_Options: {
                                ...this.state.selected_Options,
                                classroomSolution: options
                              }
                            },
                            () => this.createSolution()
                          );
                        }}
                        value={this.state.selected_Options.classroomSolution}
                        noOptionsMessage={(): string => T.t('gen_select_no_classroom')}
                      />
                    </div>
                  </div>
                </div>
              )}
            {this.props.activeTab === constants.SOLUTION_GET_SINGLE_TABLES && (
              <div className="col-md-2">
                <div className="add-custom-tag mb-3">
                  <div className="react-select-container">
                    <label>{T.t('gen_week_tables')}</label>
                    <Select
                      className="react-select"
                      isMulti={true}
                      closeMenuOnSelect={true}
                      options={weekSelectOptions}
                      placeholder={T.t('gen_select_week')}
                      onChange={(options: any) => {
                        const ids = options && options.length ? options.map((option: Types.ISelectOption) => option.value) : [];
                        this.setState(
                          {
                            ...this.state,
                            filters: {
                              ...this.state.filters,
                              weeks: ids
                            },
                            selected_Options: {
                              ...this.state.selected_Options,
                              weeks: options
                            }
                          },
                          () => this.createSolution()
                        );
                      }}
                      value={this.state.selected_Options.weeks}
                      noOptionsMessage={(): string => T.t('gen_select_no_week')}
                    />
                  </div>
                </div>
              </div>
            )}
            {this.props.activeTab == constants.SOLUTION_GET_BY_PROGRAMS ? (<div className="col-md-7 col-ml-7"></div>) : null}
            <div className="col-md-11 print-button">
              <div className="add-custom-tag">
                <button className="category-tag-square tag-green" onClick={this.printDocument}>
                  {T.t('gen_print')}
                </button>
              </div>
            </div>
          </div>
          <div className="generic-wrapper" style={{ height: '100vh' }}>
            {this.state.scheduleData.isScheduleCreated && (
              <Calendar
                events={this.state.resultModel}
                min={new Date(0, 0, 0, this.state.scheduleData.min, 0, 0)}
                max={new Date(0, 0, 0, this.state.scheduleData.max, 0, 0)}
                formats={courseFormatsValues}
                step={this.state.scheduleData.step}
                timeslots={this.state.scheduleData.slotDuration}
                defaultDate={moment('2020-11-16').toDate()}
                localizer={localizer}
                messages={messagesValues}
                toolbar={false}
                defaultView={'week'}
                views={['week']}
                onSelectEvent={(event: Object, e: React.SyntheticEvent) => {
                  this.setState({
                    ...this.state,
                    selectedEvent: event,
                    eventModalIsOpen: true
                  });
                }}
                eventPropGetter={(event, start, end, isSelected) => {
                  let newStyle = {
                    backgroundColor: '#265985',
                    color: 'white',
                    borderRadius: '4px',
                    border: '1px solid #f64332'
                  };

                  return {
                    className: '',
                    style: newStyle
                  };
                }}
              />
            )}
          </div>
          {this.state.eventModalIsOpen && (
            <DisplayEventModal
              event={this.state.selectedEvent}
              instructors={this.state.selectOptions.instructorSolution}
              modalIsOpen={this.state.eventModalIsOpen}
              onClose={() =>
                this.setState({
                  ...this.state,
                  eventModalIsOpen: !this.state.eventModalIsOpen
                })
              }
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,

  ownProps: any
): any => {
  if (!store || !store.state) {
    return ownProps;
  }

  const newProps: any = Object.assign({}, ownProps, {
    model: store.state.solution_page && store.state.solution_page.solution,
    filters: store.state.solution_page && store.state.solution_page.filters,
    solutionInfo: store.state.examPeriodModal && store.state.examPeriodModal.exam_dates,
    user: store.state.user
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.solution_page) {
    return (
      !!equal(
        prev.state.solution_page && prev.state.solution_page,
        next.state.solution_page && next.state.solution_page
      ) &&
      !!equal(
        prev.state.solution_page && prev.state.solution_page.solution && prev.state.solution_page.solution.solutionList,
        next.state.solution_page && next.state.solution_page.solution && next.state.solution_page.solution.solutionList
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SolutionGrid);

export default container;
