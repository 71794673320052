import * as Types from '../../store/types';

export const constants = {
  PROGRAM_LIST_SEARCH: 'PROGRAM:LIST_SEARCH',
  PROGRAM_CREATE: 'PROGRAM:CREATE',
  PROGRAM_MULTI_CREATE: 'PROGRAM:MULTI_CREATE',
  PROGRAM_UPDATE: 'PROGRAM:UPDATE',
  PROGRAM_DELETE: 'PROGRAM:DELETE',
  PROGRAM_GET_BY_ID: 'PROGRAM:GET_BY_ID',
  PROGRAM_GET_SELECT_OPTIONS: 'PROGRAM:GET_SELECT_OPTIONS',
  PROGRAM_CREATE_EXCEL_BULK: 'PROGRAM:CREATE_EXCEL_BULK'
};

export const ProgramSearchInitialValues: Types.IFilterProgram = {
  program_code: '',
  name: '',
  status: [],
  faculties: [],
  faculty_ids: undefined,
  select_all: false,
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const ProgramFormInitialValues: Types.IProgramItem = {
  program_id: undefined,
  program_code: '',
  status: 1,
  name: '',
  faculty_name: '',
  daily_max_class: undefined,
  min_class_interval_bw_campuses: undefined,
  description: '',
  max_gap_between_courses: 7,
  grade: 0,
  grades: [],
  grade_ids: [],
  education_type: '',
  education_types: []
};

export const ProgramGradeSelectOptions: Array<Types.ISelectOption> = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 }
];

export const EducationTypeSelectOptions: Array<Types.ISelectOption> = [
  { label: 'Örgün Öğretim', value: 'NO' },
  { label: 'İkinci Öğretim', value: 'IO' },
  { label: 'Uzaktan Öğretim', value: 'UO' }
];
