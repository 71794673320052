import * as Types from '../types';

export const constants = {
  COURSE_LIST_SEARCH: 'COURSE:LIST_SEARCH',
  COURSE_CREATE: 'COURSE:CREATE',
  COURSE_UPDATE: 'COURSE:UPDATE',
  COURSE_DELETE: 'COURSE:DELETE',
  COURSE_GET_BY_ID: 'COURSE:GET_BY_ID',
  COURSE_GET_SELECT_OPTIONS: 'COURSE:GET_SELECT_OPTIONS',
  COURSE_CREATE_EXCEL_BULK: 'COURSE:CREATE_EXCEL_BULK'
};

export const CourseSearchInitialValues: Types.IFilterCourse = {
  course_code: '',
  name: '',
  status: [],
  terms: [],
  term_id: undefined,
  add_list: [],
  select_all: false,
  campuses: [],
  campus_ids: undefined,
  lecture_staff: [],
  lecture_staff_ids: undefined,
  programs: [],
  program_ids: undefined,
  assistant_staff: [],
  assistant_staff_ids: undefined,
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const CourseHoursSearchInitialValues: Types.IFilterCourse = {
  course_code: '',
  name: '',
  status: [1],
  terms: [],
  term_id: undefined,
  add_list: [],
  select_all: false,
  campuses: [],
  campus_ids: undefined,
  classroom_combine_status: [],
  classroom_combine_status_ids: undefined,
  lecture_staff: [],
  lecture_staff_ids: undefined,
  programs: [],
  program_ids: undefined,
  assistant_staff: [],
  assistant_staff_ids: undefined,
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const CourseFormInitialValues: Types.ICourseItem = {
  course_id: undefined,
  course_code: '',
  conjugate_course_code: '',
  status: 1,
  class: undefined,
  name: '',
  student_count: undefined,
  section: '',
  activity_type: undefined,
  education_type: undefined,
  lesson_count: undefined,
  week: undefined,
  exam_duration: undefined,
  lecture_duration: undefined,
  exam_session: undefined,
  invigilator_gap_after_exam: undefined,
  invigilator_gap_before_exam: undefined,
  practical_duration: undefined,
  term: undefined,
  assistant_staff: [],
  programs: [],
  lecture_staff: [],
  faculty_id: undefined
};

export const CombineExamsDataModel: Types.ICombineExamsDataModel = {
  term_id: -1,
  combined_courses: [],
  formValues: {
    course_code: '',
    conjugate_course_code: '',
    name: '',
    student_count: -1,
    lecture_staff: [],
    lecture_staff_ids: [],
    assistant_staff: [],
    assistant_staff_ids: [],
    programs: [],
    program_ids: [],
    campus: undefined,
    campus_id: 0,
    faculty: undefined,
    faculty_id: 0,
    exam_duration: undefined,
    class: undefined,
    section: '',
    exam_session: undefined,
    classroom_combine_status: -1,
    description: ''
  }
};

export const TermTypes: Array<Types.ISelectOption> = [
  { label: 'Güz', value: 1 },
  { label: 'Bahar', value: 2 },
  { label: 'Yaz', value: 3 }
];

export const ViewOptions: Array<Types.ISelectOption> = [
  { label: 'Sınav Planlama', value: 1 },
  { label: 'Ders Planlama', value: 2 },
  { label: 'Tüm Alanlar', value: 3 }
];

export const ClassroomCombineTypes: Array<Types.ISelectOption> = [
  { label: 'Birleşik', value: 0 },
  { label: 'Öğretim Elemanlarına Göre', value: 1 },
  { label: 'Programlara Göre', value: 2 }
];

export const CourseHourStatusTypes: Array<Types.ISelectOption> = [
  { label: 'İşlem Yapılmadı', value: 0 },
  { label: 'İşlem Yapıldı', value: 1 }
];

export const EducationTypeSelectOptions: Array<Types.ISelectOption> = [
  { label: 'Örgün Öğretim', value: 'N.Ö.' },
  { label: 'İkinci Öğretim', value: 'İ.Ö.' },
  { label: 'Uzaktan Öğretim', value: 'U.E.' }
];

export const CourseTermLectureLocations: Array<Types.ISelectOption> = [
  { label: 'Kurum Dışı - Sorumlu', value: 1000 },
  { label: 'Kurum Dışı - Aktif', value: 1001 },
  { label: 'Uzaktan Eğitim', value: 1002 }
];

export const ExamPeriodCourseTermLectureLocations: Array<Types.ISelectOption> = [
  { label: 'Uzaktan Sınav', value: 1003 }
];

export const LessonCount = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
];

export const Grades = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
];