import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { EducationTypeSelectOptions } from '../../../store/constants/course-const';

import * as Constants from '../../../store/constants/all';
import Translator from '../../../services/translate-factory';
const T = Translator.create();
const Logger = Log.create('CopyCourseModal');

class CopyCourseModal extends Component<any, any> {
  state: any = {
    term_id: -1,
    combined_courses: [],
    formValues: {
      student_count: -1,
      practical_staff: [],
      practical_staff_ids: undefined,
      lecture_staff: [],
      lecture_staff_ids: undefined
    }
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    /*let regexObject = window.location.pathname.match(/([^\/]+$)/);
    let id = regexObject ? parseInt(regexObject[0], 10) : -1;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  getEducationType = (type: string) => {
    switch (type) {
      case 'N.Ö.':
      case 'NO':
        return 'N.Ö.';
      case 'İ.Ö.':
      case 'IO':
        return 'İ.Ö.';
      case 'U.E.':
      case 'UE':
        return 'U.E.';
      default:
        return '';
    }
  };

  render() {
    const event = JSON.parse(this.props.event.desc);
    const educationType = EducationTypeSelectOptions.find(
      (item) => item.value == this.getEducationType(event.educationType)
    );

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '90%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <span className="lesson-code">{event.code}</span>
            <span className="lesson-name">{event.name}</span>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body  text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-orange">
                    <span className="tits">{T.t('gen_date') + ' ' + T.t('gen_time')}</span>
                    <span className="text">{event.date}</span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-green">
                    <span className="tits">{T.t('gen_course_duration')}</span>
                    <span className="text">{event.duration} {T.t('gen_minute')}</span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-red">
                    <span className="tits">{T.t('gen_campus')}</span>
                    <span className="text">{event.campus}</span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-blue">
                    <span className="tits">{T.t('gen_building')}</span>
                    <span className="text">{event.building ? event.building : '-'}</span>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="row b-bottom-gray">
                <div className="col-md-3 col-xs-12 p-15">
                    <span className="tits">{T.t('gen_instructor')}</span>
                  <span className="text p-0-15">{event.instructor}</span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_classroom')}</span>
                  <span className="text p-0-15">{event.classroom}</span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_program')}</span>
                  <span className="text p-0-15">{event.program}</span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_student_count')}</span>
                  <span className="text p-0-15">{event.studentCount}</span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_education_type')}</span>
                  <span className="text p-0-15">{educationType ? educationType.label : '-'}</span>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default CopyCourseModal;
