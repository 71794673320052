import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Log } from 'ng2-logger';

import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { ProgramSearchInitialValues } from '../../store/constants/program-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';

import ProgramForm from './program-form';
import IntegrationModal from './program-integration-modal';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import SortedColumn from '../../components/table/sorted-column';
import Paginate from '../../components/table/paginate';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import ImportModal, { ExcelImportKeys } from '../../components/excel-imports/import-modal';
import DownloadButton from '../../components/excel-imports/export';
import { SectionTypes } from '../../store/constants/enums';
import SyncProgramModal from './program-sync-modal';

import Translator from '../../services/translate-factory';
const T = Translator.create();
const L = Log.create('FacultyListPage');

class ProgramTable extends Component<Types.IProgramPageProps, Types.IProgramPageState> {
  state: Types.IProgramPageState = {
    filters: ProgramSearchInitialValues,
    filterIsOpen: false,
    programFormIsOpen: false,
    integrationModalIsOpen: false,
    programId: undefined,
    all_ids: [],
    selected_ids: []
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.searchPrograms();
    this.getProgramSelectOptions();
  }

  searchPrograms() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.program.PROGRAM_LIST_SEARCH, this.state.filters, 'program-list-spin')
    );
  }

  getProgramSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.program.PROGRAM_GET_SELECT_OPTIONS, 'program-list-spin'));
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchPrograms();
  };

  programFormIsOpen = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.PROGRAM, '/create')));
  };

  programFormOnClose = (refresh: boolean) => {
    if (this.state.programFormIsOpen) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.PROGRAM)));
    }
    if (refresh) {
      this.searchPrograms();
    }
  };

  onProgramImport = () => {
    this.searchPrograms();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchPrograms();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ProgramSearchInitialValues);
    this.setState(this.state);
    this.searchPrograms();
  };

  onFilterCourse(model: Types.IFilterCourse, FormActions: FormikActions<Types.IFilterCourse>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchPrograms();
    FormActions.setSubmitting(false);
  }

  onProgramEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.PROGRAM, '/' + id)));
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.program.PROGRAM_LIST_SEARCH,
            this.state.filters,
            'program-list-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onDeleteProgram = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_action'),
          body: T.t('gen_delete_program_question'),
          name: 'program_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (status: number) => {
              if (status == 200) {
                this.state.selected_ids = [];
                this.state.filters = ProgramSearchInitialValues;
                this.setState(this.state);
                this.searchPrograms();
              }
            };

            const deleteList = this.state.selected_ids;
            this.props.dispatch(
              Actions.ApiRequest(Constants.program.PROGRAM_DELETE, deleteList, 'program-list-spin', resultCallback)
            );
          }
        })
      );
    }
  };

  onSelectProgram = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  switchIntegrationModalStatus = () => {
    this.setState({
      ...this.state,
      integrationModalIsOpen: !this.state.integrationModalIsOpen
    });
  };

  static getDerivedStateFromProps(props: Types.IProgramPageProps, state: Types.IProgramPageState) {
    let hasNewState: boolean = false;
    if (props.match && props.match.params.id) {
      hasNewState = true;
      state.programFormIsOpen = true;
      if (props.match.params.id !== 'create') {
        state.programId = props.match.params.id;
      } else {
        state.programId = undefined;
      }
    } else {
      hasNewState = true;
      state.programFormIsOpen = false;
      state.programId = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let programList = this.props.results;
    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="faculty-page">
          <div className="main list-campus">
            <div className="container-fluid">
              <h4 className="mb-4 mb-sm-0">{T.t('gen_programs')}</h4>
              <Spinner name="program-list-spin" />
              <div className="white-container mt-4">
                <div className="row align-items-center mb-1">
                  <div className="col-md-6 col-sm-4 col-12">
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: []
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          className="category-tag-square float-left pr-2 pl-2"
                          style={{ margin: '5px', color: '#fff', backgroundColor: '#dc3545' }}
                          onClick={this.onDeleteProgram}
                        >
                          <i className="material-icons mr-2">delete_outline</i>
                          <span> {T.t('gen_delete_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button className="category-tag-square tag-green" onClick={this.programFormIsOpen}>
                          <i className="material-icons mr-2">add</i>
                          {T.t('gen_add_program')}
                        </button>
                        <button
                          className="category-tag-square tag-green ml-1"
                          onClick={this.switchIntegrationModalStatus}
                        >
                          <i className="material-icons mr-2">playlist_add</i>
                          Senkronizasyon İşlerini Listele
                        </button>
                        <ImportModal
                          title={T.t('gen_add_with_excel')}
                          componentKey={ExcelImportKeys.Programs}
                          dispatch={this.props.dispatch}
                          onImport={this.onProgramImport}
                        />
                        <DownloadButton title={'Programlar'} sectionType={SectionTypes.Programs} />
                      </React.Fragment>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-8 col-12 text-right">
                    <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                      <button
                        className="category-tag-square tag-glass float-right ml-3 mr-3"
                        style={{ margin: '5px' }}
                        onClick={() => {
                          this.state.filterIsOpen = !this.state.filterIsOpen;
                          this.setState(this.state);
                        }}
                      >
                        <i className="material-icons mr-2">filter_list</i>
                        <span>{T.t('gen_filter')}</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* FILTER STARTS HERE  */}
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3">
                    <Formik
                      initialValues={ProgramSearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterCourse(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterProgram>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="program_code"
                                    name="program_code"
                                    value={props.values.program_code}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="program_code">{T.t('gen_code')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="name"
                                    name="name"
                                    value={props.values.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_faculty_college')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.faculties
                                          ? this.props.selectOptions.faculties
                                          : []
                                      }
                                      placeholder={T.t('gen_select_faculty')}
                                      value={props.values.faculties ? props.values.faculties : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('faculties', list);
                                        props.setFieldValue(
                                          'faculty_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <div className="text-left">
                                  <h6>{T.t('gen_status')}</h6>
                                  <div className="tick-radio position-relative mb-3 d-inline-block">
                                    <MultipleCheckbox name="status" text={T.t('gen_active')} value={1} />
                                  </div>
                                  <div className="tick-radio position-relative mb-3 d-inline-block ml-4">
                                    <MultipleCheckbox name="status" text={T.t('gen_passive')} value={0} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                      <thead>
                        <tr>
                          <th data-cell="select">
                            <div className="tick-radio position-relative">
                              <input
                                type="checkbox"
                                className="form-radio"
                                checked={this.checkAllIdsSelected()}
                                onChange={this.onSelectAll}
                              />
                            </div>
                          </th>
                          <SortedColumn
                            datacell="status"
                            title={T.t('gen_status')}
                            sortkey="status"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="program_code"
                            title={T.t('gen_code')}
                            sortkey="program_code"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="name"
                            className="d-none d-lg-table-cell d-xl-table-cell"
                            title={T.t('gen_name')}
                            sortkey="name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="faculty_name"
                            className="d-none d-lg-table-cell d-xl-table-cell"
                            title={T.t('gen_faculty_college')}
                            sortkey="faculty_name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          {this.props.term_type === 0 ? (
                            <React.Fragment>
                              <th scope="col" className="text-center">
                                {T.t('gen_education_type')}
                              </th>
                              <th scope="col" className="text-center">
                                {T.t('gen_grade')}
                              </th>
                              <th scope="col" className="text-center">
                                {T.t('gen_max_gap_between_courses')}
                              </th>
                            </React.Fragment>
                          ) : null}
                          <SortedColumn
                            datacell="daily_max_class"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_daily_max_course')}
                            sortkey="daily_max_class"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="min_class_interval_bw_campuses"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_min_gap_between_campuses')}
                            sortkey="min_class_interval_bw_campuses"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">
                            {T.t('gen_description')}
                          </th>
                          <th scope="col" className="text-right">
                            <span className="text-right">{T.t('gen_actions')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {programList && programList.length
                          ? programList.map((item: any) => (
                            <tr key={'program-' + item.program_id} data-title={item.name}>
                              <td data-cell="select">
                                <div className="tick-radio position-relative">
                                  <input
                                    type="checkbox"
                                    className="form-radio"
                                    checked={
                                      this.state.selected_ids &&
                                      this.state.selected_ids.indexOf(item.program_id ? item.program_id : -1) > -1
                                    }
                                    data-id={item.program_id}
                                    onChange={this.onSelectProgram}
                                  />
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    className={
                                      `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(item.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_code')}>
                                {item.program_code}
                              </td>
                              <td data-label={T.t('gen_name')}>{item.name}</td>
                              <td data-label={T.t('gen_faculty_college')} className="text-center">
                                {item.faculty_name}
                              </td>
                              {this.props.term_type === 0 ? (
                                <React.Fragment>
                                  <td scope="row" data-label={T.t('gen_education_type')} className="text-center">
                                    {item.education_type === 'N.Ö.' || item.education_type === 'N.O.' || item.education_type === 'NÖ' || item.education_type === 'NO' ?
                                      'Örgün Öğretim' :
                                      item.education_type === 'İ.Ö.' || item.education_type === 'İ.O.' || item.education_type === 'İÖ' || item.education_type === 'İO' || item.education_type === 'IO' ?
                                        'İkinci Öğretim' :
                                        item.education_type === 'U.Ö.' || item.education_type === 'U.O.' || item.education_type === 'UÖ' || item.education_type === 'UO' ?
                                          'Uzaktan Öğretim' : null
                                    }
                                  </td>
                                  <td data-label={T.t('gen_grade')} className="text-center">
                                    {item.grade}
                                  </td>
                                  <td data-label="{T.t('gen_max_gap_between_courses')}" className="text-center">
                                    {item.max_gap_between_courses}
                                  </td>
                                </React.Fragment>
                              ) : null}
                              <td data-label={T.t('gen_daily_max_course')} className="text-center">
                                {item.daily_max_class}
                              </td>
                              <td data-label={T.t('gen_min_gap_between_campuses')} className="text-center">
                                {item.min_class_interval_bw_campuses}
                              </td>
                              <td
                                data-cell="programs"
                                id={'description-popover-' + item.program_id}
                                style={{ textAlign: 'center' }}
                              >
                                {item.description && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {item.description}
                                    </button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'description-popover-' + item.program_id}
                                    >
                                      <PopoverHeader>{T.t('gen_description')}</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">{item.description}</PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </td>
                              <td data-label={T.t('gen_actions')} className="table-buttons">
                                <div className="table-buttons-wrapper">
                                  <button
                                    data-toggle="tooltip"
                                    data-id={item.program_id}
                                    onClick={this.onProgramEdit}
                                    title="Düzenle"
                                    className="btn btn-light btn-sm table-button"
                                  >
                                    <span className="d-block" data-toggle="modal" data-target="#addNew">
                                      <i className="material-icons">edit</i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                    <div className="row-options justify-content-end">
                      <div
                        className="page-sorting d-flex align-items-center justify-content-center"
                        style={{ marginTop: '5px' }}
                      >
                        {this.props.results && this.props.results.length > 0 && (
                          <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
        <ProgramForm
          programId={this.state.programId}
          formIsOpen={this.state.programFormIsOpen}
          onClose={this.programFormOnClose}
          term_type={this.props.term_type}
        />
        {this.state.integrationModalIsOpen ? (
          <SyncProgramModal
            modalIsOpen={this.state.integrationModalIsOpen}
            onClose={this.switchIntegrationModalStatus}
            onUpdateList={() => this.searchPrograms()}
          />
        ) : null}
      </MainLayout>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IProgramPageProps): Types.IProgramPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IProgramPageProps = Object.assign({}, ownProps, {
    results: store.state.program_page && store.state.program_page.results,
    filters: store.state.program_page && store.state.program_page.filters,
    selectOptions: store.state.select_options && store.state.select_options.programPage,
    term_type: store.state.term_type
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.program_page) {
    return (
      !!equal(
        prev.state.program_page && prev.state.program_page.results,
        next.state.program_page && next.state.program_page.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programPage,
        next.state.select_options && next.state.select_options.programPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(ProgramTable);

export default container;
