import * as Types from '../types';
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';
const localizer = momentLocalizer(moment);

export const constants = {
  COURSE_INDEX: 'SOLUTION:INDEX',
  SOLUTION_GET_SINGLE_TABLES: 'SOLUTION:GET_SINGLE_TABLES',
  COURSE_SOLUTION_PUBLIC: 'COURSE_SOLUTION:PUBLIC',
  COURSE_SOLUTION_GET_SINGLE_TABLES: 'COURSE_SOLUTION:GET_SINGLE_TABLES',
  SOLUTION_GET_BY_PROGRAMS_INSTRUCTORS: 'SOLUTION:GET_BY_PROGRAMS_INSTRUCTORS',
  SOLUTION_GET_BY_PROGRAMS: 'SOLUTION:GET_BY_PROGRAMS',
  SOLUTION_GET_BY_INSTRUCTORS: 'SOLUTION:GET_BY_INSTRUCTORS',
  SOLUTION_GET_BY_CLASSROOMS: 'SOLUTION:GET_BY_CLASSROOMS',
  SOLUTION_GET_BY_INVIGILATORS: 'SOLUTION:GET_BY_INVIGILATORS',
  SOLUTION_GET_BY_PROGRAMS_EX_INVIGILATORS: 'SOLUTION:GET_BY_PROGRAMS_EX_INVIGILATORS',
  SOLUTION_GET_INVIGILATION_COUNTS: 'SOLUTION:GET_INVIGILATION_COUNTS',
  SOLUTION_PUBLISH_SOLUTION: 'SOLUTION_PUBLISH_SOLUTION'
};

export const SolutionPageInitialValues: Types.ISolutionPageProps = {
  term_id: undefined,
  name: ''
};

export const SolutionFilterInitialValues: Types.IFilterSolutionModel = {
  term_id: undefined,
  course_id: undefined,
  solutionId: -1,
  active_tab: '0',
  query: '',
  page: 1,
  order_by: '',
  total: -1,
  size: 10,
  name: ''
};

export const ISolutionModelInitialValues: Types.ISolutionModel = {
  createDate: '',
  explanation: '',
  instructors: [],
  programs: [],
  invigilators: [],
  classrooms: [],
  solutionExams: [],
  solutionClassrooms: [],
  solutionInvigilators: [],
  solutionList: []
};

export const ISolutionGridInitialValues: Types.ISolutionGridState = {
  // necessary data to create calendar component
  scheduleData: {
    startDate: moment().toDate(),
    min: 8,
    max: 18,
    step: 15,
    slotDuration: 60,
    isScheduleCreated: false
  },
  // raw solution data comes from props
  dataModel: ISolutionModelInitialValues,
  // initial solution exam data. never modified.
  initialModel: [],
  // filtered version of the initial model.
  filteredModel: [],
  // converted version of the filtered model for the calendar component to show.
  resultModel: [],
  // filters to generate filteredModel
  filters: {
    programs: [],
    classes: [],
    classrooms: [],
    instructors: [],
    invigilators: []
  },
  // selected option IDs to use in filterin operations
  selectOptions: {
    programs: [],
    classes: [],
    classrooms: [],
    instructors: [],
    invigilators: []
  },
  // select option objects (Types.ISelectOption) to control data in react-select components
  selected_Options: {
    programs: [],
    classes: [],
    classrooms: [],
    instructors: [],
    invigilators: []
  },
  // display selected event in modal
  eventModalIsOpen: false,
  selectedEvent: {}
};
export const ICourseSolutionGridInitialValues: any = {
  // necessary data to create calendar component
  scheduleData: {
    startDate: moment().toDate(),
    min: 8,
    max: 18,
    step: 15,
    slotDuration: 60,
    isScheduleCreated: false
  },
  // raw solution data comes from props
  dataModel: ISolutionModelInitialValues,
  // initial solution exam data. never modified.
  initialModel: [],
  // filtered version of the initial model.
  filteredModel: [],
  // converted version of the filtered model for the calendar component to show.
  resultModel: [],
  // filters to generate filteredModel
  filters: {
    programs: [],
    classrooms: [],
    instructors: [],
    weeks: [],
    classroomSolution: [],
    instructorSolution: [],
    programSolution: [],
    branchSolution: [],
  },
  // selected option IDs to use in filterin operations
  selectOptions: {
    programs: [],
    classrooms: [],
    instructors: [],
    weeks: [],
    classroomSolution: [],
    instructorSolution: [],
    programSolution: [],
    branchSolution: [],
  },
  // select option objects (Types.ISelectOption) to control data in react-select components
  selected_Options: {
    programs: [],
    classrooms: [],
    instructors: [],
    weeks: [],
    classroomSolution: [],
    instructorSolution: [],
    programSolution: [],
    branchSolution: [],
  },
  // display selected event in modal
  eventModalIsOpen: false,
  selectedEvent: {}
};

export const messagesValues = {
  date: 'Tarih',
  time: 'Saat',
  event: '• Aktivite Kodu - Aktivite Adı - Sınıf - Şube • Öğretim Elemanı',
  allDay: 'Tüm Gün',
  week: 'Hafta',
  work_week: 'İş Haftası',
  day: 'Gün',
  month: 'Ay',
  previous: 'Geri',
  next: 'İleri',
  yesterday: 'Dün',
  tomorrow: 'Yarın',
  today: 'Bugün',
  agenda: 'Ajanda',
  noEventsInRange: 'Bu aralıkta hiç aktivite yok.',
  showMore: function (e: any) {
    return '+' + e + ' sınav';
  }
};

export const formatsValues = {
  dayFormat: 'D MMMM dddd',
  dateFormat: 'D',
  weekdayFormat: 'dddd',
  dayHeaderFormat: (date: any) => localizer.format(date, 'D MMMM dddd', moment.locale('tr')),
  agendaDateFormat: (date: any) => localizer.format(date, 'D MMMM dddd', moment.locale('tr')),
  dayRangeHeaderFormat: (date: any) =>
    localizer.format(date.start, 'D MMMM', moment.locale('tr')) +
    ' — ' +
    localizer.format(date.end, 'D MMMM', moment.locale('tr')),
  selectRangeFormat: (date: any) => localizer.format(date, 'h m', moment.locale('tr')),
  agendaHeaderFormat: (date: any) =>
    localizer.format(date.start, 'D MMMM YYYY', moment.locale('tr')) +
    ' — ' +
    localizer.format(date.end, 'D MMMM YYYY', moment.locale('tr'))
};

export const courseFormatsValues = {
  dayFormat: 'dddd',
  dateFormat: 'D',
  weekdayFormat: 'dddd',
  dayHeaderFormat: (date: any) => localizer.format(date, 'D MMMM dddd', moment.locale('tr')),
  agendaDateFormat: (date: any) => localizer.format(date, 'D MMMM dddd', moment.locale('tr')),
  dayRangeHeaderFormat: (date: any) =>
    localizer.format(date.start, 'D MMMM', moment.locale('tr')) +
    ' — ' +
    localizer.format(date.end, 'D MMMM', moment.locale('tr')),
  selectRangeFormat: (date: any) => localizer.format(date, 'h m', moment.locale('tr')),
  agendaHeaderFormat: (date: any) =>
    localizer.format(date.start, 'D MMMM YYYY', moment.locale('tr')) +
    ' — ' +
    localizer.format(date.end, 'D MMMM YYYY', moment.locale('tr'))
};

export const weekSelectOptions = [
 {label: '1', value: 1},
 {label: '2', value: 2},
 {label: '3', value: 3},
 {label: '4', value: 4},
 {label: '5', value: 5},
 {label: '6', value: 6},
 {label: '7', value: 7},
 {label: '8', value: 8},
 {label: '9', value: 9},
 {label: '10', value: 10},
 {label: '11', value: 11},
 {label: '12', value: 12},
 {label: '13', value: 13},
 {label: '14', value: 14},
]
