import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { CourseTermLectureLocations, TermTypes, EducationTypeSelectOptions } from '../../store/constants/course-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import { CopyCourseFormVal } from './validations/copy-course-form-val';

import Translator from '../../services/translate-factory';
const T = Translator.create();

const equal = require('deep-equal');

const Logger = Log.create('CopyCourseModal');

class CopyCourseModal extends Component<any, any> {
  state: any = {
    term_id: -1,
    combined_courses: [],
    formValues: {
      student_count: -1,
      practical_staff: [],
      practical_staff_ids: undefined,
      lecture_staff: [],
      lecture_staff_ids: undefined
    },
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/);
    let id = regexObject ? parseInt(regexObject[0], 10) : -1;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onFormSave = (model: Types.ICopyActivityFormValues, FormActions: FormikActions<Types.ICopyActivityFormValues>) => {
    let courseInfo: any = {};

    let courseArray: { id: number; item: Types.IExamPeriod_ActivityItem } = this.props.course[0];
    if (courseArray && courseArray.item && courseArray.item.activityInfo) {
      courseInfo = { ...courseArray.item.activityInfo, ...model, term_id: this.state.term_id };
    }
    if (courseInfo && Object.keys(courseInfo).length) {
      courseInfo = Object.keys(courseInfo).reduce((object: any, key: string) => {
        if (key !== 'course_id') {
          object[key] = courseInfo[key];
        }
        return object;
      }, {});
    }

    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_activity_copy'),
        body: T.t('gen_copy_activity_question'),
        name: 'examPeriod_courses_delete',
        icon: 'warning',
        iconColor: 'red',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const resultCallback = (status: number) => {
            if (status === 200 || status === 201) {
              this.setCloseModal();
              this.onUpdateListFromModal();
            }
          };

          this.props.dispatch(
            Actions.ApiRequest(
              Constants.exam_period.EXAM_PERIOD_COURSETERM_COPY_COURSE_TO_PERIOD,
              courseInfo,
              'ep-copy-course',
              resultCallback
            )
          );
        }
      })
    );
    FormActions.setSubmitting(false);
  };

  static getDerivedStateFromProps(props: any, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;

    if (state.combineExamsModalIsOpen && !props.modalIsOpen) {
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  createFieldValues = (): Types.ICopyActivityFormValues => {
    let fields: Types.ICopyActivityFormValues = {
      course_code: '',
      student_count: -1,
      activity_type: '',
      lecture_staff: [],
      lecture_staff_ids: [],
      practical_staff_ids: [],
      practical_staff: [],
      program_ids: [],
      programs: [],
      lecture_location_id: -1,
      week: ''
    };

    if (this.props.course && this.props.course.length) {
      
      let hasDifferentValues = (arr: any) =>
        !arr.every((item: any, i: any, ref: any) =>
          JSON.stringify([...ref].shift()).includes(JSON.stringify(item)));

      let educationTypesArray: any;
      let educationTypeObject;
      let educationTypeNew = this.props.course.map((i: any) => i.item.activityInfo.education_type)
      /* Check if user entered different naming for each education type */
      if (educationTypeNew.includes('N.Ö.') || educationTypeNew.includes('NO') || educationTypeNew.includes('N.O.') || educationTypeNew.includes('NÖ')) {
        educationTypeNew.forEach((e: string, i: string | number) => {
          if (e === 'N.Ö.' || e === 'NO' || e === 'N.O.' || e === 'NÖ') {
            educationTypeNew[i] = 'N.Ö.';
          }
        });
      }
      else if (educationTypeNew.includes('İ.Ö.') || educationTypeNew.includes('IO') || educationTypeNew.includes('İ.O.') || educationTypeNew.includes('İÖ')) {
        educationTypeNew.forEach((e: string, i: string | number) => {
          if (e === 'İ.Ö.' || e === 'IO' || e === 'İ.O.' || e === 'İÖ') {
            educationTypeNew[i] = 'İ.Ö.';
          }
        });
      }
      else if (educationTypeNew.includes('UO') || educationTypeNew.includes('U.Ö.') || educationTypeNew.includes('U.O.') || educationTypeNew.includes('UÖ') || educationTypeNew.includes('U.E.')) {
        educationTypeNew.forEach((e: string, i: string | number) => {
          if (e === 'UO' || e === 'U.Ö.' || e === 'U.O.' || e === 'UÖ' || e === 'U.E.') {
            educationTypeNew[i] = 'U.E.';
          }
        });
      }

      if (hasDifferentValues(educationTypeNew)) {
        educationTypesArray = null;
        educationTypeObject = undefined;
      } else {
        educationTypesArray = [this.props.course[0].item.activityInfo.education_type];
        while (educationTypesArray.includes('NO')) {
          educationTypesArray[educationTypesArray.indexOf('NO')] = 'N.Ö.'
        }
        educationTypeObject = EducationTypeSelectOptions.find((item) => educationTypesArray.includes(item.value));
      }

      let courseArray: { id: number; item: Types.IExamPeriod_ActivityItem } = this.props.course[0];
      if (courseArray && courseArray.item && courseArray.item.activityInfo) {
        let activityInfo = courseArray.item.activityInfo;
        let programs = []
        if (activityInfo.programs) {
          programs.push(activityInfo.programs)
        }

        fields = {
          course_code: activityInfo.course_code,
          week: activityInfo.week,
          sections: activityInfo.sections ? activityInfo.sections : [],
          section: activityInfo.section ? activityInfo.section : '',
          student_count: activityInfo.student_count ? activityInfo.student_count : 0,
          activity_type: activityInfo.activity_type ? activityInfo.activity_type : '',
          lecture_staff: activityInfo.instructors ? activityInfo.instructors : [],
          lecture_staff_ids: activityInfo.instructor_ids ? activityInfo.instructor_ids : [],
          practical_staff: activityInfo.practical_staff ? activityInfo.practical_staff : [],
          practical_staff_ids: activityInfo.practical_staff_ids ? activityInfo.practical_staff_ids : [],
          programs: activityInfo.programs ? activityInfo.programs : [],
          program_ids: activityInfo.program_ids ? activityInfo.program_ids : [],
          lecture_location: activityInfo.lecture_location,
          lecture_location_id: activityInfo.lecture_location_id,
          educationType_object: educationTypeObject,
          education_type: educationTypeObject ? educationTypeObject.value : undefined,
          lesson_count: activityInfo.lesson_count,
          class: activityInfo.class
        };
      }
    }

    return fields;
  };

  render() {
    const formInitialValues = this.createFieldValues();
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...CourseTermLectureLocations, ...campusOptions];

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_copy_activity')}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="white-container collapse show" id="advance-search" style={{ boxShadow: 'none' }}>
                  <Formik
                    initialValues={formInitialValues}
                    enableReinitialize={true}
                    validationSchema={CopyCourseFormVal}
                    onSubmit={(values, actions) => {
                      this.onFormSave(values, actions);
                    }}
                  >
                    {(props: FormikProps<Types.ICopyActivityFormValues>) => {
                      const { values, handleChange, errors, handleBlur, handleSubmit, isSubmitting } = props;
                      let activityArray: { id: number; item: Types.IExamPeriod_ActivityItem } = this.props.course[0];
                      let activityInfo = activityArray && activityArray.item.activityInfo
                      let programs = [], locations = []

                      if (activityInfo && activityInfo.lecture_location) {
                        locations.push(activityInfo.lecture_location)
                        activityInfo.lecture_locations = locations
                      }

                      const courseLocation = allSelectOptions.find(
                        (option) => option.value == props.values.lecture_location_id
                      );

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="course_code"
                                name="course_code"
                                value={values.course_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="course_code">{T.t('gen_course_code')} {T.t('gen_max_char_10')}</label>
                              <ErrorMessage component="div" className="error" name="course_code" />
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="student_count"
                                name="student_count"
                                value={values.student_count}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="number"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="student_count">{T.t('gen_student_count')}</label>
                              <ErrorMessage component="div" className="error" name="student_count" />
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="activity_type"
                                name="activity_type"
                                value={values.activity_type}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="activity_type">{T.t('gen_activity')}</label>
                              <ErrorMessage component="div" className="error" name="activity_type" />
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="week"
                                name="week"
                                value={values.week}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="week">{T.t('gen_week')}</label>
                              <ErrorMessage component="div" className="error" name="week" />
                            </div>
                            <div className="col-md-4">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>Öğretim Elemanları</label>
                                  <Select
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={false}
                                    options={
                                      activityInfo && activityInfo.instructors
                                        ? activityInfo.instructors
                                        : []
                                    }
                                    placeholder={T.t('gen_select_instructor')}
                                    value={values.lecture_staff}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('lecture_staff', list);
                                      props.setFieldValue(
                                        'lecture_staff_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                  />
                                </div>

                                {errors && errors.lecture_staff_ids && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>Programlar</label>
                                  <Select
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={false}
                                    options={activityInfo && activityInfo.programs
                                      ? activityInfo.programs
                                      : []}
                                    placeholder={T.t('gen_select_program')}
                                    value={props.values.programs}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('programs', list);
                                      props.setFieldValue(
                                        'program_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                  />
                                </div>
                                {errors && errors.program_ids && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_lecture_location')}</label>
                                  <Select
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={true}
                                    options={activityInfo && activityInfo.lecture_locations
                                      ? activityInfo.lecture_locations
                                      : []}
                                    placeholder={T.t('gen_select_location')}
                                    value={
                                      values.lecture_location
                                        ? values.lecture_location
                                        : courseLocation
                                          ? courseLocation
                                          : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('lecture_location', option);
                                      props.setFieldValue('lecture_location_id', option && option.value);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                  />
                                </div>
                                {errors && errors.lecture_location_id && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-input form-group date-picker mt-3">
                                <input
                                  id="lesson_count"
                                  name="lesson_count"
                                  value={values.lesson_count}
                                  onChange={handleChange}
                                  type="number"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="lesson_count">{T.t('gen_lesson_count')}</label>
                                <ErrorMessage component="div" className="error" name="lesson_count" />
                              </div>
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="class"
                                name="class"
                                value={values.class}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="class">{T.t('gen_grade')}</label>
                              <ErrorMessage component="div" className="error" name="class" />
                            </div>
                            <div className="col-md-3 form-input form-group with-icon mt-3">
                              <input
                                id="section"
                                name="section"
                                value={props.values.section}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                required
                              />
                              <span className="highlight" />
                              <span className="bar" />
                              <label htmlFor="section">{T.t('gen_section')}</label>
                              <ErrorMessage component="div" className="error" name="section" />
                            </div>
                            <div className="col-md-3">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_education_type')}</label>
                                  <Select
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={true}
                                    options={EducationTypeSelectOptions}
                                    placeholder={T.t('gen_select_education_type')}
                                    value={props.values.educationType_object}
                                    onChange={(option: any) => {
                                      props.setFieldValue('education_type', option.value);
                                      props.setFieldValue('educationType_object', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                  />
                                </div>
                                {errors && errors.education_type && props.submitCount > 0 && (
                                  <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-6"></div>
                            <div className="col-6 text-right">
                              <button
                                type="button"
                                className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2"
                                onClick={() => props.handleSubmit()}
                                disabled={isSubmitting}
                              >
                                {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                          <hr />
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="col-12">
                  <h6>{T.t('gen_copied_activity')}</h6>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          Durum
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_activity_no')}
                        </th>
                        <th scope="col" className="text-center">
                          Kodu
                        </th>
                        <th scope="col" className="text-center">
                          Adı
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_activity')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_lesson_count')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_lesson_location')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_education_type')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_term')}
                        </th>
                        <th scope="col" className="text-center">
                          Program
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_grade')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_section')}
                        </th>
                        <th scope="col" className="text-center">
                          Öğretim Elemanları
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_student_count')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_week')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_description')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.props.course ? (
                      this.props.course.length ?
                        (this.props.course.map((courseObject: { id: number; item: Types.IExamPeriod_ActivityItem }) => {
                          let courseItem = courseObject.item.activityInfo;
                          const courseLocation = allSelectOptions.find(
                            (option) => option.value == (courseItem && courseItem.lecture_location_id)
                          );
                          let termObject = TermTypes.find((object) => object.value == (courseItem && courseItem.term));
                          let keyId = 'course-' + (courseItem && courseItem.activity_no) + (Math.random() * 1000)
                          return (
                            <tr
                              key={keyId}
                              data-title={courseItem && courseItem.activity_no}
                            >
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    className={
                                      `small-tag text-uppercase` +
                                      (courseItem && courseItem.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(courseItem && courseItem.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_activity_no')}>
                                {courseItem && courseItem.activity_no !== undefined ? courseItem.activity_no : '-'}
                              </td>
                              <td scope="row" data-label={T.t('gen_code')}>
                                {courseItem && courseItem.course_code ? courseItem.course_code : '-'}
                              </td>
                              <td data-label={T.t('gen_name')}>{courseItem && courseItem.course_name}</td>
                              <td data-label={T.t('gen_activity')}>{courseItem && courseItem.activity_type}</td>
                              <td data-label={T.t('gen_lesson_count')} className="text-center">
                                {courseItem && courseItem.lesson_count}
                              </td>
                              <td scope="row" data-label={T.t('gen_lesson_location')} className="text-center">
                                {courseItem && courseItem.lecture_location && courseItem.lecture_location.label}
                              </td>
                              <td data-label={T.t('gen_education_type')} className="text-center">
                                {courseItem && (courseItem.education_type == 'NO' ||
                                  courseItem.education_type == 'N.Ö.') ?
                                  'Örgün Öğretim' :
                                  (courseItem && courseItem.education_type)
                                }
                              </td>
                              <td data-label={T.t('gen_term')} className="text-center">
                                {/*termObject && termObject.label*/}
                                {courseItem && courseItem.term}
                              </td>
                              <td
                                data-cell="connected_program"
                                id={'connected_program-popover-' + (courseItem && courseItem.activity_no)}
                                style={{ textAlign: 'center' }}
                              >
                                {courseItem && courseItem.programs && courseItem && courseItem.programs.length > 0 && (
                                  <button className="multiple-info-tag small-tag group-ellipsis">
                                    {courseItem && courseItem.programs[0].label}
                                  </button>
                                )}
                                {courseItem && courseItem.programs && courseItem && courseItem.programs.length > 1 && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      +{courseItem && courseItem.programs.length - 1} Program</button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'connected_program-popover-' + (courseItem && courseItem.activity_no)}
                                    >
                                      <PopoverHeader>{T.t('gen_connected_programs')}</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">
                                        {courseItem &&
                                          courseItem.programs &&
                                          courseItem &&
                                          courseItem.programs.length > 0 &&
                                          courseItem &&
                                          courseItem.programs.map((connected_program: Types.ISelectOption) => {
                                            return (
                                              <React.Fragment key={'connected_program-' + connected_program.value}>
                                                <button className="multiple-info-tag small-tag text-capitalize">
                                                  {connected_program.label}
                                                </button>
                                                <br />
                                              </React.Fragment>
                                            );
                                          })}
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </td>
                              <td scope="row" data-label={T.t('gen_grade')} className="text-center">
                                {courseItem && courseItem.class}
                              </td>
                              <td scope="row" data-label={T.t('gen_section')} className="text-center">
                                {courseItem && courseItem.section}
                              </td>
                              <td
                                data-cell="instructors"
                                id={'instructors-popover-' + (courseItem && courseItem.activity_no)}
                                style={{ textAlign: 'center' }}
                              >
                                {courseItem &&
                                  courseItem.instructors &&
                                  courseItem &&
                                  courseItem.instructors.length > 0 && (
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {courseItem && courseItem.instructors[0].label}
                                    </button>
                                  )}
                                {courseItem &&
                                  courseItem.instructors &&
                                  courseItem &&
                                  courseItem.instructors.length > 1 && (
                                    <React.Fragment>
                                      <button className="multiple-info-tag small-tag group-ellipsis">
                                        +{courseItem && courseItem.instructors.length - 1} Kişi</button>
                                      <UncontrolledPopover
                                        trigger="hover focus"
                                        placement="bottom"
                                        target={'instructors-popover-' + (courseItem && courseItem.activity_no)}
                                      >
                                        <PopoverHeader>Öğretim Elemanları</PopoverHeader>
                                        <PopoverBody className="scrollable-popoverbody">
                                          {courseItem &&
                                            courseItem.instructors &&
                                            courseItem &&
                                            courseItem.instructors.length > 0 &&
                                            courseItem &&
                                            courseItem.instructors.map((instructors: Types.ISelectOption) => {
                                              return (
                                                <React.Fragment key={'instructors-' + instructors.value}>
                                                  <button className="multiple-info-tag small-tag text-capitalize">
                                                    {instructors.label}
                                                  </button>
                                                  <br />
                                                </React.Fragment>
                                              );
                                            })}
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </React.Fragment>
                                  )}
                              </td>
                              <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                                {courseItem && courseItem.student_count}
                              </td>
                              <td scope="row" data-label={T.t('gen_week')} className="text-center">
                                {courseItem && courseItem.week}
                              </td>
                              <td
                                data-cell={T.t('gen_name')}
                                id={'description-popover-' + (courseItem && courseItem.course_code)}
                                style={{ textAlign: 'center' }}
                              >
                                {courseItem && courseItem.description && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {courseItem && courseItem.description}
                                    </button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'description-popover-' + (courseItem && courseItem.course_code)}
                                    >
                                      <PopoverHeader>{T.t('gen_description')}</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">
                                        {courseItem && courseItem.description}
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </td>
                            </tr>
                          );
                        })) : null
                    ) : null
                    }</tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.coursePage
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CopyCourseModal);

export default container;
