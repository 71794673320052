import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants, InstructorSearchInitialValues } from '../constants/instructor-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';

function* getInstructors(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.InstructorSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getInstructorData(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.InstructorGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateInstructor(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.InstructorUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_instructor_updated', 'gen_success'));
  } else if(response && response.status === 409){
    yield put(Actions.Notification('gen_unique_email_error_occurred', 'gen_warning', 'danger'));
  }else{
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createInstructor(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.InstructorCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_instructor_created', 'gen_success'));
  } else if(response && response.status === 409){
    yield put(Actions.Notification('gen_unique_instructor_code_error_occurred', 'gen_warning', 'danger'));
  }else{
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteInstructor(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.InstructorDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_instructor_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.INSTRUCTOR_LIST_SEARCH, InstructorSearchInitialValues));
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    }
  } else if (response && response.status === 409) {
    yield put(Actions.Notification('gen_relation_occurred_courses', 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getInstructorSelectOptions(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.InstructorGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getProgramsByFacultyIdsInstructorSelectOptions(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ProgramsByFacultyIdsInstructorGetSelectOptions(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createInstructorExcelBulk(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.FacultyInstructorExcelBulk(action.body));
    if (response && (response.status === 200 || response.status === 409 || response.status === 400)) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }

  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchInstructorSearch() {
  yield takeEvery((action: any) => action.target === constants.INSTRUCTOR_LIST_SEARCH, getInstructors);
}

export function* watchInstructorGetSelectOptions() {
  yield takeEvery(
    (action: any) => action.target === constants.INSTRUCTOR_GET_SELECT_OPTIONS,
    getInstructorSelectOptions
  );
}

export function* watchProgramsByFacultyIdsInstructorGetSelectOptions() {
  yield takeEvery(
    (action: any) => action.target === constants.PROGRAMS_BY_FACULTYIDS_INSTRUCTOR_GET_SELECT_OPTIONS,
    getProgramsByFacultyIdsInstructorSelectOptions
  );
}

export function* watchInstructorGetById() {
  yield takeEvery((action: any) => action.target === constants.INSTRUCTOR_GET_BY_ID, getInstructorData);
}

export function* watchInstructorUpdate() {
  yield takeEvery((action: any) => action.target === constants.INSTRUCTOR_UPDATE, updateInstructor);
}

export function* watchInstructorCreate() {
  yield takeEvery((action: any) => action.target === constants.INSTRUCTOR_CREATE, createInstructor);
}

export function* watchInstructorDelete() {
  yield takeEvery((action: any) => action.target === constants.INSTRUCTOR_DELETE, deleteInstructor);
}

export function* watchCreateInstructorExcelBulk() {
  yield takeEvery((action: any) => action.target === constants.INSTRUCTOR_CREATE_EXCEL_BULK, createInstructorExcelBulk);
}
