import * as Types from '../types';

export const constants = {
  SYNCHRONIZATION_CREATE: 'SYNCHRONIZATION:CREATE',
  GET_INTEGRATION: 'GET_INTEGRATION',
  ADD_MULTI_WITH_INTEGRATION: 'ADD_MULTI_WITH_INTEGRATION',
  SYNCHRONIZATION_GET_BY_ID: 'SYNCHRONIZATION:GET_BY_ID',
  SYNCHRONIZATION_GET_SELECT_OPTIONS: 'SYNCHRONIZATION:GET_SELECT_OPTIONS',
  SYNCHRONIZATION_BUILDING_LIST_SEARCH: 'SYNCHRONIZATION:LIST_SEARCH',
  SYNCHRONIZATION_PROGRAM_LIST_SEARCH: 'SYNCHRONIZATION:PROGRAM_SEARCH',
  SYNCHRONIZATION_CAMPUS_LIST_SEARCH: 'SYNCHRONIZATION:CAMPUS_SEARCH',
  SYNCHRONIZATION_CLASSROOM_LIST_SEARCH: 'SYNCHRONIZATION:CLASSROOM_SEARCH',
  SYNCHRONIZATION_FACULTY_LIST_SEARCH: 'SYNCHRONIZATION:FACULTY_SEARCH',
  SYNCHRONIZATION_INSTRUCTOR_LIST_SEARCH: 'SYNCHRONIZATION:INSTRUCTOR_SEARCH',
  SYNCHRONIZATION_COURSE_LIST_SEARCH: 'SYNCHRONIZATION:COURSE_SEARCH',
  SYNCHRONIZATION_STUDENT_LIST_SEARCH: 'SYNCHRONIZATION:STUDENT_SEARCH',
  // Synch Add To Main Tables
  SYNCHRONIZATION_BUILDING_ADD_TO_MAIN: 'SYNCHRONIZATION:BUILDING_ADD_TO_MAIN',
  SYNCHRONIZATION_CAMPUS_ADD_TO_MAIN: 'SYNCHRONIZATION:CAMPUS_ADD_TO_MAIN',
  SYNCHRONIZATION_CLASSROOM_ADD_TO_MAIN: 'SYNCHRONIZATION:CLASSROOM_ADD_TO_MAIN',
  SYNCHRONIZATION_FACULTY_ADD_TO_MAIN: 'SYNCHRONIZATION:FACULTY_ADD_TO_MAIN',
  SYNCHRONIZATION_PROGRAM_ADD_TO_MAIN: 'SYNCHRONIZATION:PROGRAM_ADD_TO_MAIN',
  SYNCHRONIZATION_INSTRUCTOR_ADD_TO_MAIN: 'SYNCHRONIZATION:INSTRUCTOR_ADD_TO_MAIN',
  SYNCHRONIZATION_COURSE_ADD_TO_MAIN: 'SYNCHRONIZATION:COURSE_ADD_TO_MAIN',
  SYNCHRONIZATION_STUDENT_ADD_TO_MAIN: 'SYNCHRONIZATION:STUDENT_ADD_TO_MAIN',
  // Synchronization For Modal List START
  SYNCHRONIZATION_MODAL_SELECT_OPTIONS: 'SYNCHRONIZATION:MODAL_SELECT_OPTIONS',
  SYNCHRONIZATION_CAMPUS_SELECT_OPTIONS: 'SYNCHRONIZATION:CAMPUS_SELECT_OPTIONS',
  SYNCHRONIZATION_BUILDING_SELECT_OPTIONS: 'SYNCHRONIZATION:BUILDING_SELECT_OPTIONS',
  SYNCHRONIZATION_PROGRAM_SELECT_OPTIONS: 'SYNCHRONIZATION:PROGRAM_SELECT_OPTIONS',
  SYNCHRONIZATION_FACULTY_SELECT_OPTIONS: 'SYNCHRONIZATION:FACULTY_SELECT_OPTIONS',
  // Getting Data For "Veri Aktarma Sayfası"
  SYNCHRONIZATION_GET_SYNC_JOB_STATUS: 'SYNCHRONIZATION:GET_SYNC_JOB_STATUS',
  // Sending Data To "Start Synchronization"
  SYNCHRONIZATION_MODAL_SEND_SYNCTYPE: 'SYNCHRONIZATION:MODAL_SEND_SYNCTYPE',
  // Displaying Data In Table
  SYNCHRONIZATION_DISPLAY_CAMPUS_DATA: 'SYNCHRONIZATION:DISPLAY_CAMPUS_DATA',
  // Senkronizasyon İşlerini Listele
  SYNCHRONIZATION_LIST_SYNC_JOBS: 'SYNCHRONIZATION:LIST_SYNC_JOBS',
  SYNCHRONIZATION_GET_FETCHED_DATA: 'SYNCHRONIZATION:GET_FETCHED_DATA',
  // Synchronization For Modal List END
};

export const SyncFormInitialValues: Types.ISyncItem = {
  sync_ids: undefined,
  sync_type: undefined,
  sync_job_id: undefined,
  campuses: [],
  buildings: [],
  programs: [],
  courses: [],
  faculties: [],
  year: undefined,
  term: undefined
};

export const SyncTypes: Array<Types.ISelectOption> = [
  { label: 'Kampüs', value: 1 },
  { label: 'Bina', value: 2 },
  { label: 'Derslik', value: 3 },
  { label: 'Fakülte / Yüksekokul / Enstitü', value: 4 },
  { label: 'Program / Bölüm', value: 5 },
  { label: 'Öğretim Elemanı', value: 6 },
  { label: 'Ders', value: 7 },
  { label: 'Öğrenci', value: 8 }
];

export const SyncTypesCourseTerm: Array<Types.ISelectOption> = [
  { label: 'Kampüs', value: 1 },
  { label: 'Bina', value: 2 },
  { label: 'Derslik', value: 3 },
  { label: 'Fakülte / Yüksekokul / Enstitü', value: 4 },
  { label: 'Program / Bölüm', value: 5 },
  { label: 'Öğretim Elemanı', value: 6 },
  { label: 'Ders', value: 7 }
];

export const Terms = [
  { label: 'Güz', value: 1 },
  { label: 'Bahar', value: 2 },
  { label: 'Yaz', value: 3 }
];

export const SyncBuildingSearchInitialValues: Types.IFilterSyncBuilding = {
  building_code: '',
  order_by: '',
  name: '',
  campuses: [],
  select_all: false,
  campus_ids: [],
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const SyncProgramSearchInitialValues: Types.IFilterSyncProgram = {
  program_code: '',
  order_by: '',
  name: '',
  faculties: [],
  select_all: false,
  faculty_ids: [],
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const SyncCampusSearchInitialValues: Types.IFilterSyncCampus = {
  campus_code: '',
  order_by: '',
  name: '',
  select_all: false,
  page: 1,
  query: '',
  total: -1,
  size: 10,
};

export const SyncCampusSearchInitialValuesModal: Types.IFilterSyncCampus = {
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
};

export const SyncClassroomSearchInitialValues: Types.IFilterSyncClassroom = {
  classroom_code: '',
  order_by: '',
  name: '',
  buildings: [],
  select_all: false,
  building_ids: [],
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const SyncFacultySearchInitialValues: Types.IFilterSyncFaculty = {
  faculty_code: '',
  order_by: '',
  name: '',
  select_all: false,
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const SyncInstructorSearchInitialValues: Types.IFilterSyncInstructor = {
  instructor_code: '',
  order_by: '',
  name: '',
  select_all: false,
  page: 1,
  query: '',
  total: -1,
  size: 10,
  year: 2020,
  term: 1
};

export const SyncCourseSearchInitialValues: Types.IFilterSyncCourse = {
  course_code: '',
  order_by: '',
  name: '',
  select_all: false,
  page: 1,
  query: '',
  total: -1,
  size: 10,
  year: 2020,
  term: 1
};

export const SyncCourseSearchInitialValuesModal: Types.IFilterSyncCourse = {
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
};

export const SyncInstructorSearchInitialValuesModal: Types.IFilterSyncInstructor = {
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
};

export const SyncProgramSearchInitialValuesModal: Types.IFilterSyncProgram = {
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
};

export const SyncStudentSearchInitialValues: Types.IFilterSyncStudent = {
  student_code: '',
  order_by: '',
  name: '',
  select_all: false,
  page: 1,
  query: '',
  total: -1,
  size: 10,
  year: 2020,
  term: 1
};
