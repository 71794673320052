import * as Yup from 'yup';

export const CombineExamFormValidation = Yup.object().shape({
  name: Yup.string().required('Boş bırakılamaz!'),
  course_code: Yup.string().required('Boş bırakılamaz!'),
  education_type: Yup.string().required('Boş bırakılamaz!'),
  activity_type: Yup.string().required('Boş bırakılamaz!'),
  week: Yup.string().required('Boş bırakılamaz!'),
  instructor_ids: Yup.array().of(Yup.number()).required('Boş bırakılamaz!'),
  program_ids: Yup.array().of(Yup.number()).required('Boş bırakılamaz!')
});
