import * as Yup from 'yup';

export const FormValidation = Yup.object().shape({
  name: Yup.string()
    .required('Boş bırakılamaz!')
    .max(100, 'En fazla 100 karakter!'),
  year: Yup.number().required('Boş bırakılamaz!'),
  academic_term: Yup.number().required('Boş bırakılamaz!'),
  description: Yup.string()
    .required('Boş bırakılamaz!')
    .max(200, 'En fazla 200 karakter!')
});
