import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import Checkbox from '../../../components/checkboxes/single-checkbox';
import Spinner from '../../../components/templates/spinner';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import {
  ActivityFormInitialValues,
  TermTypes,
  ClassroomCombineTypes,
  EducationTypeSelectOptions,
  ActivityTermLectureLocations
} from '../../../store/constants/activity-const';
import * as Types from '../../../store/types';
import { ActivityFormValidation } from './validations/course-form-val';
import * as GT from '../../../tools/general-tools';
import { routes as Routes } from '../../../store/constants/routes';

import Translator from '../../../services/translate-factory';
const T = Translator.create();
const Logger = Log.create('ClassroomForm');

const semicolon = ';'

function getInitialState(): Types.IActivityFormState {
  const initialValues: Types.IActivityFormState = {
    model: Object.assign({}, ActivityFormInitialValues)
  };
  return Object.assign({}, initialValues);
}

class CourseForm extends Component<Types.IActivityFormProps, Types.IActivityFormState> {
  state: Types.IActivityFormState = getInitialState();
  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    this.state.model.faculty_id = undefined;
    this.setState(this.state);
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.IActivityItem, FormActions: FormikActions<Types.IActivityItem>) => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.setClose(true);
        //window.open("/course", "_self")
      };
    };

    let modelWithoutClass = {
      activity_no: model.activity_no,
      activity_type: model.activity_type,
      course_code: model.course_code,
      course_name: model.course_name,
      //course_term_status: model.course_term_status,
      educationType_object: model.educationType_object,
      education_type: model.education_type,
      faculty_id: model.faculty_id,
      instructor_ids: model.instructor_ids,
      instructors: model.instructors,
      lecture_location: model.lecture_location,
      lecture_location_id: model.lecture_location_id,
      lesson_count: model.lesson_count,
      program_ids: model.program_ids,
      programs: model.programs,
      section: model.section,
      status: model.status,
      student_count: model.student_count,
      term: model.term,
      term_id: model.term_id,
      week: model.week,
    }

    if ((model.program_ids && model.program_ids.length) !== (model.section && model.section.split(semicolon).length)) {
      this.props.dispatch(
        Actions.Notification('Şube sayısı Programlarla eşittir olmalı!', 'gen_warning', 'danger')
      );
    } else {
      if (this.props.activityNo) {
        this.props.dispatch(
          Actions.ApiRequest(Constants.activity.ACTIVITY_UPDATE, model, 'activity-form-spin', resultCallback)
        );
      } else {
        this.props.dispatch(
          Actions.ApiRequest(Constants.activity.ACTIVITY_CREATE, modelWithoutClass, 'activity-form-spin', resultCallback)
        );
      }
      FormActions.setSubmitting(false);
    }
  };

  static getDerivedStateFromProps(props: Types.IActivityFormProps, state: Types.IActivityFormState) {
    let hasNewState: boolean = false;

    if (props.activityNo && props.activityNo != state.model.activity_no) {
      state.model.activity_no = props.activityNo;
      props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_BY_ID, props.activityNo, 'activity-form-spin'));
      hasNewState = true;
    }
    if (props.form && props.activityNo && props.activityNo == state.model.activity_no) {
      state.model = props.form;
      if (props.form.lecture_location) {
        state.model.lecture_location_id = props.form.lecture_location.value;
      }
      if (props.form.practical_location) {
        state.model.practical_location_id = props.form.practical_location.value;
      }
      if (props.form.campus) {
        state.model.campus_id = props.form.campus.value;
      }
      if (props.form.practical_staff) {
        state.model.practical_staff_ids = props.form.practical_staff.map((f) => f.value);
      }
      if (props.form.instructors) {
        state.model.instructor_ids = props.form.instructors.map((f) => f.value);
      }
      if (props.form.programs) {
        state.model.program_ids = props.form.programs.map((f) => f.value);
      }
      if (props.form.assistant_staff) {
        state.model.assistant_staff_ids = props.form.assistant_staff.map((f) => f.value);
      }

      if (props.form.education_type !== undefined) {
        const educationTypesArray = [props.form.education_type];
        const educationTypeObject = EducationTypeSelectOptions.find((item) => educationTypesArray.includes(item.value));
        state.model.educationType_object = educationTypeObject;
      }
      hasNewState = true;
    }
    if (hasNewState) {
      return state;
    } else if (!props.activityNo && state.model.activity_no) {
      return getInitialState();
    } else return null;
  }

  render() {
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations, ...campusOptions];

    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Spinner name="faculty-form-spin" />

        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={ActivityFormValidation}
        >
          {(props: FormikProps<Types.IActivityItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit } = props;
            let modelWithoutClass = {
              activity_no: props.values.activity_no,
              activity_type: props.values.activity_type,
              course_code: props.values.course_code,
              course_name: props.values.course_name,
              //course_term_status: this.state.model.course_term_status,
              educationType_object: this.state.model.educationType_object,
              education_type: props.values.education_type,
              faculty_id: props.values.faculty_id,
              instructor_ids: props.values.instructor_ids,
              instructors: props.values.instructors,
              lecture_location: this.state.model.lecture_location,
              lecture_location_id: this.state.model.lecture_location_id,
              lesson_count: props.values.lesson_count,
              program_ids: props.values.program_ids,
              programs: props.values.programs,
              section: props.values.section,
              status: props.values.status,
              student_count: props.values.student_count,
              term: props.values.term,
              term_id: this.state.model.term_id,
              week: props.values.week,
            }
            const courseLocation = allSelectOptions.find((option) => option.value == props.values.lecture_location_id);
            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          <i className="material-icons mr-2">add_circle_outline</i>
                          <span>{this.props.activityNo ? T.t('gen_editing') : T.t('gen_add_course')}</span>
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.setCloseForm}
                        >
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="course_code"
                              name="course_code"
                              value={values.course_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="course_code">Kod {T.t('gen_max_char_10')}</label>
                            <ErrorMessage component="div" className="error" name="course_code" />
                          </div>
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="course_name"
                              name="course_name"
                              value={values.course_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="name">Ad</label>
                            <ErrorMessage component="div" className="error" name="name" />
                          </div>
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="student_count"
                              name="student_count"
                              value={values.student_count}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="student_count">{T.t('gen_student_count')}</label>
                            <ErrorMessage component="div" className="error" name="student_count" />
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_connected_programs_departments')}</label>
                                <Select
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                  }
                                  closeMenuOnSelect={false}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.programs
                                      ? this.props.selectOptions.programs
                                      : []
                                  }
                                  placeholder={T.t('gen_select_program')}
                                  value={props.values.programs}
                                  onChange={
                                    (options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('programs', list);
                                      props.setFieldValue(
                                        'program_ids',
                                        list.map((item) => item.value)
                                      );
                                      modelWithoutClass.programs = list;
                                      modelWithoutClass.program_ids = list.map((item) => item.value);
                                    }
                                  }
                                  noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                />
                                {/*<Select
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                  }
                                  closeMenuOnSelect={true}
                                  options={this.props.selectOptions && this.props.selectOptions.programs}
                                  placeholder={T.t('gen_select_location')}
                                  value={props.values.program}
                                  onChange={(option: any) => {
                                    props.setFieldValue('program', option);
                                    props.setFieldValue('program_id', option && option.value);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                />*/}
                              </div>
                              {errors && errors.program_ids && props.submitCount > 0 && (
                                <div className="error">Boş bırakılamaz!</div>
                              )}
                            </div>
                          </div>
                          {/*<div className="col-md-3 form-input form-group with-icon">
                            <input
                              id="class"
                              name="class"
                              value={values.class}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="class">{T.t('gen_grade')}</label>
                            <ErrorMessage component="div" className="error" name="class" />
                              </div>*/}
                          <div className="col-md-3 form-input form-group with-icon">
                            <input
                              id="section"
                              name="section"
                              value={values.section}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="section">{T.t('gen_section')}</label>
                            <ErrorMessage component="div" className="error" name="section" />
                            {errors && errors.section && props.submitCount > 0 && (
                              <div className="error">Boş bırakılamaz!</div>
                            )}
                          </div>
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="activity_type"
                              name="activity_type"
                              value={values.activity_type}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="activity_type">{T.t('gen_activity_type')}</label>
                            <ErrorMessage component="div" className="error" name="activity_type" />
                          </div>
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="lesson_count"
                              name="lesson_count"
                              value={values.lesson_count}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="lesson_count">{T.t('gen_lesson_count')}</label>
                            <ErrorMessage component="div" className="error" name="lesson_count" />
                          </div>
                          <div className="col-md-4 form-input form-group with-icon">
                            <input
                              id="week"
                              name="week"
                              value={values.week}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="week">{T.t('gen_week')}</label>
                            <ErrorMessage component="div" className="error" name="week" />
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_education_type')}</label>
                                <Select
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                  }
                                  closeMenuOnSelect={true}
                                  options={EducationTypeSelectOptions}
                                  placeholder={T.t('gen_select_education_type')}
                                  value={
                                    values.education_type != undefined
                                      ? EducationTypeSelectOptions.find((option) =>
                                        option.value == (
                                          values.education_type === 'NO' ? values.education_type = 'N.Ö.' :
                                            values.education_type === 'İO' ? values.education_type = 'İ.Ö.' :
                                              values.education_type === 'UE' ? values.education_type = 'U.E.' :
                                                values.education_type
                                        )
                                      )
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('education_type', option.value);
                                    props.setFieldValue('educationType_object', option);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                />
                              </div>
                              {errors && errors.education_type && props.submitCount > 0 && (
                                <div className="error">Boş bırakılamaz!</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_term')}</label>
                                <Select
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                  }
                                  closeMenuOnSelect={true}
                                  options={TermTypes}
                                  placeholder={T.t('gen_select_term')}
                                  value={
                                    values.term != undefined
                                      ? TermTypes.find((option) => option.label === (values.term && values.term.toString()))
                                      : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('term', option.label);
                                    props.setFieldValue('term_id', option && option.value);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                />
                              </div>
                              {errors && errors.term && props.submitCount > 0 && (
                                <div className="error">Boş bırakılamaz!</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_lecture_location')}</label>
                                <Select
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                  }
                                  closeMenuOnSelect={true}
                                  options={allSelectOptions}
                                  placeholder={T.t('gen_select_location')}
                                  value={
                                    props.values.lecture_location
                                      ? props.values.lecture_location
                                      : courseLocation
                                        ? courseLocation
                                        : null
                                  }
                                  onChange={(option: any) => {
                                    props.setFieldValue('lecture_location', option);
                                    props.setFieldValue('lecture_location_id', option && option.value);
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                              {errors && errors.lecture_location_id && props.submitCount > 0 && (
                                <div className="error">Boş bırakılamaz!</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>Öğretim Elemanları</label>
                                <Select
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                  }
                                  closeMenuOnSelect={false}
                                  options={
                                    this.props.selectOptions && this.props.selectOptions.instructors
                                      ? this.props.selectOptions.instructors
                                      : []
                                  }
                                  placeholder={T.t('gen_select_instructor')}
                                  value={props.values.instructors}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: Array<Types.ISelectOption> = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('instructors', list);
                                    props.setFieldValue(
                                      'instructor_ids',
                                      list.map((item) => item.value)
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                />
                              </div>
                              {errors && errors.instructor_ids && props.submitCount > 0 && (
                                <div className="error">Boş bırakılamaz!</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 form-input form-group with-icon">
                          <textarea
                            name="description"
                            className="form-input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            rows={2}
                            id="description"
                            value={values.description}
                            placeholder={T.t('gen_description') + ' ' + T.t('gen_max_char_200')}
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="description" />
                          <ErrorMessage component="div" className="error" name="description" />
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="text-left">
                              <h6>{T.t('gen_status')}</h6>
                              <div className="tick-radio position-relative d-inline-block">
                                <Checkbox name="status" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-gray min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              type="button"
                              data-dismiss="modal"
                              onClick={() => props.handleSubmit()}
                              className="btn btn-green"
                              aria-label="alert-success"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IActivityFormProps): Types.IActivityFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IActivityFormProps = Object.assign({}, ownProps, {
    form: store.state.activity_page && store.state.activity_page.form ? store.state.activity_page.form : getInitialState(),
    selectOptions: store.state.select_options && store.state.select_options.activityPage
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.activity_page) {
    return (
      !!equal(
        prev.state.activity_page && prev.state.activity_page.form,
        next.state.activity_page && next.state.activity_page.form
      ) //&&
      /*!!equal(
        prev.state.select_options && prev.state.select_options.coursePage,
        next.state.select_options && next.state.select_options.coursePage
      )*/
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseForm);

export default container;
