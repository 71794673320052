import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants, CourseSearchInitialValues } from '../constants/course-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';

function* getCourses(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CourseSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getCourseData(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CourseGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateCourse(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CourseUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_updated', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createCourse(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CourseCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_created', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteCourse(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CourseDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.COURSE_LIST_SEARCH, CourseSearchInitialValues));
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    }
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getCourseSelectOptions(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CourseGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createCourseExcelBulk(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.CourseCreateExcelBulk(action.body));
    if (response && (response.status === 200 || response.status === 409)) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchCourseSearch() {
  yield takeEvery((action: any) => action.target === constants.COURSE_LIST_SEARCH, getCourses);
}

export function* watchCreateCourseExcelBulk() {
  yield takeEvery((action: any) => action.target === constants.COURSE_CREATE_EXCEL_BULK, createCourseExcelBulk);
}

export function* watchCourseGetById() {
  yield takeEvery((action: any) => action.target === constants.COURSE_GET_BY_ID, getCourseData);
}

export function* watchCourseGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.COURSE_GET_SELECT_OPTIONS, getCourseSelectOptions);
}

export function* watchCourseUpdate() {
  yield takeEvery((action: any) => action.target === constants.COURSE_UPDATE, updateCourse);
}

export function* watchCourseCreate() {
  yield takeEvery((action: any) => action.target === constants.COURSE_CREATE, createCourse);
}
export function* watchCourseDelete() {
  yield takeEvery((action: any) => action.target === constants.COURSE_DELETE, deleteCourse);
}
