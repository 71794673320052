import * as Yup from 'yup';

export const CourseClassroomFormValidation = Yup.object().shape({
  classroom_code: Yup.string()
    .required('Boş bırakılamaz!')
    .max(50, 'En fazla 50 karakter!'),
  name: Yup.string().required('Boş bırakılamaz!'),
  classroom_type: Yup.string().required('Boş bırakılamaz!'),
  building_id: Yup.number().required('Boş bırakılamaz!'),
  feature_codes: Yup.array()
    .of(Yup.string())
    .required('Boş bırakılamaz!'),
  door_order: Yup.number().required('Boş bırakılamaz!'),
  building_floor: Yup.string().required('Boş bırakılamaz!'),
  lecture_capacity: Yup.number().required('Boş bırakılamaz!')
});
