import * as Yup from 'yup';

export const CourseInstructorFormValidation = Yup.object().shape({
  instructor_code: Yup.string()
    .required('Boş bırakılamaz!')
    .max(50, 'En fazla 50 karakter!'),
  name: Yup.string().required('Boş bırakılamaz!'),
  email: Yup.string().email('Email adresi geçersiz'),
  daily_max_class: Yup.number().required('Boş bırakılamaz!'),
  title: Yup.string().required('Boş bırakılamaz!'),
  staff_type: Yup.number().required('Boş bırakılamaz!'),
  faculty_of_duty_id: Yup.number().required('Boş Bıraklamaz!'),
  program_of_duty_id: Yup.number().required('Boş Bıraklamaz!'),
});
