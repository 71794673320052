import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Log } from 'ng2-logger';
import Flatpickr from 'react-flatpickr';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { FileInitialValues } from '../../store/constants/file-const';
import { initialValues, EmailServiceSelectOptions } from '../../store/constants/setting-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import moment from 'moment';
import 'moment/locale/tr';
import Switch from 'react-switch';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import CalendarSettings from './calendar-setting';
import ImageHolder from '../../components/image/image-holder';
import imgLogo from '../../assets_aplan/images/aplan-logo.svg';
import Translator from '../../services/translate-factory';
const T = Translator.create();
const L = Log.create('GroupListPage');

class APlanSettings extends Component<any, any> {
  state: any = Object.assign({}, initialValues);

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getSettings();
    this.getCourseSelectOptions();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getSettings() {
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_SETTINGS, 'course-list-spin'));
  }

  getCourseSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_GET_SELECT_OPTIONS, 'course-list-spin'));
  }

  saveSettings() {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('settings_save_title'),
        body: T.t('settings_save_question'),
        name: 'courseHours_save',
        icon: 'warning',
        iconColor: 'red',
        confirm: T.t('gen_save'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (status == 200) {
              this.setState(this.state);
              this.getSettings();
            }
          };
          this.props.dispatch(
            Actions.ApiRequest(Constants.setting.UPDATE_SETTINGS, this.state, 'exam-period-modal-tab-spin', resultCallback)
          );
        }
      })
    );
  }

  static getDerivedStateFromProps(props: any, state: Types.IBuildingPageState) {
    let hasNewState: boolean = false;

    if (props.settings && Object.keys(props.settings).length) {
      state = props.settings;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onUploadLogo = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.state.general.institutionLogo = URL.createObjectURL(img);
      this.setState(this.state);
    }
  };

  onBrandLogoUploadRequest = (imageHolder: ImageHolder) => {
    let options: Types.IUploadRequestOptions = {
      accept: 'image/*',
      maxSize: 100001
    };

    if (this.props.uploadOptions) {
      options.fileMaxSizes = this.props.uploadOptions.fileMaxSizes;
    }

    let file: Types.IFileView = Object.assign({}, FileInitialValues);
    file.shared = false;
    file.container = '_logo[brand]';

    const resultCallback = (file: Types.IFileView, status: string) => {
      L.data('resultCallback :', file, status);
      if (file) {
        if (imageHolder) {
          imageHolder.updateImage();
        }
      }
    };

    this.props.dispatch(Actions.UploadRequest(options, file, resultCallback));
  };

  onChangeRadioButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      this.setState({
        general: {
          userEntryType: e.currentTarget.value
        }
      });
    }
  }

  render() {
    const lang = localStorage.getItem("langid");
    L.data('aplan_settings_xx_lang', lang);
    let { general, planning, email } = this.state;
    //const student_invigilator_numbers = JSON.parse(planning.student_invigilator_number);

    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="building-page">
          <div className="main">
            <div className="container">
              <div className="row align-items-center mb-sm-4 mb-2">
                <div className="col-12">
                  <h4 className="mb-4 mb-sm-0">{T.t('gen_settings')}</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <nav className="inner-page-nav">
                    <div className="nav nav-tabs nav-fill mb-4" id="nav-tab" role="tablist">
                      <a
                        className="nav-item nav-link active"
                        id="general-tab"
                        data-toggle="tab"
                        href="#general"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('settings_institution')}
                      </a>
                      <a
                        className="nav-item nav-link"
                        id="planning-tab"
                        data-toggle="tab"
                        href="#term"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_term')}
                      </a>
                      <a
                        className="nav-item nav-link"
                        id="calendar-tab"
                        data-toggle="tab"
                        href="#calendar"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_calendar')}
                      </a>
                      <a
                        className="nav-item nav-link"
                        id="email-tab"
                        data-toggle="tab"
                        href="#email"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_email')}
                      </a>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general">
                      <div className="row mb-4">
                        <div className="col-md-6 form-input form-group with-icon mt-3">
                          <h5>{T.t('settings_institution_info')}</h5>
                          <input
                            name="institute-name"
                            id="institute-name"
                            type="text"
                            required
                            value={this.state.general.institutionName}
                            onChange={(e: any) => {
                              this.state.general.institutionName = e.currentTarget.value;
                              this.setState(this.state);
                            }}
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="institute-name" className="settings-label">{T.t('settings_institution_name')}</label>
                        </div>
                        <div className="col-md-6 form-input form-group with-icon mt-3">
                          <h5 className="settings-radio-button-h5">Öğretim Üyesi Giriş Yöntemi</h5>
                          <div className="tick-radio position-relative settings-radio-button">
                            <input
                              type="radio"
                              name="cla_invg_num_option_0"
                              id="cla_invg_num_option_0"
                              value={0}
                              checked={this.state.general.userEntryType == 0}
                              onChange={this.onChangeRadioButton}
                              className="settings-radio-button-input"
                            />
                            <label className="settings-radio-button-label">{T.t('gen_email')}</label>
                          </div>
                          <div className="tick-radio position-relative settings-radio-button">
                            <input
                              type="radio"
                              name="cla_invg_num_option_0"
                              id="cla_invg_num_option_0"
                              value={1}
                              checked={this.state.general.userEntryType == 1}
                              onChange={this.onChangeRadioButton}
                              className="settings-radio-button-input"
                            />
                            <label className="settings-radio-button-label">{T.t('gen_instructor_code')}</label>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-12 col-md-3 col-sm-12">
                        <div className="form-group">
                          <label className="logo-title-center">Kurum Logosu</label>
                          <div className="form-input">
                            <ImageHolder
                              src={''}
                              default={imgLogo}
                              title="200 x 200 px"
                              onClick={this.onBrandLogoUploadRequest}
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-12">
                          <button type="button" className="btn btn-green" onClick={() => this.saveSettings()}>
                            <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="term" role="tabpanel" aria-labelledby="term">
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <h6>{T.t('settings_term_departmentHeadsDateRange')}</h6>
                          <div className="form-input form-group with-icon">
                            <Flatpickr
                              placeholder={T.t('gen_select_dates')}
                              value={planning.departmentHeadsDateRange = this.props.settings && this.props.settings.planning && this.props.settings.planning.departmentHeadsDateRange}
                              options={{
                                //dateFormat: 'j F l',
                                dateFormat: 'Y-m-d',
                                mode: 'range',
                                //locale: Turkish,
                                defaultDate: this.props.settings && this.props.settings.planning && this.props.settings.planning.departmentHeadsDateRange
                              }}
                              onClose={(value) => {
                                planning.departmentHeadsDateRange = value.map((item) => moment(item).format('YYYY-MM-DD'));
                                this.props.settings.planning.departmentHeadsDateRange = planning.departmentHeadsDateRange;
                                this.setState(this.state);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h6>{T.t('settings_term_instructorsDateRange')}</h6>
                          <div className="form-input form-group with-icon">
                            <Flatpickr
                              placeholder={T.t('gen_select_dates')}
                              value={planning.instructorsDateRange = this.props.settings && this.props.settings.planning && this.props.settings.planning.instructorsDateRange}
                              options={{
                                dateFormat: 'Y-m-d',
                                mode: 'range',
                                //locale: Turkish,
                                defaultDate: this.props.settings && this.props.settings.planning && this.props.settings.planning.instructorsDateRange
                              }}
                              onClose={(value) => {
                                planning.instructorsDateRange = value.map((item) => moment(item).format('YYYY-MM-DD'));
                                this.props.settings.planning.instructorsDateRange = planning.instructorsDateRange;
                                this.setState(this.state);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h6>{T.t('settings_system_will_be_opened_for')}</h6>
                          <div className="tick-radio position-relative d-block">
                            <label style={{ cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                id="radio-active"
                                className="form-radio"
                                checked={this.state.planning.allowedStaff.includes(2)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e && e.currentTarget) {
                                    let selectedList = this.state.planning.allowedStaff;
                                    if (e.currentTarget.checked) {
                                      selectedList.push(2);
                                    } else {
                                      let index = selectedList.indexOf(2);
                                      selectedList.splice(index, 1);
                                    }
                                    this.state.planning.allowedStaff = selectedList;
                                    this.setState(this.state);
                                  }
                                }}
                              />
                              &nbsp;{T.t('settings_term_fullTimeInstructors')}
                            </label>
                          </div>
                          <div className="tick-radio position-relative d-block">
                            <label style={{ cursor: 'pointer' }}>
                              <input
                                type="checkbox"
                                id="radio-active"
                                className="form-radio"
                                checked={this.state.planning.allowedStaff.includes(1)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e && e.currentTarget) {
                                    let selectedList = this.state.planning.allowedStaff;
                                    if (e.currentTarget.checked) {
                                      selectedList.push(1);
                                    } else {
                                      let index = selectedList.indexOf(1);
                                      selectedList.splice(index, 1);
                                    }
                                    this.state.planning.allowedStaff = selectedList;
                                    this.setState(this.state);
                                  }
                                }}
                              />
                              &nbsp;{T.t('settings_term_partTimeInstructors')}
                            </label>
                          </div>
                        </div>
                        {this.props.term_type === 0 ? (
                          <React.Fragment>
                            <div className="col-md-6">
                              <h6>{T.t('settings_courseDurationWithoutBreak')}</h6>
                              <div className="form-input form-group with-icon">
                                <Flatpickr
                                  value={this.state.planning.courseDuration}
                                  placeholder={T.t('gen_select_time')}
                                  options={{
                                    enableTime: true,
                                    dateFormat: 'H:i',
                                    noCalendar: true,
                                    time_24hr: true,
                                    minTime: '00:05',
                                    maxTime: '03:00',
                                    defaultHour: 1
                                  }}
                                  onClose={(value) => {
                                    let hour = moment(value[0]).format('HH:mm');
                                    planning.courseDuration = hour;
                                    this.setState(this.state);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <hr />
                              <h6>{T.t('settings_secondaryEducationStartHour')}</h6>
                              <div className="form-input form-group with-icon">
                                <Flatpickr
                                  value={this.state.planning.secondaryEducationStartHour}
                                  placeholder={T.t('gen_select_time')}
                                  options={{
                                    enableTime: true,
                                    dateFormat: 'H:i',
                                    noCalendar: true,
                                    time_24hr: true,
                                    defaultHour: 17
                                  }}
                                  onClose={(value) => {
                                    let hour = moment(value[0]).format('HH:mm');
                                    planning.secondaryEducationStartHour = hour;
                                    this.setState(this.state);
                                  }}
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                            <React.Fragment>
                              <div className="col-md-6">
                                <h6>{T.t('settings_classroom_invigilator_count_settings')}</h6>
                                <label htmlFor="cla_invg_num_option_0" style={{ cursor: 'pointer' }}>
                                  <input
                                    type="radio"
                                    name="cla_invg_num_option_0"
                                    id="cla_invg_num_option_0"
                                    value={0}
                                    checked={this.state.planning.classroomInvigilationCount == 0}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      if (e && e.currentTarget) {
                                        this.state.planning.classroomInvigilationCount = e.currentTarget.value;
                                        this.setState(this.state);
                                      }
                                    }}
                                  />
                                &nbsp;{T.t('settings_classroom_invigilator_count_settings_opt1')}
                                </label>
                                <label htmlFor="cla_invg_num_option_1" style={{ cursor: 'pointer' }}>
                                  <input
                                    type="radio"
                                    name="cla_invg_num_option_1"
                                    id="cla_invg_num_option_1"
                                    value={1}
                                    checked={this.state.planning.classroomInvigilationCount == 1}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      if (e && e.currentTarget) {
                                        this.state.planning.classroomInvigilationCount = e.currentTarget.value;
                                        this.setState(this.state);
                                      }
                                    }}
                                  />
                                &nbsp;{T.t('settings_classroom_invigilator_count_settings_opt2')}
                                </label>
                                <label htmlFor="cla_invg_num_option_2" style={{ cursor: 'pointer' }}>
                                  <input
                                    type="radio"
                                    name="cla_invg_num_option_2"
                                    id="cla_invg_num_option_2"
                                    value={2}
                                    checked={this.state.planning.classroomInvigilationCount == 2}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      if (e && e.currentTarget) {
                                        this.state.planning.classroomInvigilationCount = e.currentTarget.value;
                                        this.setState(this.state);
                                      }
                                    }}
                                  />
                                &nbsp;{T.t('settings_classroom_invigilator_count_settings_opt3')}
                                </label>
                              </div>
                              <div className="col-md-6">
                                <hr />
                                <h6>{T.t('settings_invigilator_assign_type')}</h6>
                                <label htmlFor="invg_assgnt_option_0" style={{ cursor: 'pointer' }}>
                                  <input
                                    type="radio"
                                    name="invg_assgnt_option_0"
                                    id="invg_assgnt_option_0"
                                    value={0}
                                    checked={this.state.planning.invigilatorAssignmentType == 0}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      if (e && e.currentTarget) {
                                        this.state.planning.invigilatorAssignmentType = e.currentTarget.value;
                                        this.setState(this.state);
                                      }
                                    }}
                                  />
                                &nbsp;{T.t('settings_invigilator_assign_type_opt1')}
                                </label>

                                <label htmlFor="invg_assgnt_option_1" style={{ cursor: 'pointer' }}>
                                  <input
                                    type="radio"
                                    name="invg_assgnt_option_1"
                                    id="invg_assgnt_option_1"
                                    value={1}
                                    checked={this.state.planning.invigilatorAssignmentType == 1}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      if (e && e.currentTarget) {
                                        this.state.planning.invigilatorAssignmentType = e.currentTarget.value;
                                        this.setState(this.state);
                                      }
                                    }}
                                  />
                                &nbsp;{T.t('settings_invigilator_assign_type_opt2')}
                                </label>
                              </div>
                              <div className="col-md-6">
                                <hr />
                                <h6>{T.t('settings_gap_between_campuses')}</h6>
                                <div className="tick-radio position-relative d-block">
                                  <label style={{ cursor: 'pointer' }}>
                                    <input
                                      type="checkbox"
                                      id="radio-active"
                                      className="form-radio"
                                      checked={this.state.planning.gapBetweenCampuses.includes(0)}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e && e.currentTarget) {
                                          let selectedList = this.state.planning.gapBetweenCampuses;
                                          if (e.currentTarget.checked) {
                                            selectedList.push(0);
                                          } else {
                                            let index = selectedList.indexOf(0);
                                            selectedList.splice(index, 1);
                                          }
                                          this.state.planning.gapBetweenCampuses = selectedList;
                                          this.setState(this.state);
                                        }
                                      }}
                                    />
                                  &nbsp;{T.t('settings_check_program_campus_gap')}
                                  </label>
                                </div>
                                <div className="tick-radio position-relative d-block">
                                  <label style={{ cursor: 'pointer' }}>
                                    <input
                                      type="checkbox"
                                      id="radio-active"
                                      className="form-radio"
                                      checked={this.state.planning.gapBetweenCampuses.includes(1)}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e && e.currentTarget) {
                                          let selectedList = this.state.planning.gapBetweenCampuses;
                                          if (e.currentTarget.checked) {
                                            selectedList.push(1);
                                          } else {
                                            let index = selectedList.indexOf(1);
                                            selectedList.splice(index, 1);
                                          }
                                          this.state.planning.gapBetweenCampuses = selectedList;
                                          this.setState(this.state);
                                        }
                                      }}
                                    />
                                  &nbsp;{T.t('settings_check_instructor_campus_gap')}
                                  </label>
                                </div>
                                <div className="tick-radio position-relative d-block">
                                  <label style={{ cursor: 'pointer' }}>
                                    <input
                                      type="checkbox"
                                      id="radio-active"
                                      className="form-radio"
                                      checked={this.state.planning.gapBetweenCampuses.includes(2)}
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e && e.currentTarget) {
                                          let selectedList = this.state.planning.gapBetweenCampuses;
                                          if (e.currentTarget.checked) {
                                            selectedList.push(2);
                                          } else {
                                            let index = selectedList.indexOf(2);
                                            selectedList.splice(index, 1);
                                          }
                                          this.state.planning.gapBetweenCampuses = selectedList;
                                          this.setState(this.state);
                                        }
                                      }}
                                    />
                                  &nbsp;{T.t('settings_check_student_campus_gap')}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <hr />
                                <h6>{T.t('settings_student_invigilator_count')}</h6>
                                <table className="aplan-table table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Minimum</th>
                                      <th scope="col">Maksimum</th>
                                      <th scope="col">{T.t('gen_invigilator_count')}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/*student_invigilator_numbers.map((item: any, _index: number) => (
                                    <tr>
                                      <td className="form-input">
                                        <input
                                          name={'student-invigilator-number-' + item.option}
                                          value={item.min}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            item.min = e.currentTarget.value;
                                            this.state.planning.student_invigilator_number = JSON.stringify(
                                              student_invigilator_numbers
                                            );
                                            this.setState(this.state);
                                          }}
                                          type="number"
                                          required
                                        />
                                      </td>
                                      <td className="form-input">
                                        <input
                                          name={'student-invigilator-number-' + item.option}
                                          value={item.max}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            item.max = e.currentTarget.value;
                                            this.state.planning.student_invigilator_number = JSON.stringify(
                                              student_invigilator_numbers
                                            );
                                            this.setState(this.state);
                                          }}
                                          type="number"
                                          required
                                        />
                                      </td>
                                      <td className="form-input">
                                        <input
                                          name={'student-invigilator-number-' + item.option}
                                          value={item.num_of_invigilators}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            item.num_of_invigilators = e.currentTarget.value;
                                            this.state.planning.student_invigilator_number = JSON.stringify(
                                              student_invigilator_numbers
                                            );
                                            this.setState(this.state);
                                          }}
                                          type="number"
                                          required
                                        />
                                      </td>
                                    </tr>
                                  ))*/}
                                  </tbody>
                                </table>
                              </div>
                            </React.Fragment>
                          )}
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-green float-right"
                            onClick={() => this.saveSettings()}
                          >
                            <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="email" role="tabpanel" aria-labelledby="email">
                      <div className="row mb-4">
                        <div className="col-6 col-md-6 col-sm-6">
                          <div className="react-select-container">
                            <label>{T.t('settings_mail_service')}</label>
                            <Select
                              className="react-select"
                              isSearchable={false}
                              placeholder={T.t('gen_select_service')}
                              options={EmailServiceSelectOptions}
                              value={EmailServiceSelectOptions.find((item) => item.value == this.state.email.service)}
                              onChange={(item: any) => {
                                this.state.email.service = item.value;
                                this.setState(this.state);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 form-input form-group with-icon">
                          <input
                            name="email-sender"
                            id="email-sender"
                            type="mail"
                            required
                            value={this.state.email.sendingAdress}
                            onChange={(e: any) => {
                              this.state.email.sendingAddress = e.currentTarget.value;
                              this.setState(this.state);
                            }}
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="email-sender">{T.t('settings_mail_sending_mail_address')}</label>
                        </div>
                        <div className="col-6 col-md-6 col-sm-6">
                          <div className="react-switch-container">
                            <label>{T.t('settings_mail_validation')}</label>
                            <Switch
                              id="send_email"
                              className="react-switch"
                              onChange={(checked: boolean) => {
                                this.state.email.allowValidation = checked;
                                this.setState(this.state);
                              }}
                              checked={this.state.email.allowValidation}
                            />
                          </div>
                          <div className="react-switch-container">
                            <label>{T.t('settings_mail_send_mail_on_user_created')}</label>
                            <Switch
                              id="send_email"
                              className="react-switch"
                              onChange={(checked: boolean) => {
                                this.state.email.sendEmailonUserCreated = checked;
                                this.setState(this.state);
                              }}
                              checked={this.state.email.sendEmailonUserCreated}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button type="button" className="btn btn-green" onClick={() => this.saveSettings()}>
                            <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="calendar" role="tabpanel" aria-labelledby="calendar">
                      <CalendarSettings otherSettings={this.state} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
      </MainLayout>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    results: store.state.building_page && store.state.building_page.results,
    selectOptions: store.state.select_options && store.state.select_options.buildingPage,
    term_type: store.state.term_type,
    settings: store.state.settings
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(APlanSettings);

export default container;
