import * as Types from '../types';

export const constants = {
  ACTIVITY_LIST_SEARCH: 'ACTIVITY:LIST_SEARCH',
  ACTIVITY_CREATE: 'ACTIVITY:CREATE',
  ACTIVITY_UPDATE: 'ACTIVITY:UPDATE',
  ACTIVITY_DELETE: 'ACTIVITY:DELETE',
  ACTIVITY_GET_BY_ID: 'ACTIVITY:GET_BY_ID',
  ACTIVITY_IS_RELATED: 'ACTIVITY:IS_RELATED',
  ACTIVITY_GET_SELECT_OPTIONS: 'ACTIVITY:GET_SELECT_OPTIONS',
  ACTIVITY_CREATE_EXCEL_BULK: 'ACTIVITY:CREATE_EXCEL_BULK'
};

export const ActivitySearchInitialValues: Types.IFilterActivity = {
  course_code: '',
  course_name: '',
  status: [],
  terms: [],
  term_id: undefined,
  add_list: [],
  select_all: false,
  lecture_locations: undefined,
  lecture_location_ids: undefined,
  practical_locations: undefined,
  practical_location_ids: undefined,
  campuses: undefined,
  campus_ids: undefined,
  practical_staff: [],
  practical_staff_ids: undefined,
  instructors: [],
  instructor_ids: undefined,
  lecture_staff: [],
  lecture_staff_ids: undefined,
  programs: [],
  program_ids: undefined,
  assistant_staff: [],
  assistant_staff_ids: undefined,
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const ActivityHoursSearchInitialValues: Types.IFilterActivity = {
  course_code: '',
  course_name: '',
  status: [1],
  terms: [],
  term_id: undefined,
  add_list: [],
  select_all: false,
  lecture_locations: undefined,
  lecture_location_ids: undefined,
  practical_locations: undefined,
  practical_location_ids: undefined,
  campuses: undefined,
  campus_ids: undefined,
  practical_staff: [],
  practical_staff_ids: undefined,
  exam_supervisor: [],
  exam_supervisor_ids: undefined,
  classroom_combine_statuses: undefined,
  classroom_combine_status_ids: undefined,
  instructors: [],
  instructor_ids: undefined,
  lecture_staff: [],
  lecture_staff_ids: undefined,
  programs: [],
  program_ids: undefined,
  assistant_staff: [],
  assistant_staff_ids: undefined,
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const ActivityFormInitialValues: Types.IActivityItem = {
  activity_no: undefined,
  course_code: '',
  status: 1,
  class: undefined,
  group: '',
  course_name: '',
  student_count: undefined,
  section: '',
  activity_type: undefined,
  education_type: undefined,
  lesson_count: undefined,
  week: undefined,
  exam_duration: undefined,
  lecture_duration: undefined,
  exam_session: undefined,
  invigilator_gap_after_exam: undefined,
  invigilator_gap_before_exam: undefined,
  practical_duration: undefined,
  term: undefined,
  assistant_staff: [],
  programs: [],
  practical_staff: [],
  lecture_staff: [],
  instructors: [],
  instructor_ids: undefined,
  faculty_id: undefined
};

export const TermTypes: Array<Types.ISelectOption> = [
  { label: 'Güz', value: 1 },
  { label: 'Bahar', value: 2 },
  { label: 'Yaz', value: 3 }
];

export const ViewOptions: Array<Types.ISelectOption> = [
  { label: 'Sınav Planlama', value: 1 },
  { label: 'Ders Planlama', value: 2 },
  { label: 'Tüm Alanlar', value: 3 }
];

export const ClassroomCombineTypes: Array<Types.ISelectOption> = [
  { label: 'Öğretim Elemanlarına Göre', value: 1 },
  { label: 'Birleşik', value: 2 },
  { label: 'Programlara Göre', value: 3 }
];

export const EducationTypeSelectOptions: Array<Types.ISelectOption> = [
  { label: 'Örgün Öğretim', value: 'N.Ö.' },
  { label: 'İkinci Öğretim', value: 'İ.Ö.' },
  { label: 'Uzaktan Öğretim', value: 'U.E.' }
];

export const ActivityTermLectureLocations: Array<Types.ISelectOption> = [
  { label: 'Kurum Dışı - Sorumlu', value: 1000 },
  { label: 'Kurum Dışı - Aktif', value: 1001 },
  { label: 'Uzaktan Eğitim', value: 1002 }
];
