import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Switch from 'react-switch';
import { Table } from 'reactstrap';
import XLSX from 'xlsx';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import Spinner from '../templates/spinner';
import { ExcelImportKeys } from './import-modal';

import Translator from '../../services/translate-factory';
const T = Translator.create();
const ImportingKeySelectOptions: Types.ISelectOption[] = [
  { label: 'Ders Kodu', value: 'COURSE_CODE' },
  { label: 'Kontrol Etme', value: 'NONE' }
];

const SheetJSFT = ['xlsx', 'xls', 'csv', 'ods']
  .map(function (x) {
    return '.' + x;
  })
  .join(',');

let isWrongProgramCode: boolean;

class CourseImportForm extends React.Component<Types.IImportFormProps, Types.IImportFormState> {
  modalName = ExcelImportKeys.Courses;
  craeteExcelBulkAction = (this.props.term_type === 1 ? Constants.course.COURSE_CREATE_EXCEL_BULK : Constants.activity.ACTIVITY_CREATE_EXCEL_BULK);

  state: Types.IImportFormState = {
    options: {
      overrideData: false,
      //importingKey: this.props.term_type === 1 ? 'COURSE_CODE' : 'ACTIVITY_CODE',
      importingKey: 'COURSE_CODE',
      importingKeyNone: 'NONE',
      termId: this.props.term_id ? this.props.term_id : -1
    },
    acceptedEntries: [],
    rejectedEntries: []
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.props.dispatch(
      Actions.ApiRequest(this.craeteExcelBulkAction, {
        reset: true
      })
    );
  }

  post = () => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status === 500) {
        this.props.dispatch(
          Actions.ShowModal({
            title: 'Hata!',
            body: (
              <h6>
                Silinmesi istenilen veri, diğer kayıtlar tarafından kullanıldığından silinme işlemi gerçekleştirilemez.
              </h6>
            ),
            name: this.modalName + '_error',
            icon: 'error_outline',
            iconColor: 'red'
          })
        );
        this.props.dispatch(
          Actions.ShowModal({
            name: this.modalName,
            cancel: 'Kapat',
            confirm: 'Dosya Yükle'
          })
        );
      } else if (status === 200 || status === 409) {
        this.props.dispatch(
          Actions.ShowModal({
            name: this.modalName,
            confirm: 'Sonucu Excel Olarak İndir',
            cancel: 'Kapat',
            onConfirm: this.props.apiResultTableToExcel
          })
        );
        if (this.props.onImport) {
          this.props.onImport();
        }
      } else {
        this.props.dispatch(
          Actions.ShowModal({
            name: this.modalName,
            body: <h6>Beklenmeyen bir hata oluştu, lütfen verilerinizi kontrol ederek tekrar deneyiniz...</h6>,
            cancel: 'Tamam'
          })
        );
      }
      this.props.dispatch(
        Actions.ApiRequest(this.craeteExcelBulkAction, {
          reset: true
        })
      );
    }
    if (this.props.term_type == 1) {
      isWrongProgramCode = this.state.acceptedEntries.some((item: any) => {
        if ((item.ProgramCode && item.ProgramCode.split('-')[1]) && (item.ProgramCode && item.ProgramCode.split('-')[1]).split(' ')[0] !== (item.Class !== '10' ? ('0' + item.Class) : item.Class)) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (isWrongProgramCode) {
      this.props.dispatch(Actions.Notification('Lütfen program kodu parametreyi doğru formata çevirin.', 'gen_warning', 'danger'))
    } else {
      if (this.state.rejectedEntries.length === 0) {
        let postModel: Types.IImportPost = {
          options: this.state.options,
          items: this.state.acceptedEntries
        };
        this.props.dispatch(
          Actions.ApiRequest(this.craeteExcelBulkAction, postModel, this.modalName + '-spinner', resultCallback)
        );
        this.props.dispatch(Actions.ShowModal({ name: this.modalName, cancel: 'Kapat' }));
      }
    }
  };

  excelToJSON = (file: File) => {
    let reader = new FileReader();
    const headers =
      this.props.term_type === 1
        ? [
          'CourseCode',
          'ConjugateCourseCode',
          'CourseName',
          'CampusCode',
          'FacultyCode',
          'ProgramCodes',
          'Class',
          'Section',
          'LectureStaffCodes',
          'AssistantStaffCodes',
          'StudentCount',
          'ExamDuration',
          'ExamSession',
          'InvigilatorGapBeforeExam',
          'InvigilatorGapAfterExam',
          'ClassroomCombineStatus',
        ]
        : [
          'ActivityNo',
          'CourseCode',
          'CourseName',
          'ActivityType',
          'LessonCount',
          'LectureLocationCode',
          'EducationType',
          'Term',
          'ProgramCode',
          'Class',
          'Section',
          'LectureStaffCodes',
          'StudentCount',
          'Week'
        ];
    reader.onload = (e: any) => {
      let bstr = e.target.result;
      let wb = XLSX.read(bstr, { type: 'binary' });
      let wsname = wb.SheetNames[0];
      let ws = wb.Sheets[wsname];
      let data = XLSX.utils.sheet_to_json(ws, {
        header: headers,
        range: 1,
        raw: false
      }) as Array<Types.IExcelCourseRow>;
      this.state.acceptedEntries = data;
      this.state.rejectedEntries = [];
      if (this.state.acceptedEntries.length > 0) {
        this.post();
      }
    };
    reader.readAsBinaryString(file);
  };

  JSONToExcel = (data: any) => {
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'import_report');
    XLSX.writeFile(wb, 'report.xlsx');
  };

  onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      this.excelToJSON(files[0]);
    }
    e.currentTarget.value = '';
  };

  translateImportingResult(result: string): string {
    let message = '';
    switch (result) {
      case 'rejected':
        message = 'Hata';
        break;
      case 'added':
        message = 'Eklendi';
        break;
      case 'updated':
        message = 'Güncellendi';
        break;
    }

    return message;
  }

  render() {
    const exampleExcelFileName =
      this.props.term_type === 1 ? 'Example_Course_Import.xlsx' : 'Example_CourseTerm_Course_Import.xlsx';
    let formOptions = null;
    let apiResultTable = null;
    let validationErrorsTable = null;
    let items = this.props.results;
    let options = this.props.options;

    if (this.state.rejectedEntries.length > 0) {
      let validationErrorRow = this.state.rejectedEntries.map((r) => {
        return (
          <tr key={'importing-error-' + r.RowIndex}>
            <td>{'#' + (r.RowIndex + 1)}</td>
            <td>
              {r.ValidationErrors.map((v) => {
                return (
                  <Fragment>
                    {(v.Field ? v.Field + ': ' : 'GENEL: ') + v.Message} <br />
                  </Fragment>
                );
              })}
            </td>
          </tr>
        );
      });

      validationErrorsTable = (
        <div className="mt-4" style={{ fontSize: '14px' }}>
          <Table responsive striped bordered size="sm">
            <thead>
              <tr>
                <th style={{ width: '15%' }}>Satır NO#</th>
                <th style={{ width: '85%' }}>Hata</th>
              </tr>
            </thead>
            <tbody>{validationErrorRow}</tbody>
          </Table>
        </div>
      );
    }

    if (items) {
      let rows = null;

      rows = items
        .sort((a, b) => (a.model.importingResult === 'rejected' || b.model.importingResult === 'updated' ? -1 : 1))
        .map((item: Types.IMultipleResponseItem<Types.IImportedCourse>) => {
          let { model, state } = item;
          let importingKeyObject =
            options &&
            ImportingKeySelectOptions.find(
              (item: Types.ISelectOption) => item.value == (options ? options.importingKey : '')
            );
          return (
            <tr key={this.modalName + '-' + model.courseCode} hidden={!(model.importingResult === 'rejected')}>
              <td>{this.translateImportingResult(model.importingResult)}</td>
              <td>{importingKeyObject ? importingKeyObject.label : ''}</td>

              {this.props.term_type === 0 ? (
                <React.Fragment>
                  <td>{model.activityNo}</td>
                  <td>{model.courseCode}</td>
                  <td>{model.courseName}</td>
                  <td>{model.activityType}</td>
                  <td>{model.lessonCount}</td>
                  <td>{model.lectureLocationCode}</td>
                  <td>{model.educationType}</td>
                  <td>{model.term}</td>
                  <td>{model.programCode}</td>
                  <td>{model.class}</td>
                  <td>{model.section}</td>
                  <td>{model.lectureStaffCodes}</td>
                  <td>{model.studentCount}</td>
                  <td>{model.week}</td>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <td>{model.courseCode}</td>
                  <td>{model.conjugateCourseCode}</td>
                  <td>{model.courseName}</td>
                  <td>{model.campusCode}</td>
                  <td>{model.facultyCode}</td>
                  <td>{model.programCodes}</td>
                  <td>{model.class}</td>
                  <td>{model.section}</td>
                  <td>{model.lectureStaffCodes}</td>
                  <td>{model.assistantStaffCodes}</td>
                  <td>{model.studentCount}</td>
                  <td>{model.examDuration}</td>
                  <td>{model.examSession}</td>
                  <td>{model.invigilatorGapBeforeExam}</td>
                  <td>{model.invigilatorGapAfterExam}</td>
                  <td>{model.classroomCombineStatus}</td>
                </React.Fragment>
              )}
              <td>
                {state
                  ? Array.isArray(state.details)
                    ? state.details.map((error) => {
                      return <label>{error.field + ': ' + error.message}</label>;
                    })
                    : state.details
                  : ''}
              </td>
            </tr>
          );
        });

      apiResultTable = (
        <div className="small mt-2">
          <h6>
            {this.state.acceptedEntries.length} kayıttan &nbsp;
            {items.filter((item) => item.model.importingResult !== 'rejected').length} tanesi başarıyla
            eklendi/güncellendi.
          </h6>
          <Table
            id="api-result-table"
            className="mt-3"
            responsive
            striped
            bordered
            size="sm"
            hidden={items.findIndex((item) => item.model.importingResult === 'rejected') < 0}
          >
            <thead>
              <tr>
                <th>Sonuç</th>
                <th>Anahtar Veri</th>
                {this.props.term_type === 0 ? (
                  <React.Fragment>
                    <th>Aktivite No</th>
                    <th>Ders Kodu</th>
                    <th>Ders Adı</th>
                    <th>Aktivite Türü</th>
                    <th>{T.t('gen_lesson_count')}</th>
                    <th>{T.t('gen_lesson_location')}</th>
                    <th>Öğretim Tipi</th>
                    <th>{T.t('gen_term')}</th>
                    <th>Bölüm/Program Kodu</th>
                    <th>Sınıf</th>
                    <th>Şube</th>
                    <th>Öğretim Elemanları Kodu</th>
                    <th>Öğrenci Sayısı</th>
                    <th>{T.t('gen_week')}</th>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <th>Ders Kodu</th>
                    <th>Eşlenik Ders Kodu</th>
                    <th>Ders Adı</th>
                    <th>Kampüs Kodu</th>
                    <th>Fakülte / Yüksekokul Kodu</th>
                    <th>Program Kodu</th>
                    <th>Sınıf Düzeyi</th>
                    <th>Şube</th>
                    <th>Dersin Öğretim Üyesi Kodu</th>
                    <th>Dersin Asistanın Öğretim Üyesi Kodu</th>
                    <th>Sınava Girecek Öğrenci Sayısı</th>
                    <th>Sınav Süresi</th>
                    <th>Oturum Sayısı</th>
                    <th>Göz. Öncesi Boşluk</th>
                    <th>Göz. Sonrası Boşluk</th>
                    <th>{T.t('gen_classroom_combine_status')}</th>
                  </React.Fragment>
                )}
                <th>Hata Mesajı</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>
      );
    } else {
      formOptions = (
        <div className="row">
          <Spinner name={this.modalName + '-spinner'} />
          <div className="col-12 col-md-12 col-sm-12">
            <p>
              Örnek bir Excel dosyasını,
              <a href={process.env.PUBLIC_URL + '/files/' + exampleExcelFileName}>
                {' '}
                buraya (xlsx uzantılı - MS Excel 2003 sonrası){' '}
              </a>
              tıklayarak indirebilirsiniz
            </p>
          </div>
          <div className="col-12 col-md-12 col-sm-12">
            <div className="react-select-container">
              <label>Baz Alınacak Bilgi</label>
              <Select
                className="react-select"
                isSearchable={false}
                options={ImportingKeySelectOptions}
                value={
                  this.state.options.importingKeyNone
                    ? ImportingKeySelectOptions.find((k) => k.value === this.state.options.importingKeyNone)
                    : null
                }
                onChange={(item: any) => {
                  this.state.options.importingKey = item.value;
                  this.setState(this.state);
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-sm-12">
            <div className="react-switch-container">
              <label>Var olan tablonun üzerine yaz</label>
              <Switch
                id="send_email"
                className="react-switch"
                onChange={(checked: boolean) => {
                  this.state.options.overrideData = checked;
                  this.setState(this.state);
                }}
                checked={this.state.options.overrideData}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {formOptions}
        {validationErrorsTable}
        {apiResultTable}
        <input
          id="excel_file_input"
          type="file"
          className="d-none form-control"
          accept={SheetJSFT}
          onChange={this.onFileSelected}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IImportFormProps): Types.IImportFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IImportFormProps = Object.assign({}, ownProps, {
    results: store.state.course_import_result && store.state.course_import_result.items,
    options: store.state.course_import_result && store.state.course_import_result.options,
    term_id: store.state.term_id,
    term_type: store.state.term_type
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return next.state.course_import_result === prev.state.course_import_result;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseImportForm);

export default container;
