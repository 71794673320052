import * as Types from '../../store/types';

export const constants = {
  CAMPUS_LIST_SEARCH: 'CAMPUS:LIST_SEARCH',
  CAMPUS_CREATE: 'CAMPUS:CREATE',
  CAMPUS_UPDATE: 'CAMPUS:UPDATE',
  CAMPUS_DELETE: 'CAMPUS:DELETE',
  CAMPUS_GET_BY_ID: 'CAMPUS:GET_BY_ID',
  CAMPUS_GET_DISTANCES: 'CAMPUS:GET_DISTANCES',
  CAMPUS_UPDATE_DISTANCES: 'CAMPUS:POST_DISTANCES',
  CAMPUS_GET_SELECT_OPTIONS: 'CAMPUS:GET_SELECT_OPTIONS'
};

export const CampusSearchInitialValues: Types.IFilterCampus = {
  order_by: '',
  campus_code: '',
  name: '',
  status: [],
  buildings: [],
  building_ids: [],
  select_all: false,
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const CampusFormInitialValues: Types.ICampusItem = {
  campus_id: undefined,
  campus_code: '',
  name: '',
  address: '',
  description: '',
  status: 1
};
