import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants } from '../constants/solution-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';
import { Log } from 'ng2-logger';

function* getSolution(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;

  const Logger = Log.create('SolutionSaga');

  if (action.body && action.body.reset) {
    response = new Response(null);
    content = null;
  } else {
    response = yield MockAPI.GetResponse(MockAPI.GetSolution(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getCourseSolution(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;

  const Logger = Log.create('SolutionSaga');

  if (action.body && action.body.reset) {
    response = new Response(null);
    content = null;
  } else {
    response = yield MockAPI.GetResponse(MockAPI.GetCourseSolution(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getPublicSolution(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;

  if (action.body && action.body.reset) {
    response = new Response(null);
    content = null;
  } else {
    response = yield MockAPI.GetResponse(MockAPI.PublicSolution(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* publishSolution(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.PublishSolution(action.body));

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_solution_published', 'gen_success'));
    if (action.callback) {
      action.callback();
    }
  } else {
    yield put(
      Actions.Notification('notification_solution_saved_error', 'gen_warning', 'danger')
    );
  }
}

export function* watchGetSolution() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_GET_SINGLE_TABLES, getSolution);
}

export function* watchGetCourseSolution() {
  yield takeEvery((action: any) => action.target === constants.COURSE_SOLUTION_GET_SINGLE_TABLES, getCourseSolution);
}

export function* watchSaveSolution() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_PUBLISH_SOLUTION, publishSolution);
}

export function* watchPublicSolution() {
  yield takeEvery((action: any) => action.target === constants.COURSE_SOLUTION_PUBLIC, getPublicSolution);
}