import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import Translator from '../../services/translate-factory';
import * as Constants from '../../store/constants/all';
const T = Translator.create();
const Logger = Log.create('CopyCourseModal');

class CopyCourseModal extends Component<any, any> {
  state: any = {
    term_id: -1,
    combined_courses: [],
    formValues: {
      student_count: -1,
      practical_staff: [],
      practical_staff_ids: undefined,
      lecture_staff: [],
      lecture_staff_ids: undefined
    }
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/);
    let id = regexObject ? parseInt(regexObject[0], 10) : -1;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  render() {
    const event = JSON.parse(this.props.event.desc);

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '90%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <span className="lesson-code">{event.code}</span>
            <span className="lesson-name">{event.name}</span>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body  text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-orange">
                    <span className="tits">{T.t('gen_date') + ' ' + T.t('gen_time')}</span>
                    <span className="text">{event.date}</span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-green">
                    <span className="tits">{T.t('gen_max_exam_duration')}</span>
                    <span className="text">
                      {event.duration} {T.t('gen_minute')}
                    </span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-red">
                    <span className="tits">{T.t('gen_campus')}</span>
                    <span className="text">{event.campus}</span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-blue">
                    <span className="tits">{T.t('gen_building')}</span>
                    <span className="text">{event.building ? event.building : '-'}</span>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="row b-bottom-gray">
                <div className="col-md-3 col-xs-12 p-15">
                  <span className="tits p-0-15">{T.t('gen_instructor')}</span>
                  <span className="text p-0-15">{event.instructor}</span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_faculty')}</span>
                  <span className="text p-0-15">{event.faculty}</span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_program')}</span>
                  <span className="text p-0-15">{event.program}</span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_student_count')}</span>
                  <span className="text p-0-15">{event.studentCount}</span>
                </div>
                <div className="clearfix" />
              </div>
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <div className="row b-c-red border">
                    {event.classrooms.map(
                      (classroom: {
                        id: number;
                        code: string;
                        studentCount: number;
                        invigilators: Array<{ id: number; code: string; classroomCode: string }>;
                      }) => (
                        <React.Fragment>
                          <div className="col-md-6 col-xs-12 lesson-box b-bottom-gray text-left">
                            <span className="tits">{T.t('gen_classroom')}</span>
                            <span className="text">
                              {classroom.code} - (Öğr: {classroom.studentCount})
                            </span>
                          </div>
                          <div className="col-md-6 col-xs-12 lesson-box b-bottom-gray">
                            <span className="tits">{T.t('gen_invigilators')}</span>
                            {classroom.invigilators.length
                              ? classroom.invigilators.map((invigilator) => (
                                  <span className="text" key={invigilator.id}>
                                    {invigilator.code}
                                  </span>
                                ))
                              : '-'}
                          </div>
                        </React.Fragment>
                      )
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="b-c-orange border">
                    <span className="tits">{T.t('gen_all_invigilators')}</span>
                    {event.invigilators.length
                      ? event.invigilators.map((invigilator: { id: number; code: string; classroomCode: string }) => (
                          <span className="text" key={invigilator.id}>
                            {invigilator.code}
                          </span>
                        ))
                      : '-'}
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default CopyCourseModal;
