import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import * as Types from '../../store/types';
import * as actions from '../../store/constants/all';
// pages
import WelcomePage from './welcome-page';
import AppRoutes from './app-routes';
import AppFactory from '../../services/app-factory';
import { Log } from 'ng2-logger';

const L = Log.create('App');
const AF = AppFactory.create();

export default class App extends React.Component<Types.IAppProps, Types.IAppModuleState> {
  state: Types.IAppModuleState = {
    ready: false
  };

  componentDidMount() {
    AF.on(actions.gen.CORE_APP_READY, () => {
      if (!this.state.ready) {
        this.state.ready = true;
        this.forceUpdate();
      }
    });
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps() {
  }

  componentWillUpdate() {
  }

  render() {
    let content = null;
    if (this.state.ready) {
      content = <AppRoutes />;
    } else {
      content = <WelcomePage />;
    }

    return content;
  }
}

/*
const mapStateToProps = (state: Types.IPersistedState, ownProps: Types.IAppProps): Types.IAppProps => {
  if (!state || !state.store) {
    return ownProps;
  }
  const newProps: Types.IAppProps = Object.assign({}, ownProps, {
    status: state.store.status
  });

  return newProps;
};

const dispatchProps = (dispatch: any) => ({
  dispatch
});

const app = connect(
  mapStateToProps,
  dispatchProps
)(App);

export default app;
*/
