import { Formik, FormikActions, FormikProps, ErrorMessage } from 'formik';
import { Log } from 'ng2-logger';
import React from 'react';
import * as Icons from 'react-icons/md';
import { connect } from 'react-redux';
import { ValueType } from 'react-select/lib/types';
import Select from 'react-select';
import { Modal } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { AvailableRoles, AvailableFormRoles, UserFormInitialValues } from '../../store/constants/user-const';
import * as Types from '../../store/types';
import UserFormValidation from './validations/user-form-val';
import Spinner from '../../components/templates/spinner';
import Checkbox from '../../components/checkboxes/single-checkbox';

import Translator from '../../services/translate-factory';
const T = Translator.create();
const L = Log.create('UserForm');

class UserForm extends React.Component<Types.IUserFormProps, Types.IUserFormState> {
  state: Types.IUserFormState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.IUserPost, FormActions: FormikActions<Types.IUserPost>) => {

    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.setClose(true);
      }
      if (status === 409 || status === 400) {
        this.props.dispatch(Actions.Notification('Bu bilgiler sistemde mevcüt.', 'gen_warning', 'danger'))
      }
    };

    this.state.model = model;
    model.faculty_of_responsibility_ids = model.faculty_of_responsibilities && model.faculty_of_responsibilities.map(i => i.value);
    model.program_of_responsibility_ids = model.program_of_responsibilities && model.program_of_responsibilities.map(i => i.value);

    if (!this.props.userId) {
      this.props.dispatch(Actions.ApiRequest(Constants.user.USER_CREATE, model, 'user-form-spin', resultCallback));
    } else {
      this.props.dispatch(Actions.ApiRequest(Constants.user.USER_UPDATE, model, 'user-form-spin', resultCallback));
    }

    FormActions.setSubmitting(false);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.props.dispatch(Actions.ApiRequest(Constants.group.GROUP_GET_SELECT_OPTIONS));
    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
    this.getInstructorSelectOptions(this.state.model.faculty_of_responsibility_ids);
    this.setState({
      model: {
        editModalStatus: false,
        count: 1
      }
    });
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.setState({
      model: {
        editModalStatus: false,
        count: 1
      }
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.formIsOpen !== this.props.formIsOpen) {
      this.state.model.editModalStatus = false;
      this.state.model.count = 1;
    }
  }

  getInstructorSelectOptions = (facultyIds: any) => {
    // this.setState({
    //   model: {
    //     editModalStatus: true
    //   }
    // });
    this.props.dispatch(Actions.ApiRequest(Constants.instructor.PROGRAMS_BY_FACULTYIDS_INSTRUCTOR_GET_SELECT_OPTIONS, facultyIds, 'instructor-list-spin'));
  }

  static getDerivedStateFromProps(props: Types.IUserFormProps, state: Types.IUserFormState) {
    let hasNewState: boolean = false;
    if (props.userId && props.userId !== state.model.user_id && !state.model.editModalStatus && state.model.count == 1) {
      state.model.user_id = props.userId;
      props.dispatch(Actions.ApiRequest(Constants.user.USER_GET_BY_ID, props.userId, 'user-form-spin', undefined));
      hasNewState = true;
      state.model.editModalStatus = false;
      state.model.count = 2;
    }
    //if (props.form && props.userId && props.userId === state.model.user_id) {
    if (props.form && props.userId) {
      state.model = props.form;
      hasNewState = true;
    }
    if (hasNewState) {
      return state;
    } else if (!props.userId && state.model.user_id) {
      return getInitialState();
    } else return null;
  }

  render() {
    return (
      <Modal modalClassName="modal-from-right" isOpen={this.props.formIsOpen}>
        <Spinner name="user-form-spin" />
        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          validationSchema={UserFormValidation}
          onSubmit={this.onFormSave}
        >
          {(props: FormikProps<Types.IUserPost>) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <div className="modal-header">
                  <h5 className="modal-title">
                    <Icons.MdEdit /> {this.props.userId ? 'Kullanıcı Düzenleme' : 'Yeni Kullanıcı'}
                  </h5>
                  <button type="button" className="close" onClick={this.setCloseForm}>
                    <i className="material-icons">close</i>
                    <span>esc</span>
                  </button>
                </div>
                <div className="modal-body col-12 col-md-12 col-sm-12">
                  <div className="row">
                    <input type="hidden" name="user_id" value={props.values.user_id} />
                    <div className="col-12 col-md-6 col-sm-6">
                      <div className="form-group">
                        <div className="form-input">
                          <input
                            name="name"
                            type="text"
                            value={props.values.name}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            autoComplete="off"
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label>{T.t('gen_name')}</label>
                        </div>
                        <ErrorMessage component="div" className="error" name="name" />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-sm-6">
                      <div className="form-group">
                        <div className="form-input">
                          <input
                            id="surname"
                            type="text"
                            value={props.values.surname}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label>{T.t('gen_surname')}</label>
                        </div>
                        <ErrorMessage component="div" className="error" name="surname" />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-sm-6">
                      <div className="form-group">
                        <div className="form-input">
                          <input
                            id="username"
                            type="text"
                            value={props.values.username}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            autoComplete="off"
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label>{T.t('gen_username')}</label>
                        </div>
                        <ErrorMessage component="div" className="error" name="username" />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-sm-6">
                      <div className="form-group">
                        <div className="form-input">
                          <input
                            id="email"
                            type="text"
                            value={props.values.email}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label>{T.t('gen_email')}</label>
                        </div>
                        <ErrorMessage component="div" className="error" name="email" />
                      </div>
                    </div>
                    {
                      <div className="col-12 col-md-6 col-sm-6">
                        <div className="form-group">
                          <div className="form-input">
                            <input
                              id="password"
                              type="password"
                              value={props.values.password}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              autoComplete="off"
                            // required
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>{T.t('gen_password')}</label>
                          </div>
                          {/* <ErrorMessage component="div" className="error" name="password" /> */}
                        </div>
                      </div>
                    }
                    {

                      <div className="col-12 col-md-6 col-sm-6">
                        <div className="form-group">
                          <div className="form-input">
                            <input
                              id="password_confirmation"
                              type="password"
                              value={props.values.password_confirmation}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              autoComplete="off"
                            // required
                            />
                            <span className="highlight"></span>
                            <span className="bar"></span>
                            <label>{T.t('gen_password_validate')}</label>
                          </div>
                          <ErrorMessage component="div" className="error" name="password_confirmation" />
                        </div>
                      </div>
                    }
                    <div className="col-12 col-md-12 col-sm-12">
                      <div className="react-select-container">
                        <label>{T.t('gen_role')}</label>
                        <Select
                          className="react-select"
                          options={AvailableFormRoles}
                          placeholder={T.t('gen_select_role')}
                          value={props.values.role ? AvailableFormRoles.find((r) => r.value === props.values.role) : null}
                          onChange={(value: any) => props.setFieldValue('role', value.value)}
                          noOptionsMessage={(): string => T.t('gen_select_no_type')}
                        />
                        <ErrorMessage component="div" className="error" name="role" />
                      </div>
                    </div>
                    {
                      props.values.role === 's' ? null : (
                        <div className="col-md-12">
                          <div className="add-custom-tag mb-3">
                            <div className="react-select-container">
                              <label>{T.t('gen_faculty_college_institute')}</label>
                              <Select
                                className="react-select"
                                isMulti={true}
                                filterOption={(option: any, query: any) =>
                                  option.label
                                    .toLocaleLowerCase('TR')
                                    .includes(query.toLocaleLowerCase('TR'))
                                }
                                closeMenuOnSelect={false}
                                options={
                                  this.props.selectOptions && this.props.selectOptions.faculties
                                    ? this.props.selectOptions.faculties
                                    : []
                                }
                                placeholder={T.t('gen_select_faculty')}
                                value={props.values.faculty_of_responsibilities}
                                onChange={(
                                  options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                ) => {
                                  const list: Array<Types.ISelectOption> = options
                                    ? (options as Array<Types.ISelectOption>)
                                    : [];
                                  props.setFieldValue('faculty_of_responsibilities', list);
                                  props.setFieldValue(
                                    'faculty_of_responsibility_ids',
                                    list.map((item) => item.value)
                                  );
                                  this.getInstructorSelectOptions(list.map(item => item.value))
                                }}
                                noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }
                    {
                      props.values.role === 's' ? null : (
                        <div className="col-md-12">
                          <div className="add-custom-tag mb-3">
                            <div className="react-select-container">
                              <label>{T.t('gen_program_department')}</label>
                              <Select
                                className="react-select"
                                isMulti={true}
                                filterOption={(option: any, query: any) =>
                                  option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                }
                                closeMenuOnSelect={false}
                                options={
                                  this.props.programs_related_faculty && this.props.programs_related_faculty !== undefined ?
                                    (this.props.programs_related_faculty && this.props.programs_related_faculty
                                      ? this.props.programs_related_faculty
                                      : []
                                    ) : undefined
                                }
                                placeholder={T.t('gen_select_program')}
                                value={props.values.program_of_responsibilities}
                                onChange={(
                                  options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                ) => {
                                  const list: Array<Types.ISelectOption> = options
                                    ? (options as Array<Types.ISelectOption>)
                                    : [];
                                  props.setFieldValue('program_of_responsibilities', list);
                                  props.setFieldValue(
                                    'program_of_responsibility_ids',
                                    list.map((item) => item.value)
                                  );
                                }}
                                noOptionsMessage={(): string => T.t('gen_select_no_program')}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }
                    <div className="col-md-6">
                      <div className="text-left">
                        <h6>{T.t('gen_status')}</h6>
                        <div className="tick-radio position-relative d-inline-block">
                          <Checkbox name="status" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-block">
                  <div className="row">
                    <div className="col-md">
                      <button type="button" className="btn btn-gray" onClick={this.setCloseForm}>
                        {T.t('gen_cancel')}
                      </button>
                    </div>
                    <div className="col-md text-md-right">
                      <button type="submit" className="btn btn-green" disabled={props.isSubmitting}>
                        <i className="material-icons">done</i>&nbsp;{T.t('gen_save')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

function getInitialState(): Types.IUserFormState {
  const initialValues: Types.IUserFormState = {
    model: Object.assign({}, UserFormInitialValues)
  };
  return Object.assign({}, initialValues);
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IUserFormProps): Types.IUserFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }

  const newProps: Types.IUserFormProps = Object.assign({}, ownProps, {
    form: store.state.user_pages && store.state.user_pages.form ? store.state.user_pages.form : getInitialState(),
    groupSelectOptions: store.state.group_select_options || [],
    selectOptions: store.state.select_options && store.state.select_options.instructorPage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty
  });

  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  /*if (next.state.user_pages) {
    return !!equal(prev.state.user_pages, next.state.user_pages);
  } else if (next.state.select_options && next.state.select_options.programs_related_faculty) {
    return !!equal(prev.state.select_options && prev.state.select_options.programs_related_faculty,
      next.state.select_options && next.state.select_options.programs_related_faculty);
  }
  else {
    return true;
  }*/
  return false;
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(UserForm);

export default container;
