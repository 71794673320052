import * as Types from '../types';

export const actions = {
  SETTING_RESET_PASSWORD: 'SETTING:RESET_PASSWORD',
  GET_SETTINGS: 'SETTING:GET_SETTINGS',
  UPDATE_SETTINGS: 'SETTING:SET_SETTINGS'
};

export const initialValues: any = {
  calendar: {
    applyType: 0,
    staffList: [],
    staffTypes: []
  },
  general: {
    institutionLogo: '',
    institutionName: 'APlan',
    termModulesInUse: '2',
    userEntryType: 0
  },
  planning: {
    allowedStaff: [1, 2],
    classroomInvigilationCount: '0',
    courseDuration: '01:00',
    departmentHeadsDateRange: [],
    gapBetweenCampuses: [0, 1, 2],
    instructorsDateRange: [],
    invigilatorAssignmentType: '0',
    secondaryEducationStartHour: '17:00'
  },
  email: {
    service: '',
    sendingAddress: '',
    allowValidation: false,
    sendEmailonUserCreated: false
  },
};

export const EmailServiceSelectOptions: Types.ISelectOption[] = [
  { label: 'Gmail', value: 'Gmail' },
  { label: 'Mandrill', value: 'Mandrill' },
  { label: 'Outlook', value: 'Outlook' },
  { label: 'Yandex', value: 'Yandex' },
  { label: 'Diğer', value: 'other' }
];

export const ResetPasswordFormInitialValues: Types.IResetPassword = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirm: ''
};

export const SettingsPageRoutes = {
  ChangePassword: '/password'
};
