import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import { Log } from 'ng2-logger';

import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { CampusSearchInitialValues } from '../../store/constants/campus-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';

import CampusForm from './campus-form';
import IntegrationModal from './campus-integration-modal';
import SyncCampusModal from './campus-sync-modal';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';
import { UncontrolledPopover, PopoverBody, PopoverHeader } from 'reactstrap';
import CampusDistanceModal from './campus-distances-modal';
import DownloadButton from '../../components/excel-imports/export';
import { SectionTypes } from '../../store/constants/enums';

import Translator from '../../services/translate-factory';
const T = Translator.create();

const L = Log.create('CampusListPage');

class CampusTable extends Component<Types.ICampusPageProps, Types.ICampusPageState> {
  state: Types.ICampusPageState = {
    filters: CampusSearchInitialValues,
    filterIsOpen: false,
    campusFormIsOpen: false,
    campusId: undefined,
    distanceModalIsOpen: false,
    integrationModalIsOpen: false,
    all_ids: [],
    selected_ids: []
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    window.scrollTo(0, 0);
    this.searchCampus();
    this.getCampusSelectOptions();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  searchCampus() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.campus.CAMPUS_LIST_SEARCH, this.state.filters, 'campus-list-spin')
    );
  }

  getCampusDistances() {
    this.props.dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_DISTANCES, null, 'campus-list-spin'));
  }

  getCampusSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_SELECT_OPTIONS, 'campus-list-spin'));
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchCampus();
  };

  toggleDistanceModal = () => {
    this.getCampusDistances();
    this.setState({
      ...this.state,
      distanceModalIsOpen: !this.state.distanceModalIsOpen
    });
  };

  campusFormOpen = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.CAMPUS, '/create')));
  };

  campusFormOnClose = (refresh: boolean) => {
    if (this.state.campusFormIsOpen) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.CAMPUS)));
    }
    if (refresh) {
      this.searchCampus();
    }
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchCampus();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, CampusSearchInitialValues);
    this.setState(this.state);
    this.searchCampus();
  };

  onFilterCampus(model: Types.IFilterCampus, FormActions: FormikActions<Types.IFilterCampus>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchCampus();
    FormActions.setSubmitting(false);
  }

  onCampusEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.CAMPUS, '/' + id)));
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.campus.CAMPUS_LIST_SEARCH,
            this.state.filters,
            'campus-list-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onDeleteCampus = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_action'),
          body: T.t('gen_delete_campus_question'),
          name: 'campus_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (status: number) => {
              if (status == 200) {
                this.state.selected_ids = [];
                this.state.filters.select_all = false;
                this.setState(this.state);
                this.searchCampus();
              }
            };

            const deleteList = this.state.selected_ids;
            this.props.dispatch(
              Actions.ApiRequest(Constants.campus.CAMPUS_DELETE, deleteList, 'campus-list-spin', resultCallback)
            );
          }
        })
      );
    }
  };

  onSelectCampus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  switchIntegrationModalStatus = () => {
    this.setState({
      ...this.state,
      integrationModalIsOpen: !this.state.integrationModalIsOpen
    });
  };

  static getDerivedStateFromProps(props: Types.ICampusPageProps, state: Types.ICampusPageState) {
    let hasNewState: boolean = false;
    if (props.match && props.match.params.id) {
      hasNewState = true;
      state.campusFormIsOpen = true;
      if (props.match.params.id !== 'create') {
        state.campusId = props.match.params.id;
      } else {
        state.campusId = undefined;
      }
    } else {
      hasNewState = true;
      state.campusFormIsOpen = false;
      state.campusId = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let campusList = this.props.results;
    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="campus-page">
          <div className="main list-campus">
            <div className="container-fluid">
              <div className="row align-items-center mb-1">
                <div className="col-md-6 col-sm-4 col-12">
                  <h4 className="mb-4 mb-sm-0">{T.t('gen_campuses')}</h4>
                </div>
                <div className="col-md-6 col-sm-8 col-12 text-right">
                  <button className="mr-1 category-tag-square tag-gray" onClick={this.toggleDistanceModal}>
                    <i className="material-icons mr-2">timeline</i>
                    {T.t('gen_campus_distances')}
                  </button>
                </div>
              </div>
              <Spinner name="campus-list-spin" />
              <div className="white-container mt-4">
                <div className="row align-items-center mb-1">
                  <div className="col-md-6 col-sm-4 col-12">
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: []
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          className="category-tag-square float-left pr-2 pl-2"
                          style={{ margin: '5px', color: '#fff', backgroundColor: '#dc3545' }}
                          onClick={this.onDeleteCampus}
                        >
                          <i className="material-icons mr-2">delete_outline</i>
                          <span>{T.t('gen_delete_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                        <React.Fragment>
                          <button className="category-tag-square tag-green" onClick={this.campusFormOpen}>
                            <i className="material-icons mr-2">add</i> {T.t('gen_add_campus')}
                          </button>
                          <button
                            className="category-tag-square tag-green ml-2"
                            onClick={this.switchIntegrationModalStatus}
                          >
                            <i className="material-icons mr-2">playlist_add</i>Senkronizasyon İşlerini Listele
                        </button>
                          <DownloadButton title={T.t('gen_campuses')} sectionType={SectionTypes.Campuses} />
                        </React.Fragment>
                      )}
                  </div>
                  <div className="col-md-6 col-sm-8 col-12 text-right">
                    <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                      <button
                        className="category-tag-square tag-glass float-right ml-3 mr-3"
                        style={{ margin: '5px' }}
                        onClick={() => {
                          this.state.filterIsOpen = !this.state.filterIsOpen;
                          this.setState(this.state);
                        }}
                      >
                        <i className="material-icons mr-2">filter_list</i>
                        <span>{T.t('gen_filter')}</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* FILTER STARTS HERE  */}
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3" style={{}}>
                    <Formik
                      initialValues={CampusSearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, Actions) => {
                        this.onFilterCampus(values, Actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterCampus>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-4">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="campus_code"
                                    name="campus_code"
                                    value={props.values.campus_code}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="campus_code">{T.t('gen_code')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="name"
                                    name="name"
                                    value={props.values.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-4 form-input">
                                <div className="react-select-container">
                                  <h6>{T.t('gen_connected_buildings')}</h6>
                                  <Select
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={false}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.buildings
                                        ? this.props.selectOptions.buildings
                                        : []
                                    }
                                    placeholder={T.t('gen_select_building')}
                                    value={props.values.buildings}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('buildings', list);
                                      props.setFieldValue(
                                        'building_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_building')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <div className="text-left">
                                  <h6>{T.t('gen_status')}</h6>
                                  <div className="tick-radio position-relative mb-3 d-inline-block">
                                    <MultipleCheckbox name="status" text={T.t('gen_active')} value={1} />
                                  </div>
                                  <div className="tick-radio position-relative mb-3 d-inline-block ml-4">
                                    <MultipleCheckbox name="status" text={T.t('gen_passive')} value={0} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i>{T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                      <thead>
                        <tr>
                          <th data-cell="select">
                            <div className="tick-radio position-relative">
                              <input
                                type="checkbox"
                                className="form-radio"
                                checked={this.checkAllIdsSelected()}
                                onChange={this.onSelectAll}
                              />
                            </div>
                          </th>
                          <SortedColumn
                            datacell="status"
                            title={T.t('gen_status')}
                            sortkey="status"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="campus_code"
                            title={T.t('gen_code')}
                            sortkey="campus_code"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="name"
                            className="d-none d-lg-table-cell d-xl-table-cell"
                            title={T.t('gen_name')}
                            sortkey="name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">

                            {T.t('gen_connected_buildings')}
                          </th>
                          <th scope="col" className="text-center">

                            {T.t('gen_address')}
                          </th>
                          <th scope="col" className="text-center">
                            {T.t('gen_description')}
                          </th>
                          <th scope="col" className="text-right">
                            <span className="text-right">{T.t('gen_actions')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {campusList && campusList.length
                          ? campusList.map((item: any) => (
                            <tr key={'campus-' + item.campus_id} data-title={item.name}>
                              <td data-cell="select">
                                <div className="tick-radio position-relative">
                                  <input
                                    type="checkbox"
                                    className="form-radio"
                                    checked={
                                      this.state.selected_ids &&
                                      this.state.selected_ids.indexOf(item.campus_id ? item.campus_id : -1) > -1
                                    }
                                    data-id={item.campus_id}
                                    onChange={this.onSelectCampus}
                                  />
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    className={
                                      `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(item.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_code')}>
                                {item.campus_code}
                              </td>
                              <td data-label={T.t('gen_name')}>{item.name}</td>
                              <td
                                data-cell="buildings"
                                id={'building-popover-' + item.campus_id}
                                style={{ textAlign: 'center' }}
                              >
                                {item.buildings && item.buildings.length > 0 && (
                                  <button className="multiple-info-tag small-tag group-ellipsis">
                                    {item.buildings[0].label}
                                  </button>
                                )}
                                {item.buildings && item.buildings.length > 1 && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      +{item.buildings.length - 1} Bina
                                      </button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'building-popover-' + item.campus_id}
                                    >
                                      <PopoverHeader>{T.t('gen_connected_buildings')}</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">
                                        {item.buildings &&
                                          item.buildings.length > 0 &&
                                          item.buildings.map((building: Types.ISelectOption) => {
                                            return (
                                              <React.Fragment key={'building-' + building.value}>
                                                <button className="multiple-info-tag small-tag text-capitalize">
                                                  {building.label}
                                                </button>
                                                <br />
                                              </React.Fragment>
                                            );
                                          })}
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </td>
                              <td
                                data-cell="campus"
                                id={'address-popover-' + item.campus_id}
                                style={{ textAlign: 'center' }}
                              >
                                {item.address && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {item.address}
                                    </button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'address-popover-' + item.campus_id}
                                    >
                                      <PopoverHeader>Adres</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">{item.address}</PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </td>
                              <td
                                data-cell="campus"
                                id={'description-popover-' + item.campus_id}
                                style={{ textAlign: 'center' }}
                              >
                                {item.description && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {item.description}
                                    </button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'description-popover-' + item.campus_id}
                                    >
                                      <PopoverHeader>{T.t('gen_description')}</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">{item.description}</PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </td>
                              <td data-label={T.t('gen_actions')} className="table-buttons">
                                <div className="table-buttons-wrapper">
                                  <button
                                    data-toggle="tooltip"
                                    data-id={item.campus_id}
                                    onClick={this.onCampusEdit}
                                    title={T.t('gen_edit')}
                                    className="btn btn-light btn-sm table-button"
                                  >
                                    <span className="d-block" data-toggle="modal" data-target="#addNew">
                                      <i className="material-icons">edit</i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                    <div className="row-options justify-content-end">
                      <div
                        className="page-sorting d-flex align-items-center justify-content-center"
                        style={{ marginTop: '5px' }}
                      >
                        {this.props.results && this.props.results.length > 0 && (
                          <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
        <CampusForm
          campusId={this.state.campusId}
          formIsOpen={this.state.campusFormIsOpen}
          onClose={this.campusFormOnClose}
        />
        {/*<IntegrationModal
            modalIsOpen={this.state.integrationModalIsOpen}
            onClose={this.switchIntegrationModalStatus}
            onUpdateList={() => this.searchCampus()}
          />*/}
        {this.state.integrationModalIsOpen ? (
          <SyncCampusModal
            modalIsOpen={this.state.integrationModalIsOpen}
            onClose={this.switchIntegrationModalStatus}
            onUpdateList={() => this.searchCampus()}
          />
        ) : null}
        <CampusDistanceModal modalIsOpen={this.state.distanceModalIsOpen} onClose={this.toggleDistanceModal} />
      </MainLayout>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICampusPageProps): Types.ICampusPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.ICampusPageProps = Object.assign({}, ownProps, {
    results: store.state.campus_page && store.state.campus_page.results,
    filters: store.state.campus_page && store.state.campus_page.filters,
    selectOptions: store.state.select_options && store.state.select_options.campusPage
  });
  return newProps;
};

const equal = require('deep-equal');

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.campus_page) {
    return (
      !!equal(
        prev.state.campus_page && prev.state.campus_page.results,
        next.state.campus_page && next.state.campus_page.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.campusPage,
        next.state.select_options && next.state.select_options.campusPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CampusTable);

export default container;
