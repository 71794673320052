import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import Select from 'react-select';
import { Log, Logger } from 'ng2-logger';
import { Modal, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import Paginate from '../../../components/table/paginate';
import SortedColumn from '../../../components/table/sorted-column';
import Announce from '../../../components/templates/announce';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import {
  ActivitySearchInitialValues,
  ActivityHoursSearchInitialValues,
  TermTypes,
  ActivityTermLectureLocations
} from '../../../store/constants/activity-const';
import * as Types from '../../../store/types';
import * as GT from '../../../tools/general-tools';

import Translator from '../../../services/translate-factory';
const T = Translator.create();
const L = Log.create('ActivityAddModal');

function getInitialState(): Types.IActivityPageState {
  const initialValues: Types.IActivityPageState = {
    filters: Object.assign({}, ActivityHoursSearchInitialValues),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    activityFormIsOpen: false,
    activityNo: undefined
  };
  return Object.assign({}, initialValues);
}

class ActivityAddModal extends Component<Types.IActivityPageProps, Types.IActivityPageState> {
  //class ActivityAddModal extends Component<any, any> {
  state = getInitialState();

  componentDidMount() {
    this.searchActivities();
    this.getActivitySelectOptions();
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidUpdate() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    if (id && !this.state.filters.term_id) {
      this.state.filters.term_id = id;
      this.setState(this.state);
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }


  searchActivities() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_LIST_SEARCH, this.state.filters, 'activity-list-spin')
    );
  }

  getActivitySelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_SELECT_OPTIONS, 'activity-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onSelectActivity = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
          this.state.filters.select_all = false;
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.searchActivities();
      } else {
        this.state.all_ids = [];
        this.state.selected_ids = [];
        this.state.filters.select_all = false;
        this.setState(this.state);
      }
    }
  };

  addActivities = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      if (this.checkRelationAvaliability()) {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_activity_relations'),
            body: T.t('gen_activity_relation_question'),
            name: 'activity_relations',
            icon: 'warning',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
                if (status == 200) {
                  this.setClose();
                  this.onUpdateListFromModal();
                }
              };

              const model = {
                activity_relation_id: this.props.selectedActivityNo,
                term_id: this.props.term_id,
                related_activity_ids: this.state.selected_ids,
                activity_relation_type: this.props.activityRelationType
              };

              if (model.activity_relation_id != -1) {
                this.props.dispatch(
                  Actions.ApiRequest(
                    Constants.course_period.UPDATE_ACTIVITY_RELATION,
                    model,
                    'ep-instructor-list',
                    resultCallback
                  )
                );
              } else {
                this.props.dispatch(
                  Actions.ApiRequest(
                    Constants.course_period.POST_ACTIVITY_RELATION,
                    model,
                    'ep-instructor-list',
                    resultCallback
                  )
                );
              }
            }
          })
        );
      } else {
        if (this.state.selected_ids && this.state.selected_ids.length > 1) {
          this.props.dispatch(
            Actions.ShowModal({
              body: T.t('gen_activiy_max_select'),
              name: 'activity_relations_notallowed',
              icon: 'warning',
              iconColor: 'red',
              cancel: 'Kapat'
            })
          );
        } else {
          this.props.dispatch(
            Actions.ShowModal({
              body: T.t('gen_activiy_min_select'),
              name: 'activity_relations_notallowed',
              icon: 'warning',
              iconColor: 'red',
              cancel: 'Kapat'
            })
          );
        }
      }
    }
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchActivities();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchActivities();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ActivitySearchInitialValues);
    this.state.all_ids = [];
    this.setState(this.state);
    this.searchActivities();
  };

  onFilterActivity(model: Types.IFilterActivity, FormActions: FormikActions<Types.IFilterActivity>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchActivities();
    FormActions.setSubmitting(false);
  }

  static getDerivedStateFromProps(props: Types.IActivityPageProps, state: Types.IActivityPageState) {
    let hasNewState: boolean = false;
    if (state.filters.select_all) {
      hasNewState = true;
      state.all_ids = props.all_ids;
      state.selected_ids = props.all_ids;
    }

    if (props.modalIsOpen && !state.activityFormIsOpen) {
      hasNewState = true;
      props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_LIST_SEARCH, state.filters, 'activity-list-spin'));
      state.activityFormIsOpen = true;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  checkRelationAvaliability = (): boolean => {
    if (this.state.selected_ids && this.state.selected_ids.length > 1) {
      if (
        this.props.activityRelationType == 2 ||
        this.props.activityRelationType == 3 ||
        this.props.activityRelationType == 5 ||
        this.props.activityRelationType == 6
      ) {
        return this.state.selected_ids.length > 2 ? false : true;
      } else return true;
    } else if (this.props.selectedActivityNo != -1) return true;
    else return false;
  };

  render() {
    let activityList = this.props.results;
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations, ...campusOptions];
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_activity_relations')}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12">
                  <div className="quick-actions">
                    <button
                      className="category-tag-square tag-glass float-right ml-3 mr-3"
                      style={{ margin: '5px' }}
                      onClick={() => {
                        this.state.filterIsOpen = !this.state.filterIsOpen;
                        this.setState(this.state);
                      }}
                    >
                      <i className="material-icons mr-2">filter_list</i>
                      <span>{T.t('gen_filter')}</span>
                    </button>
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: [],
                              all_ids: [],
                              filters: {
                                ...this.state.filters,
                                select_all: false
                              }
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          className="category-tag-square tag-green float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={this.addActivities}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{T.t('gen_add_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                        <React.Fragment>
                          <button
                            className="category-tag-square tag-gray float-left pr-2 pl-2"
                            style={{ margin: '5px', cursor: 'not-allowed' }}
                            disabled={true}
                          >
                            <i className="material-icons mr-2">close</i>
                            <span>
                              {T.t('gen_cancel_selection')} <b>&nbsp;(0)</b>
                            </span>
                          </button>
                          <button
                            className="category-tag-square tag-gray float-left pr-2 pl-2"
                            style={{ margin: '5px', cursor: 'not-allowed' }}
                            disabled={true}
                          >
                            <i className="material-icons mr-2">input</i>
                            <span>{T.t('gen_add_selecteds')}</span>
                          </button>
                        </React.Fragment>
                      )}
                  </div>
                </div>
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3">
                    <Formik
                      initialValues={ActivitySearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterActivity(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterActivity>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="course_code"
                                    name="course_code"
                                    value={props.values.course_code}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="course_code">{T.t('gen_code')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="course_name"
                                    name="course_name"
                                    value={props.values.course_name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_term')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={TermTypes}
                                      placeholder={T.t('gen_select_term')}
                                      value={props.values.terms ? props.values.terms : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('terms', list);
                                        props.setFieldValue(
                                          'term_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_lesson_location')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={allSelectOptions}
                                      placeholder={T.t('gen_select_location')}
                                      value={props.values.lecture_locations ? props.values.lecture_locations : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('lecture_locations', list);
                                        props.setFieldValue(
                                          'lecture_location_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>Program</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.programs
                                          ? this.props.selectOptions.programs
                                          : []
                                      }
                                      placeholder={T.t('gen_select_program')}
                                      value={props.values.programs}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('programs', list);
                                        props.setFieldValue(
                                          'program_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_instructors')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.instructors
                                          ? this.props.selectOptions.instructors
                                          : []
                                      }
                                      placeholder={T.t('gen_select_instructor')}
                                      value={props.values.lecture_staff}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('lecture_staff', list);
                                        props.setFieldValue(
                                          'instructor_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <div className="text-left">
                                  <h6>{T.t('gen_status')}</h6>
                                  {/*<div className="tick-radio position-relative mb-3 d-inline-block">
                                    <MultipleCheckbox name="status" text={T.t('gen_active')} value={1} />
                                  </div>
                                  <div className="tick-radio position-relative mb-3 d-inline-block ml-4">
                                    <MultipleCheckbox name="status" text={T.t('gen_passive')} value={0} />
                                    </div>*/}
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2 ml-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th data-cell="select">
                          <div className="tick-radio position-relative">
                            <input
                              type="checkbox"
                              className="form-radio"
                              checked={
                                this.state.selected_ids &&
                                this.props.filters &&
                                this.state.selected_ids.length == this.props.filters.total
                              }
                              onChange={this.onSelectAll}
                            />
                          </div>
                        </th>
                        <SortedColumn
                          datacell="status"
                          title={T.t('gen_status')}
                          sortkey="status"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="activity_no"
                          title={T.t('gen_activity_no')}
                          sortkey="activity_no"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="course_code"
                          title={T.t('gen_code')}
                          sortkey="course_code"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="name"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_name')}
                          sortkey="name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="activity_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_activity')}
                          sortkey="activity_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="lesson_count"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_lesson_count')}
                          sortkey="lesson_count"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="lecture_location"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_lesson_location')}
                          sortkey="lecture_location"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="education_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_education_type')}
                          sortkey="education_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />

                        <SortedColumn
                          datacell="term"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_term')}
                          sortkey="term"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          Program
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_grade')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_section')}
                        </th>

                        <th scope="col" className="text-center">
                          Öğretim Elemanları
                        </th>
                        <SortedColumn
                          datacell="student_count"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_student_count')}
                          sortkey="student_count"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_week')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_description')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {activityList && activityList.length
                        ? activityList.map((item: any) => {
                          let termObject = TermTypes.find((object) => object.value == item.term);
                          const courseLocation = allSelectOptions.find(
                            (option) => option.value == item.lecture_location_id
                          );
                          return (
                            <tr key={'activity-' + item.activity_no} data-title={item.name}>
                              <td data-cell="select">
                                <div className="tick-radio position-relative">
                                  <input
                                    type="checkbox"
                                    className="form-radio"
                                    checked={
                                      this.state.selected_ids &&
                                      this.state.selected_ids.indexOf(item.activity_no ? item.activity_no : -1) > -1
                                    }
                                    data-id={item.activity_no}
                                    onChange={this.onSelectActivity}
                                  />
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    className={
                                      `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(item.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_code')}>
                                {item.activity_no}
                              </td>
                              <td data-label={T.t('gen_code')}>{item.course_code}</td>
                              <td data-label={T.t('gen_name')}>{item.course_name}</td>
                              <td data-label={T.t('gen_activity')}>{item.activity_type}</td>
                              <td scope="row" data-label={T.t('gen_lesson_count')} className="text-center">
                                {item.lesson_count}
                              </td>
                              <td scope="row" data-label={T.t('gen_lesson_location')} className="text-center">
                                {/*courseLocation ? courseLocation.label : ''*/}
                                {item.lecture_location && item.lecture_location.label}
                              </td>
                              <td scope="row" data-label={T.t('gen_education_type')} className="text-center">
                                {item.education_type}
                              </td>
                              <td data-label={T.t('gen_term')}>
                                {/*termObject && termObject.label*/}
                                {item.term}
                              </td>
                              <td
                                data-cell="connected_program"
                                id={'connected_program-popover-' + item.activity_no}
                                style={{ textAlign: 'center' }}
                              >
                                {item.program && item.program.label}
                                {/*item.program && item.program.length > 0 && (
                                  <button className="multiple-info-tag small-tag group-ellipsis">
                                    {item.program.label}
                                  </button>
                                )*/}
                                {/*item.program && item.program.length > 1 && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      +{item.program.length - 1} Program
                                      </button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'connected_program-popover-' + item.activity_no}
                                    >
                                      <PopoverHeader>{T.t('gen_connected_programs')}</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">
                                        {item.program &&
                                          item.program.length > 0 &&
                                          item.program.map((connected_program: Types.ISelectOption) => {
                                            return (
                                              <React.Fragment key={'connected_program-' + connected_program.value}>
                                                <button className="multiple-info-tag small-tag text-capitalize">
                                                  {connected_program.label}
                                                </button>
                                                <br />
                                              </React.Fragment>
                                            );
                                          })}
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                        )*/}
                              </td>
                              <td scope="row" data-label={T.t('gen_grade')} className="text-center">
                                {item.class}
                              </td>
                              <td scope="row" data-label={T.t('gen_section')} className="text-center">
                                {item.section}
                              </td>
                              <td
                                data-cell="lecture_staff"
                                id={'lecture_staff-popover-' + item.activity_no}
                                style={{ textAlign: 'center' }}
                              >
                                {item.instructors && item.instructors.length > 0 && (
                                  <button className="multiple-info-tag small-tag group-ellipsis">
                                    {item.instructors[0].label}
                                  </button>
                                )}
                                {item.instructors && item.instructors.length > 1 && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      +{item.instructors.length - 1} Kişi
                                      </button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'lecture_staff-popover-' + item.activity_no}
                                    >
                                      <PopoverHeader>Öğretim Elemanları</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">
                                        {item.instructors &&
                                          item.instructors.length > 0 &&
                                          item.instructors.map((instructors: Types.ISelectOption) => {
                                            return (
                                              <React.Fragment key={'lecture_staff-' + instructors.value}>
                                                <button className="multiple-info-tag small-tag text-capitalize">
                                                  {instructors.label}
                                                </button>
                                                <br />
                                              </React.Fragment>
                                            );
                                          })}
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </td>
                              <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                                {item.student_count}
                              </td>
                              <td scope="row" data-label={T.t('gen_week')} className="text-center">
                                {item.week}
                              </td>
                              <td
                                data-cell="programs"
                                id={'description-popover-' + item.program_id}
                                style={{ textAlign: 'center' }}
                              >
                                {item.description && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {item.description}
                                    </button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'description-popover-' + item.program_id}
                                    >
                                      <PopoverHeader>{T.t('gen_description')}</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">{item.description}</PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </td>
                            </tr>
                          )
                        })
                        : null}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    >
                      {this.props.results && this.props.results.length > 0 ? (
                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IActivityPageProps): Types.IActivityPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IActivityPageProps = Object.assign({}, ownProps, {
    results: store.state.activity_page && store.state.activity_page.results,
    filters: store.state.activity_page && store.state.activity_page.filters,
    all_ids: store.state.activity_page && store.state.activity_page.all_ids,
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
    term_id: store.state.term_id,
    term_type: store.state.term_type,
    //activity_relations: store.state.course_period_page && store.state.course_period_page.activity_relations
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.activity_page) {
    return (
      !!equal(
        prev.state.activity_page && prev.state.activity_page.results,
        next.state.activity_page && next.state.activity_page.results
      ) &&
      !!equal(
        prev.state.activity_page && prev.state.activity_page.all_ids,
        next.state.activity_page && next.state.activity_page.all_ids
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.coursePage,
        next.state.select_options && next.state.select_options.coursePage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ActivityAddModal);

export default container;
