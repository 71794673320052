import { constants } from '../constants/sync-const';
import { actions as gen } from '../constants/general';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';
import { building } from '../constants/all';
import { isConstTypeReference } from 'typescript';

const synchronizationReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {

    case constants.SYNCHRONIZATION_BUILDING_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          sync_building_page: {
            ...state.sync_building_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters,
            all_ids: result.content ? result.content.all_ids : [],
            sync_job_id: result.content ? result.content.sync_job_id : null
          }
        };
      }
    }

    case constants.SYNCHRONIZATION_PROGRAM_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          sync_program_page: {
            ...state.sync_program_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters,
            all_ids: result.content ? result.content.all_ids : [],
            sync_job_id: result.content ? result.content.sync_job_id : null
          }
        };
      }
    }

    case constants.SYNCHRONIZATION_CAMPUS_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          sync_campus_page: {
            ...state.sync_campus_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters,
            all_ids: result.content ? result.content.all_ids : [],
            sync_job_id: result.content ? result.content.sync_job_id : null
          }
        };
      }
    }

    case constants.SYNCHRONIZATION_CLASSROOM_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          sync_classroom_page: {
            ...state.sync_classroom_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters,
            all_ids: result.content ? result.content.all_ids : [],
            sync_job_id: result.content ? result.content.sync_job_id : null
          }
        };
      }
    }

    case constants.SYNCHRONIZATION_FACULTY_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          sync_faculty_page: {
            ...state.sync_faculty_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters,
            all_ids: result.content ? result.content.all_ids : [],
            sync_job_id: result.content ? result.content.sync_job_id : null
          }
        };
      }
    }

    case constants.SYNCHRONIZATION_INSTRUCTOR_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          sync_instructor_page: {
            ...state.sync_instructor_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters,
            all_ids: result.content ? result.content.all_ids : [],
            sync_job_id: result.content ? result.content.sync_job_id : null
          }
        };
      }
    }

    case constants.SYNCHRONIZATION_COURSE_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          sync_course_page: {
            ...state.sync_course_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters,
            all_ids: result.content ? result.content.all_ids : [],
            sync_job_id: result.content ? result.content.sync_job_id : null
          }
        };
      }
    }

    case constants.SYNCHRONIZATION_STUDENT_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          sync_student_page: {
            ...state.sync_student_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters,
            all_ids: result.content ? result.content.all_ids : [],
            sync_job_id: result.content ? result.content.sync_job_id : null
          }
        };
      }
    }

    // Synch Add To Main Tables

    case constants.SYNCHRONIZATION_BUILDING_ADD_TO_MAIN: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result.response.ok) {
        state = Object.assign({}, state, {
          synchronization: {
            ...state.synchronization,
            result: true
          }
        });
      }
      return state;
    }

    case constants.SYNCHRONIZATION_CAMPUS_ADD_TO_MAIN: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      //if (result && result.content) {
      if (result.response.ok) {
        state = Object.assign({}, state, {
          synchronization: {
            ...state.synchronization,
            result: true
            // result_id: result.content // This is enum [1, 2, 3, 4, 5, 6...]
          }
        });
      }
      return state;
    }

    case constants.SYNCHRONIZATION_CLASSROOM_ADD_TO_MAIN: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result.response.ok) {
        state = Object.assign({}, state, {
          synchronization: {
            ...state.synchronization,
            result: true
          }
        });
      }
      return state;
    }

    case constants.SYNCHRONIZATION_FACULTY_ADD_TO_MAIN: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result.response.ok) {
        state = Object.assign({}, state, {
          synchronization: {
            ...state.synchronization,
            result: true
          }
        });
      }
      return state;
    }

    case constants.SYNCHRONIZATION_PROGRAM_ADD_TO_MAIN: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result.response.ok) {
        state = Object.assign({}, state, {
          synchronization: {
            ...state.synchronization,
            result: true
          }
        });
      }
      return state;
    }

    case constants.SYNCHRONIZATION_INSTRUCTOR_ADD_TO_MAIN: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result.response.ok) {
        state = Object.assign({}, state, {
          synchronization: {
            ...state.synchronization,
            result: true
          }
        });
      }
      return state;
    }

    case constants.SYNCHRONIZATION_COURSE_ADD_TO_MAIN: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result.response.ok) {
        state = Object.assign({}, state, {
          synchronization: {
            ...state.synchronization,
            result: true
          }
        });
      }
      return state;
    }

    case constants.SYNCHRONIZATION_STUDENT_ADD_TO_MAIN: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result.response.ok) {
        state = Object.assign({}, state, {
          synchronization: {
            ...state.synchronization,
            result: true
          }
        });
      }
      return state;
    }

    // Synchronization For Modal List START

    case constants.SYNCHRONIZATION_MODAL_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          synchronization: {
            ...state.synchronization,
            ...(result.content ? result.content : {}) // sync_job_id donuyor
          }
        };
      }
    }

    case constants.SYNCHRONIZATION_CAMPUS_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (result && result.content) {
          state = Object.assign({}, state, {
            synchronization: {
              ...state.synchronization,
              buildings: result.content
            }
          });
        }
        return state;
      }
    }

    case constants.SYNCHRONIZATION_BUILDING_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (result && result.content) {
          state = Object.assign({}, state, {
            synchronization: {
              ...state.synchronization,
              ...(result.content ? result.content : {})
            }
          });
        }
        return state;
      }
    }

    case constants.SYNCHRONIZATION_PROGRAM_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (result && result.content) {
          state = Object.assign({}, state, {
            synchronization: {
              ...state.synchronization,
              courses: result.content
            }
          });
        }
        return state;
      }
    }

    case constants.SYNCHRONIZATION_FACULTY_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (result && result.content) {
          state = Object.assign({}, state, {
            synchronization: {
              ...state.synchronization,
              programs: result.content
            }
          });
        }
        return state;
      }
    }

    // Sending Data To "Start Synchronization"

    case constants.SYNCHRONIZATION_MODAL_SEND_SYNCTYPE: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          synchronization: {
            ...state.synchronization,
            sync_job_id: result.content // This is sync_job_id
          }
        });
      }
      return state;
    }

    case gen.CORE_SET_STATUS: {
      state = Object.assign({}, state, {
        synchronization: {
          ...state.synchronization,
          result_id: undefined,
          result: false
        }
      });
      return state;
    }

    // Veri Aktarma Sayfası

    case constants.SYNCHRONIZATION_GET_SYNC_JOB_STATUS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          synchronization: {
            ...state.synchronization,
            //result_id: { number: result.content, date: new Date() } // This is enum [1, 2, 3, 4, 5, 6...]
            result_id: result.content
          }
        });
      }
      return state;
    }

    // Displaying Data In Table

    case constants.SYNCHRONIZATION_DISPLAY_CAMPUS_DATA: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (result && result.content) {
          state = Object.assign({}, state, {
            synchronization: {
              ...state.synchronization,
              sync_type: result.content
            }
          });
        }
        return state;
      }
    }

    // Senkronizasyon İşlerini Listele

    case constants.SYNCHRONIZATION_LIST_SYNC_JOBS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          synchronization: {
            ...state.synchronization,
            listSyncJobs: result.content
          }
        });
        return state;
      }
    }

    case constants.SYNCHRONIZATION_GET_FETCHED_DATA: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          synchronization: {
            ...state.synchronization,
            filteredSyncData: result.content
          }
        });
        return state;
      }
    }

    // Synchronization For Modal List END

    case constants.SYNCHRONIZATION_GET_BY_ID: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          sync_page: {
            ...state.sync_page,
            form: result.content.data
          }
        });
      }
      return state;
    }
    case constants.SYNCHRONIZATION_GET_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            syncPage: result.content
          }
        });
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default synchronizationReducer;
