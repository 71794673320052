import { constants } from '../constants/classroom-feature-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

const classroomFeaturesReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case constants.CLASSROOM_FEATURES_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          classroom_features_page: {
            ...state.classroom_features_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters
          }
        };
      }
    }
    case constants.CLASSROOM_FEATURES_GET_BY_ID: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          classroom_features_page: {
            ...state.classroom_features_page,
            form: result.content.data
          }
        });
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default classroomFeaturesReducer;
