import * as Types from '../../store/types';

export const constants = {
  CLASSROOM_LIST_SEARCH: 'CLASSROOM:LIST_SEARCH',
  CLASSROOM_CREATE: 'CLASSROOM:CREATE',
  CLASSROOM_UPDATE: 'CLASSROOM:UPDATE',
  CLASSROOM_DELETE: 'CLASSROOM:DELETE',
  CLASSROOM_GET_BY_ID: 'CLASSROOM:GET_BY_ID',
  CLASSROOM_GET_SELECT_OPTIONS: 'CLASSROOM:GET_SELECT_OPTIONS',
  CLASSROOM_CREATE_EXCEL_BULK: 'CLASSROOM:CREATE_EXCEL_BULK'
};

export const ClassroomSearchInitialValues: Types.IFilterClassroom = {
  classroom_code: '',
  name: '',
  status: [],
  buildings: [],
  classroom_types: [],
  classroom_ids: [],
  course_ids: [],
  term_id: undefined,
  select_all: false,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const ClassroomHoursSearchInitialValues: Types.IFilterClassroom = {
  classroom_code: '',
  name: '',
  status: [1],
  buildings: [],
  classroom_types: [],
  classroom_ids: [],
  course_ids: [],
  term_id: undefined,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
  select_all: false
};

export const ClassroomFormInitialValues: Types.IClassroomItem = {
  classroom_id: undefined,
  classroom_code: '',
  status: 1,
  name: '',
  description: '',
  building_name: '',
  campus_name: '',
  building_floor: '',
  door_order: undefined,
  exam_capacity: undefined,
  lecture_capacity: undefined,
  invigilator_count: undefined,
  classroom_type: '',
  features: []
};

export const ClassroomTypes: Array<Types.ISelectOption> = [
  { label: 'Derslik', value: '1' },
  { label: 'Laboratuvar', value: '2' }
].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
