import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants, ClassroomFeaturesSearchInitialValues } from '../constants/classroom-feature-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';

function* getClassroomFeaturess(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ClassroomFeaturesSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getClassroomFeaturesData(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ClassroomFeaturesGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateClassroomFeatures(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ClassroomFeaturesUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_feature_updated', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createClassroomFeatures(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ClassroomFeaturesCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_feature_created', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteClassroomFeatures(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ClassroomFeaturesDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_feature_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.CLASSROOM_FEATURES_LIST_SEARCH, ClassroomFeaturesSearchInitialValues));
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchClassroomFeaturesSearch() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_FEATURES_LIST_SEARCH, getClassroomFeaturess);
}

export function* watchClassroomFeaturesGetById() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_FEATURES_GET_BY_ID, getClassroomFeaturesData);
}

export function* watchClassroomFeaturesUpdate() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_FEATURES_UPDATE, updateClassroomFeatures);
}

export function* watchClassroomFeaturesCreate() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_FEATURES_CREATE, createClassroomFeatures);
}
export function* watchClassroomFeaturesDelete() {
  yield takeEvery((action: any) => action.target === constants.CLASSROOM_FEATURES_DELETE, deleteClassroomFeatures);
}
