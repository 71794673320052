import * as Types from '../../store/types';

export const constants = {
  INSTRUCTOR_LIST_SEARCH: 'INSTRUCTOR:LIST_SEARCH',
  INSTRUCTOR_CREATE: 'INSTRUCTOR:CREATE',
  INSTRUCTOR_UPDATE: 'INSTRUCTOR:UPDATE',
  INSTRUCTOR_DELETE: 'INSTRUCTOR:DELETE',
  INSTRUCTOR_GET_BY_ID: 'INSTRUCTOR:GET_BY_ID',
  INSTRUCTOR_GET_SELECT_OPTIONS: 'INSTRUCTOR:GET_SELECT_OPTIONS',
  PROGRAMS_BY_FACULTYIDS_INSTRUCTOR_GET_SELECT_OPTIONS: 'PROGRAMS_BY_FACULTYIDS_INSTRUCTOR_GET_SELECT_OPTIONS',
  INSTRUCTOR_CREATE_EXCEL_BULK: 'INSTRUCTOR:CREATE_EXCEL_BULK'
};

export const InstructorSearchInitialValues: Types.IFilterInstructor = {
  instructor_code: '',
  name: '',
  status: [],
  daily_max_class: undefined,
  min_class_interval_bw_campuses: undefined,
  invigilator_levels: [],
  invigilation_campuses: [],
  invigilation_faculties: [],
  invigilation_campus_ids: undefined,
  max_invigilation_duty: undefined,
  invigilation_faculty_ids: undefined,
  term_id: undefined,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
  select_all: false
};

export const InstructorHoursSearchInitialValues: Types.IFilterInstructor = {
  instructor_code: '',
  name: '',
  status: [1],
  daily_max_class: undefined,
  min_class_interval_bw_campuses: undefined,
  invigilator_levels: [],
  invigilation_campuses: [],
  invigilation_faculties: [],
  invigilation_campus_ids: undefined,
  max_invigilation_duty: undefined,
  invigilation_faculty_ids: undefined,
  term_id: undefined,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
  select_all: false
};

export const InstructorFormInitialValues: Types.IInstructorItem = {
  instructor_id: undefined,
  instructor_code: '',
  status: 1,
  title: undefined,
  staff_type: undefined,
  faculty_of_duty: undefined,
  faculty_of_duty_id: undefined,
  program_of_duty: undefined,
  program_of_duty_id: undefined,
  name: '',
  email: '',
  mobile: '',
  description: '',
  invigilator_level: undefined,
  daily_max_class: 0,
  max_invigilation_duty: 0,
  invigilation_campuses: [],
  invigilation_campus_ids: [],
  invigilation_faculty_ids: [],
  invigilation_program_ids: [],
  invigilation_faculties: [],
  invigilation_programs: [],
  faculties: [],
  programs: []
};

export const InvigilatorLevels = [
  { label: 'Çok Yüksek', value: 5 },
  { label: 'Yüksek', value: 4 },
  { label: 'Orta', value: 3 },
  { label: 'Düşük', value: 2 },
  { label: 'Çok Düşük', value: 1 },
  { label: 'Gözetmenlik Yapmaz', value: 0 }
];

export const Titles = [
  { label: 'Prof. Dr.', value: 'Prof. Dr.' },
  { label: 'Doç. Dr.', value: 'Doç. Dr.' },
  { label: 'Dr. Öğr. Üyesi', value: 'Dr. Öğr. Üyesi' },
  { label: 'Öğr. Gör. Dr.', value: 'Öğr. Gör. Dr.' },
  { label: 'Öğr. Gör.', value: 'Öğr. Gör.' },
  { label: 'Araş. Gör. Dr.', value: 'Araş. Gör. Dr.' },
  { label: 'Araş. Gör.', value: 'Araş. Gör.' },
  { label: 'Okutman', value: 'Okutman' },
  { label: 'Tez Danışmanı', value: 'Tez Danışmanı' },
  { label: 'Proje Danışmanı', value: 'Proje Danışmanı' },
  { label: 'Personel', value: 'Personel' }
  /*{ label: 'Dr. Öğr.', value: 'Dr. Öğr.' },
  { label: 'Öğretim Görevlisi', value: 'Öğretim Görevlisi' },
  { label: 'Öğr. Yrd.', value: 'Öğr. Yrd.' },*/
];

export const DailyMaxClasses = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
];

export const StaffTypes = [
  { label: 'Yarı Zamanlı', value: 1 },
  { label: 'Tam Zamanlı', value: 2 }
];

export const StaffApplyType = [
  { label: 'Ünvan ve Kişi Bazlı', value: 0 },
  { label: 'Kadro Tipi Bazlı', value: 1 }
]
