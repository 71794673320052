import { constants } from '../constants/solution-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

import { Log } from 'ng2-logger';

const Logger = Log.create('solutionReducer');

const solutionReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case constants.SOLUTION_GET_SINGLE_TABLES:
    case constants.COURSE_SOLUTION_GET_SINGLE_TABLES: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;

      state = Object.assign({}, state, {
        solution_page: {
          ...state.solution_page,
          solution: result.content ? result.content : {}
        }
      });

      return state;
    }
    case constants.COURSE_SOLUTION_PUBLIC: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      state = Object.assign({}, state, {
        solution_page: {
          //...state.solution_page,
          publicSolution: result.content ? result.content : {}
        }
      });
      return state;
    }
    default: {
      return state;
    }
  }
};

export default solutionReducer;
