import * as Yup from 'yup';

const weekValidateRegex = /^[0-9]+(?:-[0-9]+)?(,[0-9]+(?:-[0-9]+)?)*$/;

export const CourseFormValidation = Yup.object().shape({
  course_code: Yup.string().required('Boş bırakılamaz!').max(50, 'En fazla 50 karakter!'),
  name: Yup.string().required('Boş bırakılamaz!'),
  class: Yup.string().required('Boş bırakılamaz!'),
  lesson_count: Yup.string().required('Boş bırakılamaz!'),
  student_count: Yup.string().required('Boş bırakılamaz!'),
  education_type: Yup.string().required('Boş bırakılamaz!'),
  section: Yup.string().required('Boş bırakılamaz!'),
  lecture_location_id: Yup.number().required('Boş bırakılamaz!'),
  week: Yup.string()
    .nullable(true)
    .required('Boş bırakılamaz!')
    .matches(weekValidateRegex, 'Çoklu hafta seçimi 1-14 veya 3,4,5 formatında olmalıdır.'),
  term: Yup.string().required('Boş bırakılamaz!'),
  activity_type: Yup.string().required('Boş bırakılamaz!'),
  lecture_staff_ids: Yup.array().of(Yup.number()).required('Boş bırakılamaz!'),
  program_ids: Yup.array().of(Yup.number()).required('Boş bırakılamaz!')
});

export const ActivityFormValidation = Yup.object().shape({
  course_code: Yup.string().required('Boş bırakılamaz!').max(50, 'En fazla 50 karakter!'),
  course_name: Yup.string().required('Boş bırakılamaz!'),
  //class: Yup.string().required('Boş bırakılamaz!'),
  lesson_count: Yup.string().required('Boş bırakılamaz!'),
  student_count: Yup.string().required('Boş bırakılamaz!'),
  education_type: Yup.string().required('Boş bırakılamaz!'),
  section: Yup.string().required('Boş bırakılamaz!'),
  lecture_location_id: Yup.number().required('Boş bırakılamaz!'),
  week: Yup.string()
    .nullable(true)
    .required('Boş bırakılamaz!')
    .matches(weekValidateRegex, 'Çoklu hafta seçimi 1-14 veya 3,4,5 formatında olmalıdır.'),
  term: Yup.string().required('Boş bırakılamaz!'),
  activity_type: Yup.string().required('Boş bırakılamaz!'),
  instructor_ids: Yup.array().of(Yup.number()).required('Boş bırakılamaz!'),
  program_ids: Yup.array().of(Yup.number()).required('Boş bırakılamaz!'),
  //program_id: Yup.number().required('Boş bırakılamaz!'),
});

