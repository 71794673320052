import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import { Log } from 'ng2-logger';

import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import {
  ActivitySearchInitialValues,
  TermTypes,
  ViewOptions,
  ClassroomCombineTypes,
  ActivityTermLectureLocations
} from '../../../store/constants/activity-const';
import { routes as Routes } from '../../../store/constants/routes';
import * as GT from '../../../tools/general-tools';
import ImportModal, { ExcelImportKeys } from '../../../components/excel-imports/import-modal';
import CourseForm from './course-form';
import IntegrationModal from '../../course/course-integration-modal';
import SyncCourseModal from './course-sync-modal';
import MultipleCheckbox from '../../../components/checkboxes/multiple-checkbox';
import Spinner from '../../../components/templates/spinner';
import MainLayout from '../../layouts/main-layout';
import SimplePage from '../../../components/templates/simple-page';
import APlanHeader from '../../../components/templates/aplan-header';
import SortedColumn from '../../../components/table/sorted-column';
import Paginate from '../../../components/table/paginate';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import DownloadButton from '../../../components/excel-imports/export';
import { SectionTypes } from '../../../store/constants/enums';
import { Grades, EducationTypeSelectOptions, LessonCount } from '../../../store/constants/course-const';

import Translator from '../../../services/translate-factory';
const T = Translator.create();
const L = Log.create('CourseListPage');

class CourseTable extends Component<Types.IActivityPageProps, Types.IActivityPageState> {
  state: Types.IActivityPageState = {
    filters: ActivitySearchInitialValues,
    filterIsOpen: false,
    viewMenuIsOpen: false,
    selectedView: 'Sınav Planlama',
    integrationModalIsOpen: false,
    activityFormIsOpen: false,
    activityNo: undefined,
    selected_ids: [],
    all_ids: [],
    is_related: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.searchCourse();
    this.getCourseSelectOptions();
    this.state.is_related = this.props.is_related
    this.setState(this.state);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.is_related !== this.props.is_related) {
      if (this.props.is_related) {
        this.props.dispatch(
          Actions.ShowModal({
            title: 'Aktivite İlişkisi Var!',
            body: `Silmek İstediğiniz aktivitenin ilişkisi bulumaktadır. Aktiviteyi silmek için önce ilişkiyi kaldırmalısınız.`,
            name: 'course_delete',
            icon: 'warning',
            iconColor: 'red',
            cancel: T.t('gen_ok'),
            confirm: T.t('gen_go_to_activity_relations'),
            onConfirm: () => {
              this.props.dispatch(Actions.Navigation(GT.Route(Routes.ACTIVITY_RELATIONS)))
            }
          })
        );
      } else if (!this.props.is_related) {
        this.props.dispatch(
          Actions.ShowModal({
            title: 'Silme İşlemi',
            body: `Seçtiğiniz dersler silinecektir. Devam etmek istiyor musunuz?`,
            name: 'course_delete',
            icon: 'warning',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (status: number) => {
                if (status == 200) {
                  this.state.selected_ids = [];
                  this.state.filters = ActivitySearchInitialValues;
                  this.setState(this.state);
                  this.searchCourse();
                }
              };

              const deleteList = this.state.selected_ids;
              this.props.dispatch(
                Actions.ApiRequest(Constants.activity.ACTIVITY_DELETE, deleteList, 'activity-list-spin', resultCallback)
              );
            }
          })
        );
      }
    }
  }

  searchCourse() {
    let newModel = {
      activity_noes: this.state.filters.activity_noes_ids,
      activity_type: this.state.filters.activity_type,
      add_list: this.state.filters.add_list,
      assistant_staff: this.state.filters.assistant_staff,
      course_code: this.state.filters.course_code,
      course_name: this.state.filters.course_name,
      education_types: this.state.filters.education_types_ids,
      grades: this.state.filters.grades_ids,
      instructor_ids: this.state.filters.instructor_ids,
      instructors: this.state.filters.instructors,
      lecture_location_ids: this.state.filters.lecture_location_ids,
      lecture_locations: this.state.filters.lecture_locations,
      lecture_staff: this.state.filters.lecture_staff,
      lesson_counts: this.state.filters.lesson_counts_ids,
      order_by: this.state.filters.order_by,
      page: this.state.filters.page,
      practical_staff: this.state.filters.practical_staff,
      program_ids: this.state.filters.program_ids,
      programs: this.state.filters.programs,
      query: this.state.filters.query,
      sections: this.state.filters.sections,
      select_all: this.state.filters.select_all,
      size: this.state.filters.size,
      status: this.state.filters.status,
      term_id: this.state.filters.term_id,
      terms: this.state.filters.terms && this.state.filters.terms.map((i: any) => i.label),
      total: this.state.filters.total,
      week: this.state.filters.week
    }
    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_LIST_SEARCH, newModel, 'course-list-spin')
    );
  }

  selectView = (viewType: string) => {
    this.state.selectedView = viewType;
    this.setState(this.state);
  };

  getCourseSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_SELECT_OPTIONS, 'course-list-spin'));
  }

  onCourseImported = () => {
    this.searchCourse();
  };

  toggleViewMenu = () => {
    this.state.viewMenuIsOpen = !this.state.viewMenuIsOpen;
    this.setState(this.state);
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchCourse();
  };

  courseFormIsOpen = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE, '/create')));
  };

  courseFormOnClose = (refresh: boolean) => {
    if (this.state.activityFormIsOpen) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE)));
    }
    if (refresh) {
      this.searchCourse();
    }
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchCourse();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ActivitySearchInitialValues);
    this.setState(this.state);
    this.searchCourse();
  };

  onFilterActivity(model: Types.IFilterActivity, FormActions: FormikActions<Types.IFilterActivity>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchCourse();
    FormActions.setSubmitting(false);
  }

  onActivityEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE, '/' + id)));
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.activity.ACTIVITY_LIST_SEARCH,
            this.state.filters,
            'activity-list-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onSelectCourse = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  onDeleteCourse = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const deleteList = this.state.selected_ids;
    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_IS_RELATED, deleteList, 'activity-list-spin')
    );
    setTimeout(() => {
      if (this.props.is_related) {
        this.props.dispatch(
          Actions.ShowModal({
            title: 'Aktivite İlişkisi Var!',
            body: `Silmek İstediğiniz aktivitenin ilişkisi bulumaktadır. Aktiviteyi silmek için önce ilişkiyi kaldırmalısınız.`,
            name: 'course_delete',
            icon: 'warning',
            iconColor: 'red',
            cancel: T.t('gen_ok'),
            confirm: T.t('gen_go_to_activity_relations'),
            onConfirm: () => {
              this.props.dispatch(Actions.Navigation(GT.Route(Routes.ACTIVITY_RELATIONS)))
            }
          })
        );
      } else if (!this.props.is_related) {
        this.props.dispatch(
          Actions.ShowModal({
            title: 'Silme İşlemi',
            body: `Seçtiğiniz dersler silinecektir. Devam etmek istiyor musunuz?`,
            name: 'course_delete',
            icon: 'warning',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (status: number) => {
                if (status == 200) {
                  this.state.selected_ids = [];
                  this.state.filters = ActivitySearchInitialValues;
                  this.setState(this.state);
                  this.searchCourse();
                }
              };

              const deleteList = this.state.selected_ids;
              this.props.dispatch(
                Actions.ApiRequest(Constants.activity.ACTIVITY_DELETE, deleteList, 'activity-list-spin', resultCallback)
              );
            }
          })
        );
      }
    }, 1000);
  };

  switchIntegrationModalStatus = () => {
    this.setState({
      ...this.state,
      integrationModalIsOpen: !this.state.integrationModalIsOpen
    });
  };

  static getDerivedStateFromProps(props: Types.IActivityPageProps, state: Types.IActivityPageState) {
    let hasNewState: boolean = false;

    if (props.match && props.match.params.id) {
      hasNewState = true;
      state.activityFormIsOpen = true;
      if (props.match.params.id !== 'create') {
        state.activityNo = props.match.params.id;
      } else {
        state.activityNo = undefined;
      }
    } else {
      hasNewState = true;
      state.activityFormIsOpen = false;
      state.activityNo = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let courseList = this.props.results;
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations, ...campusOptions];

    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="faculty-page">
          <div className="main list-campus">
            <div className="container-fluid">
              <div className="row align-items-center mb-1">
                <div className="col-md-6 col-sm-4 col-12">
                  <h4 className="mb-4 mb-sm-0">Dersler</h4>
                </div>
                <div className="col-md-6 col-sm-8 col-12 text-right"></div>
              </div>
              <Spinner name="course-list-spin" />
              <div className="white-container mt-4">
                <div className="row align-items-center mb-sm-4 mb-2">
                  <div className="col-md-5 col-sm-4 col-12">
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: []
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          className="category-tag-square float-left pr-2 pl-2"
                          style={{ margin: '5px', color: '#fff', backgroundColor: '#dc3545' }}
                          onClick={this.onDeleteCourse}
                        >
                          <i className="material-icons mr-2">delete_outline</i>
                          <span> {T.t('gen_delete_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button className="category-tag-square tag-green" onClick={this.courseFormIsOpen}>
                          <i className="material-icons mr-2">add</i>{T.t('gen_add_course')}
                        </button>
                        <button
                          className="category-tag-square tag-green ml-1"
                          onClick={this.switchIntegrationModalStatus}
                        >
                          <i className="material-icons mr-2">playlist_add</i>Senkronizasyon İşlerini Listele
                          </button>
                        <ImportModal
                          title={T.t('gen_add_with_excel')}
                          componentKey={ExcelImportKeys.Courses}
                          dispatch={this.props.dispatch}
                          onImport={this.onCourseImported}
                        />
                        <DownloadButton title={'Dersler'} sectionType={SectionTypes.Courses} />
                      </React.Fragment>
                    )}
                  </div>
                  <div className="col-md-7 col-sm-8 col-12 text-right">
                    <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                      <button
                        className="category-tag-square tag-glass float-right ml-3 mr-3"
                        style={{ margin: '5px' }}
                        onClick={() => {
                          this.state.filterIsOpen = !this.state.filterIsOpen;
                          this.setState(this.state);
                        }}
                      >
                        <i className="material-icons mr-2">filter_list</i>
                        <span>{T.t('gen_filter')}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3">
                    <Formik
                      initialValues={ActivitySearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterActivity(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterActivity>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="course_code"
                                    name="course_code"
                                    value={props.values.course_code}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="course_code">{T.t('gen_code')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="course_name"
                                    name="course_name"
                                    value={props.values.course_name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_term')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={TermTypes}
                                      placeholder={T.t('gen_select_term')}
                                      value={props.values.terms ? props.values.terms : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('terms', list);
                                        props.setFieldValue(
                                          'term_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_lesson_location')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={allSelectOptions}
                                      placeholder={T.t('gen_select_location')}
                                      value={props.values.lecture_locations ? props.values.lecture_locations : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('lecture_locations', list);
                                        props.setFieldValue(
                                          'lecture_location_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="activity_type"
                                    name="activity_type"
                                    value={props.values.activity_type}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="activity_type">{T.t('gen_activity_type')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>Program</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.programs
                                          ? this.props.selectOptions.programs
                                          : []
                                      }
                                      placeholder={T.t('gen_select_program')}
                                      value={props.values.programs}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('programs', list);
                                        props.setFieldValue(
                                          'program_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_instructors')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.instructors
                                          ? this.props.selectOptions.instructors
                                          : []
                                      }
                                      placeholder={T.t('gen_select_instructor')}
                                      value={props.values.lecture_staff}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('lecture_staff', list);
                                        props.setFieldValue(
                                          'instructor_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_activity_no')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.activity_noes
                                          ? this.props.selectOptions.activity_noes
                                          : []
                                      }
                                      placeholder={T.t('gen_select_activity_no')}
                                      value={props.values.activity_noes}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('activity_noes', list);
                                        props.setFieldValue(
                                          'activity_noes_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_activity')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_lesson_count')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={LessonCount}
                                      placeholder={T.t('gen_select_lesson_count')}
                                      value={props.values.lesson_counts}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('lesson_counts', list);
                                        props.setFieldValue(
                                          'lesson_counts_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_lesson_count')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_education_type')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={EducationTypeSelectOptions}
                                      placeholder={T.t('gen_select_education_type')}
                                      value={props.values.education_types}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('education_types', list);
                                        props.setFieldValue(
                                          'education_types_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_education_type')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_grade')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={Grades}
                                      placeholder={T.t('gen_select_grade')}
                                      value={props.values.grades}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('grades', list);
                                        props.setFieldValue(
                                          'grades_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_section')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.sections
                                          ? this.props.selectOptions.sections
                                          : []
                                      }
                                      placeholder={T.t('gen_select_section')}
                                      value={props.values.sections_object}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('sections_object', list);
                                        props.setFieldValue(
                                          'sections',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_section')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="week"
                                    name="week"
                                    value={props.values.week}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="week">{T.t('gen_week')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <div className="text-left">
                                  <h6>{T.t('gen_status')}</h6>
                                  <div className="tick-radio position-relative mb-3 d-inline-block">
                                    <MultipleCheckbox name="status" text={T.t('gen_active')} value={1} />
                                  </div>
                                  <div className="tick-radio position-relative mb-3 d-inline-block ml-4">
                                    <MultipleCheckbox name="status" text={T.t('gen_passive')} value={0} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2 ml-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                      <thead>
                        <tr>
                          <th data-cell="select">
                            <div className="tick-radio position-relative">
                              <input
                                type="checkbox"
                                className="form-radio"
                                checked={this.checkAllIdsSelected()}
                                onChange={this.onSelectAll}
                              />
                            </div>
                          </th>
                          <SortedColumn
                            datacell="status"
                            title={T.t('gen_status')}
                            sortkey="status"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="activity_no"
                            title={T.t('gen_activity_no')}
                            sortkey="activity_no"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="course_code"
                            title={T.t('gen_code')}
                            sortkey="course_code"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="course_name"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_name')}
                            sortkey="course_name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="activity_type"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_activity')}
                            sortkey="activity_type"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="lesson_count"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_lesson_count')}
                            sortkey="lesson_count"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">
                            {T.t('gen_lesson_location')}
                          </th>
                          <SortedColumn
                            datacell="education_type"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_education_type')}
                            sortkey="education_type"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="term"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_term')}
                            sortkey="term"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">
                            {T.t('gen_program')}
                          </th>
                          <SortedColumn
                            datacell="grade"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_grade')}
                            sortkey="grade"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="section"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_section')}
                            sortkey="section"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">
                            {T.t('gen_instructors')}
                          </th>
                          <SortedColumn
                            datacell="student_count"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_student_count')}
                            sortkey="student_count"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="week"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_week')}
                            sortkey="week"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">
                            {T.t('gen_description')}
                          </th>
                          <th scope="col" className="text-right">
                            <span className="text-right">{T.t('gen_actions')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {courseList && courseList.length
                          ? courseList.map((item: any) => {
                            let termObject = TermTypes.find((object) => object.value == item.term);
                            const courseLocation = allSelectOptions.find(
                              (option) => option.value == item.lecture_location
                            );
                            return (
                              <tr key={'course-' + item.course_id} data-title={item.name}>
                                <td data-cell="select">
                                  <div className="tick-radio position-relative">
                                    <input
                                      type="checkbox"
                                      className="form-radio"
                                      checked={
                                        this.state.selected_ids &&
                                        this.state.selected_ids.indexOf(item.activity_no ? item.activity_no : -1) > -1
                                      }
                                      data-id={item.activity_no}
                                      onChange={this.onSelectCourse}
                                    />
                                  </div>
                                </td>
                                <td scope="row" data-label={T.t('gen_status')}>
                                  <div className="tags ml-1 mr-4">
                                    <button
                                      className={
                                        `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                      }
                                    >
                                      {GT.GetActiveStatus(item.status)}
                                    </button>
                                  </div>
                                </td>
                                <td scope="row" data-label={T.t('gen_activity_no')} className="text-center">
                                  {item.activity_no}
                                </td>
                                <td data-label={T.t('gen_code')} className="text-center">
                                  {item.course_code}
                                </td>
                                <td data-label={T.t('gen_name')} className="text-center">
                                  {item.course_name}
                                </td>
                                <td data-label={T.t('gen_activity')} className="text-center">
                                  {item.activity_type}
                                </td>
                                <td scope="row" data-label={T.t('gen_lesson_count')} className="text-center">
                                  {item.lesson_count}
                                </td>
                                <td scope="row" data-label={T.t('gen_lesson_location')} className="text-center">
                                  {/*courseLocation ? courseLocation.label : ''*/}
                                  {item.lecture_location && item.lecture_location.label}
                                </td>
                                <td scope="row" data-label={T.t('gen_education_type')} className="text-center">
                                  {item.education_type === 'N.Ö.' || item.education_type === 'N.O.' || item.education_type === 'NÖ' || item.education_type === 'NO' ?
                                    'Örgün Öğretim' :
                                    item.education_type === 'İ.Ö.' || item.education_type === 'İ.O.' || item.education_type === 'İÖ' || item.education_type === 'İO' ?
                                      'İkinci Öğretim' :
                                      item.education_type === 'U.Ö.' || item.education_type === 'U.O.' || item.education_type === 'UÖ' || item.education_type === 'UO' ?
                                        'Uzaktan Öğretim' : null
                                  }
                                </td>
                                <td data-label={T.t('gen_term')} className="text-center">
                                  {/*termObject && termObject.label*/}
                                  {item.term === '1' ? 'Güz' :
                                    item.term === '2' ? 'Bahar' :
                                      item.term === '3' ? 'Yaz' : item.term}
                                </td>
                                <td scope="row" data-label={T.t('gen_connected_programs')} className="text-center">
                                  {item.programs && item.programs.length > 0 && (
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {item.programs[0].label}
                                    </button>
                                  )}
                                  {item.programs && item.programs.length > 1 && (
                                    <React.Fragment>
                                      <button className="multiple-info-tag small-tag group-ellipsis">
                                        +{item.programs.length - 1} Program
                                        </button>
                                      <UncontrolledPopover
                                        trigger="hover focus"
                                        placement="bottom"
                                        target={'instructors-popover-' + item.activity_no}
                                      >
                                        <PopoverHeader>Program</PopoverHeader>
                                        <PopoverBody className="scrollable-popoverbody">
                                          {item.programs &&
                                            item.programs.length > 0 &&
                                            item.programs.map((programs: Types.ISelectOption) => {
                                              return (
                                                <React.Fragment key={'programs-' + programs.value}>
                                                  <button className="multiple-info-tag small-tag text-capitalize">
                                                    {programs.label}
                                                  </button>
                                                  <br />
                                                </React.Fragment>
                                              );
                                            })}
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </React.Fragment>
                                  )}
                                </td>
                                <td scope="row" data-label={T.t('gen_grade')} className="text-center">
                                  {item.class}
                                </td>
                                <td scope="row" data-label={T.t('gen_section')} className="text-center">
                                  {item.section}
                                </td>
                                <td
                                  data-cell="instructors"
                                  id={'instructors-popover-' + item.activity_no}
                                  style={{ textAlign: 'center' }}
                                >
                                  {item.instructors && item.instructors.length > 0 && (
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {item.instructors[0].label}
                                    </button>
                                  )}
                                  {item.instructors && item.instructors.length > 1 && (
                                    <React.Fragment>
                                      <button className="multiple-info-tag small-tag group-ellipsis">
                                        +{item.instructors.length - 1} Kişi
                                        </button>
                                      <UncontrolledPopover
                                        trigger="hover focus"
                                        placement="bottom"
                                        target={'instructors-popover-' + item.activity_no}
                                      >
                                        <PopoverHeader>Öğretim Elemanları</PopoverHeader>
                                        <PopoverBody className="scrollable-popoverbody">
                                          {item.instructors &&
                                            item.instructors.length > 0 &&
                                            item.instructors.map((instructors: Types.ISelectOption) => {
                                              return (
                                                <React.Fragment key={'instructors-' + instructors.value}>
                                                  <button className="multiple-info-tag small-tag text-capitalize">
                                                    {instructors.label}
                                                  </button>
                                                  <br />
                                                </React.Fragment>
                                              );
                                            })}
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </React.Fragment>
                                  )}
                                </td>
                                <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                                  {item.student_count}
                                </td>
                                <td scope="row" data-label={T.t('gen_week')} className="text-center">
                                  {item.week}
                                </td>
                                <td
                                  data-cell="programs"
                                  id={'description-popover-' + item.program_id}
                                  style={{ textAlign: 'center' }}
                                >
                                  {item.description && (
                                    <React.Fragment>
                                      <button className="multiple-info-tag small-tag group-ellipsis">
                                        {item.description}
                                      </button>
                                      <UncontrolledPopover
                                        trigger="hover focus"
                                        placement="bottom"
                                        target={'description-popover-' + item.program_id}
                                      >
                                        <PopoverHeader>{T.t('gen_description')}</PopoverHeader>
                                        <PopoverBody className="scrollable-popoverbody">
                                          {item.description}
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </React.Fragment>
                                  )}
                                </td>
                                <td data-label={T.t('gen_actions')} className="table-buttons">
                                  <div className="table-buttons-wrapper">
                                    <button
                                      data-toggle="tooltip"
                                      data-id={item.activity_no}
                                      onClick={this.onActivityEdit}
                                      title="Düzenle"
                                      className="btn btn-light btn-sm table-button"
                                    >
                                      <span className="d-block" data-toggle="modal" data-target="#addNew">
                                        <i className="material-icons">edit</i>
                                      </span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                          : null}
                      </tbody>
                    </table>
                    <div className="row-options justify-content-end">
                      <div
                        className="page-sorting d-flex align-items-center justify-content-center"
                        style={{ marginTop: '5px' }}
                      >
                        {this.props.results && this.props.results.length > 0 && (
                          <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
        <CourseForm
          activityNo={this.state.activityNo}
          formIsOpen={this.state.activityFormIsOpen}
          onClose={this.courseFormOnClose}
        />
        {this.state.integrationModalIsOpen ? (
          <SyncCourseModal
            modalIsOpen={this.state.integrationModalIsOpen}
            onClose={this.switchIntegrationModalStatus}
            onUpdateList={() => this.searchCourse()}
          />
        ) : null}
      </MainLayout>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IActivityPageProps): Types.IActivityPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IActivityPageProps = Object.assign({}, ownProps, {
    is_related: store.state.activity_page && store.state.activity_page.is_related,
    results: store.state.activity_page && store.state.activity_page.results,
    filters: store.state.activity_page && store.state.activity_page.filters,
    selectOptions: store.state.select_options && store.state.select_options.activityPage
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  /*if (next.state.course_page) {
    return (
      !!equal(
        prev.state.course_page && prev.state.course_page.results,
        next.state.course_page && next.state.course_page.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.coursePage,
        next.state.select_options && next.state.select_options.coursePage
      )
    );
  } else {
    return true;
  }*/
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseTable);

export default container;
