import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Modal } from 'reactstrap';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { CoursePeriodFormInitialValues, TermTypes } from '../../store/constants/course-period-const';
import * as Types from '../../store/types';
import { FormValidation } from './validations/course-period-form-val';

import Translator from '../../services/translate-factory';
const T = Translator.create();

const Logger = Log.create('CopyPeriodForm');

function getInitialState(): Types.ICopyFormState {
    const initialValues: Types.ICopyFormState = {
        model: Object.assign({}, CoursePeriodFormInitialValues)
    };
    return Object.assign({}, initialValues);
}

class CopyPeriodForm extends Component<Types.ICopyFormProps, Types.ICopyFormState> {
    state: Types.ICopyFormState = getInitialState();

    setClose = (refresh: boolean = false) => {
        this.setState(this.state);
        if (this.props.onClose) {
            this.props.onClose(refresh);
        }
    };

    setCloseForm = () => {
        this.setClose();
    };

    onFormSave = (model: Types.ITermItem, FormActions: FormikActions<Types.ITermItem>) => {
        const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (result && result.code) {
                let errors: any = {};
                if (result.details) {
                    const validations: Array<Types.IValidationResponse> = result.details;
                    validations.forEach((m: Types.IValidationResponse) => {
                        errors[m.field] = m.message[0];
                    });
                }
                FormActions.setErrors(errors);
            }
            if (status === 200 || status === 201) {
                setTimeout(() => {
                    if (typeof this.props.request_id == 'number') {
                        this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_COPY_REQUEST_STATUS, this.props.request_id));
                        setTimeout(() => {
                            if (this.props.request_status == 1) {
                                this.props.dispatch(Actions.Notification('gen_copy_finished', 'gen_success'))
                                this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_LIST, null, 'term-selector-spinner'));
                                this.setClose(true);
                            } else if (this.props.request_status == 0) {
                                this.props.dispatch(Actions.Notification('gen_please_wait', 'gen_warning', 'warning'));
                                this.props.dispatch(Actions.Notification('gen_please_wait_until_copying_finishes', 'gen_copying_continues', 'warning'));
                                this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_LIST, null, 'term-selector-spinner'));
                                this.setClose(true);
                            } else {
                                this.props.dispatch(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'))
                            }
                        }, 500);
                    }
                }, 500);
            }
        };
        let newModel = {
            status: model.status,
            name: model.name,
            academic_term: model.academic_term,
            term_type: model.term_type,
            year: model.year,
            calendar_status: model.calendar_status,
            description: model.description,
            term_id: model.term_id
        }
        this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_COPY, newModel, 'course-period-form-spin', resultCallback));
    };

    static getDerivedStateFromProps(props: Types.ICopyFormProps, state: Types.ICopyFormState) {
        let hasNewState: boolean = false;

        if (props.formIsOpen && props.coursePeriodId && props.coursePeriodId != state.model.term_id) {
            state.model.term_id = props.coursePeriodId;
            hasNewState = true;
        }

        if (props.form && props.coursePeriodId && props.coursePeriodId == state.model.term_id) {
            state.model = props.form;
            hasNewState = true;
        }

        if (hasNewState) {
            return state;
        } else if (!props.coursePeriodId && state.model.term_id) {
            return getInitialState();
        } else return null;
    }

    render() {
        let yearFound = (this.props.terms!.filter((i: any) => this.props.selectedTermId && this.props.selectedTermId == i.term_id))[0];
        let currentYear = yearFound && yearFound.year;
        if (currentYear == undefined) {
            currentYear = new Date().getFullYear();
        }
        let lastYear = currentYear - 1;
        let nextYear = currentYear + 1;
        let listOfYears = [
            { label: lastYear && lastYear.toString(), value: lastYear },
            { label: currentYear && currentYear.toString(), value: currentYear },
            { label: nextYear && nextYear.toString(), value: nextYear }
        ];

        return (
            <Modal
                modalClassName="modal-from-right"
                style={{ maxWidth: '100%', left: '0', display: 'inline' }}
                className="pt-0"
                isOpen={this.props.formIsOpen}
            >
                <Spinner name="course-period-form-spin" />
                <Formik
                    initialValues={this.state.model}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        this.onFormSave(values, actions);
                    }}
                    validationSchema={FormValidation}
                >
                    {(props: FormikProps<Types.ITermItem>) => {
                        const { values, errors, handleChange, handleBlur, handleSubmit } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="" id="addNew">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">
                                                    <i className="material-icons mr-2">add_circle_outline</i>
                                                    <span>{T.t('gen_copy_term')}</span>
                                                </h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.setCloseForm}>
                                                    <i className="material-icons">close</i>
                                                    <span>ESC</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="add-custom-tag mb-3">
                                                            <div className="react-select-container">
                                                                <label>{T.t('gen_year')}</label>
                                                                <Select
                                                                    className="react-select"
                                                                    isMulti={false}
                                                                    closeMenuOnSelect={true}
                                                                    options={listOfYears}
                                                                    placeholder={T.t('gen_select_year')}
                                                                    value={listOfYears.find(option => option.value === values.year)}
                                                                    onChange={(option: any) => props.setFieldValue('year', option.value)}
                                                                />
                                                                <ErrorMessage component="div" className="error" name="year" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="add-custom-tag mb-3">
                                                            <div className="react-select-container">
                                                                <label>{T.t('gen_term')}</label>
                                                                <Select
                                                                    className="react-select"
                                                                    isMulti={false}
                                                                    filterOption={(option: any, query: any) =>
                                                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                                                    }
                                                                    closeMenuOnSelect={true}
                                                                    options={TermTypes}
                                                                    placeholder={T.t('gen_select_term')}
                                                                    value={
                                                                        values.academic_term != undefined
                                                                            ? TermTypes.find(option => option.value === values.academic_term)
                                                                            : null
                                                                    }
                                                                    onChange={(option: any) => props.setFieldValue('academic_term', option.value)}
                                                                    noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                                                />
                                                            </div>
                                                            {errors && errors.academic_term && props.submitCount > 0 && <div className="error">{T.t('gen_cannot_leave_empty')}</div>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 form-input form-group with-icon">
                                                        <input
                                                            id="name"
                                                            name="name"
                                                            value={values.name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            type="text"
                                                            required
                                                        />
                                                        <span className="highlight" />
                                                        <span className="bar" />
                                                        <label htmlFor="name">{T.t('gen_name')}</label>
                                                        <ErrorMessage component="div" className="error" name="name" />
                                                    </div>
                                                    <div className="col-md-12 form-input form-group with-icon">
                                                        <textarea
                                                            name="description"
                                                            className="form-input"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            rows={2}
                                                            id="description"
                                                            value={values.description}
                                                            placeholder={T.t('gen_description') + ' ' + T.t('gen_max_char_200')}
                                                        />
                                                        <span className="highlight" />
                                                        <span className="bar" />
                                                        <label htmlFor="description" />
                                                        <ErrorMessage component="div" className="error" name="description" />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-6">
                                                        <div className="text-left">
                                                            <h6>{T.t('gen_status')}</h6>
                                                            <div className="tick-radio position-relative d-inline-block">
                                                                <Checkbox name="status" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer d-block">
                                                <div className="row">
                                                    <div className="col-md">
                                                        <button
                                                            type="button"
                                                            data-dismiss="modal"
                                                            className="btn btn-gray min-auto"
                                                            aria-label="Close"
                                                            onClick={this.setCloseForm}
                                                        >
                                                            {T.t('gen_cancel')}
                                                        </button>
                                                    </div>
                                                    <div className="col-md text-md-right">
                                                        <button
                                                            type="button"
                                                            data-dismiss="modal"
                                                            aria-label="alert-success"
                                                            onClick={() => props.handleSubmit()}
                                                            className="btn btn-green"
                                                        >
                                                            <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </Modal>
        );
    }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICopyFormProps): Types.ICopyFormProps => {
    if (!store || !store.state) {
        return ownProps;
    }
    const newProps: Types.ICopyFormProps = Object.assign({}, ownProps, {
        request_id: store.state.exam_period_page && store.state.exam_period_page.requestId,
        request_status: store.state.exam_period_page && store.state.exam_period_page.requestStatus,
        selectedTermId: store.state.term_id,
        user: store.state.user,
        terms: store.state.term_list
    });
    return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    if (next.state.course_period_page) {
        return !!equal(
            prev.state.course_period_page && prev.state.course_period_page.form,
            next.state.course_period_page && next.state.course_period_page.form
        );
    } else {
        return true;
    }
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(CopyPeriodForm);

export default container;