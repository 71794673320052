import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import Select from 'react-select';
import { Log } from 'ng2-logger';
import { Modal, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';
import Announce from '../../components/templates/announce';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { CourseSearchInitialValues, CourseHoursSearchInitialValues } from '../../store/constants/course-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';

import Translator from '../../services/translate-factory';
const T = Translator.create();
const L = Log.create('StudentIntegrationModal');

function getInitialState(): any {
  const initialValues: any = {
    filters: Object.assign({}, CourseHoursSearchInitialValues),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    selected_objects: []
  };
  return Object.assign({}, initialValues);
}

class StudentIntegrationModal extends Component<any, any> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    this.getStudents();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getStudents() {
    const term = this.props.term_list.find((item: any) => item.term_id == this.props.term_id);
    this.props.dispatch(
      Actions.ApiRequest(
        Constants.integration.GET_INTEGRATION,
        { termId: term.term_id, year: term.year, page: 'enrollments' },
        'integration-spinner'
      )
    );
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.integration.GET_INTEGRATION, { reset: true }, 'integration-spinner')
      );
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onSelectItem = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let objectList = Object.assign([], this.state.selected_objects);
      let id: string = e.currentTarget.dataset.id || '';
      let dataItem = e.currentTarget.dataset.item || '';

      if (e.target.checked) {
        checkedList.push(id);
        objectList.push(JSON.parse(dataItem));
      } else {
        let index = checkedList.indexOf(id);
        let objectIndex = objectList.findIndex((obj: any) => obj.tc_no == id);
        if (index !== -1) {
          checkedList.splice(index, 1);
          objectList.splice(objectIndex, 1);
          this.state.filters.select_all = false;
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList,
        selected_objects: objectList
      });
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.getStudents();
      } else {
        this.state.all_ids = [];
        this.state.selected_ids = [];
        this.state.filters.select_all = false;
        this.setState(this.state);
      }
    }
  };

  addFromIntegration = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: 'Öğrenci Ekleme',
          body: `Seçtiğiniz öğrenciler eklenecektir. Devam etmek istiyor musunuz?`,
          name: 'integration_program_add',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.setClose();
                this.onUpdateListFromModal();
              }
            };
            const term = this.props.term_list.find((item: any) => item.term_id == this.props.term_id);

            const model = {
              page: '/students',
              termId: term.term_id,
              year: term.year,
              status: 1,
              description: '',
              enrollments: this.state.selected_objects
            };

            this.props.dispatch(
              Actions.ApiRequest(
                Constants.integration.ADD_MULTI_WITH_INTEGRATION,
                model,
                'add-multi-with-integration',
                resultCallback
              )
            );
          }
        })
      );
    }
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getStudents();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.getStudents();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, CourseSearchInitialValues);
    this.state.all_ids = [];
    this.setState(this.state);
    this.getStudents();
  };

  onFilterCourse(model: Types.IFilterCourse, FormActions: FormikActions<Types.IFilterCourse>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.getStudents();
    FormActions.setSubmitting(false);
  }

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;
    if (state.filters.select_all) {
      hasNewState = true;
      state.all_ids = props.all_ids;
      state.selected_ids = props.all_ids;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  checkAllSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  render() {
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              Entegrasyon ile Öğrenci Ekleme
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12">
                  <div className="quick-actions">
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: [],
                              all_ids: [],
                              filters: {
                                ...this.state.filters,
                                select_all: false
                              }
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          className="category-tag-square tag-green float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={this.addFromIntegration}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{T.t('gen_add_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px', cursor: 'not-allowed' }}
                          disabled={true}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;(0)</b>
                          </span>
                        </button>
                        <button
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px', cursor: 'not-allowed' }}
                          disabled={true}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{T.t('gen_add_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        {this.props.results && this.props.results.length ? (
                          <th data-cell="select">
                            <div className="tick-radio position-relative">
                              <input
                                type="checkbox"
                                className="form-radio"
                                checked={this.checkAllSelected()}
                                onChange={this.onSelectAll}
                              />
                            </div>
                          </th>
                        ) : null}
                        <SortedColumn
                          datacell="tc_no"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_student_id')}
                          sortkey="tc_no"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="student_number"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_student_number')}
                          sortkey="student_number"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="name"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_name_surname')}
                          sortkey="name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="enrolled_program_code"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_registered_program_code')}
                          sortkey="enrolled_program_code"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="enrolled_program"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_registered_program')}
                          sortkey="enrolled_program"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="class"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_grade')}
                          sortkey="class"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="course_code"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_course_code')}
                          sortkey="course_code"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="course_name"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_name')}
                          sortkey="course_name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="group"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title="Grup"
                          sortkey="group"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="course_program_code"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_course_program_code')}
                          sortkey="course_program_code"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="course_program"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_course_program')}
                          sortkey="course_program"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="faculty_code"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_course_faculty_code')}
                          sortkey="faculty_code"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="course_faculty"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_course_faculty')}
                          sortkey="course_faculty"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.results && this.props.results.length ? (
                        this.props.results.map((item: Types.IStudentItem) => {
                          let studentID = item.tc_no ? item.tc_no : -1;
                          return (
                            <tr key={'student-' + studentID} data-title={item.name}>
                              <td data-cell="select">
                                <div className="tick-radio position-relative">
                                  <input
                                    type="checkbox"
                                    className="form-radio"
                                    checked={this.state.selected_ids.includes(studentID)}
                                    data-id={studentID}
                                    data-item={JSON.stringify(item)}
                                    onChange={this.onSelectItem}
                                  />
                                </div>
                              </td>
                              <td data-label={T.t('gen_student_id')} className="text-center">
                                {item.tc_no}
                              </td>
                              <td data-label={T.t('gen_student_number')} className="text-center">
                                {item.student_number}
                              </td>
                              <td data-label={T.t('gen_name_surname')} className="text-center">
                                {item.name}
                              </td>
                              <td data-label={T.t('gen_registered_program_code')} className="text-center">
                                {item.enrolled_program_code}
                              </td>
                              <td data-label={T.t('gen_registered_program')} className="text-center">
                                {item.enrolled_program_name}
                              </td>
                              <td data-label={T.t('gen_grade')} className="text-center">
                                {item.class}
                              </td>
                              <td data-label={T.t('gen_course_code')} className="text-center">
                                {item.course_code}
                              </td>
                              <td data-label={T.t('gen_name')} className="text-center">
                                {item.course_name}
                              </td>
                              <td data-label="Grup" className="text-center">
                                {item.group}
                              </td>
                              <td data-label={T.t('gen_course_program_code')} className="text-center">
                                {item.course_program_code}
                              </td>
                              <td data-label={T.t('gen_course_program')} className="text-center">
                                {item.course_program_name}
                              </td>
                              <td data-label={T.t('gen_course_faculty_code')} className="text-center">
                                {item.course_faculty_code}
                              </td>
                              <td data-label={T.t('gen_course_faculty')} className="text-center">
                                {item.course_faculty_name}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={24}>
                            <Announce title={T.t('gen_no_records_found')} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    >
                      {this.props.results && this.props.results.length > 0 ? (
                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    results: store.state.integration && store.state.integration.results,
    filters: store.state.integration && store.state.integration.filters,
    all_ids: store.state.integration && store.state.integration.all_ids,
    term_id: store.state.term_id,
    term_type: store.state.term_type,
    term_list: store.state.term_list
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.integration) {
    return (
      !!equal(
        prev.state.integration && prev.state.integration.results,
        next.state.integration && next.state.integration.results
      ) &&
      !!equal(
        prev.state.integration && prev.state.integration.filters,
        next.state.integration && next.state.integration.filters
      ) &&
      !!equal(
        prev.state.integration && prev.state.integration.all_ids,
        next.state.integration && next.state.integration.all_ids
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(StudentIntegrationModal);

export default container;
