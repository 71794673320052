import { Formik, FormikActions, FormikProps } from 'formik';
import { Log, Logger } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';
import * as Actions from '../../store/actions/general';
import Announce from '../../components/templates/announce';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import * as Constants from '../../store/constants/all';
import {
  CourseHoursSearchInitialValues,
  ExamPeriodCourseTermLectureLocations,
  TermTypes,
  ClassroomCombineTypes,
  CourseHourStatusTypes
} from '../../store/constants/course-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import ClassroomAddModal from './classroom-hours-add-modal';
import CombineExamsModal from './combined-exams-modal';
import CopyCourseModal from './course-copy-modal';
import CourseEditForm from './course-edit-form';
import CourseHoursListPageTableHeaders from './components/course-hours-listpage-tableheaders';
import DownloadButton from '../../components/excel-imports/export';
import { SectionTypes } from '../../store/constants/enums';

import Translator from '../../services/translate-factory';
const T = Translator.create();
const L = Log.create('CourseAddModal');
class CourseAddModal extends Component<Types.ICoursePageProps, Types.ICoursePageState> {
  state: Types.ICoursePageState = {
    filters: Object.assign({}, CourseHoursSearchInitialValues),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    selected_course_ids: [],
    selected_names: [],
    combined_list_items: [],
    selected_courses: [],
    classroomAddModalIsOpen: false,
    hasCombinedCourses: false,
    combineExamsModalIsOpen: false,
    courseCopyModalIsOpen: false,
    courseFormIsOpen: false,
    courseId: undefined,
    pageIsChanged: false
  };

  componentDidMount() {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.setState(this.state);
    this.getProgramSelectOptions([]);
  }

  componentDidUpdate(prevProps: Types.ICoursePageProps, prevState: Types.ICoursePageState) {
    if (prevProps.courseList !== this.props.courseList) {
      this.setState({
        pageIsChanged: false
      })
    }
    if (prevProps.courseList !== this.props.courseList && !this.state.pageIsChanged) {
      if (this.props.filters && !this.props.filters.select_all) {
        this.setState({
          selected_course_ids: [],
          selected_ids: [],
          selected_courses: [],
          selected_names: []
        })
      }
    }
    if (this.props.hoursSaved) {
      this.state.selected_ids = []
    }
  }

  getProgramSelectOptions = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.instructor.PROGRAMS_BY_FACULTYIDS_INSTRUCTOR_GET_SELECT_OPTIONS, facultyIds, 'instructor-list-spin'));
  }

  getCourseHours() {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;

    // TODO NEW MODEL
    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS, this.state.filters, 'ep-course-list')
    );

    // let classroomFilter: Types.IFilterClassroom =
    // {
    //   term_id: id,
    //   page: 1,
    //   total: -1,
    //   course_ids: this.state.selected_ids || [],
    //   size: 10
    // };
    // this.props.dispatch(
    //   Actions.ApiRequest(
    //     Constants.exam_period.EXAM_PERIOD_GET_ATTACHED_CLASSROOMS,
    //     classroomFilter,
    //     'course-tab-attached-classrooms'
    //   )
    // );
  }

  getCourseSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_GET_SELECT_OPTIONS, 'course-list-spin'));
  }

  setShow = (refresh: boolean = false) => {
    if (this.props.onShowModal) {
      this.props.onShowModal(refresh);
    }
  };

  onShowModal = () => {
    this.setShow();
  };

  switchClassroomModalStatus = () => {
    this.setState({
      ...this.state,
      classroomAddModalIsOpen: !this.state.classroomAddModalIsOpen
    });
  };

  switchCombineExamsModalStatus = () => {
    if (this.state.combineExamsModalIsOpen == true) {
      this.unCheckAllSelecteds();
    }
    this.setState({
      ...this.state,
      combineExamsModalIsOpen: !this.state.combineExamsModalIsOpen
    });
  };

  switchCopyExamModalStatus = () => {
    if (this.state.courseCopyModalIsOpen == true) {
      this.unCheckAllSelecteds();
    }
    this.setState({
      ...this.state,
      courseCopyModalIsOpen: !this.state.courseCopyModalIsOpen
    });
  };

  unCheckAllSelecteds = () => {
    this.setState({
      ...this.state,
      selected_ids: [],
      selected_names: [],
      selected_courses: []
    });
  };

  showCourseProgram = (e?: any, id?: number) => {
    if (this.props.onSelect) {
      this.props.onSelect(e, id);
      this.state.selectedCourseId = id;
      this.setState(this.state);
    }
  };

  onShowCourseProgram = (e?: any) => {
    let numID: number = -1;
    const id: string = e.currentTarget.dataset.id || '';
    numID = parseInt(id, 10);
    this.showCourseProgram(e, numID);
    this.onSelectCourse(e);
  };

  checkAllSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if (this.props.onCheckAll) {
    //   this.props.onCheckAll(e);
    // }
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS,
            this.state.filters,
            'ep-course-list',
            (response: any) =>
              this.setState({
                ...this.state,
                selected_ids: response.all_ids,
                selected_course_ids: response.all_course_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          selected_ids: [],
          selected_course_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  onSelectCourseCalledFromCoursesTab = (e: any) => {
    if (e && e.currentTarget) {
      if (this.props.onCheck) {
        this.props.onCheck(e);
      }
      let checkedIDList: Array<number> = Object.assign([], this.state.selected_ids);
      let selected_course_ids: Array<number> = Object.assign([], this.state.selected_course_ids);

      let checkedNameList = Object.assign([], this.state.selected_names);
      let checkedCourses = Object.assign([], this.state.selected_courses);
      let stringID: any = e.currentTarget.dataset.id || -1;
      let courseName: string = e.currentTarget.dataset.name || '';
      let string_courseItem: string = e.currentTarget.dataset.item || '';
      let courseItem: Types.IExamPeriod_CourseItem = JSON.parse(string_courseItem);
      let courseID = parseInt(stringID, 10);

      if (selected_course_ids.findIndex((_id: number) => _id === courseItem.course_id) === -1) {
        selected_course_ids.push(courseItem.course_id ? courseItem.course_id : -1)
      } else {
        selected_course_ids = selected_course_ids.filter((_id: number) => _id != courseItem.course_id)
      }
      if (checkedIDList.findIndex((_id: number) => _id === courseID) === -1) {
        checkedIDList.push(courseID);
        checkedNameList.push(courseName);
        checkedCourses.push({ id: courseID, item: courseItem });
      } else {
        let index = checkedIDList.indexOf(courseID);
        let nameIndex = checkedNameList.indexOf(courseName);
        let courseItem: any = {};
        courseItem = checkedCourses.find(
          (item: { id: number; item: Types.IExamPeriod_CourseItem }) => item.id === courseID
        );
        let courseItemIndex = courseItem ? courseItem.id : -1;
        if (index !== -1) {
          checkedIDList.splice(index, 1);
        }
        if (nameIndex !== -1) {
          checkedNameList.splice(nameIndex, 1);
        }
        if (courseItemIndex !== -1) {
          checkedCourses = checkedCourses.filter((item: any) => item.id != courseID);
        }
        this.setState({
          selected_ids: checkedIDList,
          selected_names: checkedNameList,
          selected_courses: checkedCourses,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        selected_ids: checkedIDList,
        selected_course_ids: selected_course_ids,
        selected_names: checkedNameList,
        selected_courses: checkedCourses
      });
    }
  };

  onSelectCourse = (e: any) => {
    if (e && e.currentTarget) {
      if (this.props.onCheck) {
        this.props.onCheck(e);
      }
      let checkedIDList: Array<number> = Object.assign([], this.state.selected_ids);
      let selected_course_ids: Array<number> = Object.assign([], this.state.selected_course_ids);

      let checkedNameList = Object.assign([], this.state.selected_names);
      let checkedCourses = Object.assign([], this.state.selected_courses);
      let stringID: any = e.currentTarget.dataset.id || -1;
      let courseName: string = e.currentTarget.dataset.name || '';
      let string_courseItem: string = e.currentTarget.dataset.item || '';
      let courseItem: Types.IExamPeriod_CourseItem = JSON.parse(string_courseItem);
      let courseID = parseInt(stringID, 10);

      if (selected_course_ids.findIndex((_id: number) => _id === courseItem.course_id) === -1) {
        selected_course_ids.push(courseItem.course_id ? courseItem.course_id : -1)
      } else {
        selected_course_ids = selected_course_ids.filter((_id: number) => _id != courseItem.course_id)
      }
      if (checkedIDList.findIndex((_id: number) => _id === courseID) === -1) {
        checkedIDList.push(courseID);
        checkedNameList.push(courseName);
        checkedCourses.push({ id: courseID, item: courseItem });
      } else {
        let index = checkedIDList.indexOf(courseID);
        let nameIndex = checkedNameList.indexOf(courseName);
        let courseItem: any = {};
        courseItem = checkedCourses.find(
          (item: { id: number; item: Types.IExamPeriod_CourseItem }) => item.id === courseID
        );
        let courseItemIndex = courseItem ? courseItem.id : -1;
        if (index !== -1) {
          checkedIDList.splice(index, 1);
        }
        if (nameIndex !== -1) {
          checkedNameList.splice(nameIndex, 1);
        }
        if (courseItemIndex !== -1) {
          checkedCourses = checkedCourses.filter((item: any) => item.id != courseID);
        }
        this.setState({
          //...this.state,
          selected_ids: checkedIDList,
          selected_names: checkedNameList,
          selected_courses: checkedCourses,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        //...this.state,
        selected_ids: checkedIDList,
        selected_course_ids: selected_course_ids,
        selected_names: checkedNameList,
        selected_courses: checkedCourses
      });
    }
    this.setState({
      ...this.state,
      selected_course_ids: this.state.selected_course_ids
    });
    if (this.props.updateCheckedList) {
      this.props.updateCheckedList(this.state.selected_course_ids);
    }
  };

  onSelectCourseCheckbox = async (e: any) => {
    if (e && e.currentTarget) {
      //this.state.selected_ids = this.state.selected_courses && this.state.selected_courses.map(i => i.id)
      let checkedIDList: Array<number> = Object.assign([], this.state.selected_ids);
      let selected_course_ids: Array<number> = Object.assign([], this.state.selected_course_ids);
      let result = this.state.course_list!.filter(i =>
        selected_course_ids!.some(filter => filter == i.course_id!)).map(a => a.course_hour_id!);
      checkedIDList = result;

      let checkedNameList = Object.assign([], this.state.selected_names);
      let checkedCourses = Object.assign([], this.state.selected_courses);
      let stringID: any = e.currentTarget.dataset.id || -1;
      let courseName: string = e.currentTarget.dataset.name || '';
      let string_courseItem: string = e.currentTarget.dataset.item || '';
      let courseItem: Types.IExamPeriod_CourseItem = JSON.parse(string_courseItem);
      let courseID = parseInt(stringID, 10);

      if (selected_course_ids.findIndex((_id: number) => _id === courseItem.course_id) === -1) {
        selected_course_ids.push(courseItem.course_id ? courseItem.course_id : -1)
      } else {
        selected_course_ids = selected_course_ids.filter((_id: number) => _id != courseItem.course_id)
      }
      if (checkedIDList.findIndex((_id: number) => _id === courseID) === -1) {
        checkedIDList.push(courseID);
        checkedNameList.push(courseName);
        checkedCourses.push({ id: courseID, item: courseItem });
      } else {
        let index = checkedIDList.indexOf(courseID);
        let nameIndex = checkedNameList.indexOf(courseName);
        let courseItem: any = {};
        courseItem = checkedCourses.find(
          (item: { id: number; item: Types.IExamPeriod_CourseItem }) => item.id === courseID
        );
        let courseItemIndex = courseItem ? courseItem.id : -1;
        if (index !== -1) {
          checkedIDList.splice(index, 1);
        }
        if (nameIndex !== -1) {
          checkedNameList.splice(nameIndex, 1);
        }
        if (courseItemIndex !== -1) {
          checkedCourses = checkedCourses.filter((item: any) => item.id != courseID);
        }

        this.setState({
          selected_ids: checkedIDList,
          selected_names: checkedNameList,
          selected_courses: checkedCourses,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      await this.setState({
        selected_ids: checkedIDList,
        selected_course_ids: selected_course_ids,
        selected_names: checkedNameList,
        selected_courses: checkedCourses
      });
    }
    if (this.props.updateCheckedList) {
      this.props.updateCheckedList(this.state.selected_course_ids);
    }
    if (this.props.saveAllCheckboxes) {
      this.props.saveAllCheckboxes(this.state.selected_ids);
    }
  };

  saveCombinedCourses = (
    combinedCourses?: Array<Types.IExamPeriod_CourseItem>,
    courseObject?: Types.IExamPeriod_CourseItem
  ) => {
    let courseArray: any = [];
    if (combinedCourses) {
      combinedCourses.map((item: Types.IExamPeriod_CourseItem) => {
        courseArray.push({ id: item.course_id, item: item });
      });
    }
    this.setState({
      ...this.state,
      hasCombinedCourses: true,
      combined_courses: courseArray,
      courseObject: courseObject,
      combineExamsModalIsOpen: !this.state.combineExamsModalIsOpen
    });
  };

  deleteCourses = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: 'Takvimden Kaldırılacak',
          content: (
            <div>
              <ul style={{ listStyleType: 'none' }}>
                {this.state.selected_names &&
                  this.state.selected_names.map((courseName: string) => <li>{courseName}</li>)}
              </ul>
              <p>Seçtiğiniz dersler bu sınav takviminden kaldırılacaktır. Devam etmek istiyor musunuz?</p>
            </div>
          ),
          name: 'examPeriod_courses_delete',
          icon: '',
          iconColor: '',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                if (this.props.clearCheckedItems) {
                  this.props.clearCheckedItems();
                }
                this.state.selected_ids = [];
                this.state.selected_courses = [];
                this.state.selected_names = [];
                this.state.selected_course_ids = [];
                this.getCourseHours();
              }
            };
            /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
            let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
            var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
            let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
            this.state.filters.term_id = id;
            this.state.filters.delete_list = this.state.selected_ids;
            this.state.filters.course_ids = this.state.selected_course_ids

            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_DELETE_COURSES_FROM_PERIOD,
                this.state.filters,
                'ep-course-list',
                resultCallback
              )
            );
            this.state.selected_ids = [];
            this.state.selected_courses = [];
            this.state.selected_names = [];
            this.state.selected_course_ids = [];
          }
        })
      );
    }
  };

  sort = (sortkey: string, order_by: string) => {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getCourseHours();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.state.pageIsChanged = true;
    this.setState(this.state);
    this.getCourseHours();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, CourseHoursSearchInitialValues);
    GT.GetTermIdFromURL(window, this.state.filters.term_id);
    this.setState(this.state);
    this.getCourseHours();
  };

  onFilterCourse(model: Types.IFilterCourse, FormActions: FormikActions<Types.IFilterCourse>) {
    GT.GetTermIdFromURL(window, this.state.filters.term_id);
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.state.pageIsChanged = true;
    this.setState(this.state);
    this.getCourseHours();
    FormActions.setSubmitting(false);
  }

  isAvailableToCombine = () => {
    let isSelectedAlreadyCombined: boolean = false;
    let selected_ids = this.state && this.state.selected_ids;
    let combined_ids = this.state && this.state.combined_list_items;
    selected_ids &&
      selected_ids.map((selectedID: number) => {
        if (combined_ids && combined_ids.includes(selectedID)) {
          isSelectedAlreadyCombined = true;
        }
      });

    if (isSelectedAlreadyCombined) {
      this.props.dispatch(
        Actions.ShowModal({
          title: 'Hata',
          body: `Birleştirilmiş bir dersi, birleştirmeye dahil edemezsiniz.`,
          name: 'examPeriod_delete',
          icon: 'error_outline',
          iconColor: 'red',
          cancel: 'Tamam'
        })
      );
    } else {
      this.setState({
        ...this.state,
        hasCombinedCourses: false,
        combineExamsModalIsOpen: !this.state.combineExamsModalIsOpen
      });
    }
  };

  static getDerivedStateFromProps(props: Types.ICoursePageProps, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;
    if (props.courseList) {
      hasNewState = true;
      state.course_list = props.courseList;
    }

    if (props.compactTable && props.selectedCourseId && !!!state.selectedCourseId) {
      hasNewState = true;
      state.selectedCourseId = props.selectedCourseId;
    }

    if (props.all_ids && props.all_ids.length) {
      hasNewState = true;
      state.all_ids = props.all_ids;
    }

    if (
      props.compactTable &&
      props.checkedCourseIds &&
      props.checkedCourseIds.length > 0 &&
      state.selected_ids &&
      state.selected_ids.length == 0
    ) {
      hasNewState = true;
      //state.selected_ids = props.checkedCourseIds;
    }

    if (
      props.compactTable &&
      props.checkedCourseIds &&
      props.checkedCourseIds.length == 0 &&
      state.selected_ids &&
      state.selected_ids.length > 0
    ) {
      hasNewState = true;
      //state.selected_ids = [];
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  getProgramsRelatedFaculty = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.instructor.PROGRAMS_BY_FACULTYIDS_INSTRUCTOR_GET_SELECT_OPTIONS, facultyIds, 'instructor-list-spin'));
  }

  render() {
    if (this.props.getCurrentPage) {
      this.props.getCurrentPage(this.state)
    }

    let courseList = this.state.course_list;
    if (this.props.compactTable) {
      if (this.state.filters.select_all) {
        //this.state.selected_ids = this.state.all_ids
      }
      if (this.props.hoursSaved) {
        this.state.selected_ids = []
      }
    }
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const CampusSelectOptions = [...ExamPeriodCourseTermLectureLocations, ...campusOptions];
    // TODO: dersler için bu gerekli değilse kaldırılmalı
    let placeHolder = (
      <div className="text-center">
        <p>{T.t('gen_classrooms_info_placeholder')}</p>
        {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e') ? (
          <button className="category-tag-square tag-green" onClick={this.onShowModal}>
            <i className="material-icons mr-2">playlist_add</i>
            <span>{T.t('gen_add_course')}</span>
          </button>
        ) : null}
      </div>
    );

    let Table = (
      <div className="row">
        <div className="col-12">
          <div className="quick-actions">
            <span
              className="category-tag-square tag-glass float-right"
              onClick={() => {
                this.state.filterIsOpen = !this.state.filterIsOpen;
                this.setState(this.state);
              }}
            >
              <i data-toggle="tooltip" className="material-icons" data-original-title="Listeye Ekle">
                filter_list
              </i>
              {T.t('gen_filter')}
            </span>
            {this.state.selected_course_ids && this.state.selected_course_ids.length ? (
              <React.Fragment>
                {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e') ? (
                  this.state.selected_course_ids && this.state.selected_course_ids.length !== 0 ? (
                    <button
                      className="category-tag-square tag-gray float-left pr-2 pl-2"
                      style={{ margin: '2px' }}
                      onClick={() => {
                        if (this.props.clearCheckedItems) {
                          this.props.clearCheckedItems();
                        }
                        this.setState({
                          ...this.state,
                          selected_ids: [],
                          selected_names: [],
                          selected_courses: [],
                          selected_course_ids: [],
                          all_ids: []
                        });
                        this.state.filters.select_all = false;
                      }}
                    >
                      <i className="material-icons mr-2">close</i>
                      <span>{this.props.compactTable ? T.t('gen_cancel') : T.t('gen_cancel_selection')}</span>
                      <b>
                        &nbsp;(
                      {this.state.selected_course_ids && this.state.selected_course_ids.length})
                  </b>
                    </button>
                  ) : (
                    <button
                      className="category-tag-square tag-green float-left pr-2 pl-2"
                      onClick={() => {
                        if (this.props.compactTable && this.props.toggleAddModal) {
                          this.props.toggleAddModal();
                        } else {
                          this.onShowModal();
                        }
                      }}
                    >
                      <i className="material-icons mr-2">playlist_add</i>
                      <span>{T.t('gen_add_course')}</span>
                    </button>
                  )
                ) : null}
                {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e') ? (
                  <button
                    className="category-tag-square float-left pr-2 pl-2"
                    style={{ margin: '2px', color: '#fff', backgroundColor: '#dc3545' }}
                    onClick={this.deleteCourses}
                  >
                    <i className="material-icons mr-2" style={{ color: 'white' }}>
                      delete_outline
                  </i>
                    <span>{this.props.compactTable ? T.t('gen_delete') : T.t('gen_delete_selecteds')}</span>
                  </button>
                ) : null}
                {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e') ? (
                  <button
                    className="category-tag-square tag-green float-left pr-2 pl-2"
                    style={{ margin: '2px' }}
                    onClick={this.switchClassroomModalStatus}
                  >
                    <i className="material-icons mr-2">input</i>
                    <span>{T.t('gen_assign_classroom')}</span>
                  </button>
                ) : null}
                {!this.props.compactTable &&
                  this.state.selected_ids &&
                  this.state.selected_ids.length &&
                  this.state.selected_ids.length < 2 ? (
                  <button
                    className="category-tag-square float-left pr-2 pl-2"
                    style={{ margin: '2px', color: '#fff', background: 'indigo' }}
                    onClick={() =>
                      this.setState({
                        courseCopyModalIsOpen: !this.state.courseCopyModalIsOpen
                      })
                    }
                  >
                    <i className="material-icons mr-2" style={{ color: 'white' }}>
                      content_copy
                    </i>
                    <span>{T.t('gen_copy_activity')}</span>
                  </button>
                ) : null}
                {!this.props.compactTable && this.state.selected_ids && this.state.selected_ids.length > 1 ? (
                  <button
                    className="category-tag-square tag-blue float-left pr-2 pl-2"
                    style={{ margin: '2px' }}
                    onClick={this.isAvailableToCombine}
                  >
                    <i className="material-icons mr-2" style={{ color: 'white' }}>
                      call_merge
                    </i>
                    <span>Sınavları Birleştir</span>
                  </button>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e') ? (
                  <button
                    className="category-tag-square tag-green float-left pr-2 pl-2"
                    onClick={() => {
                      if (this.props.compactTable && this.props.toggleAddModal) {
                        this.props.toggleAddModal();
                      } else {
                        this.onShowModal();
                      }
                    }}
                  >
                    <i className="material-icons mr-2">playlist_add</i>
                    <span>{T.t('gen_add_course')}</span>
                  </button>
                ) : null}
                <DownloadButton title={'Bu sınav dönemindeki dersler'} sectionType={SectionTypes.Exam_Period_Courses} />
              </React.Fragment>
            )}
          </div>
        </div>
        <div
          className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
          id="advance-search"
        >
          <div className="advance-search d-block mt-3">
            <Formik
              initialValues={CourseHoursSearchInitialValues}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                this.onFilterCourse(values, actions);
              }}
              onReset={this.onFormReset}
            >
              {(props: FormikProps<Types.IFilterCourse>) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <h6>{T.t('gen_filter_parameters')}</h6>
                      </div>
                      <div className="col-md-4">
                        <div className="form-input form-group date-picker mt-3">
                          <input
                            id="course_code"
                            name="course_code"
                            value={props.values.course_code}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="course_code">{T.t('gen_code')}</label>
                          <i className="material-icons">filter_1</i>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-input form-group date-picker mt-3">
                          <input
                            id="conjugate_course_code"
                            name="conjugate_course_code"
                            value={props.values.conjugate_course_code}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="conjugate_course_code">{T.t('gen_conjugate_course_code')}</label>
                          <i className="material-icons">filter_1</i>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-input form-group date-picker mt-3">
                          <input
                            id="name"
                            name="name"
                            value={props.values.name}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="name">{T.t('gen_name')}</label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_campus')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                              }
                              closeMenuOnSelect={false}
                              options={CampusSelectOptions}
                              placeholder={T.t('gen_select_location')}
                              value={props.values.campuses ? props.values.campuses : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('campuses', list);
                                props.setFieldValue(
                                  'campus_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 form-input form-group with-icon mt-3">
                        <input
                          id="exam_duration"
                          name="exam_duration"
                          value={props.values.exam_duration}
                          onChange={props.handleChange}
                          type="number"
                          required
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor="exam_duration">{T.t('gen_exam_duration')} ({T.t('gen_slot_count')})</label>
                      </div>
                      <div className="col-md-4 form-input form-group with-icon mt-3">
                        <input
                          id="section"
                          name="section"
                          value={props.values.section}
                          onChange={props.handleChange}
                          type="text"
                          required
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor="section">{T.t('gen_section')}</label>
                      </div>
                      <div className="col-md-4 form-input form-group with-icon mt-3">
                        <input
                          id="exam_session"
                          name="exam_session"
                          value={props.values.exam_session}
                          onChange={props.handleChange}
                          type="number"
                          required
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor="exam_session">{T.t('gen_session_count')}</label>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_grade')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.class
                                  ? this.props.selectOptions.class
                                  : []
                              }
                              placeholder={T.t('gen_select_grade')}
                              value={props.values.class ? props.values.class : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('class', list);
                                props.setFieldValue(
                                  'class_ids',
                                  list.map((item) => item.value)
                                );
                                this.getProgramsRelatedFaculty(list.map(item => item.value))
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_faculty_college')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.faculties
                                  ? this.props.selectOptions.faculties
                                  : []
                              }
                              placeholder={T.t('gen_select_faculty')}
                              value={props.values.faculties ? props.values.faculties : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('faculties', list);
                                props.setFieldValue(
                                  'faculty_ids',
                                  list.map((item) => item.value)
                                );
                                this.getProgramSelectOptions(list.map(item => item.value))
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_connected_programs')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                              }
                              closeMenuOnSelect={false}
                              options={this.props.programs_related_faculty}
                              placeholder={T.t('gen_select_program')}
                              value={props.values.programs}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('programs', list);
                                props.setFieldValue(
                                  'program_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_program')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_instructors')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.instructors
                                  ? this.props.selectOptions.instructors
                                  : []
                              }
                              placeholder={T.t('gen_select_instructor')}
                              value={props.values.instructors}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('instructors', list);
                                props.setFieldValue(
                                  'instructor_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_classroom_combine_status')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                              }
                              closeMenuOnSelect={false}
                              options={ClassroomCombineTypes}
                              placeholder={T.t('gen_classroom_combine_status')}
                              value={props.values.classroom_combine_status ? props.values.classroom_combine_status : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('classroom_combine_status', list);
                                props.setFieldValue(
                                  'classroom_combine_status_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_assistants')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.instructors
                                  ? this.props.selectOptions.instructors
                                  : []
                              }
                              placeholder={T.t('gen_select_assistant')}
                              value={props.values.assistant_staff}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('assistant_staff', list);
                                props.setFieldValue(
                                  'assistant_staff_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_assistan')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_course_hour_status')}</label>
                            <Select
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={CourseHourStatusTypes}
                              placeholder={T.t('gen_course_hour_status')}
                              value={props.values.course_hour_status ? props.values.course_hour_status : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('course_hour_status', list);
                                props.setFieldValue(
                                  'course_hour_status_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row mt-3">
                      <div className="col-6">
                        <button
                          type="button"
                          onClick={() => {
                            this.state.filterIsOpen = false;
                            this.setState(this.state);
                          }}
                          className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                        >
                          <i className="material-icons">arrow_upward</i>
                        </button>
                        <button
                          type="reset"
                          onClick={props.handleReset}
                          className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                        >
                          <i className="material-icons">delete_sweep</i>
                        </button>
                      </div>
                      <div className="col-6 text-right">
                        <button
                          type="button"
                          className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                          onClick={() => props.handleSubmit()}
                          disabled={props.isSubmitting}
                        >
                          <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="col-12">
          <table
            className={this.props.compactTable ? "aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table table-course-hours-classrooms" : "aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table"}
            style={this.props.compactTable ? { cursor: 'pointer' } : {}}
          >
            <CourseHoursListPageTableHeaders
              compactTable={this.props.compactTable}
              fnSelectAll={this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e') ? (this.onSelectAll) : null}
              fnColumnSorter={this.sort}
              selected_ids={this.state.selected_ids}
              selected_course_ids={this.state.selected_course_ids}
              all_ids={this.state.all_ids}
              filters={this.state.filters}
              courseList={this.props.courseList}
            />

            <tbody>
              {courseList && courseList.length ? (
                courseList.map((item: Types.IExamPeriod_CourseItem, index: any) => {
                  let termObject: any = {};
                  let ClassroomCombineStatusObject: any = {};
                  if (item.courseInfo && item.courseInfo.term) {
                    let term = item.courseInfo.term;
                    termObject = TermTypes.find((object) => object.value == term);
                  }
                  //if (item.courseInfo && item.courseInfo.classroom_combine_status) {
                  let combineStatus = item.courseInfo && item.courseInfo.classroom_combine_status;
                  ClassroomCombineStatusObject = ClassroomCombineTypes.find(
                    (object) => object.value == combineStatus
                  );
                  //}

                  let combinedCourses = item.courseInfo && item.courseInfo.combined_courses;

                  let hasCombinedCourses =
                    item.courseInfo && item.courseInfo.combined_courses && item.courseInfo.combined_courses.length > 0;

                  if (hasCombinedCourses) {
                    this.state.combined_list_items && this.state.combined_list_items.push(item.course_id);
                  }

                  let tableContent = null;

                  if (this.props.compactTable) {
                    tableContent = (
                      <tr className="table-course-hours-classrooms-tr" key={index}>
                        <td data-cell="select">
                          {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e') ? (
                            <div className="tick-radio position-relative">
                              <input
                                type="checkbox"
                                className="form-radio"
                                onChange={this.onSelectCourseCheckbox}
                                checked={
                                  //this.state.filters.select_all ? 
                                  this.state.selected_ids && this.state.selected_ids.indexOf(item.course_hour_id ? item.course_hour_id : -1) > -1
                                }
                                data-id={item.course_hour_id}
                                data-name={item.courseInfo && item.courseInfo.course_code + ' - ' + item.courseInfo.name}
                                data-item={JSON.stringify(item)}
                              />
                            </div>
                          ) : null}
                        </td>
                        <td
                          key={'course-' + item.course_id}
                          className="examperiod-coursehours-td"
                          data-title={item.courseInfo && item.courseInfo.name}
                          data-id={item.course_id}
                          data-course_id={item.course_id}
                          data-name={item.courseInfo && item.courseInfo.course_code + ' - ' + item.courseInfo.name}
                          data-item={JSON.stringify(item)}
                          onClick={this.onShowCourseProgram}
                          style={
                            this.state.selectedCourseId == item.course_id
                              ? {
                                backgroundColor: 'rgba(218, 225, 232, 0.96)',
                                border: '1px solid darkgrey'
                              }
                              : {}
                          }
                        >
                          <table>
                            <tbody>
                              <tr>
                                <td className="examp-compt-select">
                                  {item.status ? (
                                    <i className="material-icons" style={{ color: '#33c900c4' }}>
                                      check_circle
                                    </i>
                                  ) : (
                                    <i className="material-icons" style={{ color: '#ff0000c4' }}>
                                      cancel
                                    </i>
                                  )}
                                </td>
                                <td data-label={T.t('gen_name')} className="table-course-hours-classrooms-td-8 text-center" style={{ width: '40%' }}>
                                  {item.courseInfo && `(${item.courseInfo.course_code}) ${item.courseInfo.name}`}
                                </td>
                                <td
                                  data-cell="instructors"
                                  id={'instructors-popover-' + item.course_id}
                                  style={{
                                    textAlign: 'center',
                                    width: '30%'
                                  }}
                                  className="table-course-hours-classrooms-td-8"
                                >
                                  {
                                    item.courseInfo && item.courseInfo.instructors && item.courseInfo.instructors.length > 10
                                      ?
                                      item.courseInfo.instructors && item.courseInfo.instructors.map((i: any, index) => <span key={index}>{i.label}, </span>)
                                      :
                                      <>
                                        {
                                          item.courseInfo && item.courseInfo.instructors && item.courseInfo.instructors.length == 1 &&
                                          (
                                            <React.Fragment>
                                              <button className="multiple-info-tag small-tag group-ellipsis">
                                                {item.courseInfo.instructors && item.courseInfo.instructors[0].label}
                                              </button>
                                              <UncontrolledPopover
                                                trigger="hover focus"
                                                placement="bottom"
                                                target={'instructors-popover-' + item.course_id}
                                              >
                                                <PopoverHeader>{T.t('gen_lecture_staff')}</PopoverHeader>
                                                <PopoverBody className="scrollable-popoverbody">
                                                  {item.courseInfo.instructors && item.courseInfo.instructors[0].label}
                                                </PopoverBody>
                                              </UncontrolledPopover>
                                            </React.Fragment>
                                          )
                                        }
                                        {
                                          item.courseInfo &&
                                          item.courseInfo.instructors &&
                                          item.courseInfo.instructors.length > 0 &&
                                          item.courseInfo.instructors.length != 1 &&
                                          (<button className="multiple-info-tag small-tag group-ellipsis">
                                            {item.courseInfo.instructors && item.courseInfo.instructors[0].label}
                                          </button>)
                                        }
                                        {
                                          item.courseInfo && item.courseInfo.instructors && item.courseInfo.instructors.length > 1 &&
                                          (
                                            <React.Fragment>
                                              <button className="multiple-info-tag small-tag group-ellipsis">
                                                +{item.courseInfo.instructors.length - 1} Öğretim Elemanları
                                              </button>
                                              <UncontrolledPopover
                                                trigger="hover focus"
                                                placement="bottom"
                                                target={'instructors-popover-' + item.course_id}
                                              >
                                                <PopoverHeader>{T.t('gen_lecture_staff')}</PopoverHeader>
                                                <PopoverBody className="scrollable-popoverbody">
                                                  {item.courseInfo.instructors &&
                                                    item.courseInfo.instructors.length > 0 && item.courseInfo.instructors.length < 10 &&
                                                    item.courseInfo.instructors.map((instructors: Types.ISelectOption, index: any) => {
                                                      return (
                                                        <React.Fragment key={index}>
                                                          <button className="multiple-info-tag small-tag text-capitalize">
                                                            {instructors && instructors.label}
                                                          </button>
                                                          <br />
                                                        </React.Fragment>
                                                      );
                                                    })}
                                                </PopoverBody>
                                              </UncontrolledPopover>
                                            </React.Fragment>
                                          )
                                        }
                                      </>
                                  }
                                </td>
                                <td
                                  data-cell="connected_program"
                                  id={'connected_program-popover-' + item.course_id}
                                  style={{
                                    textAlign: 'center',
                                    width: '30%'
                                  }}
                                  className="table-course-hours-classrooms-td-8"
                                >
                                  {/*<td
                                    data-cell="class"
                                    id={'class-popover-' + item.course_id}
                                    style={{
                                      textAlign: 'center',
                                      width: '30%'
                                    }}
                                  >*/}
                                  {
                                    item.courseInfo && item.courseInfo.programs && item.courseInfo.programs.length > 10
                                      ?
                                      <>
                                        {item.courseInfo.programs && item.courseInfo.programs.map((i: any) => <>{i.label}, </>)}
                                        <br /><br />
                                        <button className="multiple-info-tag small-tag group-ellipsis">
                                          {item.courseInfo.classes && item.courseInfo.classes[0].label}
                                        </button>
                                      </>
                                      :
                                      <>
                                        {
                                          item.courseInfo && item.courseInfo.programs && item.courseInfo.programs.length == 1 &&
                                          (
                                            <React.Fragment>
                                              <button className="multiple-info-tag small-tag group-ellipsis">
                                                {item.courseInfo.programs && item.courseInfo.programs[0].label}
                                              </button>
                                              <UncontrolledPopover
                                                trigger="hover focus"
                                                placement="bottom"
                                                target={'connected_program-popover-' + item.course_id}
                                              >
                                                <PopoverHeader>{T.t('gen_connected_programs')}</PopoverHeader>
                                                <PopoverBody className="scrollable-popoverbody">
                                                  {item.courseInfo.programs && item.courseInfo.programs[0].label}
                                                </PopoverBody>
                                              </UncontrolledPopover>
                                            </React.Fragment>
                                          )
                                        }
                                        {
                                          item.courseInfo &&
                                          item.courseInfo.programs &&
                                          item.courseInfo.programs.length > 0 &&
                                          item.courseInfo.programs.length != 1 && (
                                            <button className="multiple-info-tag small-tag group-ellipsis">
                                              {item.courseInfo.programs && item.courseInfo.programs[0].label}
                                            </button>
                                          )
                                        }
                                        {
                                          item.courseInfo &&
                                          item.courseInfo.classes &&
                                          item.courseInfo.classes.length > 0 &&
                                          item.courseInfo.classes.length != 1 && (
                                            <button className="multiple-info-tag small-tag group-ellipsis">
                                              {item.courseInfo.classes && item.courseInfo.classes[0].label}
                                            </button>
                                          )
                                        }
                                        {
                                          item.courseInfo && item.courseInfo.programs && item.courseInfo.programs.length > 1 &&
                                          item.courseInfo && item.courseInfo.classes && item.courseInfo.classes.length > 1 && (
                                            <React.Fragment>
                                              <button className="multiple-info-tag small-tag group-ellipsis">
                                                +{item.courseInfo.programs.length - 1} Program
                                                  +{item.courseInfo && item.courseInfo.classes.length - 1} {T.t('gen_grade')}
                                              </button>
                                              <UncontrolledPopover
                                                trigger="hover focus"
                                                placement="bottom"
                                                target={'connected_program-popover-' + item.course_id}
                                              >
                                                <PopoverHeader>{T.t('gen_connected_programs')} - {T.t('gen_grade')}</PopoverHeader>
                                                <PopoverBody className="scrollable-popoverbody">
                                                  {item.courseInfo.programs &&
                                                    item.courseInfo.programs.length > 0 && item.courseInfo.programs.length < 10 &&
                                                    item.courseInfo.programs.map((connected_program: Types.ISelectOption, index: any) => {
                                                      return (
                                                        <React.Fragment key={index}>
                                                          <button className="multiple-info-tag small-tag text-capitalize">
                                                            {connected_program && connected_program.label}
                                                          </button>
                                                          <br />
                                                        </React.Fragment>
                                                      );
                                                    })}
                                                  {item.courseInfo && item.courseInfo.classes &&
                                                    item.courseInfo.classes.length > 0 && item.courseInfo.classes.length < 10 &&
                                                    item.courseInfo.classes.map((classes: Types.ISelectOption, index: any) => {
                                                      return (
                                                        <React.Fragment key={index}>
                                                          <button className="multiple-info-tag small-tag text-capitalize">
                                                            {classes.label}
                                                          </button>
                                                          <br />
                                                        </React.Fragment>
                                                      );
                                                    })}
                                                </PopoverBody>
                                              </UncontrolledPopover>
                                            </React.Fragment>
                                          )
                                        }
                                      </>
                                  }
                                  {/*</td>*/}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                  } else {
                    tableContent = (
                      <tr key={'course-' + item.course_id} data-title={item.courseInfo && item.courseInfo.name}>
                        {/*<td data-cell="select">
                          {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') ? (
                            <div className="tick-radio position-relative">
                              <input
                                type="checkbox"
                                className="form-radio"
                                checked={
                                  this.state.selected_ids &&
                                  this.state.selected_ids.indexOf(item.course_id ? item.course_id : -1) > -1
                                }
                                data-id={item.course_id}
                                data-name={item.courseInfo && item.courseInfo.course_code + ' - ' + item.courseInfo.name}
                                data-item={JSON.stringify(item)}
                                onChange={this.onSelectCourse}
                              />
                            </div>
                          ) : null}
                              </td>*/}
                        <td data-cell="select">
                          {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e') ? (
                            <div className="tick-radio position-relative">
                              <input
                                type="checkbox"
                                className="form-radio"
                                checked={
                                  this.state.selected_ids &&
                                  this.state.selected_ids.indexOf(item.course_hour_id ? item.course_hour_id : -1) > -1
                                }
                                data-id={item.course_hour_id}
                                data-name={
                                  item.courseInfo && item.courseInfo.course_code + ' - ' + item.courseInfo.name
                                }
                                data-item={JSON.stringify(item)}
                                onChange={this.onSelectCourseCalledFromCoursesTab}
                              />
                            </div>
                          ) : null}
                        </td>
                        <td scope="row" data-label={T.t('gen_status')}>
                          <div className="tags ml-1 mr-4">
                            <button
                              className={
                                `small-tag text-uppercase` +
                                (item.courseInfo && item.courseInfo.status == 1 ? ` tag-green` : ` tag-red`)
                              }
                            >
                              {GT.GetActiveStatus(item.courseInfo && item.courseInfo.status)}
                            </button>
                          </div>
                        </td>
                        <td data-label="Onay" className="text-center">
                          {item.status == 1 ? (
                            <span
                              className="badge badge-success"
                              style={{
                                fontSize: '95%',
                                textAlign: 'center'
                              }}
                            >
                              {T.t('gen_badge_approved')}
                            </span>
                          ) : (
                            <span
                              className="badge badge-warning"
                              style={{
                                fontSize: '95%',
                                textAlign: 'center'
                              }}
                            >
                              {T.t('gen_badge_waiting')}
                            </span>
                          )}
                          {combinedCourses && combinedCourses.length > 0 ? (
                            <span
                              className="badge badge-danger"
                              style={{
                                fontSize: '95%',
                                textAlign: 'center'
                              }}
                            >
                              Birleştirilmiş Sınavlar
                            </span>
                          ) : null}
                        </td>
                        <td scope="row" data-label={T.t('gen_code')} className="text-center">
                          <div className="table-scrollable-td">{item.courseInfo && item.courseInfo.course_code ? item.courseInfo.course_code.split(';').join('; ') : '-'}</div>
                        </td>
                        <td data-label={T.t('gen_name')} className="text-center">
                          <div className="table-scrollable-td">{item.courseInfo && item.courseInfo.name}</div>
                        </td>
                        <td data-label={T.t('gen_name')} className="text-center">
                          {item.courseInfo && item.courseInfo.conjugate_course_code ? item.courseInfo.conjugate_course_code : '-'}
                        </td>
                        <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                          {item.courseInfo && item.courseInfo.student_count}
                        </td>
                        <td
                          data-cell="class"
                          id={'class-popover-' + item.course_id}
                          style={{ textAlign: 'center' }}
                        >
                          {
                            item.courseInfo && item.courseInfo.classes && item.courseInfo.classes.length > 10 ?
                              item.courseInfo.classes && item.courseInfo.classes.map((i: any) => <>{i.label}, </>)
                              :
                              <>
                                {
                                  item.courseInfo && item.courseInfo.classes && item.courseInfo.classes.length > 0 && (
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {item.courseInfo.classes[0].label}
                                    </button>
                                  )
                                }
                                {
                                  item.courseInfo && item.courseInfo.classes && item.courseInfo.classes.length > 1 && (
                                    <React.Fragment>
                                      <button className="multiple-info-tag small-tag group-ellipsis">
                                        +{item.courseInfo && item.courseInfo.classes.length - 1} {T.t('gen_grade')}
                                      </button>
                                      <UncontrolledPopover
                                        trigger="hover focus"
                                        placement="bottom"
                                        target={'class-popover-' + item.course_id}
                                      >
                                        <PopoverHeader>{T.t('gen_grade')}</PopoverHeader>
                                        <PopoverBody className="scrollable-popoverbody">
                                          {item.courseInfo.classes.map((classes: Types.ISelectOption, index: any) => {
                                            return (
                                              <React.Fragment key={index}>
                                                <button className="multiple-info-tag small-tag text-capitalize">
                                                  {classes.label}
                                                </button>
                                                <br />
                                              </React.Fragment>
                                            );
                                          })}
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </React.Fragment>
                                  )
                                }
                              </>
                          }
                        </td>
                        <td
                          data-cell="section"
                          id={'section-popover-' + item.course_id}
                          style={{ textAlign: 'center' }}
                        >
                          {
                            item.courseInfo && item.courseInfo.sections && item.courseInfo.sections.length > 10 ?
                              item.courseInfo.sections && item.courseInfo.sections.map((i: any) => <>{i}, </>) :
                              <>
                                {item.courseInfo && item.courseInfo.sections && item.courseInfo.sections.length > 0 && (
                                  <button className="multiple-info-tag small-tag group-ellipsis">
                                    {item.courseInfo.sections[0]}
                                  </button>
                                )}
                                {item.courseInfo && item.courseInfo.sections && item.courseInfo.sections.length > 1 && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      +{item.courseInfo && item.courseInfo.sections.length - 1} {T.t('gen_section')}
                                    </button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'section-popover-' + item.course_id}
                                    >
                                      <PopoverHeader>{T.t('gen_section')}</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">
                                        {item.courseInfo.sections.map((sections: Types.ISelectOption, index: any) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <button className="multiple-info-tag small-tag text-capitalize">
                                                {sections}
                                              </button>
                                              <br />
                                            </React.Fragment>
                                          );
                                        })}
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </>
                          }
                        </td>
                        <td
                          data-cell="faculty"
                          id={'faculty-popover-' + item.course_id}
                          style={{ textAlign: 'center' }}
                        >
                          {
                            item.courseInfo && item.courseInfo.faculties && item.courseInfo.faculties.length > 10 ?
                              <div className="table-scrollable-td">{item.courseInfo.faculties && item.courseInfo.faculties.map((i: any) => <>{i.label}, </>)}</div> :
                              <>
                                {item.courseInfo && item.courseInfo.faculties && item.courseInfo.faculties.length > 0 && (
                                  <button className="multiple-info-tag small-tag group-ellipsis">
                                    {item.courseInfo.faculties[0].label}
                                  </button>
                                )}
                                {item.courseInfo && item.courseInfo.faculties && item.courseInfo.faculties.length > 1 && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      +{item.courseInfo && item.courseInfo.faculties.length - 1} {T.t('gen_faculty')}
                                    </button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'faculty-popover-' + item.course_id}
                                    >
                                      <PopoverHeader>{T.t('gen_faculty')}</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">
                                        {item.courseInfo.faculties.map((faculty: Types.ISelectOption, index: any) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <button className="multiple-info-tag small-tag text-capitalize">
                                                {faculty.label}
                                              </button>
                                              <br />
                                            </React.Fragment>
                                          );
                                        })}
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </>
                          }
                        </td>
                        <td data-label={T.t('gen_campus')}>{item.courseInfo && item.courseInfo.campus_name}</td>
                        <td scope="row" data-label={T.t('gen_exam_duration')} className="text-center">
                          {item.courseInfo && item.courseInfo.exam_duration}
                        </td>
                        <td data-label={T.t('gen_session_count')} className="text-center">
                          {item.courseInfo && item.courseInfo.exam_session}
                        </td>
                        <td data-label={T.t('gen_classroom_combine_status')} className="text-center">
                          {ClassroomCombineStatusObject && ClassroomCombineStatusObject.label}
                        </td>
                        <td
                          data-cell="connected_program"
                          id={'connected_program-popover-' + item.course_id}
                          style={{
                            textAlign: 'center'
                          }}
                        >
                          {
                            item.courseInfo && item.courseInfo.programs && item.courseInfo.programs.length > 10 ?
                              <div className="table-scrollable-td">{item.courseInfo.programs && item.courseInfo.programs.map((i: any) => <>{i.label}, </>)}</div> :
                              <>
                                {item.courseInfo && item.courseInfo.programs && item.courseInfo.programs.length > 0 && (
                                  <button className="multiple-info-tag small-tag group-ellipsis">
                                    {item.courseInfo.programs && item.courseInfo.programs[0].label}
                                  </button>
                                )}
                                {item.courseInfo && item.courseInfo.programs && item.courseInfo.programs.length > 1 && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      +{item.courseInfo.programs.length - 1} Program
                              </button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'connected_program-popover-' + item.course_id}
                                    >
                                      <PopoverHeader>{T.t('gen_connected_programs') + ' - ' + T.t('gen_grade')}</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">
                                        {item.courseInfo.programs.map((connected_program: Types.ISelectOption, index: any) => {
                                          return (
                                            <React.Fragment key={index}>
                                              <button className="multiple-info-tag small-tag text-capitalize">
                                                {connected_program && connected_program.label}
                                              </button>
                                              <br />
                                            </React.Fragment>
                                          );
                                        })}
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </>
                          }
                        </td>
                        <td
                          data-cell="instructors"
                          id={'instructors-popover-' + item.course_id}
                          style={{
                            textAlign: 'center'
                          }}
                        >{
                            item.courseInfo && item.courseInfo.instructors && item.courseInfo.instructors.length > 10 ?
                              <div className="table-scrollable-td">{item.courseInfo.instructors && item.courseInfo.instructors.map((i: any) => <>{i.label}, </>)}</div> :
                              <>
                                {
                                  item.courseInfo &&
                                    item.courseInfo.instructors &&
                                    item.courseInfo.instructors.length > 0 ? (
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {item.courseInfo.instructors && item.courseInfo.instructors[0].label}
                                    </button>
                                  ) : (
                                    '-'
                                  )
                                }
                                {
                                  item.courseInfo &&
                                  item.courseInfo.instructors &&
                                  item.courseInfo.instructors.length > 1 && (
                                    <React.Fragment>
                                      <button className="multiple-info-tag small-tag group-ellipsis">
                                        +{item.courseInfo.instructors.length - 1} Kişi</button>
                                      <UncontrolledPopover
                                        trigger="hover focus"
                                        placement="bottom"
                                        target={'instructors-popover-' + item.course_id}
                                      >
                                        <PopoverHeader>{T.t('gen_lecture_staff')}</PopoverHeader>
                                        <PopoverBody className="scrollable-popoverbody">
                                          {item.courseInfo.instructors.map((instructors: Types.ISelectOption, index: any) => {
                                            return (
                                              <React.Fragment key={index}>
                                                <button className="multiple-info-tag small-tag text-capitalize">
                                                  {instructors && instructors.label}
                                                </button>
                                                <br />
                                              </React.Fragment>
                                            );
                                          })}
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </React.Fragment>
                                  )}
                              </>
                          }
                        </td>
                        <td
                          data-cell="assistant"
                          id={'assistant-popover-' + item.course_id}
                          style={{
                            textAlign: 'center'
                          }}
                        >
                          {item.courseInfo &&
                            item.courseInfo.assistant_staff &&
                            item.courseInfo.assistant_staff.length > 0 && (
                              <button className="multiple-info-tag small-tag group-ellipsis">
                                {item.courseInfo.assistant_staff && item.courseInfo.assistant_staff[0].label}
                              </button>
                            )}
                          {item.courseInfo &&
                            item.courseInfo.assistant_staff &&
                            item.courseInfo.assistant_staff.length > 1 && (
                              <React.Fragment>
                                <button className="multiple-info-tag small-tag group-ellipsis">
                                  +{item.courseInfo.assistant_staff.length - 1} Kişi
                                </button>
                                <UncontrolledPopover
                                  trigger="hover focus"
                                  placement="bottom"
                                  target={'assistant-popover-' + item.course_id}
                                >
                                  <PopoverHeader>{T.t('gen_assistants')}</PopoverHeader>
                                  <PopoverBody className="scrollable-popoverbody">
                                    {item.courseInfo.assistant_staff.length > 0 && item.courseInfo.assistant_staff.length < 10 &&
                                      item.courseInfo.assistant_staff.map((asistan: Types.ISelectOption, index: any) => {
                                        return (
                                          <React.Fragment key={index}>
                                            <button className="multiple-info-tag small-tag text-capitalize">
                                              {asistan && asistan.label}
                                            </button>
                                            <br />
                                          </React.Fragment>
                                        );
                                      })}
                                  </PopoverBody>
                                </UncontrolledPopover>
                              </React.Fragment>
                            )}
                        </td>
                        <td data-label={T.t('gen_actions')} className="table-buttons">
                          <div className="table-buttons-wrapper">
                            {combinedCourses && combinedCourses.length ? (
                              <button
                                data-toggle="tooltip"
                                title={T.t('gen_badge_combined_exams')}
                                className="btn btn-light btn-sm table-button"
                                onClick={() => {
                                  this.saveCombinedCourses(combinedCourses, item);
                                }}
                              >
                                <span className="d-block">
                                  <i className="material-icons">library_books</i>
                                </span>
                              </button>
                            ) : (
                              <button
                                data-toggle="tooltip"
                                title={T.t('gen_activity_edit')}
                                className="btn btn-light btn-sm table-button"
                                onClick={() => {
                                  this.setState({
                                    ...this.state,
                                    courseFormIsOpen: !this.state.courseFormIsOpen,
                                    editCourse: item
                                  });
                                }}
                              >
                                <span className="d-block">
                                  <i className="material-icons">edit</i>
                                </span>
                              </button>
                            )}
                            <button
                              data-toggle="tooltip"
                              data-id={item.course_id}
                              onClick={() =>
                                this.props.dispatch(
                                  Actions.ShowModal({
                                    title: T.t('gen_hours'),
                                    body: T.t('gen_exam_dates_default_assigned_hours_info'),
                                    name: 'examPeriod_delete',
                                    icon: 'error_outline',
                                    iconColor: 'red',
                                    cancel: 'Tamam'
                                  })
                                )
                              }
                              title={T.t('gen_display_hours')}
                              className="btn btn-light btn-sm table-button"
                            >
                              <span className="d-block" data-toggle="modal" data-target="#addNew">
                                <i className="material-icons">access_time</i>
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }

                  return tableContent;
                })
              ) : (
                <tr>
                  <td colSpan={24}>
                    <Announce title={T.t('gen_no_records_found')} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row-options justify-content-end">
            <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
              {this.state.course_list && this.state.course_list.length > 0 ? (
                <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
              ) : null}
            </div>
          </div>
        </div>
        <ClassroomAddModal
          modalIsOpen={this.state.classroomAddModalIsOpen}
          onClose={this.switchClassroomModalStatus}
          onUpdateList={() => this.getCourseHours()}
          calledFromCourseTab={true}
          term_id={this.state.filters.term_id}
          selectedCourseIDs={this.state.selected_ids}
        />
        {this.state.combineExamsModalIsOpen && (
          <CombineExamsModal
            courseList={this.state.selected_courses}
            combinedDataList={this.state.combined_courses}
            modalIsOpen={this.state.combineExamsModalIsOpen}
            courseObject={this.state.courseObject}
            onClose={this.switchCombineExamsModalStatus}
            showCoursesOnly={this.state.hasCombinedCourses}
            onUpdateList={() => this.getCourseHours()}
            coursesAreCombined={this.state.hasCombinedCourses ? true : false}
          />
        )}
        {this.state.courseCopyModalIsOpen && (
          <CopyCourseModal
            course={this.state.selected_courses}
            modalIsOpen={this.state.courseCopyModalIsOpen}
            onClose={this.switchCopyExamModalStatus}
            showCoursesOnly={this.state.hasCombinedCourses}
            onUpdateList={() => this.getCourseHours()}
          />
        )}
        {this.state.courseFormIsOpen && (
          <CourseEditForm
            programs_related_faculty={[]}
            course={this.state.editCourse}
            formIsOpen={this.state.courseFormIsOpen}
            onClose={() => {
              this.setState({
                courseFormIsOpen: !this.state.courseFormIsOpen
              });
              this.getCourseHours();
            }}
          />
        )}
      </div>
    );

    return <div className="container-fluid p-0">{Table}</div>;
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICoursePageProps): Types.ICoursePageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICoursePageProps = Object.assign({}, ownProps, {
    user: store.state.user,
    courseList:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.course_hours &&
      store.state.examPeriodModal.course_hours.course_list,
    results: store.state.course_page && store.state.course_page.results,
    filters: store.state.examPeriodModal && store.state.examPeriodModal.filters,
    all_ids:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.course_hours &&
      store.state.examPeriodModal.course_hours.all_ids,
    selectOptions: store.state.select_options && store.state.select_options.coursePage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseAddModal);

export default container;
