import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Modal } from 'reactstrap';
import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { ExamPeriodFormInitialValues, TermTypes } from '../../store/constants/exam-period-const';
import * as Types from '../../store/types';
import { FormValidation } from './validations/exam-period-form-val';

import Translator from '../../services/translate-factory';
const T = Translator.create();

const Logger = Log.create('ExamPeriodForm');

function getInitialState(): Types.IExamPeriodFormState {
  const initialValues: Types.IExamPeriodFormState = {
    model: Object.assign({}, ExamPeriodFormInitialValues)
  };
  return Object.assign({}, initialValues);
}

class ExamPeriodForm extends Component<Types.IExamPeriodFormProps, Types.IExamPeriodFormState> {
  state: Types.IExamPeriodFormState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }
  
  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.ITermItem, FormActions: FormikActions<Types.ITermItem>) => {

    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }
        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_LIST, null, 'term-selector-spinner'));
        this.setClose(true);
      }
    };

    if (this.props.examPeriodId) {
      this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_UPDATE, model, 'exam-period-form-spin', resultCallback));
    } else {
      this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_CREATE, model, 'exam-period-form-spin', resultCallback));
    }
    FormActions.setSubmitting(false);
  };

  static getDerivedStateFromProps(props: Types.IExamPeriodFormProps, state: Types.IExamPeriodFormState) {
    let hasNewState: boolean = false;

    if (props.formIsOpen && props.examPeriodId && props.examPeriodId != state.model.term_id) {
      state.model.term_id = props.examPeriodId;
      props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_BY_ID, props.examPeriodId, 'exam-period-form-spin'));
      hasNewState = true;
    }

    if (props.form && props.examPeriodId && props.examPeriodId == state.model.term_id) {
      state.model = props.form;
      hasNewState = true;
    }

    if (hasNewState) {
      return state;
    } else if (!props.examPeriodId && state.model.term_id) {
      return getInitialState();
    } else return null;
  }

  render() {
    return (
      <Modal
        modalClassName="modal-from-right"
        style={{ maxWidth: '100%', left: '0', display: 'inline' }}
        className="pt-0"
        isOpen={this.props.formIsOpen}
      >
        <Spinner name="exam-period-form-spin" />
        <Formik
          initialValues={this.state.model}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={FormValidation}
        >
          {(props: FormikProps<Types.ITermItem>) => {
            const { values, errors, handleChange, handleBlur, handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          <i className="material-icons mr-2">add_circle_outline</i>
                          <span>{this.props.examPeriodId ? T.t('gen_editing') : T.t('gen_add_exam_term')}</span>
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.setCloseForm}>
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="year"
                              name="year"
                              value={values.year}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="year">{T.t('gen_year')}</label>
                            <ErrorMessage component="div" className="error" name="year" />
                          </div>
                          <div className="col-md-6">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_term')}</label>
                                <Select
                                  className="react-select"
                                  isMulti={false}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                  }
                                  closeMenuOnSelect={true}
                                  options={TermTypes}
                                  placeholder={T.t('gen_select_term')}
                                  value={
                                    values.academic_term != undefined
                                      ? TermTypes.find(option => option.value === values.academic_term)
                                      : null
                                  }
                                  onChange={(option: any) => props.setFieldValue('academic_term', option.value)}
                                  noOptionsMessage={(): string => T.t('gen_select_no_term')}
                                />
                              </div>
                              {errors && errors.academic_term && props.submitCount > 0 && <div className="error">Boş bırakılamaz!</div>}
                            </div>
                          </div>
                          <div className="col-md-12 form-input form-group with-icon">
                            <input
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="name">Adı</label>
                            <ErrorMessage component="div" className="error" name="name" />
                          </div>
                          <div className="col-md-12 form-input form-group with-icon">
                            <textarea
                              name="description"
                              className="form-input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              rows={2}
                              id="description"
                              value={values.description}
                            placeholder={T.t('gen_description') + ' ' + T.t('gen_max_char_200')}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="description" />
                            <ErrorMessage component="div" className="error" name="description" />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="text-left">
                              <h6>{T.t('gen_status')}</h6>
                              <div className="tick-radio position-relative d-inline-block">
                                <Checkbox name="status" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-gray min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              type="button"
                              data-dismiss="modal"
                              aria-label="alert-success"
                              onClick={() => props.handleSubmit()}
                              className="btn btn-green"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IExamPeriodFormProps): Types.IExamPeriodFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IExamPeriodFormProps = Object.assign({}, ownProps, {
    form: store.state.exam_period_page && store.state.exam_period_page.form ? store.state.exam_period_page.form : getInitialState()
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.exam_period_page) {
    return !!equal(
      prev.state.exam_period_page && prev.state.exam_period_page.form,
      next.state.exam_period_page && next.state.exam_period_page.form
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(ExamPeriodForm);

export default container;
