import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import Paginate from '../../components/table/paginate';
import Announce from '../../components/templates/announce';
import SortedColumn from '../../components/table/sorted-column';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  ClassroomSearchInitialValues,
  ClassroomHoursSearchInitialValues,
  ClassroomTypes
} from '../../store/constants/classroom-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import { ClassroomFeatureOptions } from '../../store/constants/classroom-feature-const';

import Translator from '../../services/translate-factory';
const T = Translator.create();
const L = Log.create('ClassroomAddModal');

function getInitialState(): Types.IClassroomPageState {
  const initialValues: Types.IClassroomPageState = {
    filters: Object.assign({}, ClassroomHoursSearchInitialValues),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    classroomFormIsOpen: false,
    classroomId: undefined
  };
  return Object.assign({}, initialValues);
}

class ClassroomAddModal extends Component<Types.IClassroomPageProps, Types.IClassroomPageState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentDidUpdate(prevProps: Types.IClassroomPageProps) {
    if (this.props.term_id != undefined) {
      this.state.filters.term_id = this.props.term_id;
    }
    if (this.props.modalIsOpen && !prevProps.modalIsOpen) {
      this.searchClassrooms();
      this.getClassroomSelectOptions();
    }
  }

  searchClassrooms() {
    if (this.props.calledFromCourseTab) {
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS,
          this.state.filters,
          'ep-classroom-list'
        )
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(Constants.classroom.CLASSROOM_LIST_SEARCH, this.state.filters, 'ep-classroom-list')
      );
    }
  }

  getClassroomSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_SELECT_OPTIONS, 'classroom-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onSelectClassroom = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
          this.state.filters.select_all = false;
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.searchClassrooms();
      } else {
        this.state.all_ids = [];
        this.state.selected_ids = [];
        this.state.filters.select_all = false;
        this.setState(this.state);
      }
    }
  };

  addClassrooms = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      // Dersler sekmesinden bağlı derslik ekleme modal'ında çalışacak
      if (this.props.calledFromCourseTab && this.props.selectedCourseIDs && this.props.selectedCourseIDs.length) {
        this.props.dispatch(
          Actions.ShowModal({
            title: 'Derslere Aktarılacaklar',
            body: T.t('gen_assign_classroom_to_courses_question'),
            name: 'examPeriod_attach_classrooms',
            icon: 'warning',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
                if (status == 200) {
                  this.setClose();
                  this.onUpdateListFromModal();
                }
              };

              /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
              let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
              var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
              let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
              this.state.filters.term_id = id;
              this.state.filters.classroom_ids = this.state.selected_ids;
              this.state.filters.course_ids = this.props.selectedCourseIDs;

              this.props.dispatch(
                Actions.ApiRequest(
                  Constants.exam_period.EXAM_PERIOD_ATTACH_CLASSROOMS_TO_COURSES,
                  this.state.filters,
                  'ep-instructor-list',
                  resultCallback
                )
              );
            }
          })
        );
      }
      // Derslik sekmesinden yeni derslik eklemede else çalışacak
      else {
        this.props.dispatch(
          Actions.ShowModal({
            title: 'Programa Eklenecek',
            body: `Seçtiğiniz sınıflar sınav takvimine eklenecektir. Devam etmek istiyor musunuz?`,
            name: 'examPeriod_instructors_delete',
            icon: 'warning',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
                if (status == 200) {
                  this.setClose();
                  this.onUpdateListFromModal();
                  this.setState({
                    ...this.state,
                    selected_ids: []
                  });
                }
              };
              /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
              let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
              var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
              let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
              this.state.filters.term_id = id;
              this.state.filters.add_list = this.state.selected_ids;

              this.props.dispatch(
                Actions.ApiRequest(
                  Constants.exam_period.EXAM_PERIOD_ADD_CLASSROOMS_TO_PERIOD,
                  this.state.filters,
                  'ep-instructor-list',
                  resultCallback
                )
              );
            }
          })
        );
      }
    }
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchClassrooms();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchClassrooms();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ClassroomSearchInitialValues);
    this.state.all_ids = [];
    this.setState(this.state);
    this.searchClassrooms();
  };

  onFilterClassroom(model: Types.IFilterCourse, FormActions: FormikActions<Types.IFilterClassroom>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchClassrooms();
    FormActions.setSubmitting(false);
  }

  static getDerivedStateFromProps(props: Types.IClassroomPageProps, state: Types.IClassroomPageState) {
    let hasNewState: boolean = false;
    if (state.filters.select_all) {
      hasNewState = true;
      state.all_ids = props.all_ids;
      state.selected_ids = props.all_ids;
    }

    if (props.calledFromCourseTab && props.calledFromCourseTab === true) {
      state.classroom_list = props.term_classrooms;
    } else {
      state.results = props.results;
    }
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let classroomList = this.props.calledFromCourseTab === true ? this.state.classroom_list : this.state.results;

    let ClassroomTable =
      this.state.results && this.state.results.length ? (
        this.state.results.map((item: Types.IClassroomItem) => {
          let typeObject = ClassroomTypes.find((object) => object.value == item.classroom_type);
          let classroomFeatures: any = [];
          item.feature_codes &&
            item.feature_codes.length &&
            item.feature_codes.map((featureCode: string) => {
              let feature = ClassroomFeatureOptions.find((item: Types.ISelectOption) =>
                item.value.localeCompare(featureCode, undefined, { sensitivity: 'accent' })
              );
              classroomFeatures.push(feature);
            });
          return (
            <tr key={'classroom-' + item.classroom_id} data-title={item.name}>
              <td data-cell="select">
                <div className="tick-radio position-relative">
                  <input
                    type="checkbox"
                    className="form-radio"
                    checked={
                      this.state.selected_ids &&
                      this.state.selected_ids.indexOf(item.classroom_id ? item.classroom_id : -1) > -1
                    }
                    data-id={item.classroom_id}
                    onChange={this.onSelectClassroom}
                  />
                </div>
              </td>
              <td scope="row" data-label={T.t('gen_status')}>
                <div className="tags ml-1 mr-4">
                  <button className={`small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)}>
                    {GT.GetActiveStatus(item.status)}
                  </button>
                </div>
              </td>
              <td scope="row" data-label={T.t('gen_code')}>
                {item.classroom_code}
              </td>
              <td data-label={T.t('gen_name')} className="text-center">
                {item.name}
              </td>
              <td data-label={T.t('gen_campus_and_building')} className="text-center">
                {item.building_name}
              </td>
              <td data-label={T.t('gen_floor')} className="text-center">
                {item.building_floor}
              </td>
              <td data-label={T.t('gen_door_number')} className="text-center">
                {item.door_order}
              </td>
              <td data-label={T.t('gen_exam_capacity')} className="text-center">
                {item.exam_capacity}
              </td>
              <td data-label={T.t('gen_lecture_capacity')} className="text-center">
                {item.lecture_capacity}
              </td>
              <td data-label="Gözetmen Sayısı" className="text-center">
                {item.invigilator_count}
              </td>
              <td data-label={T.t('gen_type')} className="text-center">
                {typeObject && typeObject.label}
              </td>
              <td
                data-cell="classroom_features"
                id={'classroom_features-popover-' + item.classroom_id}
                style={{ textAlign: 'center' }}
              >
                {classroomFeatures && classroomFeatures.length > 0 && (
                  <button className="multiple-info-tag small-tag group-ellipsis">
                    {classroomFeatures && classroomFeatures.label}
                  </button>
                )}
                {classroomFeatures && classroomFeatures.length > 1 && (
                  <React.Fragment>
                    <button className="multiple-info-tag small-tag group-ellipsis">
                      +{classroomFeatures.length - 1} {T.t('gen_feature')}
                    </button>
                    <UncontrolledPopover
                      trigger="hover focus"
                      placement="bottom"
                      target={'classroom_features-popover-' + item.classroom_id}
                    >
                      <PopoverHeader>{T.t('gen_features')}</PopoverHeader>
                      <PopoverBody className="scrollable-popoverbody">
                        {classroomFeatures &&
                          classroomFeatures.length > 0 &&
                          classroomFeatures.map((classroom_features: Types.ISelectOption) => {
                            return (
                              <React.Fragment key={'classroom_features-' + classroom_features.value}>
                                <button className="multiple-info-tag small-tag text-capitalize">
                                  {classroom_features.label}
                                </button>
                                <br />
                              </React.Fragment>
                            );
                          })}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </React.Fragment>
                )}
              </td>
              <td data-cell="classroom" id={'description-popover-' + item.classroom_id} className="text-center">
                {item.description && (
                  <React.Fragment>
                    <button className="multiple-info-tag small-tag group-ellipsis">{item.description}</button>
                    <UncontrolledPopover
                      trigger="hover focus"
                      placement="bottom"
                      target={'description-popover-' + item.classroom_id}
                    >
                      <PopoverHeader>{T.t('gen_description')}</PopoverHeader>
                      <PopoverBody className="scrollable-popoverbody">{item.description}</PopoverBody>
                    </UncontrolledPopover>
                  </React.Fragment>
                )}
              </td>
            </tr>
          );
        })
      ) : (
          <tr>
            <td colSpan={24}>
              <Announce title={T.t('gen_no_records_found')} />
            </td>
          </tr>
        );

    let TermClassroomTable =
      this.state.classroom_list && this.state.classroom_list.length ? (
        this.state.classroom_list.map((item: Types.IExamPeriod_ClassroomItem) => {
          let typeObject: any = {};
          let classroomFeatures: any = [];
          if (item.classroomInfo && item.classroomInfo.classroom_type) {
            let classroomType = item.classroomInfo.classroom_type;
            typeObject = ClassroomTypes.find((object) => object.value == classroomType);
          }
          if (item.classroomInfo && item.classroomInfo.feature_codes) {
            item.classroomInfo.feature_codes.map((featureCode: string) => {
              let feature = ClassroomFeatureOptions.find((item: Types.ISelectOption) => item.value == featureCode);
              classroomFeatures.push(feature);
            });
          }

          return (
            <tr key={'classroom-' + item.classroom_id} data-title={item.classroomInfo && item.classroomInfo.name}>
              <td data-cell="select">
                <div className="tick-radio position-relative">
                  <input
                    type="checkbox"
                    className="form-radio"
                    checked={
                      this.state.selected_ids &&
                      this.state.selected_ids.indexOf(item.classroom_id ? item.classroom_id : -1) > -1
                    }
                    data-id={item.classroom_id}
                    onChange={this.onSelectClassroom}
                  />
                </div>
              </td>
              <td scope="row" data-label={T.t('gen_status')} className="text-center">
                <div className="tags ml-1 mr-4">
                  <button
                    className={
                      `small-tag text-uppercase` +
                      (item.classroomInfo && item.classroomInfo.status == 1 ? ` tag-green` : ` tag-red`)
                    }
                  >
                    {GT.GetActiveStatus(item.classroomInfo && item.classroomInfo.status)}
                  </button>
                </div>
              </td>
              <td data-label={T.t('gen_hours')} className="text-center">
                {item.status == 1 ? (
                  <span className="badge badge-success" style={{ fontSize: '95%', textAlign: 'center' }}>
                    {T.t('gen_badge_approved')}
                  </span>
                ) : (
                    <span className="badge badge-warning" style={{ fontSize: '95%', textAlign: 'center' }}>
                      {T.t('gen_badge_waiting')}
                    </span>
                  )}
              </td>
              <td data-label={T.t('gen_code')} className="text-center">
                {item.classroomInfo && item.classroomInfo.classroom_code}
              </td>
              <td data-label={T.t('gen_name')} className="text-center">
                {item.classroomInfo && item.classroomInfo.name}
              </td>
              <td data-label={T.t('gen_campus_and_building')} className="text-center">
                {item.classroomInfo && item.classroomInfo.building_name}
              </td>
              <td data-label={T.t('gen_floor')} className="text-center">
                {item.classroomInfo && item.classroomInfo.building_floor}
              </td>
              <td data-label={T.t('gen_door_number')} className="text-center">
                {item.classroomInfo && item.classroomInfo.door_order}
              </td>
              <td data-label={T.t('gen_exam_capacity')} className="text-center">
                {item.classroomInfo && item.classroomInfo.exam_capacity}
              </td>
              <td data-label={T.t('gen_lecture_capacity')} className="text-center">
                {item.classroomInfo && item.classroomInfo.lecture_capacity}
              </td>
              <td data-label="Gözetmen Sayısı" className="text-center">
                {item.classroomInfo && item.classroomInfo.invigilator_count}
              </td>
              <td data-label={T.t('gen_type')} className="text-center">
                {typeObject && typeObject.label}
              </td>
              <td
                data-cell="classroom_features"
                id={'classroom_features-popover-' + item.classroom_id}
                style={{ textAlign: 'center' }}
              >
                {classroomFeatures && classroomFeatures.length > 0 && (
                  <button className="multiple-info-tag small-tag group-ellipsis">
                    {classroomFeatures && classroomFeatures.label}
                  </button>
                )}
                {classroomFeatures && classroomFeatures.length > 1 && (
                  <React.Fragment>
                    <button className="multiple-info-tag small-tag group-ellipsis">
                      +{classroomFeatures.length - 1} {T.t('gen_feature')}
                    </button>
                    <UncontrolledPopover
                      trigger="hover focus"
                      placement="bottom"
                      target={'classroom_features-popover-' + item.classroom_id}
                    >
                      <PopoverHeader>{T.t('gen_features')}</PopoverHeader>
                      <PopoverBody className="scrollable-popoverbody">
                        {classroomFeatures &&
                          classroomFeatures.length > 0 &&
                          classroomFeatures.map((classroom_features: Types.ISelectOption) => {
                            return (
                              <React.Fragment key={'classroom_features-' + (classroom_features && classroom_features.value)}>
                                <button className="multiple-info-tag small-tag text-capitalize">
                                  {classroom_features && classroom_features.label}
                                </button>
                                <br />
                              </React.Fragment>
                            );
                          })}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </React.Fragment>
                )}
              </td>
              <td data-cell="classroom" id={'description-popover-' + item.classroom_id} className="text-center">
                {item.classroomInfo && item.classroomInfo.description && (
                  <React.Fragment>
                    <button className="multiple-info-tag small-tag group-ellipsis">
                      {item.classroomInfo && item.classroomInfo.description}
                    </button>
                    <UncontrolledPopover
                      trigger="hover focus"
                      placement="bottom"
                      target={'description-popover-' + item.classroom_id}
                    >
                      <PopoverHeader>{T.t('gen_description')}</PopoverHeader>
                      <PopoverBody className="scrollable-popoverbody">
                        {item.classroomInfo && item.classroomInfo.description}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </React.Fragment>
                )}
              </td>
            </tr>
          );
        })
      ) : (
          <tr>
            <td colSpan={24}>
              <Announce title={T.t('gen_no_records_found')} />
            </td>
          </tr>
        );
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {this.props.calledFromCourseTab ? T.t('gen_transfer_classrooms') : T.t('gen_add_classrooms_to_list')}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12">
                  <div className="quick-actions">
                    <button
                      className="category-tag-square tag-glass float-right ml-3 mr-3"
                      style={{ margin: '5px' }}
                      onClick={() => {
                        this.state.filterIsOpen = !this.state.filterIsOpen;
                        this.setState(this.state);
                      }}
                    >
                      <i className="material-icons mr-2">filter_list</i>
                      <span>{T.t('gen_filter')}</span>
                    </button>
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: [],
                              all_ids: [],
                              filters: {
                                ...this.state.filters,
                                select_all: false
                              }
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          className="category-tag-square tag-green float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={this.addClassrooms}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{this.props.calledFromCourseTab ? 'Seçilenleri Aktar' : T.t('gen_add_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                        <React.Fragment>
                          <button
                            className="category-tag-square tag-gray float-left pr-2 pl-2"
                            style={{ margin: '5px', cursor: 'not-allowed' }}
                            disabled={true}
                          >
                            <i className="material-icons mr-2">close</i>
                            <span>
                              {T.t('gen_cancel_selection')} <b>&nbsp;(0)</b>
                            </span>
                          </button>
                          <button
                            className="category-tag-square tag-gray float-left pr-2 pl-2"
                            style={{ margin: '5px', cursor: 'not-allowed' }}
                            disabled={true}
                          >
                            <i className="material-icons mr-2">input</i>
                            <span>{T.t('gen_add_selecteds')}</span>
                          </button>
                        </React.Fragment>
                      )}
                  </div>
                </div>
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3">
                    <Formik
                      initialValues={ClassroomSearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterClassroom(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterClassroom>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-4">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="classroom_code"
                                    name="classroom_code"
                                    value={props.values.classroom_code}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="classroom_code">{T.t('gen_code')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="name"
                                    name="name"
                                    value={props.values.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_classroom_type')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={ClassroomTypes}
                                      placeholder={T.t('gen_select_type')}
                                      value={props.values.classroom_types ? props.values.classroom_types : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classroom_types', list);
                                        props.setFieldValue(
                                          'classroom_type_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_campus_and_building')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.buildings
                                          ? this.props.selectOptions.buildings
                                          : []
                                      }
                                      placeholder={T.t('gen_select_building')}
                                      value={props.values.buildings ? props.values.buildings : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('buildings', list);
                                        props.setFieldValue(
                                          'building_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_building')}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_classroom_features')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={ClassroomFeatureOptions}
                                      placeholder={T.t('gen_select_feature')}
                                      value={props.values.classroom_features}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classroom_features', list);
                                        props.setFieldValue(
                                          'classroom_feature_codes',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_feature')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th data-cell="select">
                          <div className="tick-radio position-relative">
                            <input
                              type="checkbox"
                              className="form-radio"
                              checked={
                                this.state.selected_ids &&
                                this.props.filters &&
                                this.state.selected_ids.length == this.props.filters.total
                              }
                              onChange={this.onSelectAll}
                            />
                          </div>
                        </th>
                        <SortedColumn
                          datacell="status"
                          title={T.t('gen_status')}
                          sortkey="status"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="classroom_code"
                          title={T.t('gen_code')}
                          sortkey="classroom_code"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="name"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_name')}
                          sortkey="name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="building_name"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_campus_and_building')}
                          sortkey="building_name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="building_floor"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_floor')}
                          sortkey="building_floor"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="door_order"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_door_number')}
                          sortkey="door_order"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="exam_capacity"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_exam_capacity')}
                          sortkey="exam_capacity"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="lecture_capacity"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_lecture_capacity')}
                          sortkey="lecture_capacity"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="invigilator_count"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title="Gözetmen Sayısı"
                          sortkey="invigilator_count"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="classroom_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_type')}
                          sortkey="classroom_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_features')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_description')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.props.calledFromCourseTab === true ? TermClassroomTable : ClassroomTable}</tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    >
                      {(this.props.results && this.props.results.length > 0) ||
                        (this.props.term_classrooms && this.props.term_classrooms.length > 0) ? (
                          <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                        ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IClassroomPageProps
): Types.IClassroomPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IClassroomPageProps = Object.assign({}, ownProps, {
    results: store.state.classroom_page && store.state.classroom_page.results,
    term_classrooms:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.classroom_hours &&
      store.state.examPeriodModal.classroom_hours.classroom_list,
    filters: store.state.classroom_page && store.state.classroom_page.filters,
    all_ids: store.state.classroom_page && store.state.classroom_page.all_ids,
    selectOptions: store.state.select_options && store.state.select_options.classroomPage
  });

  if (ownProps.calledFromCourseTab && store.state.examPeriodModal && store.state.examPeriodModal.classroom_hours) {
    newProps.filters = store.state.examPeriodModal.classroom_hours.classroom_hours_filters;
    newProps.all_ids = store.state.examPeriodModal.classroom_hours.all_ids;
  }

  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  let areEqual = true;
  if (next.state.classroom_page) {
    areEqual =
      !!equal(
        prev.state.classroom_page && prev.state.classroom_page,
        next.state.classroom_page && next.state.classroom_page
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.classroomPage,
        next.state.select_options && next.state.select_options.classroomPage
      );
  }
  if (areEqual && next.state.examPeriodModal) {
    areEqual = !!equal(
      prev.state.examPeriodModal && prev.state.examPeriodModal.classroom_hours,
      next.state.examPeriodModal && next.state.examPeriodModal.classroom_hours
    );
  }
  return areEqual;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ClassroomAddModal);

export default container;
