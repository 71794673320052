import * as Types from '../../store/types';

export const actions = {
  LOGIN_AUTH_TOKEN: 'LOGIN:AUTH_TOKEN',
  USER_LIST_SEARCH: 'USER:LIST_SEARCH',
  USER_SELECT_OPTIONS: 'USER:SELECT_OPTIONS',
  USER_BROWSE: 'USER:BROWSE',
  USER_BROWSE_ALL: 'USER:BROWSE_ALL',
  USER_BROWSE_GET_BY_IDS: 'USER:BROWSE_GET_BY_IDS',
  USER_GET_BY_ID: 'USER:GET_BY_ID',
  USER_CREATE: 'USER:CREATE',
  USER_UPDATE: 'USER:UPDATE',
  USER_DELETE: 'USER:DELETE',
  USER_CREATE_BULK: 'USER:CREATE_BULK',
  USER_UPDATE_ROLE: 'USER:UPDATE_ROLE',
  USER_PROFILE_UPDATE: 'USER:PROFILE_UPDATE'
};

export const AvailableRoles: Array<Types.ISelectOption> = [
  { label: 'System Admin', value: 's' },
  { label: 'Program Başkanı', value: 'a' },
  { label: 'Program Başkanı', value: 'b' },
  { label: 'Öğretim Elemanı', value: 'e' },
  { label: 'Öğretim Elemanı', value: 'd' }
].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));

export const AvailableFormRoles: Array<Types.ISelectOption> = [
  { label: 'System Admin', value: 's' },
  { label: 'Program Başkanı', value: 'a' },
].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));

export const DisplayRoleLabel = (roleValue: string): string => {
  const roleObject = AvailableRoles.find(role => role.value === roleValue);

  return roleObject ? roleObject.label : '-';
}


export const UserSearchInitialValues: Types.IUserPagedQuery = {
  order_by: '',
  page: 1,
  size: 10,
  query: '',
  total: -1,
  username: '',
  name: '',
  surname: '',
  email: '',
  role: '',
  terms: [],
  status: [1]
};

export const UserFormInitialValues: Types.IUserPost = {
  user_id: undefined,
  name: ' ',
  surname: ' ',
  username: '⠀',
  email: '',
  password: '',
  password_confirmation: '',
  status: 1,
  login_allowed: true,
  active: true,
  role: 'u',
  lang: 'tr-TR',
  faculty_of_responsibility_ids: [],
  program_of_responsibility_ids: [],
  editModalStatus: false,
  count: 1
};

export const userBrowseFilterInitials: Types.IUserBrowseQuery = {
  order_by: '',
  page: 1,
  size: 10,
  query: '',
  groups: [],
  groupIds: [],
  sessions: [],
  sessionIds: [],
  total: -1,
  role: '',
  selectAll: false,
  excludeSessionIds: []
};

export const userInitials: Types.IUser = {
  user_id: -1,
  name: '',
  surname: '',
  username: '',
  email: '',
  status: 1,
  role: 'u',
};
