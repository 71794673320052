import * as Yup from 'yup';

export const BuildingFormValidation = Yup.object().shape({
  building_code: Yup.string()
    .required('Boş bırakılamaz!')
    .max(50, 'En fazla 50 karakter!'),
  name: Yup.string().required('Boş bırakılamaz!'),
  campus_id: Yup.number().required('Boş bırakılamaz!'),
  loc_x: Yup.number().typeError('Sayı giriniz!'),
  loc_y: Yup.number().typeError('Sayı giriniz!'),
  description: Yup.string().max(200, 'En fazla 200 karakter!')
});
