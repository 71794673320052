import * as Yup from 'yup';
const validation = Yup.object({
  user_id: Yup.string(),
  name: Yup.string().required('Boş bırakılamaz!'),
  surname: Yup.string().required('Boş bırakılamaz!'),
  username: Yup.string().required('Boş bırakılamaz!'),
  email: Yup.string()
    .required('Boş bırakılamaz!')
    .email('Geçerli bir E-Posta adresi olmalıdır!'),
  expires_at: Yup.date()
    .nullable(true)
    .typeError('Geçerli bir tarih olmalıdır!'),
  role: Yup.string().required('Boş bırakılamaz!'),
  // password: Yup.string()
  // .when('user_id', {
  //   is: (id: string) => !!id,
  //   then: Yup.string()
  //     .notRequired()
  //     .nullable(true),
  //   otherwise: Yup.string()
  //     .nullable(false)
  //     .required('Boş bırakılamaz!')
  // }),
  password_confirmation: Yup.string()
    // .when('user_id', {
    //   is: (id: string) => !!id,
    //   then: Yup.string()
    //     .notRequired()
    //     .nullable(true),
    //   otherwise: Yup.string()
    //     .nullable(false)
    //     .required('Boş bırakılamaz!')
    // })
    .when('password', {
      is: (password: string) => !!password,
      then: Yup.string()
        .nullable(false)
        .required('Boş bırakılamaz!')
        .oneOf([Yup.ref('password'), null], 'Şifreler birbirinden farklı!'),
      otherwise: Yup.string().notRequired()
    })
});
export default validation;
