import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Switch from 'react-switch';
import { Table } from 'reactstrap';
import XLSX from 'xlsx';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import Spinner from '../templates/spinner';
import { ExcelImportKeys } from './import-modal';
import { Log } from 'ng2-logger';

const ImportingKeySelectOptions: Types.ISelectOption[] = [
  { label: 'Öğretim Elemanı Kodu', value: 'INSTRUCTOR_CODE' },
  { label: 'Kontrol Etme', value: 'NONE' }
];

const SheetJSFT = ['xlsx', 'xls', 'csv', 'ods']
  .map(function (x) {
    return '.' + x;
  })
  .join(',');

const Logger = Log.create('InstructorImportForm');

class InstructorImportForm extends React.Component<Types.IImportFormProps, Types.IImportFormState> {
  modalName = ExcelImportKeys.Instructors;
  craeteExcelBulkAction = Constants.instructor.INSTRUCTOR_CREATE_EXCEL_BULK;
  state: Types.IImportFormState = {
    options: {
      overrideData: false,
      importingKey: 'INSTRUCTOR_CODE',
      termId: this.props.term_id ? this.props.term_id : -1
    },
    acceptedEntries: [],
    rejectedEntries: []
  };

  componentWillUnmount() {
    this.props.dispatch(
      Actions.ApiRequest(this.craeteExcelBulkAction, {
        reset: true
      })
    );
  }

  post = () => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status === 500) {
        this.props.dispatch(
          Actions.ShowModal({
            title: 'Hata!',
            body: (
              <h6>
                Silinmesi istenilen veri, diğer kayıtlar tarafından kullanıldığından silinme işlemi gerçekleştirilemez.
              </h6>
            ),
            name: this.modalName + '_error',
            icon: 'error_outline',
            iconColor: 'red'
          })
        );
        this.props.dispatch(
          Actions.ShowModal({
            name: this.modalName,
            cancel: 'Kapat',
            confirm: 'Dosya Yükle'
          })
        );
      } else if (status === 200 || status === 409) {
        this.props.dispatch(
          Actions.ShowModal({
            name: this.modalName,
            confirm: 'Sonucu Excel Olarak İndir',
            cancel: 'Kapat',
            onConfirm: this.props.apiResultTableToExcel
          })
        );
        if (this.props.onImport) {
          this.props.onImport();
        }
      } else {
        this.props.dispatch(
          Actions.ShowModal({
            name: this.modalName,
            body: <h6>Beklenmeyen bir hata oluştu, lütfen verilerinizi kontrol ederek tekrar deneyiniz...</h6>,
            cancel: 'Tamam'
          })
        );
      }
      this.props.dispatch(
        Actions.ApiRequest(this.craeteExcelBulkAction, {
          reset: true
        })
      );
    };

    if (this.state.rejectedEntries.length === 0) {
      let postModel: Types.IImportPost = {
        options: this.state.options,
        items: this.state.acceptedEntries
      };
      this.props.dispatch(
        Actions.ApiRequest(this.craeteExcelBulkAction, postModel, this.modalName + '-spinner', resultCallback)
      );
      this.props.dispatch(Actions.ShowModal({ name: this.modalName, cancel: 'Kapat' }));
    }
  };

  excelToJSON = (file: File) => {
    let reader = new FileReader();
    const headers =
      this.props.term_type === 0
        ? [
          'instructor_code',
          'faculty_code_of_duty',
          'program_code_of_duty',
          'title',
          'name',
          'title_and_name',
          'status',
          'staff_type',
          'daily_max_class',
          'email',
          'mobile'
        ]
        : [
          'instructor_code',
          'faculty_code_of_duty',
          'program_code_of_duty',
          'title',
          'name',
          'title_and_name',
          'status',
          'staff_type',
          'invigilator_level',
          'invigilation_faculty_codes',
          'invigilation_program_codes',
          'invigilation_campus_codes',
          'daily_max_class',
          'max_invigilation_duty',
          'email',
          'mobile'
        ];
    reader.onload = (e: any) => {
      let bstr = e.target.result;
      let wb = XLSX.read(bstr, { type: 'binary' });
      let wsname = wb.SheetNames[0];
      let ws = wb.Sheets[wsname];
      let data = XLSX.utils.sheet_to_json(ws, {
        header: headers,
        range: 1
      }) as Array<Types.IExcelInstructorRow>;
      this.state.acceptedEntries = data;
      this.state.rejectedEntries = [];
      // this.validateStudents(data);
      if (this.state.acceptedEntries.length > 0) {
        this.post();
      }
    };
    reader.readAsBinaryString(file);
  };

  JSONToExcel = (data: any) => {
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'import_report');
    XLSX.writeFile(wb, 'report.xlsx');
  };

  onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      this.excelToJSON(files[0]);
    }
    e.currentTarget.value = '';
  };

  translateImportingResult(result: string): string {
    let message = '';
    switch (result) {
      case 'rejected':
        message = 'Hata';
        break;
      case 'added':
        message = 'Eklendi';
        break;
      case 'updated':
        message = 'Güncellendi';
        break;
    }

    return message;
  }

  render() {
    const exampleExcelFileName =
      this.props.term_type === 1 ? 'Example_Instructor_Import.xlsx' : 'Example_CourseTerm_Instructor_Import.xlsx';

    let formOptions = null;
    let apiResultTable = null;
    let validationErrorsTable = null;
    let items = this.props.results;
    let options = this.props.options;

    if (this.state.rejectedEntries.length > 0) {
      let validationErrorRow = this.state.rejectedEntries.map((r) => {
        return (
          <tr key={'importing-error-' + r.RowIndex}>
            <td>{'#' + (r.RowIndex + 1)}</td>
            <td>
              {r.ValidationErrors.map((v) => {
                return (
                  <Fragment>
                    {(v.Field ? v.Field + ': ' : 'GENEL: ') + v.Message} <br />
                  </Fragment>
                );
              })}
            </td>
          </tr>
        );
      });

      validationErrorsTable = (
        <div className="mt-4" style={{ fontSize: '14px' }}>
          <Table responsive striped bordered size="sm">
            <thead>
              <tr>
                <th style={{ width: '15%' }}>Satır NO#</th>
                <th style={{ width: '85%' }}>Hata</th>
              </tr>
            </thead>
            <tbody>{validationErrorRow}</tbody>
          </Table>
        </div>
      );
    }

    if (items) {
      let rows = null;

      rows = items
        .sort((a, b) => (a.model.importingResult === 'rejected' || b.model.importingResult === 'updated' ? -1 : 1))
        .map((item: Types.IMultipleResponseItem<Types.IImportedInstructor>) => {
          let { model, state } = item;
          let importingKeyObject =
            options &&
            ImportingKeySelectOptions.find(
              (item: Types.ISelectOption) => item.value == (options ? options.importingKey : '')
            );
          return (
            <tr key={this.modalName + '-' + model.instructor_code} hidden={!(model.importingResult === 'rejected')}>
              <td>{this.translateImportingResult(model.importingResult)}</td>
              <td>{importingKeyObject ? importingKeyObject.label : ''}</td>
              <td>{model.termId}</td>
              <td>{model.instructor_code}</td>
              <td>{model.faculty_code_of_duty}</td>
              <td>{model.program_code_of_duty}</td>
              <td>{model.title}</td>
              <td>{model.name}</td>
              <td>{model.status}</td>
              <td>{model.staff_type}</td>
              {this.props.term_type === 1 ? (
                <React.Fragment>
                  <td>{model.invigilator_level}</td>
                  <td>{model.invigilation_faculty_codes}</td>
                  <td>{model.invigilation_program_codes}</td>
                  <td>{model.invigilation_campus_codes}</td>
                  <td>{model.daily_max_class}</td>
                  <td>{model.max_invigilation_duty}</td>
                </React.Fragment>
              ) : (
                <td>{model.daily_max_class}</td>
              )}
              <td>{model.email}</td>
              <td>{model.mobile}</td>
              <td>
                {state
                  ? Array.isArray(state.details)
                    ? state.details.map((error) => {
                      return <label>{error.field + ': ' + error.message}</label>;
                    })
                    : state.details
                  : ''}
              </td>
            </tr>
          );
        });

      apiResultTable = (
        <div className="small mt-2">
          <h6>
            {this.state.acceptedEntries.length} kayıttan &nbsp;
            {items.filter((item) => item.model.importingResult !== 'rejected').length} tanesi başarıyla
            eklendi/güncellendi.
          </h6>
          <Table
            id="api-result-table"
            className="mt-3"
            responsive
            striped
            bordered
            size="sm"
            hidden={items.findIndex((item) => item.model.importingResult === 'rejected') < 0}
          >
            <thead>
              <tr>
                <th>Sonuç</th>
                <th>Anahtar Veri</th>
                <th>TermId</th>
                <th>Kod</th>
                <th>Görev Yeri Fakülte/Yüksek Okul Kodu</th>
                <th>Görev Yeri Bölüm/Program Kodu</th>
                <th>Unvan</th>
                <th>Ad Soyad</th>
                <th>Aktiflik</th>
                <th>Kadro</th>
                {this.props.term_type === 1 ? (
                  <React.Fragment>
                    <th>Gözetmenlik Derecesi</th>
                    <th>Gözetmenlik Yeri Fakülte Kodu</th>
                    <th>Gözetmenlik Yeri Program Kodu</th>
                    <th>Gözetmenlik Yeri Kampüs Kodu</th>
                    <th>Günlük Verebileceği Maksimum Ders</th>
                    <th>Gözetmenlik Maksimum Sayı</th>
                  </React.Fragment>
                ) : (
                  <th>Günlük Verebileceği Maksimum Ders</th>
                )}
                <th>Eposta Adresi</th>
                <th>Cep Telefonu</th>
                <th>Hata Mesajı</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>
      );
    } else {
      formOptions = (
        <div className="row">
          <Spinner name={this.modalName + '-spinner'} />
          <div className="col-12 col-md-12 col-sm-12">
            <p>
              Örnek bir Excel dosyasını,
              <a href={process.env.PUBLIC_URL + '/files/' + exampleExcelFileName}>
                {' '}
                buraya (xlsx uzantılı - MS Excel 2003 sonrası){' '}
              </a>
              tıklayarak indirebilirsiniz
            </p>
          </div>
          <div className="col-12 col-md-12 col-sm-12">
            <div className="react-select-container">
              <label>Baz Alınacak Bilgiler</label>
              <Select
                className="react-select"
                isSearchable={false}
                options={ImportingKeySelectOptions}
                value={
                  this.state.options.importingKey
                    ? ImportingKeySelectOptions.find((k) => k.value === this.state.options.importingKey)
                    : null
                }
                onChange={(item: any) => {
                  this.state.options.importingKey = item.value;
                  this.setState(this.state);
                }}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-sm-12">
            <div className="react-switch-container">
              <label>Var olan tablonun üzerine yaz</label>
              <Switch
                id="send_email"
                className="react-switch"
                onChange={(checked: boolean) => {
                  this.state.options.overrideData = checked;
                  this.setState(this.state);
                }}
                checked={this.state.options.overrideData}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {formOptions}
        {validationErrorsTable}
        {apiResultTable}
        <input
          id="excel_file_input"
          type="file"
          className="d-none form-control"
          accept={SheetJSFT}
          onChange={this.onFileSelected}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IImportFormProps): Types.IImportFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IImportFormProps = Object.assign({}, ownProps, {
    results: store.state.instructor_import_result && store.state.instructor_import_result.items,
    options: store.state.instructor_import_result && store.state.instructor_import_result.options,
    term_id: store.state.term_id,
    term_type: store.state.term_type
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return next.state.instructor_import_result === prev.state.instructor_import_result;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(InstructorImportForm);

export default container;
