import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import * as Actions from '../../store/actions/general';
const T = Translator.create();

function getInitialState(): any {
  const initialValues: any = {
    faculty_ids: [],
    faculties: [],
    program_ids: [],
    programs: [],
    class_ids: [],
    classes: [],
    section_ids: [],
    sections: [],
    lecture_staff_ids: [],
    lecture_staff: [],
    indexes: [],
  };
  return Object.assign({}, initialValues);
}

class MultiSelectionTable extends Component<any, any> {
  state: {
    faculty_ids?: Array<any>;
    faculties?: Array<any>;
    program_ids?: Array<any>;
    programs?: Array<any>;
    class_ids?: Array<any>;
    classes?: Array<any>;
    section_ids?: Array<any>;
    sections?: Array<any>;
    lecture_staff_ids?: Array<any>;
    lecture_staff?: Array<any>;
    indexes?: Array<any>;
  } = getInitialState();

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.faculty_ids_labels !== prevProps.faculty_ids_labels ||
      this.props.program_ids_labels !== prevProps.program_ids_labels ||
      this.props.class_ids_labels !== prevProps.class_ids_labels ||
      this.props.lecture_staff_ids_labels !== prevProps.lecture_staff_ids_labels) {
      this.setState({
        ...this.state,
        faculty_ids: this.props.faculty_ids_labels,
        faculties: this.props.faculties_labels,
        program_ids: this.props.program_ids_labels,
        programs: this.props.programs_labels,
        class_ids: this.props.class_ids_labels,
        classes: this.props.classes_labels,
        section_ids: this.props.section_ids_labels,
        sections: this.props.sections_labels,
        lecture_staff_ids: this.props.lecture_staff_ids_labels,
        lecture_staff: this.props.lecture_staff_labels,
        indexes: [...Array(this.props.faculties_labels.length)].map(() => Math.floor(Math.random() * 100)),
      });
      this.state.faculty_ids = this.props.faculty_ids_labels
    }
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      faculty_ids: this.state.faculty_ids && this.state.faculty_ids.concat(this.props.faculty_ids_labels),
      faculties: this.state.faculties && this.state.faculties.concat(this.props.faculties_labels),
      program_ids: this.state.program_ids && this.state.program_ids.concat(this.props.program_ids_labels),
      programs: this.state.programs && this.state.programs.concat(this.props.programs_labels),
      class_ids: this.state.class_ids && this.state.class_ids.concat(this.props.class_ids_labels),
      classes: this.state.classes && this.state.classes.concat(this.props.classes_labels),
      section_ids: this.state.section_ids && this.state.section_ids.concat(this.props.section_ids_labels),
      sections: this.state.sections && this.state.sections.concat(this.props.sections_labels),
      lecture_staff_ids: this.state.lecture_staff_ids && this.state.lecture_staff_ids.concat(this.props.lecture_staff_ids_labels),
      lecture_staff: this.state.lecture_staff && this.state.lecture_staff.concat(this.props.lecture_staff_labels),
      indexes: [...Array(this.props.faculties_labels.length)].map(() => Math.floor(Math.random() * 100)),
    });
  }

  onDeleteRow = (i: any) => {
    let index = this.state.indexes && this.state.indexes.indexOf(i)
    if (index !== -1) {
      this.state.faculties && this.state.faculties.splice(index!, 1);
      this.state.faculty_ids && this.state.faculty_ids.splice(index!, 1);
      this.state.programs && this.state.programs.splice(index!, 1);
      this.state.program_ids && this.state.program_ids.splice(index!, 1);
      this.state.classes && this.state.classes.splice(index!, 1);
      this.state.class_ids = this.state.classes!.map((i: any) => i.value);
      this.state.sections && this.state.sections.splice(index!, 1);
      this.state.section_ids && this.state.section_ids.splice(index!, 1);
      this.state.lecture_staff && this.state.lecture_staff.splice(index!, 1);
      this.state.lecture_staff_ids && this.state.lecture_staff_ids.splice(index!, 1);
      this.state.indexes && this.state.indexes.splice(index!, 1);
      this.setState(this.state);
    }
  };

  render() {
    this.props.multiSelectionObjectFunction(
      this.state.faculty_ids,
      this.state.program_ids,
      this.state.classes && this.state.classes.map((i: any) => i.value),
      this.state.section_ids,
      this.state.lecture_staff_ids,
      this.state.faculties,
      this.state.programs,
      this.state.classes,
      this.state.sections,
      this.state.lecture_staff,
    );

    return (
      <div className="col-md-12">
        <div className="add-custom-tag mb-3">
          <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table courseformtable">
            <thead>
              <tr className="courseformtable-thead-tr">
                <th scope="col" style={{ 'width': '25%' }}>
                  <span>{T.t('gen_faculty')}</span>
                </th>
                <th scope="col" style={{ 'width': '30%' }}>
                  <span>{T.t('gen_program')}</span>
                </th>
                <th scope="col" style={{ 'width': '9%' }}>
                  <span>{T.t('gen_grade')}</span>
                </th>
                <th scope="col" style={{ 'width': '10%' }}>
                  <span>{T.t('gen_section')}</span>
                </th>
                <th scope="col" style={{ 'width': '26%' }}>
                  <span>{T.t('gen_course_instructors')}</span>
                </th>
                <th scope="col" style={{ 'width': '10%' }}>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="courseformtable-tbody-tr">
                <td className="courseformtable-td" style={{ 'width': '25%' }}>
                  <table>
                    <tbody>
                      {this.state.faculties && this.state.faculties.map((item: any, index: any) =>
                        <tr className="courseformtable-td-tr" key={index}>
                          <td scope="row" data-label={T.t('gen_faculty')}>{item.label}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
                <td className="courseformtable-td" style={{ 'width': '30%' }}>
                  <table>
                    <tbody>
                      {this.state.programs && this.state.programs.map((item: any, index: any) =>
                        <tr className="courseformtable-td-tr" key={index}>
                          <td scope="row" data-label={T.t('gen_program')}>{item.label}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
                <td className="courseformtable-td" style={{ 'width': '9%' }}>
                  <table>
                    <tbody>
                      {this.state.classes && this.state.classes.map((item: any, index: any) =>
                        <tr className="courseformtable-td-tr" key={index}>
                          <td scope="row" data-label={T.t('gen_grade')}>{item.label}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
                <td className="courseformtable-td" style={{ 'width': '10%' }}>
                  <table>
                    <tbody>
                      {this.state.sections && this.state.sections.map((item: any, index: any) =>
                        <tr className="courseformtable-td-tr" key={index}>
                          <td scope="row" data-label={T.t('gen_section')}>{item}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
                <td className="courseformtable-td" style={{ 'width': '26%' }}>
                  <table>
                    <tbody>
                      {this.state.lecture_staff && this.state.lecture_staff.map((item: any, index: any) =>
                        <tr className="courseformtable-td-tr" key={index}>
                          <td scope="row" data-label={T.t('gen_lecture_staff')}>{item.label}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
                <td className="courseformtable-td" style={{ 'width': '10%' }}>
                  <table>
                    <tbody>
                      {this.state.indexes && this.state.indexes.map((index: any, i: any) =>
                        <tr className="courseformtable-td-tr" key={i}>
                          <td scope="row">
                            <button
                              className="ms-table-delete-button"
                              onClick={() => this.onDeleteRow(index)}
                            >
                              <i className="material-icons pr-1 pl-1">delete_outline</i>
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(MultiSelectionTable);

export default container;