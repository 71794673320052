import { Log } from 'ng2-logger';
import React from 'react';
import { Modal } from 'reactstrap';
import { IModalAction, IModalBoxState } from '../../store/types';
const L = Log.create('ModalBox');
let _instance: ModalBox;
class ModalBox extends React.Component<any, IModalBoxState> {
  _timer: any;

  state: IModalBoxState = {
    modals: []
  };

  constructor(props: any) {
    super(props);
    if (_instance) {
      this.state = _instance.state;
      this.setCleanerTimer();
    }
    _instance = this;
  }

  static getInstance = (): ModalBox => {
    return _instance;
  };

  componentWillUnmount() {
    if (this._timer) {
      clearInterval(this._timer);
    }
  }

  setMessage = (action: IModalAction) => {
    action.timestamp = Date.now();
    var modelIndex = this.state.modals.findIndex((m) => m.name === action.name);
    if (modelIndex !== -1) {
      let model = this.state.modals[modelIndex];
      this.state.modals[modelIndex] = Object.assign({}, this.state.modals[modelIndex], {
        cancel: action.cancel,
        confirm: action.confirm,
        onConfirm: action.onConfirm ? action.onConfirm : model.onConfirm,
        onCancel: action.onCancel ? action.onCancel : model.onCancel,
        confirmDisabled: action.confirmDisabled,
        noCloseButton: action.noCloseButton,
        body: action.body ? action.body : model.body
      });
    } else {
      this.state.modals.push(action);
      this.setCleanerTimer();
    }
    this.forceUpdate();
  };

  setCleanerTimer = () => {
    const alertCleaner = () => {
      if (this.state.modals.length > 0) {
        this.state.modals
          .filter((a) => !a.closed)
          .forEach((a) => {
            if (a.timestamp && a.timeout) {
              a.closed = Date.now() - a.timestamp > (a.timeout || 3000);
            } else {
              a.closed = false;
            }
          });
        this.state.modals = this.state.modals.filter((a) => !a.closed);
        this.forceUpdate();
      } else if (this._timer) {
        clearInterval(this._timer);
        this._timer = null;
      }
    };

    if (!this._timer) {
      this._timer = setInterval(alertCleaner, 1000);
    }
  };

  setClose = (name: string) => {
    this.state.modals
      .filter((a) => a.name === name)
      .forEach((a) => {
        a.closed = true;
      });
    this.forceUpdate();
  };

  render() {
    const stateHasOpenModal = this.state.modals.some((modal) => !modal.closed);

    const boxes = stateHasOpenModal
      ? this.state.modals.map((action) => {
          let btnCancel = null;
          let btnConfirm = null;
          let modalFooter = null;

          if (action.name == 'term_select' && window.location.pathname.includes('create')) {
            this.setClose(action.name);
          }

          L.data('modal_box_action', action);
          const onCancel = () => {
            if (action.onCancel) {
              action.onCancel();
            }
            this.setClose(action.name);
          };

          const onConfirm = () => {
            if (action.onConfirm) {
              action.onConfirm();
            }
            if (action.closeOnConfirm === undefined || action.closeOnConfirm) {
              this.setClose(action.name);
            }
          };

          if (action.cancel) {
            btnCancel = (
              <button type="button" className={'btn btn-gray ' + action.cancelCss} onClick={onCancel}>
                {action.cancel || ''}
              </button>
            );
          }

          if (action.confirm) {
            btnConfirm = (
              <button
                type="button"
                className={'btn btn btn-green ' + action.confirmCss}
                disabled={action.confirmDisabled}
                onClick={onConfirm}
              >
                {action.confirm || ''}
              </button>
            );
          }

          if (!action.cancel && !action.confirm) {
            btnConfirm = (
              <button type="button" className={'btn btn-gray '} onClick={onCancel}>
                {'Tamam'}
              </button>
            );
          }

          modalFooter = (
            <div className="modal-footer" style={!action.cancel || !action.confirm ? { justifyContent: 'center' } : {}}>
              {btnCancel}
              {btnConfirm}
            </div>
          );
          const isSimple: boolean = (!action.className ||
            (action.className && action.className.indexOf('alert-simple') > -1)) as boolean;
          let titleMode1 = isSimple ? (
            <React.Fragment>
              <i className="material-icons mb-3" style={action.iconColor ? { color: action.iconColor } : {}}>
                {action.icon || ''}
              </i>
              <h3>{action.title || ''}</h3>
            </React.Fragment>
          ) : null;
          let titleMode2 = !isSimple ? (
            <h6 className="modal-title d-inline-flex align-items-center">{action.title || ''}</h6>
          ) : null;
          return (
            <Modal
              isOpen={!action.closed}
              key={'m-' + action.timestamp}
              modalClassName={action.className || 'alert-simple'}
            >
              <div className="modal-header border-0">
                {titleMode2}
                {!action.noCloseButton ? (
                  <button type="button" className="close" onClick={onCancel}>
                    <i className="material-icons">close</i>
                  </button>
                ) : null}
              </div>

              <div className={'modal-body ' + (action.bodyCss || ' text-center')}>
                {/* https://material.io/tools/icons/?icon=featured_play_list&style=baseline */}
                {titleMode1}
                {action.content || action.body || ''}
              </div>
              {modalFooter}
            </Modal>
          );
        })
      : null;

    return <div className="modal-container">{boxes}</div>;
  }
}

export default ModalBox;
