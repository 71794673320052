import * as Types from '../../store/types';

export const constants = {
  CLASSROOM_FEATURES_LIST_SEARCH: 'CLASSROOM_FEATURES:LIST_SEARCH',
  CLASSROOM_FEATURES_CREATE: 'CLASSROOM_FEATURES:CREATE',
  CLASSROOM_FEATURES_UPDATE: 'CLASSROOM_FEATURES:UPDATE',
  CLASSROOM_FEATURES_DELETE: 'CLASSROOM_FEATURES:DELETE',
  CLASSROOM_FEATURES_GET_BY_ID: 'CLASSROOM_FEATURES:GET_BY_ID'
  // CLASSROOM_FEATURES_GET_SELECT_OPTIONS: "CLASSROOM_FEATURES:GET_SELECT_OPTIONS",
  // CLASSROOM_FEATURES_GET_SESSION_ATTENDEE_SELECT_OPTIONS:
  // "CLASSROOM_FEATURES:GET_SESSION_ATTENDEE_SELECT_OPTIONS",
};

export const ClassroomFeaturesSearchInitialValues: Types.IFilterClassroomFeatures = {
  //   faculty_no: "A001",
  //   faculty_name: "",
  //   faculty_status: [],
  //   faculty_buildings: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const ClassroomFeaturesFormInitialValues: Types.IClassroomFeaturesItem = {
  feature_id: undefined,
  assigned_classrooms: [],
  name: '',
  description: ''
};

export const ClassroomFeatureOptions = [
  { label: 'Akıllı Tahta', value: 'AT' },
  { label: 'Bilgisayar', value: 'Bil' },
  { label: 'DVD', value: 'DVD' },
  { label: 'İnternet Erişimi', value: 'Int' },
  { label: 'Klima', value: 'Klima' },
  { label: 'Projektör', value: 'Proj' },
  { label: 'Sabit Perde', value: 'SbtPer' },
  { label: 'Ses Sistemi', value: 'SesSist' },
  { label: 'Video', value: 'Vid' }
];
