import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  ClassroomCombineTypes,
  CourseFormInitialValues,
  CourseTermLectureLocations,
  EducationTypeSelectOptions,
  TermTypes
} from '../../store/constants/course-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import { CombineExamFormValidation } from './validations/combine-exams-form-val';
import Translator from '../../services/translate-factory';
const T = Translator.create();
const equal = require('deep-equal');

const Logger = Log.create('CombineExamsModal');

class CombineExamsModal extends Component<Types.ICombineModalProps, Types.ICombineActivitiesDataModel> {
  state: Types.ICombineActivitiesDataModel = {
    term_id: -1,
    combined_courses: [],
    formValues: {
      name: '',
      course_code: '',
      activity_type: '',
      lesson_count: 0,
      programs: [],
      program_ids: [],
      instructors: [],
      instructor_ids: [],
      student_count: 0,
      week: '',
      section: ''
    }
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/);
    let id = regexObject ? parseInt(regexObject[0], 10) : -1;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onFormSave = (model: any, FormActions: FormikActions<Types.IActivityItem>) => {
    //let combinedCoursesArray: any = this.props.courseList && this.props.courseList.map((obj: any) => obj.id);
    let combinedCoursesArray: any = this.props.courseList && this.props.courseList.map(
      (obj: any) => obj.item.course_id
    );

    this.state.combined_courses = combinedCoursesArray;

    this.state.formValues.name = model.name;
    this.state.formValues.course_code = model.course_code;
    this.state.formValues.activity_type = model.activity_type;
    this.state.formValues.week = model.week;
    this.state.formValues.instructors = model.instructors;
    this.state.formValues.instructor_ids = model.instructor_ids;
    this.state.formValues.programs = model.programs;
    this.state.formValues.program_ids = model.program_ids;
    this.state.formValues.education_type = model.education_type;
    this.state.formValues.lecture_location_id = model.lecture_location_id;
    this.state.formValues.section = model.section;

    this.props.dispatch(
      Actions.ShowModal({
        title: 'Dersler Birleştirilecek',
        body: 'Seçtiğiniz dersler birleştirilecektir. Devam etmek istiyor musunuz?',
        name: 'examPeriod_courses_delete',
        icon: 'warning',
        iconColor: 'red',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (result && result.code) {
              let errors: any = {};
              if (result.details) {
                const validations: Array<Types.IValidationResponse> = result.details;
                validations.forEach((m: Types.IValidationResponse) => {
                  errors[m.field] = m.message[0];
                });
              }
              FormActions.setErrors(errors);
            }
            if (status === 200 || status === 201) {
              this.setCloseModal();
              this.onUpdateListFromModal();
            }
          };

          this.state.activity_no = this.props.courseObject ? this.props.courseObject.course_id : -1;

          if (this.props.showCoursesOnly) {
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_CREATE_COMBINE_ACTIVITES,
                this.state,
                'ep-course-list',
                resultCallback
              )
            );
          } else {
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_CREATE_COMBINE_ACTIVITES,
                this.state,
                'ep-course-list',
                resultCallback
              )
            );
          }
        }
      })
    );
    FormActions.setSubmitting(false);
  };

  static getDerivedStateFromProps(props: Types.ICombineModalProps, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;

    if (state.combineExamsModalIsOpen && !props.modalIsOpen) {
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  getCombinedExamsFieldsIfEqual = (): Types.ICombineActivitiesFormValues => {
    let fields: Types.ICombineActivitiesFormValues = Object.assign({}, this.state.formValues);

    if (this.props.courseList && this.props.courseList.length) {
      const names = this.props.courseList.map((course: any) => course.item.activityInfo.course_name);
      const name = names.join('; ');

      const course_codes = this.props.courseList.map((course: any) => course.item.activityInfo.course_code);
      const course_code = course_codes.join('; ');

      const _lesson_count = this.props.courseList.map((course: any) => course.item.activityInfo.lesson_count);
      const lesson_count = Math.max(..._lesson_count);

      const _student_count = this.props.courseList.map((course: any) => course.item.activityInfo.student_count);
      const student_count = _student_count.reduce((a, b) => a + b, 0);

      const _programs = this.props.courseList.map((course: any) => course.item.activityInfo.programs);
      const flattedPrograms = _programs.flat();
      // Removes dublicate objects with same 'value' key in an array. https://stackoverflow.com/a/56757215
      const programs = flattedPrograms.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);
      const program_ids = programs.map((program: any) => program && program.value);

      const _instructors = this.props.courseList.map((course: any) => course.item.activityInfo.instructors);
      const flattedStaff = _instructors.flat();

      const instructors = flattedStaff.filter((v, i, a) => a.findIndex((t) => t.value === v.value) === i);
      const instructor_ids = instructors.map((staff) => staff.value);

      const weeks = this.props.courseList.map((course: any) => course.item.activityInfo.week);
      const checkAllWeeksEqual = weeks.every((type: string) => type === weeks[0]);
      const week = checkAllWeeksEqual ? weeks[0] : '';

      const sections = this.props.courseList.map((course: any) => course.item.activityInfo.section);
      const section = sections.join('; ');

      const activity_types = this.props.courseList.map((course: any) => course.item.activityInfo.activity_type);
      const checkAllTypesEqual = activity_types.every((type: string) => type === activity_types[0]);
      const activity_type = checkAllTypesEqual ? activity_types[0] : '';

      let hasDifferentValues = (arr: any) =>
        !arr.every((item: any, i: any, ref: any) =>
          JSON.stringify([...ref].shift()).includes(JSON.stringify(item)));

      let educationTypesArray: any;
      let educationTypeObject;
      let educationTypeNew = this.props.courseList.map((i: any) => i.item.activityInfo.education_type)
      /* Check if user entered different naming for each education type */
      if (educationTypeNew.includes('N.Ö.') || educationTypeNew.includes('NO') || educationTypeNew.includes('N.O.') || educationTypeNew.includes('NÖ')) {
        educationTypeNew.forEach((e, i) => {
          if (e === 'N.Ö.' || e === 'NO' || e === 'N.O.' || e === 'NÖ') {
            educationTypeNew[i] = 'N.Ö.';
          }
        });
      }
      else if (educationTypeNew.includes('İ.Ö.') || educationTypeNew.includes('IO') || educationTypeNew.includes('İ.O.') || educationTypeNew.includes('İÖ')) {
        educationTypeNew.forEach((e, i) => {
          if (e === 'İ.Ö.' || e === 'IO' || e === 'İ.O.' || e === 'İÖ') {
            educationTypeNew[i] = 'İ.Ö.';
          }
        });
      }
      else if (educationTypeNew.includes('UO') || educationTypeNew.includes('U.Ö.') || educationTypeNew.includes('U.O.') || educationTypeNew.includes('UÖ') || educationTypeNew.includes('U.E.')) {
        educationTypeNew.forEach((e, i) => {
          if (e === 'UO' || e === 'U.Ö.' || e === 'U.O.' || e === 'UÖ' || e === 'U.E.') {
            educationTypeNew[i] = 'U.E.';
          }
        });
      }

      if (hasDifferentValues(educationTypeNew)) {
        educationTypesArray = null;
        educationTypeObject = undefined;
      } else {
        educationTypesArray = [this.props.courseList[0].item.activityInfo.education_type];
        while (educationTypesArray.includes('NO')) {
          educationTypesArray[educationTypesArray.indexOf('NO')] = 'N.Ö.'
        }
        educationTypeObject = EducationTypeSelectOptions.find((item) => educationTypesArray.includes(item.value));
      }

      let lecture_location_id;
      let lecture_location;
      let courseLocationNew = this.props.courseList.map((i: any) => i.item.activityInfo.lecture_location_id)
      if (hasDifferentValues(courseLocationNew)) {
        lecture_location_id = null;
        lecture_location = undefined;
      } else {
        lecture_location_id = this.props.courseList[0].item.activityInfo.lecture_location_id;
        lecture_location = {
          label: this.props.courseList[0].item.activityInfo.lecture_location_name,
          value: this.props.courseList[0].item.activityInfo.lecture_location_id
        };
      }

      fields = {
        name,
        course_code,
        lesson_count,
        student_count,
        programs,
        program_ids,
        instructors,
        instructor_ids,
        activity_type,
        week,
        section,
        educationType_object: educationTypeObject,
        lecture_location,
        lecture_location_id,
        education_type: educationTypeObject ? educationTypeObject.value : undefined
      };
    }
    return fields;
  };

  render() {
    let courseList = this.props.showCoursesOnly ? this.props.combinedDataList : this.props.courseList;
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...CourseTermLectureLocations, ...campusOptions];

    let fields = this.getCombinedExamsFieldsIfEqual();
    let formInitialValues = Object.assign({}, CourseFormInitialValues, fields);

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              Dersleri Birleştirme
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="white-container collapse show" id="advance-search" style={{ boxShadow: 'none' }}>
                  <Formik
                    initialValues={
                      this.props.showCoursesOnly
                        ? this.props.coursePeriodCourseObject
                          ? this.props.coursePeriodCourseObject
                          : Object.assign({}, formInitialValues)
                        : Object.assign({}, formInitialValues)
                    }
                    enableReinitialize={true}
                    validationSchema={CombineExamFormValidation}
                    onSubmit={(values, actions) => {
                      this.onFormSave(values, actions);
                    }}
                  >
                    {(props: FormikProps<Types.IActivityItem>) => {
                      const { values, handleChange, errors, handleBlur, handleSubmit, isSubmitting } = props;
                      const courseLocation = allSelectOptions.find(
                        (option) => option.value == props.values.lecture_location_id
                      );
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-input form-group date-picker">
                                <input
                                  id="course_code"
                                  name="course_code"
                                  value={values.course_code}
                                  onChange={handleChange}
                                  type="text"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="course_code">Kodu</label>
                                <ErrorMessage component="div" className="error" name="course_code" />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-input form-group date-picker">
                                <input
                                  id="name"
                                  name="name"
                                  value={values.name}
                                  onChange={handleChange}
                                  type="text"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="name">{T.t('gen_activity_name')}</label>
                                <ErrorMessage component="div" className="error" name="name" />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-input form-group date-picker">
                                <input
                                  id="activity_type"
                                  name="activity_type"
                                  value={values.activity_type}
                                  onChange={handleChange}
                                  type="text"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="activity_type">{T.t('gen_activity_type')}</label>
                                <ErrorMessage component="div" className="error" name="activity_type" />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-input form-group date-picker">
                                <input
                                  id="lesson_count"
                                  name="lesson_count"
                                  value={values.lesson_count}
                                  onChange={handleChange}
                                  type="number"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="lesson_count">{T.t('gen_lesson_count')}</label>
                                <ErrorMessage component="div" className="error" name="lesson_count" />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-input form-group date-picker">
                                <input
                                  id="student_count"
                                  name="student_count"
                                  value={values.student_count}
                                  onChange={handleChange}
                                  type="number"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="student_count">{T.t('gen_student_count')}</label>
                                <ErrorMessage component="div" className="error" name="student_count" />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-input form-group date-picker">
                                <input
                                  id="week"
                                  name="week"
                                  value={values.week}
                                  onChange={handleChange}
                                  type="text"
                                  required
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="week">{T.t('gen_week')}</label>
                                <ErrorMessage component="div" className="error" name="week" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_lecture_location')}</label>
                                  <Select
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={true}
                                    options={allSelectOptions}
                                    placeholder={T.t('gen_select_location')}
                                    value={
                                      courseLocation
                                      //props.values.lecture_location
                                      // ? props.values.lecture_location
                                      // : courseLocation
                                      //   ? courseLocation
                                      //   : null
                                    }
                                    onChange={(option: any) => {
                                      props.setFieldValue('lecture_location', option);
                                      props.setFieldValue('lecture_location_id', option && option.value);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                  />
                                </div>
                                {errors && errors.lecture_location_id && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_education_type')}</label>
                                  <Select
                                    className="react-select"
                                    isMulti={false}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={true}
                                    options={EducationTypeSelectOptions}
                                    placeholder={T.t('gen_select_education_type')}
                                    value={props.values.educationType_object}
                                    onChange={(option: any) => {
                                      props.setFieldValue('education_type', option.value);
                                      props.setFieldValue('educationType_object', option);
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                  />
                                </div>
                                {errors && errors.education_type && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>Programlar</label>
                                  <Select
                                    className="react-select"
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.programs
                                        ? this.props.selectOptions.programs
                                        : []
                                    }
                                    placeholder={T.t('gen_select_program')}
                                    value={props.values.programs}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('programs', list);
                                      props.setFieldValue(
                                        'program_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                  />
                                </div>
                                {errors && errors.program_ids && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="add-custom-tag mb-3">
                                <div className="react-select-container">
                                  <label>Öğretim Elemanları</label>
                                  <Select
                                    className="react-select"
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    options={
                                      this.props.selectOptions && this.props.selectOptions.instructors
                                        ? this.props.selectOptions.instructors
                                        : []
                                    }
                                    placeholder={T.t('gen_select_instructor')}
                                    value={props.values.instructors}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('instructors', list);
                                      props.setFieldValue(
                                        'instructor_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                  />
                                </div>
                                {errors && errors.instructor_ids && props.submitCount > 0 && (
                                  <div className="error">Boş bırakılamaz!</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-6"></div>
                            <div className="col-6 text-right">
                              <button
                                type="button"
                                className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2"
                                onClick={() => props.handleSubmit()}
                                disabled={isSubmitting}
                              >
                                {T.t('gen_save')}
                              </button>
                            </div>
                          </div>
                          <hr />
                          <div>
                            <h6>Birleştirilen Dersler</h6>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          Durum
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_activity_no')}
                        </th>
                        <th scope="col" className="text-center">
                          Kodu
                        </th>
                        <th scope="col" className="text-center">
                          Adı
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_activity')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_lesson_count')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_lesson_location')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_education_type')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_term')}
                        </th>
                        <th scope="col" className="text-center">
                          Program
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_grade')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_section')}
                        </th>
                        <th scope="col" className="text-center">
                          Öğretim Elemanları
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_student_count')}
                        </th>

                        <th scope="col" className="text-center">
                          {T.t('gen_week')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_description')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{courseList ? (
                      courseList.length ? (
                        courseList.map((courseObject: { id: number; item: Types.IExamPeriod_ActivityItem }) => {
                          let courseItem = courseObject.item.activityInfo;
                          const courseLocation = allSelectOptions.find(
                            (option) => option.value == (courseItem && courseItem.lecture_location_id)
                          );

                          let termObject = TermTypes.find((object) => object.value == (courseItem && courseItem.term));
                          return (
                            <tr
                              key={'course-' + (courseItem && courseItem.activity_no)}
                              data-title={courseItem && courseItem.course_name}
                            >
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    className={
                                      `small-tag text-uppercase` +
                                      (courseItem && courseItem.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(courseItem && courseItem.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_activity_no')}>
                                {courseItem && courseItem.activity_no !== undefined ? courseItem.activity_no : '-'}
                              </td>
                              <td scope="row" data-label={T.t('gen_code')}>
                                {courseItem && courseItem.course_code ? courseItem.course_code : '-'}
                              </td>
                              <td data-label={T.t('gen_name')}>{courseItem && courseItem.course_name}</td>
                              <td data-label={T.t('gen_activity')}>{courseItem && courseItem.activity_type}</td>
                              <td data-label={T.t('gen_lesson_count')} className="text-center">
                                {courseItem && courseItem.lesson_count}
                              </td>
                              <td scope="row" data-label={T.t('gen_lesson_location')} className="text-center">
                                {courseLocation ? courseLocation.label : ''}
                              </td>
                              <td data-label={T.t('gen_education_type')} className="text-center">
                                {courseItem && (courseItem.education_type == 'NO' || courseItem.education_type == 'N.Ö.' ?
                                  'Örgün Öğretim' :
                                  (courseItem.education_type == 'İ.Ö.' || courseItem.education_type == 'IO') ?
                                    'İkinci Öğretim' :
                                    (courseItem.education_type == 'U.Ö.' || courseItem.education_type == 'UO' || courseItem.education_type == 'U.E.') ?
                                      'Uzaktan Eğitim' :
                                      courseItem.education_type
                                )
                                }
                              </td>
                              <td data-label={T.t('gen_term')} className="text-center">
                                {/*termObject && termObject.label*/}
                                {courseItem && courseItem.term}
                              </td>
                              <td
                                data-cell="connected_program"
                                id={'connected_program-popover-' + (courseItem && courseItem.activity_no)}
                                style={{ textAlign: 'center' }}
                              >
                                {courseItem && courseItem.programs && courseItem && courseItem.programs.length > 0 && (
                                  <button className="multiple-info-tag small-tag group-ellipsis">
                                    {courseItem && courseItem.programs[0].label}
                                  </button>
                                )}
                                {courseItem && courseItem.programs && courseItem && courseItem.programs.length > 1 && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      +{courseItem && courseItem.programs.length - 1} Program</button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'connected_program-popover-' + (courseItem && courseItem.activity_no)}
                                    >
                                      <PopoverHeader>{T.t('gen_connected_programs')}</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">
                                        {courseItem &&
                                          courseItem.programs &&
                                          courseItem &&
                                          courseItem.programs.length > 0 &&
                                          courseItem &&
                                          courseItem.programs.map((connected_program: Types.ISelectOption) => {
                                            return (
                                              <React.Fragment key={'connected_program-' + connected_program.value}>
                                                <button className="multiple-info-tag small-tag text-capitalize">
                                                  {connected_program.label}
                                                </button>
                                                <br />
                                              </React.Fragment>
                                            );
                                          })}
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </td>
                              <td scope="row" data-label={T.t('gen_grade')} className="text-center">
                                {courseItem && courseItem.class}
                              </td>
                              <td scope="row" data-label={T.t('gen_section')} className="text-center">
                                {courseItem && courseItem.section}
                              </td>
                              <td
                                data-cell="lecture_staff"
                                id={'lecture_staff-popover-' + (courseItem && courseItem.activity_no)}
                                style={{ textAlign: 'center' }}
                              >
                                {courseItem &&
                                  courseItem.instructors &&
                                  courseItem &&
                                  courseItem.instructors.length > 0 && (
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {courseItem && courseItem.instructors[0].label}
                                    </button>
                                  )}
                                {courseItem &&
                                  courseItem.instructors &&
                                  courseItem &&
                                  courseItem.instructors.length > 1 && (
                                    <React.Fragment>
                                      <button className="multiple-info-tag small-tag group-ellipsis">
                                        +{courseItem && courseItem.instructors.length - 1} Kişi</button>
                                      <UncontrolledPopover
                                        trigger="hover focus"
                                        placement="bottom"
                                        target={'lecture_staff-popover-' + (courseItem && courseItem.activity_no)}
                                      >
                                        <PopoverHeader>{T.t('gen_theorical_instructors')}</PopoverHeader>
                                        <PopoverBody className="scrollable-popoverbody">
                                          {courseItem &&
                                            courseItem.instructors &&
                                            courseItem &&
                                            courseItem.instructors.length > 0 &&
                                            courseItem &&
                                            courseItem.instructors.map((instructors: Types.ISelectOption) => {
                                              return (
                                                <React.Fragment key={'lecture_staff-' + instructors.value}>
                                                  <button className="multiple-info-tag small-tag text-capitalize">
                                                    {instructors.label}
                                                  </button>
                                                  <br />
                                                </React.Fragment>
                                              );
                                            })}
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </React.Fragment>
                                  )}
                              </td>
                              <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                                {courseItem && courseItem.student_count}
                              </td>
                              <td scope="row" data-label={T.t('gen_week')} className="text-center">
                                {courseItem && courseItem.week}
                              </td>
                              <td
                                data-cell={T.t('gen_name')}
                                id={'description-popover-' + (courseItem && courseItem.course_code)}
                                style={{ textAlign: 'center' }}
                              >
                                {courseItem && courseItem.description && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {courseItem && courseItem.description}
                                    </button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'description-popover-' + (courseItem && courseItem.course_code)}
                                    >
                                      <PopoverHeader>{T.t('gen_description')}</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">
                                        {courseItem && courseItem.description}
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : null
                    ) : null}</tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.ICombineModalProps
): Types.ICombineModalProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICombineModalProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.activityPage
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CombineExamsModal);

export default container;
