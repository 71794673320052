import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import { Log } from 'ng2-logger';

import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import {
  InstructorSearchInitialValues,
  InvigilatorLevels,
  Titles,
  StaffTypes,
  DailyMaxClasses
} from '../../store/constants/instructor-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';

import InstructorForm from './instructor-form';
import IntegrationModal from './instructor-integration-modal';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import SortedColumn from '../../components/table/sorted-column';
import Paginate from '../../components/table/paginate';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import ImportModal, { ExcelImportKeys } from '../../components/excel-imports/import-modal';
import DownloadButton from '../../components/excel-imports/export';
import { SectionTypes } from '../../store/constants/enums';
import SyncInstructorModal from './instructor-sync-modal';

import Translator from '../../services/translate-factory';
const T = Translator.create();
const L = Log.create('InstructorListPage');

class InstructorTable extends Component<Types.IInstructorPageProps, Types.IInstructorPageState> {
  state: Types.IInstructorPageState = {
    filters: InstructorSearchInitialValues,
    filterIsOpen: false,
    instructorFormIsOpen: false,
    integrationModalIsOpen: false,
    instructorId: undefined,
    all_ids: [],
    selected_ids: []
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.searchInstructors();
    this.getInstructorSelectOptions();
    this.getProgramSelectOptions([]);
  }

  searchInstructors() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.instructor.INSTRUCTOR_LIST_SEARCH, this.state.filters, 'instructor-list-spin')
    );
  }

  getInstructorSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.instructor.INSTRUCTOR_GET_SELECT_OPTIONS, 'instructor-list-spin'));
  }

  getProgramSelectOptions = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.instructor.PROGRAMS_BY_FACULTYIDS_INSTRUCTOR_GET_SELECT_OPTIONS, facultyIds, 'instructor-list-spin'));
  }

  createStaffType(type: number) {
    let staffType = StaffTypes.find((item) => item.value == type);
    return staffType && staffType.label;
  }

  createBadge(degree: number) {
    switch (degree) {
      case 0:
        return (
          <span className="badge badge-danger" style={{ fontSize: '95%', textAlign: 'center' }}>
            Gözetmenlik Yapmaz
          </span>
        );
      case 1:
        return (
          <span className="badge badge-warning" style={{ fontSize: '95%' }}>
            Çok Düşük
          </span>
        );
      case 2:
        return (
          <span className="badge badge-warning" style={{ fontSize: '95%' }}>
            Düşük
          </span>
        );
      case 3:
        return (
          <span className="badge badge-info" style={{ fontSize: '95%' }}>
            Orta
          </span>
        );
      case 4:
        return (
          <span className="badge badge-success" style={{ fontSize: '95%' }}>
            Yüksek
          </span>
        );
      case 5:
        return (
          <span className="badge badge-success" style={{ fontSize: '95%' }}>
            Çok Yüksek
          </span>
        );
      default:
        return (
          <span className="badge badge-dark" style={{ fontSize: '95%' }}>
            Derece Verilmedi
          </span>
        );
    }
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchInstructors();
  };

  instructorFormIsOpen = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.INSTRUCTOR, '/create')));
  };

  instructorFormOnClose = (refresh: boolean) => {
    if (this.state.instructorFormIsOpen) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.INSTRUCTOR)));
    }
    if (refresh) {
      this.searchInstructors();
    }
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchInstructors();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, InstructorSearchInitialValues);
    this.setState(this.state);
    this.searchInstructors();
  };

  onFilterInstructor(model: Types.IFilterInstructor, FormActions: FormikActions<Types.IFilterInstructor>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchInstructors();
    FormActions.setSubmitting(false);
  }

  onInstructorEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.INSTRUCTOR, '/' + id)));
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.instructor.INSTRUCTOR_LIST_SEARCH,
            this.state.filters,
            'instructor-list-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onDeleteInstructor = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_action'),
          body: T.t('gen_delete_instructor_question'),
          name: 'instructor_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (status: number) => {
              if (status == 200) {
                this.state.selected_ids = [];
                this.state.filters = InstructorSearchInitialValues;
                this.setState(this.state);
                this.searchInstructors();
              }
            };

            const deleteList = this.state.selected_ids;
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.instructor.INSTRUCTOR_DELETE,
                deleteList,
                'instructor-list-spin',
                resultCallback
              )
            );
          }
        })
      );
    }
  };

  onSelectInstructor = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  switchIntegrationModalStatus = () => {
    this.setState({
      ...this.state,
      integrationModalIsOpen: !this.state.integrationModalIsOpen
    });
  };

  static getDerivedStateFromProps(props: Types.IInstructorPageProps, state: Types.IInstructorPageState) {
    let hasNewState: boolean = false;
    if (props.match && props.match.params.id) {
      hasNewState = true;
      state.instructorFormIsOpen = true;
      if (props.match.params.id !== 'create') {
        state.instructorId = props.match.params.id;
      } else {
        state.instructorId = undefined;
      }
    } else {
      hasNewState = true;
      state.instructorFormIsOpen = false;
      state.instructorId = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onInstructorImported = () => {
    this.searchInstructors();
  };

  showInfoModalCourseReport = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: 'Öğretim Üyesi Ders Raporu İndirilecek',
          body: 'Devam etmek istiyor musunuz?',
          name: 'examPeriod_courses_delete',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const model = {
              termId: this.props.termId,
              sectionType: 13
            };
            this.props.dispatch(Actions.ApiRequest(Constants.building.DOWNLOAD_ALL_DATA, model, ''));
          }
        })
      );
    }
  };

  render() {
    let instructorList = this.props.results;
    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="faculty-page">
          <div className="main list-campus">
            <div className="container-fluid">
              <h4 className="mb-4 mb-sm-0">{T.t('gen_instructors')}</h4>
              {/* TABLE STARTS HERE */}
              <Spinner name="instructor-list-spin" />
              <div className="white-container mt-4">
                <div className="row align-items-center mb-1">
                  <div className="col-md-6 col-sm-4 col-12">
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: []
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          className="category-tag-square float-left pr-2 pl-2"
                          style={{ margin: '5px', color: '#fff', backgroundColor: '#dc3545' }}
                          onClick={this.onDeleteInstructor}
                        >
                          <i className="material-icons mr-2">delete_outline</i>
                          <span> {T.t('gen_delete_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button className="category-tag-square tag-green" onClick={this.instructorFormIsOpen}>
                          <i className="material-icons mr-2">add</i>
                          {T.t('gen_add_instructor')}
                        </button>
                        <button
                          className="category-tag-square tag-green ml-1"
                          onClick={this.switchIntegrationModalStatus}
                        >
                          <i className="material-icons mr-2">playlist_add</i>
                          Senkronizasyon İşlerini Listele
                        </button>
                        <ImportModal
                          title={T.t('gen_add_with_excel')}
                          componentKey={ExcelImportKeys.Instructors}
                          dispatch={this.props.dispatch}
                          onImport={this.onInstructorImported}
                        />
                        <DownloadButton title={T.t('gen_instructors')} sectionType={SectionTypes.Instructors} />
                        <button
                          className="category-tag-square tag-green ml-1"
                          onClick={this.showInfoModalCourseReport}
                        >
                          <i className="material-icons mr-2" style={{ color: 'white' }}>cloud_download</i>
                          {T.t('gen_instructors_course_report')}
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-8 col-12 text-right">
                    <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                      <button
                        className="category-tag-square tag-glass float-right ml-3 mr-3"
                        style={{ margin: '5px' }}
                        onClick={() => {
                          this.state.filterIsOpen = !this.state.filterIsOpen;
                          this.setState(this.state);
                        }}
                      >
                        <i className="material-icons mr-2">filter_list</i>
                        <span>{T.t('gen_filter')}</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* TODO: FILTER STARTS HERE  */}
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3" style={{}}>
                    <Formik
                      initialValues={InstructorSearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterInstructor(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterInstructor>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="instructor_code"
                                    name="instructor_code"
                                    value={props.values.instructor_code}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="instructor_code">Kodu</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="name"
                                    name="name"
                                    value={props.values.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">Adı</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_title')}</label>
                                  <Select
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={false}
                                    options={Titles}
                                    placeholder={T.t('gen_select_title')}
                                    value={props.values.titles ? props.values.titles : null}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('titles', list);
                                      props.setFieldValue(
                                        'title_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_title')}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="react-select-container">
                                  <label>{T.t('gen_daily_max_course')}</label>
                                  <Select
                                    className="react-select"
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    options={DailyMaxClasses}
                                    placeholder={T.t('gen_select_daily_max_classes')}
                                    value={props.values.daily_max_classes}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('daily_max_classes', list);
                                      props.setFieldValue(
                                        'daily_max_classes_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_daily_max_classes')}
                                  />
                                </div>
                              </div>
                              {this.props.term_type === 1 ? (
                                <React.Fragment>
                                  <div className="col-md-4">
                                    <div className="form-input form-group date-picker">
                                      <input
                                        id="max_invigilation_duty"
                                        name="max_invigilation_duty"
                                        value={props.values.max_invigilation_duty}
                                        onChange={props.handleChange}
                                        type="number"
                                        required
                                      />
                                      <span className="highlight" />
                                      <span className="bar" />
                                      <label htmlFor="max_invigilation_duty">{T.t('gen_max_invigilation_count')}</label>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-input form-group date-picker">
                                      <input
                                        id="min_class_interval_bw_campuses"
                                        name="min_class_interval_bw_campuses"
                                        value={props.values.min_class_interval_bw_campuses}
                                        onChange={props.handleChange}
                                        type="number"
                                        required
                                      />
                                      <span className="highlight" />
                                      <span className="bar" />
                                      <label htmlFor="min_class_interval_bw_campuses">
                                        {T.t('gen_min_gap_between_campuses')}
                                      </label>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : (
                                <div className="col-md-4">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_faculty_of_duty')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.faculties
                                          ? this.props.selectOptions.faculties
                                          : []
                                      }
                                      placeholder={T.t('gen_select_faculty')}
                                      value={props.values.faculties}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('faculties', list);
                                        props.setFieldValue(
                                          'faculty_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="col-md-4">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_program_of_duty')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.programs
                                          ? this.props.selectOptions.programs
                                          : []
                                      }
                                      placeholder={T.t('gen_select_program')}
                                      value={props.values.programs}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('programs', list);
                                        props.setFieldValue(
                                          'program_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="react-select-container">
                                  <label>{T.t('gen_staff')}</label>
                                  <Select
                                    className="react-select"
                                    isMulti={true}
                                    filterOption={(option: any, query: any) =>
                                      option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                    }
                                    closeMenuOnSelect={false}
                                    options={StaffTypes}
                                    placeholder={T.t('gen_select_staff_type')}
                                    value={props.values.staff_types ? props.values.staff_types : null}
                                    onChange={(
                                      options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                    ) => {
                                      const list: Array<Types.ISelectOption> = options
                                        ? (options as Array<Types.ISelectOption>)
                                        : [];
                                      props.setFieldValue('staff_types', list);
                                      props.setFieldValue(
                                        'staff_type_ids',
                                        list.map((item) => item.value)
                                      );
                                    }}
                                    noOptionsMessage={(): string => T.t('gen_select_no_staff_type')}
                                  />
                                </div>
                              </div>
                              {this.props.term_type === 1 ? (
                                <React.Fragment>
                                  <div className="col-md-2">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_invigilation_level')}</label>
                                      <Select
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                        }
                                        closeMenuOnSelect={false}
                                        options={InvigilatorLevels}
                                        placeholder={T.t('gen_select_level')}
                                        value={props.values.invigilator_levels ? props.values.invigilator_levels : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('invigilator_levels', list);
                                          props.setFieldValue(
                                            'invigilator_level_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_level')}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="add-custom-tag mb-3">
                                      <div className="react-select-container">
                                        <label>{T.t('gen_invigilation_campuses')}</label>
                                        <Select
                                          className="react-select"
                                          isMulti={true}
                                          filterOption={(option: any, query: any) =>
                                            option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                          }
                                          closeMenuOnSelect={false}
                                          options={
                                            this.props.selectOptions && this.props.selectOptions.campuses
                                              ? this.props.selectOptions.campuses
                                              : []
                                          }
                                          placeholder={T.t('gen_select_campus')}
                                          value={
                                            props.values.invigilation_campuses
                                              ? props.values.invigilation_campuses
                                              : null
                                          }
                                          onChange={(
                                            options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                          ) => {
                                            const list: Array<Types.ISelectOption> = options
                                              ? (options as Array<Types.ISelectOption>)
                                              : [];
                                            props.setFieldValue('invigilation_campuses', list);
                                            props.setFieldValue(
                                              'invigilation_campus_ids',
                                              list.map((item) => item.value)
                                            );
                                          }}
                                          noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="add-custom-tag mb-3">
                                      <div className="react-select-container">
                                        <label>{T.t('gen_invigilation_faculties')}</label>
                                        <Select
                                          className="react-select"
                                          isMulti={true}
                                          filterOption={(option: any, query: any) =>
                                            option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                          }
                                          closeMenuOnSelect={false}
                                          options={
                                            this.props.selectOptions && this.props.selectOptions.faculties
                                              ? this.props.selectOptions.faculties
                                              : []
                                          }
                                          placeholder={T.t('gen_select_faculty')}
                                          value={
                                            props.values.invigilation_faculties
                                              ? props.values.invigilation_faculties
                                              : null
                                          }
                                          onChange={(
                                            options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                          ) => {
                                            const list: Array<Types.ISelectOption> = options
                                              ? (options as Array<Types.ISelectOption>)
                                              : [];
                                            props.setFieldValue('invigilation_faculties', list);
                                            props.setFieldValue(
                                              'invigilation_faculty_ids',
                                              list.map((item) => item.value)
                                            );
                                            this.getProgramSelectOptions(list.map(item => item.value));
                                          }}
                                          noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="add-custom-tag mb-3">
                                      <div className="react-select-container">
                                        <label>{T.t('gen_invigilation_programs')}</label>
                                        <Select
                                          className="react-select"
                                          isMulti={true}
                                          filterOption={(option: any, query: any) =>
                                            option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                                          }
                                          closeMenuOnSelect={false}
                                          options={this.props.programs_related_faculty}
                                          placeholder={T.t('gen_select_program')}
                                          value={
                                            props.values.invigilation_programs ? props.values.invigilation_programs : null
                                          }
                                          onChange={(
                                            options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                          ) => {
                                            const list: Array<Types.ISelectOption> = options
                                              ? (options as Array<Types.ISelectOption>)
                                              : [];
                                            props.setFieldValue('invigilation_programs', list);
                                            props.setFieldValue(
                                              'invigilation_program_ids',
                                              list.map((item) => item.value)
                                            );
                                          }}
                                          noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ) : null}
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <div className="text-left">
                                  <h6>{T.t('gen_status')}</h6>
                                  <div className="tick-radio position-relative mb-3 d-inline-block">
                                    <MultipleCheckbox name="status" text={T.t('gen_active')} value={1} />
                                  </div>
                                  <div className="tick-radio position-relative mb-3 d-inline-block ml-4">
                                    <MultipleCheckbox name="status" text={T.t('gen_passive')} value={0} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                      <thead>
                        <tr>
                          <th data-cell="select">
                            <div className="tick-radio position-relative">
                              <input
                                type="checkbox"
                                className="form-radio"
                                checked={this.checkAllIdsSelected()}
                                onChange={this.onSelectAll}
                              />
                            </div>
                          </th>
                          <SortedColumn
                            datacell="status"
                            title={T.t('gen_status')}
                            sortkey="status"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="instructor_code"
                            title={T.t('gen_code')}
                            sortkey="instructor_code"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="staff_type"
                            title={T.t('gen_staff_type')}
                            sortkey="staff_type"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="title"
                            title={T.t('gen_title')}
                            sortkey="title"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="name"
                            className="d-none d-lg-table-cell d-xl-table-cell"
                            title={T.t('gen_name_surname')}
                            sortkey="name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="email"
                            className="d-none d-lg-table-cell d-xl-table-cell"
                            title="Email"
                            sortkey="email"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">
                            {T.t('gen_cellphone')}
                          </th>

                          <SortedColumn
                            datacell="faculty_of_duty"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_faculty_of_duty')}
                            sortkey="faculty_of_duty"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="program_of_duty"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_program_of_duty')}
                            sortkey="program_of_duty"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="daily_max_class"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_daily_max_course')}
                            sortkey="daily_max_class"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          {this.props.term_type === 1 ? (
                            <React.Fragment>
                              <SortedColumn
                                datacell="max_invigilation_duty"
                                className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                title={T.t('gen_max_invigilation_count')}
                                sortkey="max_invigilation_duty"
                                sortedcolumn={this.state.filters.order_by}
                                sort={this.sort}
                              />
                              <SortedColumn
                                datacell="invigilator_level"
                                className="d-none d-lg-table-cell d-xl-table-cell text-center"
                                title={T.t('gen_invigilation_level')}
                                sortkey="invigilator_level"
                                sortedcolumn={this.state.filters.order_by}
                                sort={this.sort}
                              />
                              <th scope="col" className="text-center">
                                {T.t('gen_invigilation_campuses')}
                              </th>
                              <th scope="col" className="text-center">
                                {T.t('gen_invigilation_faculties')}
                              </th>
                              <th scope="col" className="text-center">
                                {T.t('gen_invigilation_programs')}
                              </th>
                            </React.Fragment>
                          ) : null}
                          <th scope="col" className="text-center">
                            {T.t('gen_description')}
                          </th>
                          <th scope="col" className="text-right">
                            <span className="text-right">{T.t('gen_actions')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {instructorList && instructorList.length
                          ? instructorList.map((item: Types.IInstructorItem) => (
                            <tr key={'instructor-' + item.instructor_code} data-title={item.name}>
                              <td data-cell="select">
                                <div className="tick-radio position-relative">
                                  <input
                                    type="checkbox"
                                    className="form-radio"
                                    checked={
                                      this.state.selected_ids &&
                                      this.state.selected_ids.indexOf(item.instructor_id ? item.instructor_id : -1) >
                                      -1
                                    }
                                    data-id={item.instructor_id}
                                    onChange={this.onSelectInstructor}
                                  />
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_status')}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    className={
                                      `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                    }
                                  >
                                    {GT.GetActiveStatus(item.status)}
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_code')}>
                                {item.instructor_code}
                              </td>
                              <td data-label={T.t('gen_staff_type')}>
                                {this.createStaffType(item.staff_type ? item.staff_type : -1)}
                              </td>
                              <td data-label={T.t('gen_title')}>{item.title}</td>
                              <td data-label={T.t('gen_name')}>{item.name}</td>
                              <td data-label="Email">{item.email}</td>
                              <td data-label={T.t('gen_cellphone')}>{item.mobile}</td>
                              <td data-label={T.t('gen_faculty_of_duty')} className="text-center">
                                {item.faculty_of_duty && item.faculty_of_duty.label}
                              </td>
                              <td data-label={T.t('gen_program_of_duty')} className="text-center">
                                {item.program_of_duty && item.program_of_duty.label}
                              </td>
                              <td data-label={T.t('gen_daily_max_course')} className="text-center">
                                {item.daily_max_class}
                              </td>
                              {this.props.term_type === 1 ? (
                                <React.Fragment>
                                  <td data-label={T.t('gen_max_invigilation_count')} className="text-center">
                                    {item.max_invigilation_duty}
                                  </td>
                                  <td data-label={T.t('gen_invigilation_level')} style={{ textAlign: 'center' }}>
                                    {this.createBadge(item.invigilator_level != null ? item.invigilator_level : -1)}
                                  </td>
                                  <td
                                    data-cell="invigilation_campuses"
                                    id={'invigilation_campuses-popover-' + item.instructor_id}
                                    style={{ textAlign: 'center' }}
                                  >
                                    {item.invigilation_campuses && item.invigilation_campuses.length > 0 ? (
                                      <button className="multiple-info-tag small-tag group-ellipsis">
                                        {item.invigilation_campuses[0].label}
                                      </button>
                                    ) : (
                                      '-'
                                    )}
                                    {item.invigilation_campuses && item.invigilation_campuses.length > 1 && (
                                      <React.Fragment>
                                        <button className="multiple-info-tag small-tag group-ellipsis">
                                          +{item.invigilation_campuses.length - 1} {T.t('gen_campus')}
                                        </button>
                                        <UncontrolledPopover
                                          trigger="hover focus"
                                          placement="bottom"
                                          target={'invigilation_campuses-popover-' + item.instructor_id}
                                        >
                                          <PopoverHeader>{T.t('gen_invigilation_campuses')}</PopoverHeader>
                                          <PopoverBody className="scrollable-popoverbody">
                                            {item.invigilation_campuses &&
                                              item.invigilation_campuses.length > 0 &&
                                              item.invigilation_campuses.map((location: Types.ISelectOption) => {
                                                return (
                                                  <React.Fragment key={'invigilation_campuses-' + location.value}>
                                                    <button className="multiple-info-tag small-tag text-capitalize">
                                                      {location.label}
                                                    </button>
                                                    <br />
                                                  </React.Fragment>
                                                );
                                              })}
                                          </PopoverBody>
                                        </UncontrolledPopover>
                                      </React.Fragment>
                                    )}
                                  </td>
                                  <td
                                    data-cell="invigilation_faculties"
                                    id={'invigilation_faculties-popover-' + item.instructor_id}
                                    style={{ textAlign: 'center' }}
                                  >
                                    {item.invigilation_faculties && item.invigilation_faculties.length > 0 ? (
                                      <button className="multiple-info-tag small-tag group-ellipsis">
                                        {item.invigilation_faculties[0].label}
                                      </button>
                                    ) : (
                                      '-'
                                    )}
                                    {item.invigilation_faculties && item.invigilation_faculties.length > 1 && (
                                      <React.Fragment>
                                        <button className="multiple-info-tag small-tag group-ellipsis">
                                          +{item.invigilation_faculties.length - 1} {T.t('gen_faculty')}
                                        </button>
                                        <UncontrolledPopover
                                          trigger="hover focus"
                                          placement="bottom"
                                          target={'invigilation_faculties-popover-' + item.instructor_id}
                                        >
                                          <PopoverHeader>{T.t('gen_invigilation_faculties')}</PopoverHeader>
                                          <PopoverBody className="scrollable-popoverbody">
                                            {item.invigilation_faculties &&
                                              item.invigilation_faculties.length > 0 &&
                                              item.invigilation_faculties.map((location: Types.ISelectOption) => (
                                                <React.Fragment key={'invigilation_faculties-' + location.value}>
                                                  <button className="multiple-info-tag small-tag text-capitalize">
                                                    {location.label}
                                                  </button>
                                                  <br />
                                                </React.Fragment>
                                              ))}
                                          </PopoverBody>
                                        </UncontrolledPopover>
                                      </React.Fragment>
                                    )}
                                  </td>
                                  <td
                                    data-cell="invigilation_programs"
                                    id={'invigilation_programs-popover-' + item.instructor_id}
                                    style={{ textAlign: 'center' }}
                                  >
                                    {item.invigilation_programs && item.invigilation_programs.length > 0 ? (
                                      <button className="multiple-info-tag small-tag group-ellipsis">
                                        {item.invigilation_programs[0].label}
                                      </button>
                                    ) : (
                                      '-'
                                    )}
                                    {item.invigilation_programs && item.invigilation_programs.length > 1 && (
                                      <React.Fragment>
                                        <button className="multiple-info-tag small-tag group-ellipsis">
                                          +{item.invigilation_programs.length - 1} {T.t('gen_faculty')}
                                        </button>
                                        <UncontrolledPopover
                                          trigger="hover focus"
                                          placement="bottom"
                                          target={'invigilation_programs-popover-' + item.instructor_id}
                                        >
                                          <PopoverHeader>{T.t('gen_invigilation_programs')}</PopoverHeader>
                                          <PopoverBody className="scrollable-popoverbody">
                                            {item.invigilation_programs &&
                                              item.invigilation_programs.length > 0 &&
                                              item.invigilation_programs.map((location: Types.ISelectOption) => (
                                                <React.Fragment key={'invigilation_programs-' + location.value}>
                                                  <button className="multiple-info-tag small-tag text-capitalize">
                                                    {location.label}
                                                  </button>
                                                  <br />
                                                </React.Fragment>
                                              ))}
                                          </PopoverBody>
                                        </UncontrolledPopover>
                                      </React.Fragment>
                                    )}
                                  </td>
                                </React.Fragment>
                              ) : null}
                              <td
                                data-cell="description"
                                id={'description-popover-' + item.instructor_id}
                                style={{ textAlign: 'center' }}
                              >
                                {item.description && (
                                  <React.Fragment>
                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                      {item.description}
                                    </button>
                                    <UncontrolledPopover
                                      trigger="hover focus"
                                      placement="bottom"
                                      target={'description-popover-' + item.instructor_id}
                                    >
                                      <PopoverHeader>{T.t('gen_description')}</PopoverHeader>
                                      <PopoverBody className="scrollable-popoverbody">{item.description}</PopoverBody>
                                    </UncontrolledPopover>
                                  </React.Fragment>
                                )}
                              </td>
                              <td data-label={T.t('gen_actions')} className="table-buttons">
                                <div className="table-buttons-wrapper">
                                  <button
                                    data-toggle="tooltip"
                                    data-id={item.instructor_id}
                                    onClick={this.onInstructorEdit}
                                    title="Düzenle"
                                    className="btn btn-light btn-sm table-button"
                                  >
                                    <span className="d-block" data-toggle="modal" data-target="#addNew">
                                      <i className="material-icons">edit</i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                    <div className="row-options justify-content-end">
                      <div
                        className="page-sorting d-flex align-items-center justify-content-center"
                        style={{ marginTop: '5px' }}
                      >
                        {this.props.results && this.props.results.length > 0 && (
                          <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
        <InstructorForm
          instructorId={this.state.instructorId}
          formIsOpen={this.state.instructorFormIsOpen}
          onClose={this.instructorFormOnClose}
        />
        {this.state.integrationModalIsOpen ? (
          <SyncInstructorModal
            modalIsOpen={this.state.integrationModalIsOpen}
            onClose={this.switchIntegrationModalStatus}
            onUpdateList={() => this.searchInstructors()}
          />
        ) : null}
      </MainLayout>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IInstructorPageProps
): Types.IInstructorPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IInstructorPageProps = Object.assign({}, ownProps, {
    results: store.state.instructor_page && store.state.instructor_page.results,
    filters: store.state.instructor_page && store.state.instructor_page.filters,
    selectOptions: store.state.select_options && store.state.select_options.instructorPage,
    term_type: store.state.term_type,
    termId: store.state.term_id,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  // if (next.state.instructor_page) {
  //   return (
  //     !!equal(
  //       prev.state.instructor_page && prev.state.instructor_page.results,
  //       next.state.instructor_page && next.state.instructor_page.results
  //     ) &&
  //     !!equal(
  //       prev.state.select_options && prev.state.select_options.instructorPage,
  //       next.state.select_options && next.state.select_options.instructorPage
  //     )
  //   );
  // } else {
  //   return true;
  // }
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(InstructorTable);

export default container;
