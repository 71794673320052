import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/constants/all';
import * as Actions from '../../store/actions/general';
import * as Types from '../../store/types';
import { Formik, FormikActions, FormikProps, ErrorMessage } from 'formik';
import PwFormValidation from './validations/pw-form-val';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import { Log } from 'ng2-logger';

const L = Log.create('PasswordForm');

class PasswordForm extends React.Component<Types.ILoginPageProps, Types.IForgotView> {
  state: Types.IForgotView = {
    password: '',
    password_confirm: '',
    token: '',
    account_id: '',
    email: '',
    usernameOrEmail: ''
  };

  onSubmit = (model: Types.IForgotView, LoginActions: FormikActions<Types.IForgotView>) => {
    if (this.props.match && this.props.match.params) {
      model.token = this.props.match.params.token;
    }
    let newModel = {
      code: this.props.code,
      newPassword: model.password,
      newPasswordConfirm: model.password_confirm
    }
    this.props.dispatch(Actions.ApiRequest(actions.login.LOGIN_RESET_PASSWORD, newModel, 'login-form-spin'));
    LoginActions.setSubmitting(false);
  };

  goLogin = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN)));
  };

  render() {
    return (
      <React.Fragment>
        <h1>Sadece bir adım kaldı!</h1>
        <p>Lütfen yeni şifrenizi tanımlayın ve güncelleyin. </p>
        <Formik initialValues={this.state} onSubmit={this.onSubmit} validationSchema={PwFormValidation}>
          {(props: FormikProps<Types.IForgotView>) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <div className="form-group">
                  <div className="form-input">
                    <input
                      id="password"
                      type="password"
                      value={props.values.password}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label>Yeni Şifre</label>
                  </div>
                  <ErrorMessage component="div" className="error" name="password" />
                </div>
                <div className="form-group">
                  <div className="form-input">
                    <input
                      id="password_confirm"
                      type="password"
                      value={props.values.password_confirm}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label>Yeni Şifre (Tekrar)</label>
                  </div>
                  <ErrorMessage component="div" className="error" name="password_confirm" />
                </div>

                <div className="row align-items-center">
                  <div className="col">
                    <a href="javascript:;" onClick={this.goLogin}>
                      <i className="material-icons mr-3">keyboard_backspace</i>Geri
                    </a>
                  </div>
                  <div className="col text-right">
                    <button className="btn btn-primary" type="submit" disabled={props.isSubmitting}>
                      Şifremi Güncelle<i className="material-icons">arrow_forward</i>
                    </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ILoginPageProps): Types.ILoginPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }

  const newProps: any = Object.assign({}, ownProps, {
    account: store.state.account,
    definitions: store.state.definitions
  });

  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps)(PasswordForm);

export default container;
